<template>
  <div class="home-header">
    <div class="header-ico" @click="gohome"></div>
    <!-- <div class="area" @click="gospecial">
      <img src="~@/assets/img/shouye/home-s.png" alt="" />
      <span class="nav-title">专题</span>
    </div> -->
    <div class="year">
      <img src="~@/assets/img/shouye/rili-3.png" alt="" />
      <el-select
        v-model="queryYear"
        placeholder="请选择"
        @change="handleYearChange"
        size="mini"
      >
        <el-option
          v-for="value in yearOptionList"
          :key="value"
          :label="value"
          :value="value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="title">{{ currentUser }}大学生思想现状调查</div>
    <div class="date">
      {{ dateYear }}
    </div>  
    <div class="time">
      {{ dateDay }}
    </div>
    <div class="login-right" @click="handleClick" ref="toggleButton">
      <!-- <span class="iconfont" :class="avatar ? '' : 'icon-geren'" v-if="!avatar"></span>
      <img :src="avatar" alt="" class="border" v-if="avatar"/> -->
        <el-avatar :size="20" :src="avatar" icon="el-icon-user-solid">
        </el-avatar>
      <span class="titleUserName">{{ this.userName }}</span>
    </div>
    <div class="window" v-show="openQuit" ref="popup">
      <!-- <div class="handoff" @click="handoff">
        <i class="iconfont icon-manage-circuit"> </i>
        <span> 专题切换</span>
      </div> -->
      <div class="quit" @click="lineOut">
        <i class="iconfont icon-tuichu1"> </i>
        <span> 退出</span>
      </div>
    </div>
  </div>
</template>
<script>
import { formatTime } from "@/utils";
import { mapGetters, mapActions } from "vuex";
import { showYear } from "../../src/api/api";
import { ACCESS_TOKEN } from "@/store/mutation-types";
export default {
  props: {
    value: Number,
  },
  data() {
    return {
      queryYear: 2021,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      yearOptionList: [],
      userName: "",
      currentUser: "",
      openQuit: false,
      avatar: "",
    };
  },
  created() {
    this.queryYear = this.value;
    this.userName = JSON.parse(localStorage.getItem("user")).name;
    this.currentUser = JSON.parse(localStorage.getItem("info")).orgName;
    this.avatar = JSON.parse(localStorage.getItem("avatar"));
  },
  async mounted() {
    let resp = await showYear();
    this.yearOptionList = resp.data.list;
    this.timeFn();
  },
  methods: {
    // 111111111
    ...mapActions([
      "Logout",
      "setUserName",
      "setTargetLevel1",
      "setCity",
      "setSchool",
    ]),
    // 撤销监听事件
     hide() {
      document.removeEventListener('click', this.hidePanel, false)
    },
    // 不是指定元素直接关闭
     hidePanel(e) {
      if (!this.$refs.toggleButton.contains(e.target) && !this.$refs.popup.contains(e.target)) {
        this.openQuit = false
        this.hide()
      }
    },
    // 弹窗函数
    handleClick(){
      this.openQuit=!this.openQuit
      if(this.openQuit){
        document.addEventListener('click', this.hidePanel, false)
      }else{
        this.hide()
      }
    },
    gohome() {
      this.setCity("");
      this.setSchool("");
      this.$router.push({
        name: "portal",
      });
    },
    lineOut() {
      const _that = this;
      this.$confirm("确认退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
        customClass: "reLoginBox",
      })
        .then(() => {
          console.log(111);
          // debugger;
          _that.Logout({ token: this.$ls.get(ACCESS_TOKEN) }).then(() => {
            console.log(222);
            _that.logoutVisible = false;
            _that.setUserName("");
            _that.$router.push({
              name: "portal",
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH:mm:ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    // gohome() {
    //   this.$router.push({
    //     name: "portal",
    //   });
    // },
    gospecial() {
      this.$router.push({
        name: "special",
      });
    },
    handleYearChange(value) {
      this.$emit("year-change", value);
    },
    handoff() {
      this.$router.push({
        name: "special",
      });
    },
  },
  computed: {
    ...mapGetters(["getTargetLevel1"]),
  },
};
</script>
<style lang="scss">
.el-avatar{
  // margin-top: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.home-header {
  height: 0.75rem;
  background-image: url("~@/assets/img/shengfen/top0.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* display: flex;
  justify-content: space-between; */
  color: #fff;
  position: relative;
  .header-ico {
    background-image: url("~@/assets/img/shengfen/ico.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 1.5rem;
    height: 0.4rem;
    position: absolute;
    top: 20%;
    left: 1%;
    cursor: pointer;
  }
  .area {
    width: 1.5rem;
    font-size: 0.24rem;
    position: absolute;
    left: 2.5rem;
    top: 0.1rem;
    height: 0.58rem;
    line-height: 0.58rem;
    text-align: center;
    cursor: pointer;
    background-color: #5f0707;
    img {
      height: 0.25rem;
      display: inline;
      align-self: center;
      float: left;
      margin-top: 0.15rem;
      margin-left: 0.3rem;
    }
    .nav-title {
      float: left;
      margin-left: 0.1rem;
    }
  }
  .year {
    //
    width: 2rem;
    font-size: 0.24rem;
    position: absolute;
    left: 4.5rem;
    top: 0.1rem;
    height: 0.58rem;
    line-height: 0.58rem;
    font-size: 0.24rem;
    cursor: pointer;
    display: flex;
    img {
      height: 0.25rem;
      display: inline;
      align-self: center;
      float: left;
      margin-left: 0.3rem;
    }
    .el-select {
      width: 2rem;
      height: 100%;
      .el-input__inner {
        font-size: 0.24rem;
        background-color: transparent;
        border: 0px;
        color: #fff;
        // line-height: 0.58rem;
        // margin-bottom: 0.1rem;
      }
      .el-input__suffix {
        .el-input__suffix-inner {
          float: left;
          margin-top: -0.01rem;
        }
      }
    }
  }

  .title {
    width: 6rem;
    height: 0.9rem;
    line-height: 0.9rem;
    text-align: center;
    font-size: 0.4rem;
    letter-spacing: 0.08rem;
    font-family: "微软雅黑";
    font-weight: bold;
    color: #dbe5fa;
    line-height: 1.3rem;
    position: absolute;
    right: 37.5%;
    top: -0.3rem;
    background-image: linear-gradient(to bottom, #fff, #e9bfc1);
    -webkit-background-clip: text;
    color: transparent;
  }
  .weather {
    width: 1.5rem;
    font-size: 0.24rem;
    position: absolute;
    // position: fixed;
    right: 4.5rem;
    top: 0.22rem;
  }
  .date {
    width: 2rem;
    font-size: 0.24rem;
    position: absolute;
    right: 4rem;
    top: 0.22rem;
  }
  .time {
    width: 2rem;
    font-size: 0.24rem;
    position: absolute;
    right: 2.5rem;
    top: 0.22rem;
  }
}
.el-select-dropdown {
  border: 1px solid #5a0911 !important;
  background-color: transparent !important;

  .el-select-dropdown__item.selected {
    color: #5a0911;
  }
  .el-select-dropdown__item {
    color: #fff;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #1f0405 !important;
    // background-color: transparent !important;
  }
  &.el-popper[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: #5a0911 !important;
  }
  &.el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: #5a0911 !important;
  }
}
.userName {
  position: absolute;
  right: 0.3rem;
  top: 0.1rem;
  display: inline-block;
  width: 1.5rem;
  text-align: center;
  border-radius: 30px;
  height: 0.6rem;
  line-height: 0.6rem;
  /*background-color: #dbe5fa;*/
  border: 1px solid #fff;
  cursor: pointer;
}
.lineOut {
  // position: absolute;
  // right: 0.3rem;
  // top: 0.1rem;
  // width: 1rem;
  // height: 0.5rem;
  line-height: 0.7rem;
  text-align: center;
  // padding-left: 0.03rem;
  // background-color: #5f0707;
  // border-radius: 5px;
  cursor: pointer;
}
.titleUserName {
  // position: absolute;
  // right: 0.65rem;
  // top: 0.05rem;
  display: inline-block;
  // width: 1.5rem;
  text-align: center;
  // height: 0.6rem;
  line-height: 0.7rem;
  /*background-color: #dbe5fa;*/
  padding-left: 00.1rem;
  margin-left: 0.4rem;
  cursor: pointer;
}
.login-right {
  display: flex;
  position: absolute;
  right: 0;
  height: 0.7rem;
  line-height: 00.7rem;
  left: 21.6rem;
  margin-right: 0rem;
  margin-top: 00.03rem;
}
.center-login {
  padding: 0 0.1rem;
}
.window {
  width: 1.7rem;
  height: 0.7rem;
  background-color: #230607;
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  // justify-content: center;
  .handoff,
  .quit {
    padding-left: 00.2rem;
    flex: 1;
    text-align: left;
    line-height: 00.5rem;
    cursor: pointer;
    display: flex;
    // justify-content: center;
    align-items: center;
    i {
      margin-right: 00.1rem;
    }
    &:hover {
      background-color: #570d0f;
    }
  }
}
.border {
  width: .4rem;
  border-radius: 50%;
  padding: 0.15rem 0;
}
</style>

