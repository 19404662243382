import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { Message, MessageBox } from 'element-ui';
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: 'https://apisxlj.ninemm.cn/', // api base_url
  timeout: 20000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)

    if (error.response.status === 403) {
      console.log('服务器403啦，要重新登录！')
      Message.error(data.message)
    }
    if (error.response.status === 500) {
      if (data.message.length > 0) {
        Message.error(data.message)
      }
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
}, err)

/**
 * response interceptor
 * 所有请求统一返回
 */
service.interceptors.response.use((response) => {
  if (response.request.responseType === 'blob') {
    return response
  }
  const resData = response.data
  const code = response.data.code
  if (code === 1011006 || code === 1011007 || code === 1011008 || code === 1011009) {
    MessageBox.confirm(resData.message, '提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning',
      center: true,
      customClass: 'reLoginBox',
      closeOnClickModal: false,
      closeOnPressEscape: false,
      showClose: false
    }).then(() => {
      Vue.ls.remove(ACCESS_TOKEN)
      window.location.reload()
    }).catch(() => {
      Vue.ls.remove(ACCESS_TOKEN)
    })

  }
  if (code === 1013002 || code === 1016002 || code === 1015002) {
    Message.error(response.data.message)
    return response.data
  }
  return resData
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
