<template>
  <!-- 回归分析 -->
  <div class="overview">
    <!-- 左侧的柱状图区域以及头部的标签 -->
    <div class="overview-center">
      <crumb></crumb>

      <span class="selectBtn" @click="openDialog"
        ><i class="iconfont icon-funnelloudou"></i> 因(自)变量设置</span
      >
      <!-- 中间内容部分部分 -->
      <!--<div class="second-center-img">
        <div class="target">
          <img
            src="../../assets/img/weidu/regression_center_img1.png"
            class="center-circular-img"
          />
          <img
            src="../../assets/img/weidu/regression_center_img3.png"
            height="170"
            width="555"
            class="center-circular-bottom-img1"
          />

          &lt;!&ndash;                <img src="../../assets/img/weidu/regression_center_img2.png" height="500" width="555" class="center-circular-bottom-img2"/>&ndash;&gt;
          <span>{{ this.variableNum }}</span>


        </div>
        <div
          class="sub-target sub-target-top-left"
          ref="subTargetTopLeft"
          @click="setRadar(subTargetNames.leftTop.name)"
          v-show="this.subTargetNames.leftTop.name"
        >
          <span>{{ this.subTargetNames.leftTop.name }}</span>
        </div>
        <div
          class="sub-target-value-top-left"
          v-show="this.subTargetNames.leftTop.name"
        >
          <p>{{ this.subTargetNames.leftTop.value }}</p>
        </div>

        <div
          class="sub-target sub-target-bottom-left"
          @click="setRadar(subTargetNames.leftBottom.name)"
          v-show="this.subTargetNames.leftBottom.name"
        >
          <span>{{ this.subTargetNames.leftBottom.name }}</span>
        </div>
        <div
          class="sub-target-value-bottom-left"
          v-show="this.subTargetNames.leftBottom.name"
        >
          <p>{{ this.subTargetNames.leftBottom.value }}</p>
        </div>

        <div
          class="sub-target sub-target-top-right"
          @click="setRadar(subTargetNames.rightTop.name)"
          v-show="this.subTargetNames.rightTop.name"
        >
          <span>{{ this.subTargetNames.rightTop.name }}</span>
        </div>
        <div
          class="sub-target-value-top-right"
          v-show="this.subTargetNames.rightTop.name"
        >
          <p>{{ this.subTargetNames.rightTop.value }}</p>
        </div>

        <div
          class="sub-target sub-target-bottom-right"
          @click="setRadar(subTargetNames.rightBottom.name)"
          v-show="this.subTargetNames.rightBottom.name"
        >
          <span>{{ this.subTargetNames.rightBottom.name }}</span>
        </div>

        <div
          class="sub-target-value-bottom-right"
          v-show="this.subTargetNames.rightBottom.name"
        >
          <p>{{ this.subTargetNames.rightBottom.value }}</p>
        </div>
        <p class="second-center-text" ref="centerValue">
          &lt;!&ndash; {{this.centerValue}}&ndash;&gt;
          &lt;!&ndash;加强教学内容对价值认同有促退作用
          <br>
          人生目标指数=0.001*教学内容指数+4.021&ndash;&gt;
          {{ this.rValue }}
        </p>
        &lt;!&ndash;<div class="img-top-left" @click="setRadar('人生价值')"></div>
        <div class="img-top-right" @click="setRadar('人生态度')"></div>&ndash;&gt;
      </div>-->
      <div class="center-content">
        <div class="main">
          <div class="total">
            <!--        <div class="data1">
                        <span>年销售</span>
                        <p>5684710411</p>
                    </div>
                    <div class="data2">
                        <span>月销售</span>
                        <p>1457104</p>
                    </div>
                    <div class="data3">
                        <span>周销售</span>
                        <p>564710</p>
                    </div>
                    <div class="data4">
                        <span>日销售</span>
                        <p>14504</p>
                    </div>-->
            <canvas class="rain"></canvas>
            <canvas class="dashed"></canvas>
            <div class="sphere">
              <div class="sphere-bg"></div>
              <div class="sum">
                <span>{{ this.variableNum }}</span>
                <!-- <p>{{ this.rValue }}</p> -->
              </div>
            </div>
            <div class="cicle3">
              <!-- <div class="animate wave">
                <img
                  id="corrugated1"
                  src="~@/assets/img/shouye/q1.png"
                  style="position: absolute; top: 3rem; left: 3rem"
                />
                <img
                  id="corrugated2"
                  src="~@/assets/img/shouye/q2.png"
                  style="position: absolute; top: 3rem; left: 2.5rem"
                />
                <img
                  id="corrugated3"
                  src="~@/assets/img/shouye/q3.png"
                  style="position: absolute; top: 2.6rem; left: 1.6rem"
                />
                <img
                  id="corrugated4"
                  src="~@/assets/img/shouye/q4.png"
                  style="position: absolute; top: 2.5rem; left: 0.8rem"
                />
                <img
                  id="corrugated5"
                  src="~@/assets/img/shouye/q5.png"
                  style="position: absolute; top: 2.2rem; left: -0.4rem"
                />
                <img
                  id="corrugated6"
                  src="~@/assets/img/shouye/q6.png"
                  style="position: absolute; top: 2rem; left: -2rem"
                />
              </div> -->
            </div>
            <!--           <div class="cicle4"></div>-->
            <!--            <div class="cicle5"></div>
            <div class="cicle6"></div>
            <div class="cicle7"></div>-->
            <div
              @mouseenter="mouseIn"
              @mouseleave="mouseOut"
              class="subItem cicle8"
              ref="subTargetTopLeft"
              @click="setRadar(subTargetNames.leftTop.name)"
              v-show="this.subTargetNames.leftTop.name"
            >
              <span>{{ this.subTargetNames.leftTop.name }}</span>
              <p>{{ this.subTargetNames.leftTop.value }}</p>
            </div>
            <div
              @mouseenter="mouseIn"
              @mouseleave="mouseOut"
              class="subItem cicle9"
              @click="setRadar(subTargetNames.leftBottom.name)"
              v-show="this.subTargetNames.leftBottom.name"
            >
              <span>{{ this.subTargetNames.leftBottom.name }}</span>
              <p>{{ this.subTargetNames.leftBottom.value }}</p>
            </div>
            <div
              @mouseenter="mouseIn"
              @mouseleave="mouseOut"
              class="subItem cicle10"
              @click="setRadar(subTargetNames.rightTop.name)"
              v-show="this.subTargetNames.rightTop.name"
            >
              <span>{{ this.subTargetNames.rightTop.name }}</span>
              <p>{{ this.subTargetNames.rightTop.value }}</p>
            </div>
            <div
              @mouseenter="mouseIn"
              @mouseleave="mouseOut"
              class="subItem cicle11"
              @click="setRadar(subTargetNames.rightBottom.name)"
              v-show="this.subTargetNames.rightBottom.name"
            >
              <span>{{ this.subTargetNames.rightBottom.name }}</span>
              <p>{{ this.subTargetNames.rightBottom.value }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧的雷达图部分以及结论 -->
    <div class="overview-right">
      <div class="overview-right-top">
        <div class="ranking">
          <div class="fenbu">
            <!-- 右上角分析器标题 -->
            <h3 class="fenbu-title">{{ radarTitle }}</h3>
          </div>
          <div class="lookchart">
            <!-- 右上角标题的内容 -->
            <Radar :radarData="age" ref="radar" />
          </div>
        </div>
      </div>
      <div class="overview-right-bottom">
        <div class="fenbu">
          <!-- 右下角分析器标题 -->
          <h3 class="fenbu-title">分析结论</h3>
        </div>
        <!-- 右下角r值组件 -->
        <!--<div class="ring">
           &lt;!&ndash;<Ring :ringData="ringData" />&ndash;&gt;

        </div>-->
        <!--<p class="r-value">R值: {{rNum}}</p>-->
        <p ref="rValue2" class="conclusionText2"></p>
        <p ref="rValue" class="conclusionText1"></p>
      </div>
    </div>
    <!--<RegDialog ref="regDialog" @getTarget="getDialogTarget"></RegDialog>-->
    <RegressionDialog
      ref="regDialog"
      @getTarget="getDialogTarget"
      class="goLeft"
    ></RegressionDialog>
  </div>
</template>

<script>
import Histogram from "../../components/Histogram.vue";
import Radar from "../../components/Radar.vue";
import Ring from "../../components/Ring.vue";
import crumb from "@/components/crumb";
import RegDialog from "@/components/RegDialog";
// import IndicatorFilterDialog from "@/components/IndicatorFilterDialog";
import RegressionDialog from "@/components/RegressionDialog";
import { regression, targetQuestionDetailForGraph } from "@/api/api";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Histogram,
    Radar,
    Ring,
    crumb,
    RegDialog,
    RegressionDialog,
  },
  data() {
    return {
      year: 2020,
      themeName: "思想政治状况",
      radarTitle: "您是否对自己的人生前途充满信心？",
      age: {
        name: "全国",
        data: [0.57, 2.2, 12.03, 64.4, 20.79],
        Xname: "x轴", //这个可以不用传入
        Xdata: ["完全没有", "很不确定", "说不清楚", "有大体方向", "有明确目标"],
      },
      /*因变量*/
      variableNum: "人生目标",
      /*自变量*/
      selfNum: ["总体评价", "教学内容", "教学方法", "师资水平"],
      /*r值*/
      rNum: 0.004,
      /*r内容*/
      rValue: "",
      /*中间内容*/
      centerValue: "",
      centerValue2: "",
      ringData: 70,
      //二级主题
      subTargetNames: {
        leftTop: { pre: "", name: "总体评价", value: "0.001" },
        rightTop: { pre: "", name: "教学内容", value: "0.003" },
        leftBottom: { pre: "", name: "教学方法", value: "0.001" },
        rightBottom: { pre: "", name: "师资水平", value: "0.001" },
      },
    };
  },
  created() {},
  mounted() {
    this.$refs.radar.lookpie1(this.age);
    this.getDialogTarget(this.variableNum, this.selfNum);
  },
  methods: {
    ...mapActions(["setCity", "setSchool"]),
        // 将省份添加后缀的函数
    changeProvince(provinceName) {
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = provinceName;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      provinceName = city;
      return provinceName;
    },
    getDialogTarget(variableNumTarget, selfNumTargetList) {
      this.variableNum = variableNumTarget;
      this.selfNum = selfNumTargetList;
      this.subTargetNames.leftTop.name = selfNumTargetList[0];
      this.subTargetNames.rightTop.name = selfNumTargetList[1];
      this.subTargetNames.leftBottom.name = selfNumTargetList[2];
      this.subTargetNames.rightBottom.name = selfNumTargetList[3];
      let lookpieOption = this.$echarts.init(this.$refs.radar.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      let city = this.getCity;
      if (city) {
        city = this.changeProvince(city);
      }
      /*调接口取值*/
      regression({
        year: this.year,
        themeName: this.themeName,
        universityName: this.getSchool,
        province: city,
        targetName: this.variableNum,
        subTargetNames: this.selfNum.join(),
      }).then((data) => {
        /*获取到回归分析界面的取值*/
        if (data.code == 200) {
          /*设置中间内容*/
          this.rValue = data.data.remark;
          /*this.centerValue = data.data.conclusion
          this.$refs.centerValue.innerHTML = this.centerValue*/
          /*设置r内容*/
          console.log(data.data, "右下角数据");
          this.centerValue = data.data.conclusion;
          this.centerValue2 = data.data.remark;
          this.$refs.rValue2.innerHTML = this.centerValue2;
          this.$refs.rValue.innerHTML = this.centerValue;
          /*设置r值*/
          this.rNum = data.data.rvalue;
          /*设置圆环图*/
          console.log("r值");
          console.log(this.rNum);
          /*设置回归分析各项数值*/
          for (let item in this.subTargetNames) {
            for (let i = 0; i < this.selfNum.length; i++) {
              if (this.selfNum[i] == this.subTargetNames[item].name) {
                this.subTargetNames[item].value =
                  data.data.data[this.selfNum[i]];
              }
            }
          }

          /*设置雷达图数值*/
          targetQuestionDetailForGraph({
            year: this.year,
            themeName: this.themeName,
            targetName: this.variableNum,
            province: city,
            universityName: this.getSchool,
          }).then((data) => {
            if (data.code == 200) {
              console.log(data.data.questionTitle);
              /*设置雷达图标题*/
              this.radarTitle = data.data.questionTitle;
              /*渲染雷达图*/
              let radarValue = {
                name: "全国",
                data: data.data.data.country.values,
                provinceName: "",
                provinceData: "",
                schoolName: "",
                schoolData: "",
                Xname: "x轴", //这个可以不用传入
                Xdata: data.data.data.country.cols,
              };

              if (data.data.data.province) {
                radarValue.provinceName = city;
                radarValue.provinceData = data.data.data.province.values;
              }

              if (data.data.data.university) {
                radarValue.schoolName = this.getSchool;
                radarValue.schoolData = data.data.data.university.values;
              }

              this.$refs.radar.lookpie1(radarValue);
            }
          });
        }
      });
    },
    setRadar(target) {
      console.log(target);
      let city = this.getCity;
      if (city) {
        city = this.changeProvince(city);
      }
      targetQuestionDetailForGraph({
        year: this.year,
        themeName: this.themeName,
        targetName: target,
        province: city,
        universityName: this.getSchool,
      }).then((data) => {
        console.log(data);
        if (data.code == 200) {
          /*设置雷达图标题*/
          this.radarTitle = data.data.questionTitle;
          /*渲染雷达图*/
          let radarValue = {
            name: "全国",
            data: data.data.data.country.values,
            provinceName: "",
            provinceData: "",
            schoolName: "",
            schoolData: "",
            Xname: "x轴", //这个可以不用传入
            Xdata: data.data.data.country.cols,
          };
          if (data.data.data.province) {
            radarValue.provinceName = city;
            radarValue.provinceData = data.data.data.province.values;
          }
          if (data.data.data.university) {
            radarValue.schoolName = this.getSchool;
            radarValue.schoolData = data.data.data.university.values;
          }
          this.$refs.radar.lookpie1(radarValue);
        }
      });
    },
    openDialog() {
      /*console.log(JSON.parse(localStorage.getItem("role")))
      // console.log(localStorage.getItem("info"))*/
      this.$refs.regDialog.showRegDialog();
    },
    mouseIn() {
      let items = document.querySelectorAll(".subItem");
      for (let i = 0; i < items.length; i++) {
        items[i].style.animationPlayState = "paused";
      }
    },
    mouseOut() {
      let items = document.querySelectorAll(".subItem");
      for (let i = 0; i < items.length; i++) {
        items[i].style.animationPlayState = "running";
      }
    },
  },
  computed: {
    ...mapGetters(["getCity", "getSchool", "getRoles", "getOrgInfo"]),
  },
  watch: {
    getCity: function (newValue) {
      this.getDialogTarget(this.variableNum, this.selfNum);
    },
    getSchool: function (newValue) {
      this.getDialogTarget(this.variableNum, this.selfNum);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/regression.scss";
.overview {
  height: 100%;
  width: 100%;
  display: flex;
  //   margin-top: 0.25rem;
  .overview-center {
    height: 100%;
    flex: 4;
    margin-right: 0.2rem;
    .overview-center-title {
      height: 0.4rem;
      margin-bottom: 1rem;
      line-height: 0.4rem;
      border: 1px solid #470201;
      border-right: none;
      border-left: none;
      display: flex;
      justify-content: space-between;
    }
    .selectBtn {
      /*color: red;*/
      position: relative;
      left: 9.2rem;
      top: -0.4rem;
      cursor: pointer;
      font-size: 14px;
      i {
        font-size: 14px;
      }
    }
  }
  .overview-right {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    /* position: relative;
    left: 0.3rem;*/
    // .ranking {
    //   height: 5rem;
    // }
    .fenbu {
      height: 0.45rem;
      border-radius: 0.05rem 0rem 0rem 0rem;
      .fenbu-title {
        background-image: linear-gradient(
          to right,
          rgba(44, 3, 3, 0.7),
          rgba(44, 3, 3, 0)
        );
        color: #fff;
        height: 0.45rem;
        line-height: 0.45rem;
        padding: 0 0 0rem 0.17rem;
        position: relative;
        font-family: "微软雅黑";
        font-size: 14px;
        &:after {
          content: "";
          width: 0.05rem;
          height: 0.25rem;
          background-color: #a40000;
          position: absolute;
          top: 0.1rem;
          left: 0;
          margin: auto 0;
        }
      }
    }
    .overview-right-top {
      height: 4rem;
      #lookpie {
        width: 5.2rem;
        height: 100%;
        padding: 0.2rem 0.5rem 0 0.2rem;
        font-size: 0.2rem;
        line-height: 0.5rem;
        color: #74c6f5;
      }
      .lookchart {
        width: 6rem;
        height: 12.4rem;
        background: rgba(17, 18, 22, 0.5);

        .looktitle {
          font-size: 0.1875rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cccccc;
          position: relative;
          padding-left: 0.175rem;
          margin-left: 0.1875rem;
        }

        .looktitle::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.05rem;
          height: 0.225rem;
          background: #0079fe;
        }
      }
    }
    .overview-right-bottom {
      flex: 1;
      margin: 1rem 0 0;
      .ring {
        width: 3rem;
        height: 3rem;
        // background-color: yellow;
        margin-left: 0.5rem;
      }
      .Pyramid-c-box {
        position: relative;
        left: 15%;
        margin-bottom: 0.2rem;
        width: 4rem;
        height: auto;
        background: transparent;
        border: 1px solid #4ed5f8;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1em 2em rgba(0, 0, 0, 0.3);
        animation: breath1 2s infinite;

        p {
          font-size: 0.1rem;
        }
      }

      .Pyramid-c-box::before {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 15px;
        width: calc(100% - 30px);
        content: "";
        border-top: 1px solid #016886;
        border-bottom: 1px solid #016886;
        z-index: 0;
      }

      .Pyramid-c-box::after {
        position: absolute;
        top: 15px;
        right: -1px;
        left: -1px;
        height: calc(100% - 30px);
        content: "";
        border-right: 1px solid #016886;
        border-left: 1px solid #016886;
        z-index: 0;
      }

      @keyframes breath1 {
        0% {
          border: 1px solid #4ed5f8;
        }

        /* 动画开始时的不透明度 */
        50% {
          border: 1px solid #176785;
        }

        /* 动画50% 时的不透明度 */
        100% {
          border: 1px solid #4ed5f8;
        }

        /* 动画结束时的不透明度 */
      }

      .Pyramid-c-box1 {
        position: relative;
        top: 5%;
        left: 15%;
        margin-bottom: 0.5rem;
        width: 4rem;
        height: 2rem;
        background: transparent;
        border: 1px solid #4ed5f8;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1em 2em rgba(0, 0, 0, 0.3);
        animation: breath2 2s infinite;
      }

      .Pyramid-c-box1::before {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 15px;
        width: calc(100% - 30px);
        content: "";
        border-top: 1px solid #016886;
        border-bottom: 1px solid #016886;
        z-index: 0;
      }

      .Pyramid-c-box1::after {
        position: absolute;
        top: 15px;
        right: -1px;
        left: -1px;
        height: calc(100% - 30px);
        content: "";
        border-right: 1px solid #016886;
        border-left: 1px solid #016886;
        z-index: 0;
      }

      @keyframes breath2 {
        0% {
          border: 1px solid #4ed5f8;
        }

        /* 动画开始时的不透明度 */
        50% {
          border: 1px solid #176785;
        }

        /* 动画50% 时的不透明度 */
        100% {
          border: 1px solid #4ed5f8;
        }

        /* 动画结束时的不透明度 */
      }
    }
  }
  .second-center-img {
    position: relative;
    left: 0rem;
    /*background: url('../../assets/img/weidu/regression_center_img1.png');*/
    width: 11.9rem;
    height: 12rem;
    background-color: rgba(11, 12, 16, 0.4);
    /*background-color: red;*/
    .target {
      /*background-color: blue;*/
      width: 5rem;
      height: auto;
      position: absolute;
      left: 3.5rem;
      top: 0.5rem;
      /*background-color: red;*/
      background-image: none;
      span {
        display: inline-block;
        width: 1.8rem;
        height: 0.8rem;
        /*background-color: blue;*/
        line-height: 0.5rem;
        text-align: center;
        color: #00ffff;
        font-size: 0.35rem;
        position: absolute;
        left: 53%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .center-circular-img {
        margin-left: 0.15rem;
        width: 100%;
        height: 100%;
      }
      .center-circular-bottom-img1 {
        width: 100%;
        height: auto;
        /*height: 100%;*/
        position: absolute;
        left: 0.2rem;
        bottom: -0.5rem;
      }
      /* .center-circular-bottom-img2{
         width: 500px;
         height: 100px;
         position: absolute;
       }*/
    }
    div {
      width: 0.9rem;
      height: 0.6rem;
      position: absolute;
      background: url("../../assets/img/weidu/regression_arrow.png") no-repeat;
      background-size: cover;
      p {
        position: absolute;
        font-weight: bold;
        font-size: 0.2rem;
        color: #d7ffff;
      }
    }
    .sub-target {
      width: 1.5rem;
      height: 1.7rem;
      position: absolute;
      background: url("../../assets/img/weidu/regression_item.png") no-repeat;
      background-size: cover;
      transition: transform 1s;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }
      span {
        display: inline-block;
        width: 100%;
        font-size: 0.22rem;
        font-weight: bold;
        color: #d7ffff;
        position: absolute;
        top: 50%;
        left: 50%;
        white-space: normal;
        text-align: center;
        transform: translate(-50%, -50%);
      }
      div {
        width: 0.9rem;
        height: 0.6rem;
        position: absolute;
        background: url("../../assets/img/weidu/regression_arrow.png") no-repeat;
        background-size: cover;
        p {
          position: absolute;
          font-weight: bold;
          font-size: 0.2rem;
          color: #d7ffff;
        }
      }
      &.sub-target-top-left {
        top: 0em;
        left: 1rem;
        div {
          left: 1.8rem;
          top: 1.4rem;
          transform: rotate(240deg);
          p {
            top: 0.18rem;
            right: 0.1rem;
            transform: rotate(150deg);
          }
        }
      }

      &.sub-target-bottom-left {
        //sub-target-bottom-left
        bottom: 6.3rem;
        left: 1rem;
        div {
        }
      }
      &.sub-target-top-right {
        //sub-target-top-right
        top: 0rem;
        right: 0.6rem;
        div {
          background-color: red;
          right: 1.7rem;
          top: 1.3rem;
          p {
            left: 0.4rem;
            top: 0.13rem;
            transform: rotate(330deg);
          }
        }
      }
      &.sub-target-bottom-right {
        bottom: 6.3rem;
        right: 0.6rem;
        div {
          right: 1.7rem;
          top: 0.4rem;
          transform: rotate(60deg);
          p {
            left: 0.4rem;
            top: 0.13rem;
            transform: rotate(330deg);
          }
        }
      }
    }
    .sub-target-value-top-left {
      left: 2.4rem;
      top: 1.4rem;
      transform: rotate(240deg);
      width: 66px;
      height: 48px;
      /*background-color: red;*/
      p {
        top: 0.22rem;
        right: 0.1rem;
        transform: rotate(155deg);
      }
    }
    .sub-target-value-bottom-left {
      left: 2.6rem;
      top: 3.9rem;
      width: 66px;
      height: 48px;
      transform: rotate(180deg);
      p {
        top: 0.22rem;
        right: 0.11rem;
        transform: rotate(150deg);
      }
    }
    .sub-target-value-top-right {
      right: 2.1rem;
      top: 1.4rem;
      width: 66px;
      height: 48px;
      p {
        left: 0.4rem;
        top: 0.2rem;
        transform: rotate(333deg);
      }
    }
    .sub-target-value-bottom-right {
      right: 2.1rem;
      top: 3.7rem;

      width: 66px;
      height: 48px;
      transform: rotate(60deg);
      p {
        left: 0.4rem;
        top: 0.2rem;
        transform: rotate(340deg);
      }
    }
    .second-center-text {
      display: block;
      width: 70%;
      /*background-color: red;*/
      position: absolute;
      height: auto !important;
      text-align: left;
      bottom: 5rem;
      left: 53%;
      transform: translateX(-50%);
      font-size: 0.25rem;
      line-height: 0.4rem;
      color: #e1fef9;
      font-family: PingFang SC;
      font-weight: 400;
    }
  }
  .shuzhi4 {
    position: absolute;
    left: 8.275rem;
    bottom: 1.05rem;
    color: #e1fef9;
  }

  .shuzhi5 {
    position: absolute;
    left: 4.15rem;
    bottom: 1.875rem;
    color: #e1fef9;
  }

  .shuzhi6 {
    position: absolute;
    left: 2.675rem;
    bottom: 1.025rem;
    color: #e1fef9;
  }
  .r-value {
    text-align: center;
    font-size: 0.4rem;
    line-height: 1.6rem;
  }

  .conclusionText1 {
    margin-top: 0.2rem;
    padding-left: 0.2rem;
    text-align: left;
    line-height: 0.4rem;
    font-size: 0.2rem;
  }
  .conclusionText2 {
    margin-top: 0.2rem;
    padding-left: 0.2rem;
    text-align: left;
    line-height: 0.4rem;
    font-size: 0.2rem;
    color: rgb(0, 140, 255);
  }
  // .goLeft{
  //   transform: translateX(-0.1rem);
  // }
}
.sum {
  span {
    font-size: 1rem;
  }
}
</style>
