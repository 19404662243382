<template>
  <div class="header-box">
    <div class="header-top">
      <!--<div class="goIndex"></div>-->
      <div class="header-ico" @click="gohome"></div>
      <div class="title">{{ currentUser }}大学生思想现状调查</div>
      <div class="date">
        {{ dateYear }}
      </div>
      <div class="time">
        {{ dateDay }}
      </div>
      <div class="login-right" @click="handleClick" ref="toggleButton">
        <!-- <span class="iconfont icon-geren"></span> -->
        <!-- <span class="iconfont" :class="avatar ? '' : 'icon-geren'" v-if="!avatar"></span>
        <img :src="avatar" alt="" class="border" v-if="avatar"/> -->
        <el-avatar :size="20" :src="avatar" icon="el-icon-user-solid"> </el-avatar>
        <span class="titleUserName">{{ this.userName }}</span>
        <!-- <div class="center-login">|</div>
      <span class="lineOut" @click="lineOut">退出</span> -->
      </div>
      <div class="window" v-show="openQuit">
        <!-- <div class="handoff" @click="handoff">
          <i class="iconfont icon-manage-circuit"> </i>
          <span>专题切换</span>
        </div>-->
        <div class="quit" @click="lineOut" ref="popup">
          <!-- <i><img src="@/assets/img/shouye/quit.png" alt=""></i> -->
          <i class="iconfont icon-tuichu1"> </i>
          <span>退出</span>
        </div>
      </div>
    </div>
    <div class="goIndex" @click="gohome()"></div>
    <div class="header-nav">
      <div class="menu">
        <!--流星-->
        <div class="texiao1"></div>
        <div class="texiao2"></div>
        <!--首页按钮-->
        <div class="uncheck" @click="gohome">
          <img class="xtb" src="~@/assets/img/shengfen/home-s1.png" alt="" />
          首页
        </div>
        <!--选择年份-->
        <div class="uncheck" v-show="!getIsHome" @click="openValue0">
          <!-- 2021年 -->
          <div class="input">
            <input
              v-model="value0"
              type="text"
              placeholder="2020年"
              style="position: relative; left: -0.33rem"
            />
          </div>
          <div class="list" v-show="show0">
            <ul>
              <li
                @click="getvalue0(index, item)"
                v-for="(item, index) in tableData0"
                :key="item.index"
              >
                {{ item }}年
              </li>
            </ul>
          </div>
          <img
            class="xtb1"
            :class="transform ? 'transform' : ''"
            src="~@/assets/img/xiala1.png"
            alt=""
          />
        </div >
        <div class="uncheck" @click="openValue"></div>
        <div class="specialcheck1" v-show="!getIsHome" @click="openValue2" v-if="regression">
          <div class="input target-input">
            <input
              v-model="value2"
              type="text"
              placeholder="人生观"
              style="width: 1rem"
              disabled="disabled"
            />
            <div
              class="list questionList"
              v-show="show2"
              style="height: auto; width: 2.5rem"
            >
              <ul>
                <li
                  @click="getvalue2(index, item)"
                  v-for="(item, index) in tableData2"
                  :key="item.index"
                >
                  {{ item.dimension }}
                </li>
              </ul>
            </div>
          </div>
          <img
            class="xtb1 targe-img"
            :class="transform2 ? 'transform2' : ''"
            src="~@/assets/img/xiala1.png"
          />
        </div>
        <div class="proBtn">
          <div class="uncheck" v-show="!getCity && getIsHome" @click="cityBtn">
            <img class="xtb" src="~@/assets/img/shengIcon.png" alt="" />
            省份
          </div>
          <div class="uncheck zhutiBtn" v-show="getIsHome" @click="analyseBtn"><i class="el-icon-menu" style="margin-right: 5px;margin-left: -20px;font-size: 16px"></i> 主题分析</div>
        </div>

<!--        <div class="analyseBtn" v-show="!isShowBtn" @click="proAnalyseBtn">省份分析详情</div>-->
        <div class="uncheck" @click="homePage()" id="return">
          <img class="xtb" src="~@/assets/img/shengfen/return.png" alt="" />
          返回
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import {formatTime} from "@/utils";
// import {
//     Provincexiala,
//     queryTargetByThemeName,
//     shouyeLeida,
//     showSchool,
//     showYear,
//     varianceFenxi,
// } from "../../api/api";
import { mapGetters, mapActions } from "vuex";
import { queryTargetByThemeName } from "../api/api";
import dayjs from "dayjs";
import { ACCESS_TOKEN } from "@/store/mutation-types";
export default {
  name: "HeaderTitle",
  data() {
    return {
      isShowBtn:true,
      userName: "",
      currentUser: "",
      dateYear: "",
      dateDay: "",
      queryThemeName: "思想政治状况",
      queryYear: "2020",
      show0: false,
      show1: false,
      show2: false,
      show: false,
      value0: "",
      value: "",
      value2: "",
      tableData0: [2020, 2021, 2022],
      tableData: [],
      tableData2: [],
      openQuit: false,
      regression: true,
      transform: true,
      transform2: true,
    };
  },
  methods: {
    ...mapActions([
      "Logout",
      "setUserName",
      "setTargetLevel1",
      "setUserName",
      "setCity",
      "setSchool",
      'setIsHome'
    ]),

    cityBtn(){
      this.$router.push('/province')
    },
    proAnalyseBtn(){

    },
    analyseBtn(){
      this.$router.push('/homePage')
      this.setIsHome(false)
      // this.isShowBtn = false
    },

    // 撤销监听事件
    hide() {
      document.removeEventListener("click", this.hidePanel, false);
    },
    // 不是指定元素直接关闭
    hidePanel(e) {
      if (
        !this.$refs.toggleButton.contains(e.target) &&
        !this.$refs.popup.contains(e.target)
      ) {
        this.openQuit = false;
        this.hide();
      }
    },
    // 弹窗函数
    handleClick() {
      this.openQuit = !this.openQuit;
      if (this.openQuit) {
        document.addEventListener("click", this.hidePanel, false);
      } else {
        this.hide();
      }
    },
    /*登出*/
    lineOut() {
      const _that = this;
      this.$confirm("确认退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
        customClass: "reLoginBox",
      })
        .then(() => {
          _that.Logout({ token: this.$ls.get(ACCESS_TOKEN) }).then(() => {
            _that.logoutVisible = false;
            _that.setUserName("");
            _that.$router.push({
              name: "portal",
            });
            localStorage.removeItem("currentIndex");
          });
        })
        .catch(() => {});
    },
    /*...mapActions([""]),*/
    //格式化时间
    timeFn() {
      this.dateYear = dayjs().format("YYYY.MM.DD");
      this.dateDay = dayjs().format("HH:mm:ss");
      setTimeout(() => {
        this.timeFn();
      }, 1000);
    },
    //返回首页
    gohome() {
      this.setCity("");
      this.setSchool("");
      // this.setIsHome(true)
      this.$router.push('/');
      localStorage.removeItem("currentIndex");

    },

    openValue0() {
      this.show0 = !this.show0;
      this.show1 = false;
      this.show2 = false;
      this.show = false;
      this.transform = !this.transform;
    },
    openValue() {
      this.show = !this.show;
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
    },
    getvalue0(index, item) {
      this.value0 = item + "年";
      this.curyear = item;
      this.init();
    },
    openValue2() {
      this.transform2 = !this.transform2;
      this.show2 = !this.show2;
      this.show = false;
      this.show0 = false;
      this.show1 = false;
    },
    init() {
      // let _this = this;
      //{k:v,k:v}
      let y = {
        years: this.curyear,
        provinceId: this.provinceId,
        univsCode: this.univsCode,
      };
      if (!y.years) {
        y.years = "2022";
      }
      if (!y.provinceId) {
        y.provinceId = "420000000000";
      }
      if (!y.univsCode) {
        y.univsCode = "4142010486";
      }

      Provincexiala().then((res) => {
        if (res.success) {
          let tableData = res.data.list;
          this.$data.tableData = tableData;
        } else {
          console.log(res);
        }
      });

      showYear().then((res) => {
        if (res.success) {
          this.$data.tableData0 = res.data.list;
        } else {
          console.log(res);
        }
      });

      // showSchool(y).then((res) => {
      //     if (res.success) {
      //         this.$data.tableData1 = res.data.list;
      //     } else {
      //         console.log(res);
      //     }
      // });

      shouyeLeida(y).then((res) => {
        if (res.success) {
          let totalList = [];
          let maxCapacity = res.data.univs[0].maxCapacity;
          // res.data.univs.forEach((item) => {
          //   totalList.push(item.total);
          // });
          totalList.push(res.data.univs[1].total);
          totalList.push(res.data.univs[2].total);
          totalList.push(res.data.univs[3].total);
          totalList.push(res.data.univs[4].total);
          totalList.push(res.data.univs[5].total);
          totalList.push(res.data.univs[6].total);
          totalList.push(res.data.univs[7].total);
          totalList.push(res.data.univs[0].total);

          this.subject(totalList, maxCapacity);
          console.log(maxCapacity);
        } else {
          console.log(res);
        }
      });

      varianceFenxi(y).then((res) => {
        if (res.success) {
          let conclusion = res.data.school.p;
          this.lastchart(conclusion);
        } else {
          console.log(res);
        }
      });
    },
    homePage() {

      this.setCity('')
      this.setIsHome(true)
      this.$router.push('/chinaHome');

      /*let path =
        JSON.parse(localStorage.getItem("role"))[0].name == "高校"
          ? "/special"
          : "/homePage"; //高校
      this.$router.push(path);*/

      localStorage.removeItem("currentIndex");
      location.reload()
    },
    getvalue2(index, item) {
      this.value2 = item.dimension;
      this.transform2 = false;
    },
    //获取主题列表
    getQueryList() {
      queryTargetByThemeName({
        themeName: this.queryThemeName,
        year: this.queryYear,
      }).then((data) => {
        if (data.code == 200) {
          console.log(data);
          let arr = [];
          for (let i = 0; i < data.data.list.length; i++) {
            let obj = {
              dimension: data.data.list[i].name,
            };
            arr.push(obj);
          }
          this.tableData2 = arr;
        }
      });
    },
    //给父组件传递一级指标
    sendLevel1() {
      this.$emit("getLevel1", this.value2);
    },
    /*点击其他位置关闭弹窗*/
    closeView() {
      document.addEventListener("click", (e) => {
        let that = this;
        if (!this.$el.contains(e.target)) {
          that.show2 = false; //点击其他区域关闭
        }
      });
    },
    handoff() {
      this.$router.push({
        name: "special",
      });
      localStorage.removeItem("currentIndex");
    },
  },
  created() {
    this.getQueryList();
    /*  console.log('kkkkkkkk')
    console.log(JSON.parse(localStorage.getItem('role')))*/
    this.currentUser = JSON.parse(localStorage.getItem("info")).orgName;
    this.userName = JSON.parse(localStorage.getItem("user")).name;
    this.avatar = JSON.parse(localStorage.getItem("avatar"));
  },
  mounted() {
    this.timeFn();
    // this.init();
    this.closeView();
    this.value2 = this.getTargetLevel1;
    // console.log(this.$route.path,"当前路由");
  },
  watch: {
    value2: function () {
      this.setTargetLevel1(this.value2);
      console.log(this.getTargetLevel1);
    },
  },
  computed: {
    ...mapGetters(["getTargetLevel1",'getIsHome','getCity']),
  },
  updated() {
    if (this.$route.path == "/regression") {
      this.regression = false;
    } else {
      this.regression = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-avatar{
  // margin-top: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // transform: translateX(-8px);
  cursor: pointer;
}
.header-top {
  width: 100%;
  height: 0.8rem;
  background: url("../assets/img/shengfen/top0.png") no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  .header-ico {
    background-image: url("~@/assets/img/shengfen/ico.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 1.5rem;
    height: 0.4rem;
    position: absolute;
    top: 20%;
    left: 1%;
    cursor: pointer;
  }
  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -80%);
    width: 6rem;
    height: 0.9rem;
    text-align: center;
    font-size: 0.4rem;
    letter-spacing: 0.08rem;
    font-family: "微软雅黑";
    font-weight: bold;
    color: #dbe5fa;
    line-height: 1.3rem;
    background-image: linear-gradient(to bottom, #fff, #e9bfc1);
    -webkit-background-clip: text;
    color: transparent;
  }
  .weather {
    width: 1.5rem;
    font-size: 0.24rem;
    position: absolute;
    right: 5rem;
    top: 0.22rem;
  }
  .date {
    width: 2.34rem;
    font-size: 0.24rem;
    position: absolute;
    right: 3rem;
    top: 0.22rem;
  }
  .time {
    width: 2rem;
    font-size: 0.24rem;
    position: absolute;
    right: 2rem;
    top: 0.22rem;
  }
}
.header-nav {
  width: 100%;
  height: 0.6rem;
  .menu {
    margin-top: 0.05rem;
    height: 100%;
    width: 100%;
    background: url("../assets/img/shengfen/top2.png") no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    position: relative;
    /*滑动流星*/
    .texiao1 {
      width: 1.2rem;
      height: 0.02rem;
      position: absolute;
      animation: ab 10s linear infinite;
      background: linear-gradient(to left, #e20c1a, #5a0911);
    }
    @keyframes ab {
      from {
        left: 0%;
      }
      to {
        left: 100%;
      }
    }
    .texiao2 {
      width: 1.2rem;
      height: 0.02rem;
      position: absolute;
      bottom: 0;
      right: 0;
      animation: bc 10s linear infinite;
      background: linear-gradient(to right, #e20c1a, #1f0405);
    }
    @keyframes bc {
      from {
        left: 100%;
      }

      to {
        left: 0%;
      }
    }
    /*首页按钮*/
    #return {
      position: absolute;
      left: 88%;
    }
    .uncheck {
      width: 2rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      left: -0.3rem;
      font-size: 0.19rem;
      margin-right: 0.01rem;
      .transform {
        transform: rotate(0deg);
        transition: 0.5s;
      }

      ul li {
        list-style: none;
      }
      .input {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      input::placeholder {
        color: #fff;
      }

      .input input {
        border: none;
        outline: none;
        width: 90%;
        background-color: #1f0405;
        color: #fff;
        cursor: pointer;
      }

      .input img {
        position: absolute;
        right: 34px;
        top: 48%;
      }

      .list {
        width: 2rem;
        border: 1px solid #5a0911;
        overflow: hidden;
        position: absolute;
        top: 100%;
        left: 0%;
        height: 0.8rem;
        overflow-y: auto;
        text-align: center;
        padding-right: 0;
        z-index: 99;
        height: 100px;
      }

      .list::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
        scrollbar-arrow-color: #fff;
      }

      .list ul li {
        width: 100%;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
      }

      .list ul li:hover {
        background-color: #1f0405;
      }
      .xtb {
        width: 0.23rem;
        height: 0.23rem;
        margin-right: 0.2rem;
      }
    }
    ul li {
      list-style: none;
    }
    .input {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    input::placeholder {
      color: #fff;
    }

    .input input {
      border: none;
      outline: none;
      width: 90%;
      background-color: #1f0405;
      color: #fff;
      cursor: pointer;
      font-size: 0.2rem;
    }

    .input img {
      position: absolute;
      right: 34px;
      top: 48%;
    }
    .xtb1 {
      width: 0.15rem;
      height: 0.1rem;
      margin-right: 0.1rem;
      margin-top: 0.05rem;
      margin-left: 000.13rem;
      position: relative;
      left: -0.3rem;
      transform: rotate(180deg);
      transition: 0.5s;
    }
    .transform2 {
      transform: rotate(0deg);
      transition: 0.5s;
    }
    .xtb {
      width: 0.3rem;
      height: 0.3rem;
      margin-right: 0.2rem;
    }

    .cityBtn{
      /*position: absolute;*/
      /*left: 6.5%;
      top: 26%;*/
      /*line-height: 0.64rem;*/
      /*margin-right: 0.2rem;
      margin-left: -2.6rem;*/
      margin-right: 0.2rem;
      cursor: pointer;
    }
    .analyseBtn{
      /*position: absolute;*/
      /*left: 10%;
      top: 26%;*/
      /*line-height: 0.64rem;*/
      cursor: pointer;
    }
  }
}
.questionList {
  background-color: rgba(44, 3, 3, 0.85);
  padding-right: 0 !important;
  left: -65% !important;
  ul {
    width: 100%;
    li {
      &:nth-child(1) {
        border-top: none;
      }
      width: 100% !important;
      font-size: 0.2rem;
      border-top: #1b1b1b solid 1px;
    }
  }
}
.header-box {
  margin-bottom: 0.1rem;
}
.specialcheck1 {
  width: 3rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-align: center;
  position: relative;
  left: -2.8rem;

  ul li {
    list-style: none;
  }

  .input {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 0.15rem;
  }

  input::placeholder {
    color: #fff;
  }

  .input input {
    border: none;
    outline: none;
    width: 20%;
    background-color: #1f0405;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .input img {
    position: absolute;
    right: 34px;
    top: 48%;
  }

  .list {
    width: 3rem;
    border: 1px solid rgba(44, 3, 3, 0.85);
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0%;
    height: 0.8rem;
    overflow-y: auto;
    text-align: center;
    padding-right: 41%;
    z-index: 99;
  }

  .list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
    scrollbar-arrow-color: #fff;
  }

  .list ul li {
    width: 200%;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
  }

  .list ul li:hover {
    background-color: #1f0405;
  }

  .targe-img {
    position: absolute;
    margin-left: 00.13rem;
  }
}
.goIndex {
  position: fixed;
  top: 0.23rem;
  left: 0.3rem;
  width: 1.8rem;
  height: 0.5rem;
  /*background-color: red;*/
  cursor: pointer;
}
.titleUserName {
  // position: absolute;
  // right: 0.65rem;
  // top: 0.05rem;
  display: inline-block;
  // width: 1.5rem;
  text-align: center;
  // height: 0.6rem;
  line-height: 0.7rem;
  /*background-color: #dbe5fa;*/
  padding-left: 00.1rem;
  margin-left: 0.4rem;
  cursor: pointer;
}
.login-right {
  height: 100%;
  display: flex;
  position: absolute;
  height: 0.7rem;
  line-height: 00.7rem;
  left: 21.6rem;
  margin-right: 0rem;
  margin-top: 00.03rem;
}
.center-login {
  padding: 0 0.1rem;
}
.window {
  width: 2rem;
  height: 0.7rem;
  background-color: #230607;
  position: absolute;
  right: 0.6rem;
  top: 0.7rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  .handoff,
  .quit {
    i {
      margin-right: 00.1rem;
    }
    padding-left: 0.2rem;
    flex: 1;
    text-align: left;
    line-height: 00.5rem;
    cursor: pointer;
    &:hover {
      background-color: #570d0f;
    }
  }
}
.border {
  width: 0.4rem;
  border-radius: 50%;
  padding: 0.15rem 0;
  background-size: cover;
}
  .proBtn{
    /*style="display: flex;line-height: 38px;margin-left: -150px;position: relative"*/
    display: flex;
    /*line-height: ;*/
    margin-left: -2.3rem;
  }
</style>
