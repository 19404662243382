var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AddGroup-template"},[_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.closeDialog}},[_c('div',{staticClass:"alreadyGroupBox"},[_c('h3',[_vm._v("已有分组")]),_c('div',{staticClass:"father"},_vm._l((_vm.alreadyGroupData),function(item,index){return _c('div',{key:index + 'group',class:[
            'groupItem',
            _vm.alreadyGroupActiveIndex == index ? 'activeAlreadyGroup' : '',
          ],on:{"click":function($event){return _vm.clickAlreadyGroup(item, index)}}},[_c('span',{staticClass:"groupIndex"},[_vm._v(_vm._s(_vm.numberTransform(index) + "组"))]),_c('span',[_vm._v(_vm._s(item.title1))]),_c('span',{staticClass:"line",attrs:{"id":"line"}},[_vm._v(" | ")]),_c('span',[_vm._v(_vm._s(item.title2))]),_c('span',[_vm._v(_vm._s(item.title3))])])}),0),(_vm.alreadyGroupData.length < 2)?_c('el-button',{staticClass:"addNewBnt",attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("新增")]):_vm._e()],1),(!_vm.isObjectNull && _vm.alreadyGroupData.length > 0)?_c('div',{staticClass:"slectGroupBox"},[_c('div',{staticClass:"annotation"},[_c('span',[_vm._v("注释：选择条件限制三个")])]),_c('div',{staticClass:"slectGroup"},[_c('h3',[_vm._v("性别")]),_c('div',{staticClass:"sex_row"},_vm._l((_vm.sexOption),function(item,index){return _c('div',{key:index + 'sex',class:[
              'groupPanel',
              _vm.alreadyGroupActiveItem.sex == item.label
                ? 'activePaneClass'
                : '',
            ],on:{"click":function($event){return _vm.handleItemClick(item, item.label, 'sex')}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('h3',[_vm._v("民族")]),_c('div',{staticClass:"nationality_row"},_vm._l((_vm.nationalityOption),function(item,index){return _c('div',{key:index + 'nationality',class:[
              'groupPanel',
              _vm.alreadyGroupActiveItem.nationality == item.label
                ? 'activePaneClass'
                : '',
            ],on:{"click":function($event){return _vm.handleItemClick(item, item.label, 'nationality')}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('h3',[_vm._v("年级")]),_c('div',{staticClass:"grade_row"},_vm._l((_vm.gradeOption),function(item,index){return _c('div',{key:index + 'grade',class:[
              'groupPanel',
              _vm.alreadyGroupActiveItem.grade == item.label
                ? 'activePaneClass'
                : '',
            ],on:{"click":function($event){return _vm.handleItemClick(item, item.label, 'grade')}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('h3',[_vm._v("年龄")]),_c('div',{staticClass:"age_row"},_vm._l((_vm.ageOption),function(item,index){return _c('div',{key:index + 'age',class:[
              'groupPanel',
              _vm.alreadyGroupActiveItem.age == item.label
                ? 'activePaneClass'
                : '',
            ],on:{"click":function($event){return _vm.handleItemClick(item, item.label, 'age')}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),_c('h3',[_vm._v("学科门类")]),_c('div',{staticClass:"subject_row"},_vm._l((_vm.subjectOption),function(item,index){return _c('div',{key:index + 'subject',class:[
              'groupPanel',
              _vm.alreadyGroupActiveItem.subject == item.label
                ? 'activePaneClass'
                : '',
            ],on:{"click":function($event){return _vm.handleItemClick(item, item.label, 'subject')}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)])]):_vm._e(),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }