<template>
  <!-- 概况 -->
  <div class="overview">
    <!-- 左侧的柱状图区域以及头部的标签 -->
    <div class="overview-center">
      <!-- 头部标签部分 -->
      <crumb></crumb>
      <!-- 底部柱状图部分 -->
      <Histogram :age="age" v-on:son-click="changeRadar" ref="tab" />
    </div>
    <!-- 右侧的雷达图部分以及结论 -->
    <div class="overview-right">
      <div class="overview-right-top">
        <div class="ranking">
          <div class="fenbu">
            <!-- 右上角分析器标题 -->
            <h3 class="fenbu-title">{{ rightData.columnLabel }}</h3>
          </div>
          <div class="lookchart">
            <!-- 右上角标题的内容 -->
            <div id="lookpie">
              {{ rightData.description }}
            </div>
          </div>
        </div>
      </div>
      <div class="overview-right-bottom">
        <div class="fenbu">
          <!-- 右下角分析器标题 -->
          <h3 class="radar-title fenbu-title">{{ rightData.questionTitle }}</h3>
        </div>
        <!-- 右下角雷达图组件 -->
        <Radar :radarData="radarData" ref="tab2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Histogram from "../../components/Histogram.vue";
import Radar from "../../components/Radar.vue";
import {
  queryTargetValuesForGraph,
  targetQuestionDetailForGraph,
} from "../../api/modular/analyse/analyse";
import crumb from "@/components/crumb";
export default {
  components: {
    Histogram,
    Radar,
    crumb,
  },
  data() {
    return {
      // 柱状图的数据
      age: {
        name: "全国",
        data: [],
        provinceName: "",
        provinceData: [],
        schoolName: "",
        schoolData: [],
        Xname: "", //这个可以不用传入
        Xdata: ["人生目标", "人生态度", "人生价值"],
      },
      // 雷达图的数据
      radarData: {
        name: "全国",
        data: [20, 20, 20],
        provinceName: "湖北省",
        provinceData: [30, 30, 30],
        schoolName: "武汉大学",
        schoolData: [50, 50, 50],
        Xname: "", //这个可以不用传入
        Xdata: ["自由", "民主", "和谐"],
      },
      // 右侧三个的对象
      rightData: {
        // 右侧头部标题
        columnLabel: `人生目标分析说明`,
        // 右侧头部内容
        description:
          "人生目标是人在相当长的一段时间、甚至是终其一生需要达成的状态，是对自己理想的实现，也可以称之为理想目标。",
        // 右侧下面标题
        questionTitle: "您是否拥有人生目标？",
      },
      rightTitlr: "人生目标",
    };
  },
  created() {},
  mounted() {
    // 判断如果有学校，就用学校的数据生成
    if (this.getCity) {
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      this.targetQuestionDetailForGraph(this.age.Xdata[0], this.getCity);
    } else {
      this.queryTargetValuesForGraph();
      this.targetQuestionDetailForGraph(this.age.Xdata[0]);
    }
  },
  computed: {
    ...mapGetters(["getCity", "getSchool", "getTargetLevel1"]),
  },
  methods: {
    // 将省份添加后缀的函数
    changeProvince(provinceName) {
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = provinceName;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      provinceName = city;
      return provinceName;
    },
    // 查询中间柱状图的分数值,并且赋值
    async queryTargetValuesForGraph(provinceName, schoolName) {
      // 加载柱状图的loding
      let columnEchart = this.$echarts.init(this.$refs.tab.$refs.headertab);
      columnEchart.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1)",
      });
      // 加载雷达图的loding
      let lookpieOption = this.$echarts.init(this.$refs.tab2.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1)",
      });
      // if (provinceName) {
      //   let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //   //处理直辖市的数据
      //   let central = ["北京", "天津", "上海", "重庆"];
      //   let city = provinceName;
      //   if (autonomousRegion.indexOf(city) !== -1) {
      //     city = city == "新疆" ? "新疆维吾尔自治区" : city;
      //     city = city == "宁夏" ? "宁夏回族自治区" : city;
      //     city = city == "广西" ? "广西壮族自治区" : city;
      //     city = city == "西藏" ? "西藏自治区" : city;
      //     city = city == "内蒙古" ? "内蒙古自治区" : city;
      //   } else if (central.indexOf(city) !== -1) {
      //     city = city + "市";
      //   } else {
      //     city = city + "省";
      //   }
      //   provinceName = city;
      // }
      // 将省份的名字添加后缀
      if (provinceName) {
        provinceName = this.changeProvince(provinceName);
      }
      let str = await queryTargetValuesForGraph({
        year: 2020,
        themeName: "思想政治状况",
        targetName: this.getTargetLevel1,
        province: provinceName || "",
        universityName: schoolName || "",
      });
      // 定义柱状图的数据，最后赋值改变柱状图
      let countrydata = {
        name: "全国",
        data: [],
        provinceName: provinceName,
        provinceData: [],
        schoolName: schoolName,
        schoolData: [],
        Xname: "x轴", //这个可以不用传入
        Xdata: [],
      };
      // console.log("指标的分数值",str);
      // 如果是没有省份和学校，将全国的数据填充进去
      if (!provinceName && !schoolName) {
        // 全国的数据
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
      }
      // 如果只有省份，没有学校，将全国和省份的数据填充进去
      if (provinceName && !schoolName) {
        countrydata = {
          name: "全国",
          data: [],
          provinceName: provinceName,
          provinceData: [],
          Xname: "x轴", //这个可以不用传入
          Xdata: [],
        };
        // 全国的数据
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
        // 全省份的数据
        str.data.province.list.map((item) => {
          countrydata.provinceData.push(item.targetValue);
        });
        console.log(countrydata);
      }
      // 如果有省份和学校的数据，将全部数据填充进去
      else if (provinceName && schoolName) {
        // 全国的数据
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
        // 省份的数据
        str.data.province.list.map((item) => {
          countrydata.provinceData.push(item.targetValue);
        });
        // 学校的数据
        str.data.university.list.map((item) => {
          countrydata.schoolData.push(item.targetValue);
        });
      }
      // console.log('全国的值',countrydata);
      this.age = countrydata;
      // 调用右侧雷达图和介绍接口
      this.targetQuestionDetailForGraph(
        countrydata.Xdata[0],
        this.getCity,
        this.getSchools
      );
    },
    // 查询雷达图以及右侧内容的数据，并且赋值
    async targetQuestionDetailForGraph(targetName, provinceName, schoolName) {
      if (provinceName) {
        provinceName = this.changeProvince(provinceName);
      }
      let str = await targetQuestionDetailForGraph({
        year: 2020,
        themeName: "思想政治状况",
        targetName,
        province: provinceName || "",
        universityName: schoolName || "",
      });
      console.log("雷达图的数据", str);
      // 定义数据
      let countrydata = {
        name: "全国",
        data: str.data.data.country.values,
        provinceName: provinceName || "",
        provinceData: str.data.data.province
          ? str.data.data.province.values
          : [],
        schoolName: schoolName || "",
        schoolData: str.data.data.university
          ? str.data.data.university.values
          : [],
        Xname: "x轴", //这个可以不用传入
        Xdata: str.data.data.country.cols,
      };
      // 右侧其他的文字数据
      this.rightData = {
        // 右侧头部标题
        columnLabel: `${targetName}分析说明`,
        // 右侧头部内容
        description: str.data.description,
        // 右侧下面标题
        questionTitle: str.data.questionTitle,
      };
      // 赋值雷达图数据
      this.radarData = countrydata;
    },
    // 子传父函数，点击中间柱状图更新右侧内容
    changeRadar(item) {
      // 加载雷达图加载动画的函数
      let lookpieOption = this.$echarts.init(this.$refs.tab2.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1)",
      });
      // 雷达图赋值
      this.targetQuestionDetailForGraph(
        item,
        this.getCity || "",
        this.getSchool || ""
      );
    },
  },
  watch: {
    getSchool() {
      // 监听后更新柱状图更新雷达图和右侧内容
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
    },
    getCity() {
      // 监听后更新柱状图更新雷达图和右侧内容
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
    },
    getTargetLevel1() {
      // 监听后更新柱状图更新雷达图和右侧内容
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  height: 100%;
  width: 100%;
  display: flex;
  .overview-center {
    height: 84vh;
    flex: 4;
    margin-right: 0.2rem;
    position: relative;
    background: url(@/assets/img/center.png) no-repeat bottom;
    background-size: cover;
    .overview-center-title-right {
      position: absolute;
      right: 0px;
      top: 0.13rem;
    }
    .overview-center-title {
      height: 0.4rem;
      margin-bottom: 1rem;
      line-height: 0.4rem;
      border: 1px solid #470201;
      border-right: none;
      border-left: none;
      display: flex;
      justify-content: space-between;
    }
  }
  .overview-right {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    .fenbu {
      // height: 0.45rem;
      border-radius: 0.05rem 0rem 0rem 0rem;
      .fenbu-title {
        height: 0.45rem;
        line-height: 0.45rem;
        padding: 0 0 0.1rem 0.17rem;
        position: relative;
        font-size: 14px;
        font-family: "微软雅黑";
        &:before {
          content: "";
          width: 0.05rem;
          height: 0.25rem;
          background-color: #a40000;
          position: absolute;
          top: 0.1rem;
          left: 0;
        }
        // background-color: rgba(44, 3, 3, 0.7);
        background-image: linear-gradient(
          to right,
          rgba(44, 3, 3, 0.7),
          rgba(44, 3, 3, 0)
        );
        color: #fff;
        // border-left: red 0.05rem solid;
      }
    }
    .overview-right-top {
      flex: 0 0 5rem;
      .ranking {
        height: 100%;
      }
      #lookpie {
        width: 5.6rem;
        height: 100%;
        /*margin: 0.2rem 0.5rem 0 0.2rem;*/
        padding: 0.2rem 0 0 0.2rem;
        line-height: 0.35rem;
        color: #74c6f5;
      }
      .lookchart {
        width: 6rem;
        // height: 5rem;
        background: rgba(17, 18, 22, 0.5);

        .looktitle {
          font-size: 0.1875rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cccccc;
          position: relative;
          padding-left: 0.175rem;
          margin-left: 0.1875rem;
        }
        .looktitle::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.05rem;
          height: 0.225rem;
          background: #0079fe;
        }
      }
    }
    .overview-right-bottom {
      flex: 1;
    }
  }
}
.radar-title {
  // margin-top: 2.35rem;
  // overflow: hidden;
  word-break: break-all;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  z-index: 9999;
}
</style>
