<template>
  <div>
    <iscrollWrapper v-show="!this.isShowDes">
      <ul class="father" v-if="schoolShow">
        <li
          v-for="(item, index) in schoolList"
          :key="item.id"
          @click="schoolBtn(item, index)"
          ref="schoolItem"
        >
          <span class="left">{{ item.id + "、" }}{{ item.name }}</span>
          <span class="right">查看详情 ></span>
        </li>
      </ul>
      <ul v-if="!schoolShow">
        <span>该区域暂无数据</span>
      </ul>
    </iscrollWrapper>
    <schoolDescribe
      v-if="this.isShowDes"
      :schoolName="this.currentSchool"
    ></schoolDescribe>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getSchoolList } from "@/api/modular/analyse/analyse";
import iscrollWrapper from "@/components/iscrollWrapper";
import schoolDescribe from "@/components/schoolDescribe";
export default {
  name: "SchoolList",
  data() {
    return {
      isShowDes: false,
      schoolList: [],
      year: 2020,
      themeName: "思想政治状况",
      activeSchool: "",
      // 权限
      role: [],
      // 具体名称
      name: {},
      currentSchool: "",
      schoolShow: true,
    };
  },
  created() {
    /*组件创建的时候先获取所有学校*/
    /*
    let city = JSON.parse(localStorage.getItem("info")).province*/

    /*  console.log('学校:' +this.currentSchool)
    console.log("城市:" + city)*/
    /* if (city == '全国'){
      city = ''
    }*/
    /*  console.log('rrr')
    console.log(city)*/
    // /*  console.log('vuex')*/
    //   console.log(this.getSchoolListCity)
    this.isShowDes =
      JSON.parse(localStorage.getItem("role"))[0].name == "高校" ? true : false; //高校
    this.currentSchool = JSON.parse(localStorage.getItem("info")).orgName;
    let city = this.getCity;
    if (city) {
      //处理自治区数据
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
    }
    this.getAllSchool(city);
    // console.log(this.schoolList);
  },
  mounted() {
    // 首页进来后如果有学校左下角高亮
    if (this.getSchool) {
      setTimeout(() => {
        this.schoolList.map((item, index) => {
          if (item.name == this.getSchool) {
            // console.log(item);
            for (let i = 0; i < this.$refs.schoolItem.length; i++) {
              this.$refs.schoolItem[i].classList.remove("active");
            }
            this.$refs.schoolItem[index].classList.add("active");
          }
        });
        console.log(this.schoolList);
      }, 1000);
    }
  },

  // 测试账号密码123456：quanguo1  hubei  wuda1
  methods: {
    ...mapActions(["setCity", "setSchool"]),
    /*学校点击事件*/
    schoolBtn(item, index) {
      /*设置学校*/
      let city = item.city;
      if (city.endsWith("自治区")) {
        city = city == "新疆维吾尔自治区" ? "新疆" : city;
        city = city == "宁夏回族自治区" ? "宁夏" : city;
        city = city == "广西壮族自治区" ? "广西" : city;
        city = city == "西藏自治区" ? "西藏" : city;
        city = city == "内蒙古自治区" ? "内蒙古" : city;
      } else {
        city = city.slice(0, city.length - 1);
      }

      this.setCity(city);
      this.setSchool(item.name);
      this.activeSchool = item.name;
      /*添加选中的学校样式*/
      for (let i = 0; i < this.$refs.schoolItem.length; i++) {
        this.$refs.schoolItem[i].classList.remove("active");
      }
      this.$refs.schoolItem[index].classList.add("active");
    },
    /*获取所有学校*/
    getAllSchool(city) {
      /*if (JSON.parse(localStorage.getItem("role"))[0].name !== '全国'){
        return
      }
*/
      // if (this.$refs.schoolItem) {
      //   for (let i = 0; i < this.$refs.schoolItem.length; i++) {
      //     this.$refs.schoolItem[i].classList.remove("active");
      //   }
      // }
      getSchoolList({
        year: this.year,
        themeName: this.themeName,
        province: city,
      }).then((data) => {
        console.log(data);
        if (data.code == 200) {
          this.schoolShow = true;
          this.schoolList = [];
          for (let i = 0; i < data.data.list.length; i++) {
            let obj = {
              id: i + 1,
              name: data.data.list[i].name,
              city: data.data.list[i].province,
            };
            this.schoolList.push(obj);
          }
          // console.log(this.schoolList);
          // console.log(data.data.list);
          // debugger
          if (data.data.list.length === 0) {
            // debugger
            this.schoolShow = false;
            this.$message({
              message: "该区域暂无数据",
              type: "warning",
            });
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getCity", "getSchool", "getSchoolListCity"]),
  },
  watch: {
    // getSchoolListCity: function (newValue) {
    //   // debugger
    //   // this.setCity(newValue);
    //   let city = newValue;
    //   if (city) {
    //     //处理自治区数据
    //     let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
    //     //处理直辖市的数据
    //     let central = ["北京", "天津", "上海", "重庆"];
    //     if (autonomousRegion.indexOf(city) !== -1) {
    //       city = city == "新疆" ? "新疆维吾尔自治区" : city;
    //       city = city == "宁夏" ? "宁夏回族自治区" : city;
    //       city = city == "广西" ? "广西壮族自治区" : city;
    //       city = city == "西藏" ? "西藏自治区" : city;
    //       city = city == "内蒙古" ? "内蒙古自治区" : city;
    //     } else if (central.indexOf(city) !== -1) {
    //       city = city + "市";
    //     } else {
    //       city = city + "省";
    //     }
    //   }
    //   this.getAllSchool(city);
    // },
    getSchool() {
      if (!this.getSchool) {
        for (let i = 0; i < this.$refs.schoolItem.length; i++) {
          this.$refs.schoolItem[i].classList.remove("active");
        }
      }
    },
    getCity(newName, old) {
      if (newName == "") {
        this.getAllSchool();
      }else{
              let city = newName;
        //处理自治区数据
        let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        //处理直辖市的数据
        let central = ["北京", "天津", "上海", "重庆"];
        if (autonomousRegion.indexOf(city) !== -1) {
          city = city == "新疆" ? "新疆维吾尔自治区" : city;
          city = city == "宁夏" ? "宁夏回族自治区" : city;
          city = city == "广西" ? "广西壮族自治区" : city;
          city = city == "西藏" ? "西藏自治区" : city;
          city = city == "内蒙古" ? "内蒙古自治区" : city;
        } else if (central.indexOf(city) !== -1) {
          city = city + "市";
        } else {
          city = city + "省";
        }
        console.log(city);
        // debugger
      this.getAllSchool(city);
      }
    },
  },
  components: {
    iscrollWrapper,
    schoolDescribe,
  },
};
</script>

<style lang="scss" scoped>
div {
  /*background-color: red;*/
  width: 100%;
  height: 4.2rem;
  overflow: hidden;
}
ul {
  width: 100%;
  height: auto;
  padding-top: 0.15rem;
  li {
    width: 100%;
    line-height: 00.5rem;
    height: 0.5rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .right {
      color: #0082ff;
      font-size: 0.16rem;
    }
    &.active {
      color: #0082ff;
      font-weight: 600;
      // font-size: 0.16rem;
    }
  }
  li:nth-child(odd) {
    //双行
    // background: red;
    // background-color: red;
    background-color: rgba(3, 57, 110, 0.2);
  }
  li:nth-child(even) {
    //单行
    // background: yellow;
  }
  // li {
  //   /*background-color: red;*/
  //   /*margin: -0.1rem 0;*/
  //   vertical-align: center;
  //   width: 100%;
  //   height: 0.4rem;
  //   line-height: 0.4rem;
  //   cursor: pointer;
  //   padding: 0 0.1rem;
  //   .left {
  //     float: left;
  //   }
  //   .right {
  //     float: right;
  //     color: #3092f1;
  //   }
  //   &.active {
  //     background-color: #2695ff;
  //     span {
  //       color: #fff;
  //     }
  //   }
  // }
}
</style>
