<template>
  <!-- 相关分析 -->
  <div class="overview">
    <!-- 左侧的柱状图区域以及头部的标签 -->
    <div class="overview-center">
      <crumb></crumb>
      <span class="selectBtn" @click="openDialog">
        <i class="iconfont icon-funnelloudou"></i>
        指标筛选</span
      >
      <!-- 留白区域 -->
      <div class="borderNone">
        <div class="imgbox">
          <div class="relevant-y">
            <img src="../../assets/img/weidu/relevant_y.png" alt="" />
          </div>
          <div class="shuzhi bold" v-show="centerItem.topItem">
            {{ leftToTop }}
          </div>
          <div class="shuzhi2 bold" v-show="centerItem.topItem">
            {{ rightToTop }}
          </div>
          <div
            class="shuzhi3 bold"
            v-show="centerItem.topItem && centerItem.bottomItem"
          >
            {{ topToBottom }}
          </div>
          <div class="shuzhi4 bold" v-show="centerItem.bottomItem">
            {{ rightToBottom }}
          </div>
          <div class="shuzhi5 bold">{{ leftToRight }}</div>
          <div class="shuzhi6 bold" v-show="centerItem.bottomItem">
            {{ leftToBottom }}
          </div>
          <div
            v-show="centerItem.leftItem"
            class="left-img"
            @click="setRadar(centerItem.leftItem)"
          ></div>
          <div
            v-show="centerItem.topItem"
            class="top-img"
            @click="setRadar(centerItem.topItem)"
          ></div>
          <div
            v-show="centerItem.rightItem"
            class="right-img"
            @click="setRadar(centerItem.rightItem)"
          ></div>
          <div
            v-show="centerItem.bottomItem"
            class="bottom-img"
            @click="setRadar(centerItem.bottomItem)"
          ></div>
          <div
            :class="'shuzhi' + (1 + index)"
            v-for="(item, index) in resultList"
            :key="item.index"
          >
            {{ item }}
          </div>
          <div v-show="centerItem.leftItem" class="ideal">
            {{ centerItem.leftItem }}
          </div>
          <div v-show="centerItem.topItem" class="attitude">
            {{ centerItem.topItem }}
          </div>
          <div v-show="centerItem.rightItem" class="value">
            {{ centerItem.rightItem }}
          </div>
          <div v-show="centerItem.bottomItem" class="look">
            {{ centerItem.bottomItem }}
          </div>
          <div class="bj5">
            <div class="yuan"></div>
          </div>
          <div class="bj6"></div>
          <div class="bj7"></div>
          <div class="bj8"></div>
          <div class="bj9"></div>
          <div class="bj10"></div>
        </div>
        <div class="second-nav">{{ centerValue }}</div>
      </div>
    </div>
    <!-- 右侧的雷达图部分以及结论 -->
    <div class="overview-right">
      <div class="overview-right-top">
        <div class="ranking">
          <div class="fenbu">
            <!-- 右上角分析器标题 -->
            <h3 class="fenbu-title">{{ currentTarget }}</h3>
          </div>
          <div class="lookchart">
            <!-- 右上角标题的内容 -->
            <div id="lookpie">
              {{ currentTargetValue }}
            </div>
            <div class="fenbu">
              <!-- 右下角分析器标题 -->
              <h3 class="fenbu-title">{{ radarTitle }}</h3>
            </div>
            <Radar :radarData="age" class="radar" ref="radar" />
          </div>
        </div>
      </div>
      <div class="overview-right-bottom">
        <div class="fenbu">
          <!-- 右下角分析器标题 -->
          <h3 class="fenbu-title">分析结论</h3>
          <p ref="rValue" class="conclusionText1"></p>
        </div>
        <!--<p class="r-value">R值:{{rNum}}</p>-->
        <!-- 右下角雷达图组件 -->
      </div>
    </div>
    <correlationDialog
      ref="corDialog"
      @getTarget="getSubTargetList"
    ></correlationDialog>
  </div>
</template>

<script>
import Histogram from "../../components/Histogram.vue";
import Radar from "../../components/Radar.vue";
import Ring from "../../components/Ring.vue";
import crumb from "@/components/crumb";
import correlationDialog from "@/components/correlationDialog";
import { mapGetters, mapActions } from "vuex";
import {
  queryOneTwoTarget,
  targetQuestionDetailForGraph,
  correlation,
} from "@/api/api";
export default {
  components: {
    Histogram,
    Radar,
    Ring,
    crumb,
    correlationDialog,
  },
  data() {
    return {
      year: 2020,
      themeName: "思想政治状况",
      /*中间内容*/
      centerValue:
        "相关值在[-1,0]负相关[0,1]正相关；绝对值越大表示相关性越强(0.8~1非常强 0.6~0.8强相关 0.4~0.6中度相关 0.2~0.4弱相关 0~0.2无关)",
      /*  /!*r标题*!/
      rTitle:'',*/
      /*r值*/
      rNum: 0.001,
      /*r内容*/
      rValue: "",
      /*雷达图标题*/
      radarTitle: "对于下列说法，您的看法是：",
      /*分析标题*/
      currentTarget: "人生目标分析解读",
      /*分析内容*/
      currentTargetValue:
        "人生目标是人在相当长的一段时间、甚至是终其一生需要达成的状态，是对自己理想的实现，也可以称之为理想目标。",
      age: {
        name: "全国",
        data: [0.57, 2.2, 12.03, 64.4, 20.79],
        Xname: "x轴", //这个可以不用传入
        Xdata: ["完全没有", "很不确定", "说不清楚", "有大体方向", "有明确目标"],
      },
      ringData: 70,
      resultList: [],
      /*中间项数值*/
      leftToTop: "",
      leftToBottom: "",
      leftToRight: "",
      rightToTop: "",
      rightToBottom: "",
      topToBottom: "",
      /*中间二级指标项*/
      centerItem: {
        leftItem: "人生目标",
        rightItem: "人生态度",
        topItem: "人生价值",
        bottomItem: "价值认知",
      },
    };
  },
  created() {},
  mounted() {
    /*this.$refs.radar.lookpie1(this.age)*/
    let arr = [];
    for (let item in this.centerItem) {
      arr.push(this.centerItem[item]);
    }
    arr.length = 4;
    this.setCenterValue(arr);
  },
  methods: {
    // 将省份添加后缀的函数
    changeProvince(provinceName) {
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = provinceName;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      provinceName = city;
      return provinceName;
    },
    // 设置雷达图的数据
    setRadar(target) {
      console.log(target);
      // 加载雷达图的loding
      let lookpieOption = this.$echarts.init(this.$refs.radar.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      let city = this.getCity;
      if (city) {
        // //处理自治区数据
        // let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        // //处理直辖市的数据
        // let central = ["北京", "天津", "上海", "重庆"];
        // if (autonomousRegion.indexOf(city) !== -1) {
        //   city = city == "新疆" ? "新疆维吾尔自治区" : city;
        //   city = city == "宁夏" ? "宁夏回族自治区" : city;
        //   city = city == "广西" ? "广西壮族自治区" : city;
        //   city = city == "西藏" ? "西藏自治区" : city;
        //   city = city == "内蒙古" ? "内蒙古自治区" : city;
        // } else if (central.indexOf(city) !== -1) {
        //   city = city + "市";
        // } else {
        //   city = city + "省";
        // }
        city = this.changeProvince(city);
      }
      targetQuestionDetailForGraph({
        year: this.year,
        themeName: this.themeName,
        targetName: target,
        province: city,
        universityName: this.getSchool,
      }).then((data) => {
        console.log(data);
        if (data.code == 200) {
          console.log("ppppppppppp");
          console.log(data.data);
          /*设置右侧分析解读*/
          this.currentTarget = target + "分析解读";
          this.currentTargetValue = data.data.description;
          /*设置雷达图*/
          this.radarTitle = data.data.questionTitle;
          /*this.$refs.radar.lookpie1(this.age)*/
          let radarValue = {
            name: "全国",
            data: data.data.data.country.values,
            provinceName: "",
            provinceData: "",
            schoolName: "",
            schoolData: "",
            Xname: "x轴", //这个可以不用传入
            Xdata: data.data.data.country.cols,
          };

          if (data.data.data.province) {
            radarValue.provinceName = city;
            radarValue.provinceData = data.data.data.province.values;
          }

          if (data.data.data.university) {
            radarValue.schoolName = this.getSchool;
            radarValue.schoolData = data.data.data.university.values;
          }

          this.$refs.radar.lookpie1(radarValue);
        }
      });
    },
    /*打开筛选指标弹窗*/
    openDialog() {
      this.$refs.corDialog.openDialog();
    },
    /*获取指标筛选结果*/
    getSubTargetList(list) {
      console.log("结果", list);

      this.setCenterValue(list);
    },
    /*设置中间数值*/
    setCenterValue(item) {
      this.setRadar(item[0]);
      let city = this.getCity;
      if (city) {
        //处理自治区数据
        city = this.changeProvince(city);
      }
      let keys = Object.keys(this.centerItem);
      this.centerItem = {
        leftItem: "",
        rightItem: "",
        topItem: "",
        bottomItem: "",
      };
      for (let i = 0; i < keys.length; i++) {
        this.centerItem[keys[i]] = item[i];
      }

      /*设置中间数值*/
      correlation({
        year: this.year,
        themeName: this.themeName,
        universityName: this.getSchool,
        province: city,
        subTargetNames: item.join(),
      }).then((data) => {
        /*console.log(data.data.conclusion)*/
        if (data.code == 200) {
          /*设置页码文本内容*/
          this.centerValue = data.data.remark;
          this.$refs.rValue.innerHTML = data.data.conclusion;

          let keys = Object.keys(data.data.data);
          let objArr = [];
          let arr = [];
          for (let i = 0; i < keys.length; i++) {
            arr.push(keys[i].split("-")[0]);
            arr.push(keys[i].split("-")[1]);
            let obj = {
              name: keys[i],
              value: data.data.data[keys[i]],
            };
            objArr.push(obj);
          }
          for (let i = 0; i < objArr.length; i++) {
            let res = objArr[i].name.split("-");
            if (
              res.includes(this.centerItem.leftItem) &&
              res.includes(this.centerItem.topItem)
            ) {
              this.leftToTop = objArr[i].value;
            }
            if (
              res.includes(this.centerItem.leftItem) &&
              res.includes(this.centerItem.bottomItem)
            ) {
              this.leftToBottom = objArr[i].value;
            }
            if (
              res.includes(this.centerItem.leftItem) &&
              res.includes(this.centerItem.rightItem)
            ) {
              this.leftToRight = objArr[i].value;
            }
            if (
              res.includes(this.centerItem.rightItem) &&
              res.includes(this.centerItem.topItem)
            ) {
              this.rightToTop = objArr[i].value;
            }
            if (
              res.includes(this.centerItem.rightItem) &&
              res.includes(this.centerItem.bottomItem)
            ) {
              this.rightToBottom = objArr[i].value;
            }
            if (
              res.includes(this.centerItem.bottomItem) &&
              res.includes(this.centerItem.topItem)
            ) {
              this.topToBottom = objArr[i].value;
            }
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getCity", "getSchool", "getTargetLevel1"]),
  },
  watch: {
    getTargetLevel1: function (newValue, oldValue) {
      queryOneTwoTarget({
        targetName: newValue,
      }).then((data) => {
        if (data.code == 200) {
          let arr = data.data.list.map((item) => {
            return item.name;
          });
          arr.length = 4;
          /*let keys = Object.keys(this.centerItem)
          this.centerItem = {
            leftItem:'',
            rightItem:'',
            topItem:'',
            bottomItem:'',
          }
          for (let i = 0;i < keys.length;i++){
            this.centerItem[keys[i]] = data.data.list[i].name
          }*/
          this.setCenterValue(arr);
        }
      });
    },
    getCity: function (newValue, oldValue) {
      console.log(newValue, oldValue);
      let arr = [];
      for (let item in this.centerItem) {
        arr.push(this.centerItem[item]);
      }
      arr.length = 4;
      this.setCenterValue(arr);
    },
    getSchool: function (newValue, oldValue) {
      console.log(newValue, oldValue);
      console.log(this.centerItem);
      let arr = [];
      for (let item in this.centerItem) {
        arr.push(this.centerItem[item]);
      }
      arr.length = 4;
      this.setCenterValue(arr);
    },
  },
};
</script>

<style lang="scss" scoped>
/*@import "../../assets/scss/correlation.scss";*/
.overview {
  height: 100%;
  width: 100%;
  display: flex;
  //   margin-top: 0.25rem;
  .overview-center {
    height: 100%;
    flex: 4;
    margin-right: 0.2rem;
    .overview-center-title {
      height: 0.4rem;
      margin-bottom: 1rem;
      line-height: 0.4rem;
      border: 1px solid #470201;
      border-right: none;
      border-left: none;
      display: flex;
      justify-content: space-between;
    }
    .selectBtn {
      /*color: red;*/
      position: relative;
      left: 9.8rem;
      top: -0.4rem;
      cursor: pointer;
      font-size: 14px;
      i {
        font-size: 14px;
      }
    }
    .borderNone {
      width: 100%;
      height: 10.5rem;
      background: url("../../assets/img/bj9.png") no-repeat;
      background-size: cover;
    }
  }
  .overview-right {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    // .ranking {
    //   height: 5rem;
    // }
    .fenbu {
      height: 0.45rem;
      border-radius: 0.05rem 0rem 0rem 0rem;
      .fenbu-title {
        padding: 0.1rem 0 0.1rem 0.17rem;
        position: relative;
        font-family: "微软雅黑";
        font-size: 14px;
        &:after {
          content: "";
          width: 0.05rem;
          height: 0.25rem;
          background-color: #a40000;
          position: absolute;
          left: 0;
          margin: auto 0;
        }
        height: 0.5rem;
        line-height: 00.35rem;
        background-image: linear-gradient(
          to right,
          rgba(44, 3, 3, 0.7),
          rgba(44, 3, 3, 0)
        );
        color: #fff;
      }
    }
    .overview-right-top {
      height: 5rem;
      #lookpie {
        width: 5.2rem;
        height: 1rem;
        margin-top: 0.2rem;
        margin-left: 0.2rem;
        font-size: 0.2rem;
        line-height: 0.35rem !important;
        color: #74c6f5;
      }
      .lookchart {
        width: 6rem;
        height: 11.3rem;
        background: rgba(17, 18, 22, 0.5);

        .looktitle {
          font-size: 0.1875rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cccccc;
          position: relative;
          padding-left: 0.175rem;
          margin-left: 0.1875rem;
        }

        .looktitle::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.05rem;
          height: 0.225rem;
          background: #0079fe;
        }
      }
    }
    .overview-right-bottom {
      flex: 1;
      margin: 3rem 0 0 !important;
    }

    .shuzhi {
      position: absolute;
      left: 3.2rem;
      top: 0.4rem;
    }
    .second-center-img {
      position: relative;
    }
  }
  .bj5 {
    width: 7.375rem;
    height: 7.375rem;
    transition: all 0.3s;
    position: absolute;
    top: 0.6rem;
    left: 1.5rem;
    border-radius: 50%;
    z-index: 10;
    // -webkit-transform: rotateX(-48deg);
    animation: xx 5s infinite linear;
    border: 0.03rem solid #1f6379;
    margin-top: -1.45rem;

    .yuan {
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 2px;
      background: linear-gradient(to left, #28ebc0, #276378);
      position: absolute;
      top: 0rem;
      left: 3rem;
    }
  }

  @keyframes xx {
    0% {
      transform: rotateX(65deg) rotateZ(0);
    }

    100% {
      transform: rotateX(65deg) rotateZ(360deg);
    }
  }

  .bj6 {
    width: 0.25rem;
    height: 0.25rem;
    background: rgba(46, 223, 231, 0.8);

    position: absolute;
    border-radius: 50%;
    left: 1.2rem;
    bottom: -0.35rem;
    animation: bb 3s infinite linear;
  }

  .bj7 {
    width: 0.25rem;
    height: 0.25rem;
    background: rgba(46, 223, 231, 0.6);

    position: absolute;
    border-radius: 50%;
    left: 3.5625rem;
    bottom: -0.875rem;
    animation: bb 3s infinite linear;
  }

  .bj8 {
    width: 0.25rem;
    height: 0.25rem;
    background: rgba(46, 223, 231, 0.4);
    position: absolute;
    border-radius: 50%;
    left: 4.6rem;
    bottom: -0.1rem;
    animation: bb 3s infinite linear;
  }

  .bj9 {
    width: 0.25rem;
    height: 0.25rem;
    background: rgba(46, 223, 231, 0.4);
    position: absolute;
    border-radius: 50%;
    left: 5.6rem;
    bottom: -1.2rem;
    animation: bb 3s infinite linear;
  }

  .bj10 {
    width: 0.25rem;
    height: 0.25rem;
    background: rgba(46, 223, 231, 0.4);
    position: absolute;
    border-radius: 50%;
    left: 8.2rem;
    bottom: -0.8rem;
    animation: bb 3s infinite linear;
  }

  @keyframes bb {
    0% {
      box-shadow: 0rem 0rem 0.0125rem rgb(46, 223, 231);
    }

    100% {
      box-shadow: 0rem 0rem 0.25rem rgb(46, 223, 231);
    }
  }
  .imgbox {
    width: 100%;
    height: 5rem;
    margin-top: 1.625rem;
    position: relative;
    left: 0.65rem !important;
    font-size: 16px !important;

    > img {
      width: 100%;
      height: 5rem;
      object-fit: contain;
      position: absolute;
      left: 0;
      z-index: 11;
    }
  }

  .imgbox {
    width: 100%;
    height: 6.25rem;
    margin-top: 0.625rem;
    position: relative;
    left: 0;

    #province {
      height: 6.25rem;
    }
  }
  .bold {
    font-weight: 600;
  }
  .shuzhi {
    position: absolute;
    left: 2.81rem;
    top: 0.9rem;
    color: #fff;
    z-index: 30;
    // font-size: 14px;
  }

  .shuzhi2 {
    position: absolute;
    left: 7.7rem !important;
    top: 1rem !important;
    color: #fff;
    z-index: 30;
    // font-size: 14px;
  }

  .shuzhi3 {
    position: absolute;
    right: 2.63rem !important;
    top: 2.45rem !important;
    color: #fff;
    z-index: 30;
    // font-size: 14px;
  }

  .ideal {
    position: absolute;
    left: 1rem !important;
    top: 1.7rem !important;
    // font-size: 14px;
  }
  .attitude {
    position: absolute;
    left: 4.7rem !important;
    top: 0.2rem !important;
    // font-size: 14px;
  }
  .value {
    position: absolute;
    right: 2.4rem !important;
    top: 1.6rem !important;
    // font-size: 14px;
  }

  .look {
    position: absolute;
    right: 6rem;
    bottom: 0.8rem;
  }
  .shuzhi1 {
    position: absolute;

    left: 3.125rem;
    top: 0.375rem;
    color: #e1fef9;
  }

  .shuzhi2 {
    position: absolute;
    left: 8.05rem;
    top: 0.525rem;
    color: #e1fef9;
  }

  .shuzhi3 {
    position: absolute;
    left: 6.875rem;
    top: 1.825rem;
    color: #e1fef9;
  }

  .shuzhi4 {
    position: absolute;
    left: 7.8rem;
    bottom: 1.7rem;
    color: #e1fef9;
  }

  .shuzhi5 {
    position: absolute;
    left: 4.15rem;
    bottom: 2.43rem;
    color: #e1fef9;
  }

  .shuzhi6 {
    position: absolute;
    left: 2.3rem;
    bottom: 1.8rem;
    color: #e1fef9;
  }

  .relevant-y {
    display: inline-block;
    position: absolute;
    left: 1.5rem;
    top: 1.1rem;
    /*background-color: red;*/
    width: 7.4rem;
    height: 3.3rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .left-img {
    width: 1.5rem;
    height: 1.5rem;
    /*background-color: red;*/
    position: absolute;
    left: 0.9rem;
    top: 2.2rem;
    z-index: 99;
    background: url("../../assets/img/weidu/relevant_1.png") no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: transform 1s;
    &:hover {
      transform: scale(1.3);
    }
  }
  .top-img {
    width: 1.5rem;
    height: 1.5rem;
    /*background-color: red;*/
    position: absolute;
    right: 5.8rem;
    top: 0.6rem;
    z-index: 99;
    background: url("../../assets/img/weidu/relevant_3.png") no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: transform 1s;
    &:hover {
      transform: scale(1.3);
    }
  }

  .right-img {
    width: 1.5rem;
    height: 1.5rem;
    /*background-color: red;*/
    position: absolute;
    right: 2.3rem;
    top: 2.1rem;
    z-index: 99;
    background: url("../../assets/img/weidu/relevant_2.png") no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: transform 1s;
    &:hover {
      transform: scale(1.3);
    }
  }

  .bottom-img {
    width: 1.8rem;
    height: 1.8rem;
    /*background-color: red;*/
    position: absolute;
    right: 5.6rem;
    top: 3.6rem;
    z-index: 99;
    background: url("../../assets/img/weidu/relevant_4.png") no-repeat;
    background-size: cover;
    cursor: pointer;
    transition: transform 1s;
    &:hover {
      transform: scale(1.3);
    }
  }
  .second-nav {
    text-align: left;
    padding-left: 0.2rem;
    height: 0.8rem;
    width: 7.5rem;
    background: rgba(5, 27, 74, 0.32);
    border: 0.0125rem solid #0a43bc;
    box-shadow: 0rem 0rem 0.5rem 0rem rgba(0, 229, 255, 0.09);
    opacity: 0.85;
    margin: 0 auto;
    margin-top: -0.5rem;
    line-height: 0.4rem;
    color: #fff;
    backdrop-filter: saturate(60%) blur(20px);
    overflow: hidden;
  }
  .r-value {
    text-align: center;
    font-size: 0.4rem;
    line-height: 1.6rem;
  }
  .overview-right {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    // .ranking {
    //   height: 5rem;
    // }
    .fenbu {
      height: 0.45rem;
      background: linear-gradient(
        91deg,
        rgba(31, 4, 5, 1) 0%,
        rgba(182, 9, 15, 0) 100%
      );
      border-radius: 0.05rem 0rem 0rem 0rem;
      .fenbu-title {
        height: 0.45rem;
        line-height: 0.45rem;
        padding: 0 0 0.1rem 0.17rem;
        position: relative;
        color: #fff;
        font-family: "微软雅黑";
        font-size: 12px;
        &:after {
          content: "";
          width: 0.05rem;
          height: 0.25rem;
          background-color: #a40000;
          position: absolute;
          top: 0.1rem;
          left: 0;
          margin: auto 0;
        }
      }
    }
    .overview-right-top {
      height: 4rem;
      #lookpie {
        width: 5.2rem;
        height: 17%;
        padding: 0.2rem 0 0 0.2rem;
        font-size: 0.2rem;
        line-height: 0.5rem;
        color: #74c6f5;
      }
      .lookchart {
        width: 6rem;
        height: 12.4rem;
        background: rgba(17, 18, 22, 0.5);

        .looktitle {
          font-size: 0.1875rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cccccc;
          position: relative;
          padding-left: 0.175rem;
          margin-left: 0.1875rem;
        }

        .looktitle::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.05rem;
          height: 0.225rem;
          background: #0079fe;
        }
      }
    }
    .overview-right-bottom {
      flex: 1;
      margin: 1rem 0 0;
      .ring {
        width: 3rem;
        height: 3rem;
        // background-color: yellow;
        margin-left: 0.5rem;
      }
      .Pyramid-c-box {
        position: relative;
        left: 15%;
        margin-top: 0.7rem;
        margin-bottom: 0.2rem;
        width: 5rem;
        height: auto;
        background: transparent;
        border: 1px solid #4ed5f8;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1em 2em rgba(0, 0, 0, 0.3);
        animation: breath1 2s infinite;

        p {
          font-size: 0.25rem;
          line-height: 0.6rem;
        }
      }

      .Pyramid-c-box::before {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 15px;
        width: calc(100% - 30px);
        content: "";
        border-top: 1px solid #016886;
        border-bottom: 1px solid #016886;
        z-index: 0;
      }

      .Pyramid-c-box::after {
        position: absolute;
        top: 15px;
        right: -1px;
        left: -1px;
        height: calc(100% - 30px);
        content: "";
        border-right: 1px solid #016886;
        border-left: 1px solid #016886;
        z-index: 0;
      }

      @keyframes breath1 {
        0% {
          border: 1px solid #4ed5f8;
        }

        /* 动画开始时的不透明度 */
        50% {
          border: 1px solid #176785;
        }

        /* 动画50% 时的不透明度 */
        100% {
          border: 1px solid #4ed5f8;
        }

        /* 动画结束时的不透明度 */
      }

      .Pyramid-c-box1 {
        position: relative;
        top: 5%;
        left: 15%;
        margin-bottom: 0.5rem;
        width: 4rem;
        height: 2rem;
        background: transparent;
        border: 1px solid #4ed5f8;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1em 2em rgba(0, 0, 0, 0.3);
        animation: breath2 2s infinite;
      }

      .Pyramid-c-box1::before {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 15px;
        width: calc(100% - 30px);
        content: "";
        border-top: 1px solid #016886;
        border-bottom: 1px solid #016886;
        z-index: 0;
      }

      .Pyramid-c-box1::after {
        position: absolute;
        top: 15px;
        right: -1px;
        left: -1px;
        height: calc(100% - 30px);
        content: "";
        border-right: 1px solid #016886;
        border-left: 1px solid #016886;
        z-index: 0;
      }

      @keyframes breath2 {
        0% {
          border: 1px solid #4ed5f8;
        }

        /* 动画开始时的不透明度 */
        50% {
          border: 1px solid #176785;
        }

        /* 动画50% 时的不透明度 */
        100% {
          border: 1px solid #4ed5f8;
        }

        /* 动画结束时的不透明度 */
      }
    }
  }
  .conclusionText1 {
    margin-top: 0.2rem;
    padding-left: 0.2rem;
    text-align: left;
    line-height: 0.4rem;
    font-size: 0.2rem;
  }
  .radar {
    position: relative;
    top: -0.4rem;
  }
}
</style>
