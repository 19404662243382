import Vue from 'vue'
import VueRouter from 'vue-router'
import portal from '../views/portal/index.vue'
import Login from '../views/login/index'
import chinaHome from "@/views/homePage/chinaHome";
import schoolHome from "@/views/homePage/schoolHome";
import province from "@/views/homePage/province";
// 专题页
import special from '../views/special/index'
import homePage from '../views/homePage/homePage'
import Analyse from '../views/analyse'
import contrast from "@/views/analyse/contrast"
import correlation from "@/views/analyse/correlation"
import overview from "@/views/analyse/overview"
import regression from "@/views/analyse/regression"
import time from "@/views/analyse/time"
import variance from "@/views/analyse/variance"

// import index from '../views/homePage/homePage'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'portal',
    component: portal
  },
  {
    path: '/chinaHome',
    name: 'chinaHome',
    component: chinaHome
  },
  {
    path: '/schoolHome',
    name: 'schoolHome',
    component: schoolHome
  },
  {
    path: '/province',
    name: 'province',
    component: province
  },
  {
    path:"/login",
    name:"login",
    component: Login
  },
  {
    path:"/homePage",
    name:"homePage",
    component: homePage
  },
  {
    path:"/special",
    name:"special",
    component: special
  },
  {
    path:"/analyse",
    name:"analyse",
    component: Analyse,
    redirect:"/overview",
    children: [
      {
        path: "/contrast",
        // component: () => import("@/views/analyse/contrast"),
        component:contrast
      },
      {
        path: "/correlation",
        // component: () => import("@/views/analyse/correlation"),
        component: correlation,
      },
      {
        path: "/overview",
        component:overview
        // component: () => import("@/views/analyse/overview"),
      },
      {
        path: "/regression",
        // component: () => import("@/views/analyse/regression"),
        component: regression
      },
      {
        path: "/time",
        component: time
        // component: () => import("@/views/analyse/time"),
      },
      {
        path: "/variance",
        // component: () => import("@/views/analyse/variance"),
        component: variance
      },
    ],
  },
  // {
  //   path:"/index",
  //   name:"index",
  //   component: index
  // }
]

const router = new VueRouter({
  routes
})

export default router
