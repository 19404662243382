<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="scale-slide">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
// import { mapActions} from "vuex";
export default {
  // data() {
  //   return {
  //     gap_time: 0,
  //     beforeUnload_time: 0,
  //   };
  // },
  // methods: {
  //   ...mapActions(["Logout"]),
  //   // 关闭窗口之前执行
  //   beforeunloadHandler() {
  //     this.beforeUnload_time = new Date().getTime();
  //   },
  //   unloadHandler() {
  //     this.gap_time = new Date().getTime() - this.beforeUnload_time;
  //     //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
  //     if (this.gap_time <= 10) {
  //       this.logOut(); // 退出登录接口 这里应当换为个人的登出方法
  //     } else {
  //       console.log(1111);
  //     }
  //   },
  //   logout() {
  //     const _that = this;
  //       _that.Logout({ token: this.$ls.get(ACCESS_TOKEN) }).then(() => {
  //         _that.logoutVisible = false;
  //         _that.openQuit = false;
  //       });
  // },
  // },
  // destroyed() {
  //   // 移除监听
  //   window.removeEventListener("beforeunload", () =>
  //     this.beforeunloadHandler()
  //   );
  //   window.removeEventListener("unload", () => this.unloadHandler());
  // },
  // mounted() {
  //   // 监听浏览器关闭
  //   window.addEventListener("beforeunload", () => this.beforeunloadHandler());
  //   window.addEventListener("unload", () => this.unloadHandler());
  // },
};
</script>
<style lang="scss" >
#app {
  overflow: hidden;
  font-size: 0.2rem;
  font-family: "微软雅黑";
  position:relative;
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  outline: none;
  box-sizing: border-box;
}

html,body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.select option {
  background: rgba(149, 1, 18, 1);
  color: #fff;
  opacity: 0.1;
}

.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}

.el-message-box.reLoginBox.el-message-box--center {
  background-color: rgb(70, 2, 2);
  border-color: rgb(70, 2, 2);
  width: 250px;
}

.el-message-box.reLoginBox.el-message-box--center
  .el-message-box__header
  .el-message-box__title {
  color: white;
}
.el-message-box.reLoginBox.el-message-box--center .el-message-box__message {
  color: white;
}
.el-message-box.reLoginBox.el-message-box--center
  .el-message-box__btns
  button.el-button.el-button--default.el-button--small.el-button--primary {
  background-color: white;
  border-color: white;
  color: black;
}
.el-message-box.reLoginBox.el-message-box--center
  .el-message-box__btns
  button.el-button.el-button--default.el-button--small {
  background-color: rgba(0, 0, 0, 0);
  color: white;
}
.el-loading-mask.loginLoading .el-loading-spinner .el-loading-text {
  color: white;
}
.el-loading-mask.loginLoading .el-loading-spinner .path {
  stroke: white;
}
// 阿里字体图标设置
.icon,
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
// .el-message-box.reLoginBox.el-message-box--center{
//   width: 4rem;
//   border-radius: .5rem;
// }
</style>
