import { axios } from "@/utils/request";

/**
 * 年级人数查询
 *
 * @author frl
 */
 export function queryGradeRatio(parameter) {
    return axios({
        url: "/analysis_api/survey/queryGradeRatio",
        method: "get",
        params: parameter,
    });
}
/**
 * 年龄段数查询
 *
 * @author frl
 */
export function queryAgeRatio(parameter) {
    return axios({
        url: "/analysis_api/survey/queryStudentAge",
        method: "get",
        params: parameter,
    });
}

/**
 * 全国省份高校数
 *
 * @author frl
 */
export function querySurveyNumber(parameter) {
    return axios({
        url: "/analysis_api/survey/querySurveyNumber",
        method: "get",
        params: parameter,
    });
}



/**
 * 各高校问卷数
 *
 * @author frl
 */
export function queryUniversityTypeGroupAnswer(parameter) {
    return axios({
        url: "/analysis_api/survey/queryUniversityTypeGroupAnswer",
        method: "get",
        params: parameter,
    });
}

/**
 * 各高校问卷数
 *
 * @author frl
 */
export function dailyIncreaseAnswerNumber(parameter) {
    return axios({
        url: "/analysis_api/survey/dailyIncreaseAnswerNumber",
        method: "get",
        params: parameter,
    });
}


/**
 * 各高校数量
 *
 * @author frl
 */
export function queryUniversityTypeNumber(parameter) {
    return axios({
        url: "/analysis_api/survey/queryUniversityTypeNumber",
        method: "get",
        params: parameter,
    });
}

/**
 * 各高校数量
 *
 * @author frl
 */
export function queryStudentSex(parameter) {
    return axios({
        url: "/analysis_api/survey/queryStudentSex",
        method: "get",
        params: parameter,
    });
}


/**
 * 交互分析
 *
 * @author frl
 */
export function interactive(parameter) {
    return axios({
        url: "/analysis_api/interactive/query",
        method: "get",
        params: parameter,
    });
}

/**
 * 回归分析
 *
 * @author frl
 */
export function regression(parameter) {
    return axios({
        url: "/analysis_api/regression/query",
        method: "get",
        params: parameter,
    });
}

export function correlation(parameter) {
    return axios({
        url: "/analysis_api/correlation/query",
        method: "get",
        params: parameter,
    });
}


/**
 * 存在问卷省份查询
 *
 * @author zgk
 * @date 2022-7-02
 */
export function analysis_province_query(parameter) {
    return axios({
        url: "/analysis_api/province/query",
        method: "get",
        params: parameter,
    });
}
/**
 * 高校信息查询
 *
 * @author zgk
 * @date 2022-7-02
 */
export function analysis_university_queryByProvice(parameter) {
    return axios({
        url: "/analysis_api/university/queryByProvice",
        method: "get",
        params: parameter,
    });
}
/**
 * 高校信息查询
 *
 * @author zgk
 * @date 2022-7-02
 */
export function analysis_university_query(parameter) {
    return axios({
        url: "/analysis_api/university/query",
        method: "get",
        params: parameter,
    });
}

/**
 * 查询指标的分数值
 *
 * @author zgk
 * @date 2022-7-02
 */
export function queryTargetScore(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/queryTargetValue",
        method: "get",
        params: parameter,
    });
}
/**
 * 获取主题下面的一级指标
 *
 * @author zgk
 * @date 2022-7-02
 */
export function queryTargetByThemeName(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/queryByTheme",
        method: "get",
        params: parameter,
    });
}
/**
 * 获取一、二级指标
 *
 * @author zgk
 * @date 2022-7-02
 */
export function queryOneTwoTarget(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/list",
        method: "get",
        params: parameter,
    });
}

/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa2(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa3(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa4(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa5(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa6(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa7(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa8(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa9(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa10(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa11(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa12(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa13(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa14(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa15(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa16(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}
/**
 * 各年级分布情况
 *
 * @author zgk
 * @date 2022-7-02
 */
export function aaaaaaaaa17(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}

/**
 * 各年级分布情况
 *
 * @author frl
 * @date 2022-5-04
 */
export function shouyeLeida(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeInfo",
        method: "get",
        params: parameter,
    });
}

/**
 * 高校等级分类问卷数
 *
 * @author frl
 * @date 2022-5-04
 */
export function shouyeZhuzhuang(parameter) {
    return axios({
        url: "/analysis_api/school/univsTypeAnswerInfo",
        method: "get",
        params: parameter,
    });
}

/**
 * 学校等级数量汇总
 *
 * @author frl
 * @date 2022-5-05
 */
export function shouyeBintu(parameter) {
    return axios({
        url: "/analysis_api/school/univsTypeInfo",
        method: "get",
        params: parameter,
    });
}

/**
 * 首页问卷统计
 *
 * @author frl
 * @date 2022-5-04
 */
export function shouyeWenjuan(parameter) {
    return axios({
        url: "/analysis_api/survey/statistics",
        method: "get",
        params: parameter,
    });
}

/**
 * 各个年龄段分布
 *
 * @author frl
 * @date 2022-5-04
 */
export function shouyeSanjiao(parameter) {
    return axios({
        url: "/analysis_api/survey/age_distribution",
        method: "get",
        params: parameter,
    });
}

/**
 * 性别比例
 *
 * @author frl
 * @date 2022-5-05
 */
export function shouyeSex(parameter) {
    return axios({
        url: "/analysis_api/survey/sex_rate",
        method: "get",
        params: parameter,
    });
}

/**
 * 每日新增答卷数量
 *
 * @author frl
 * @date 2022-5-05
 */
export function meiridajuan(parameter) {
    return axios({
        url: "/analysis_api/survey/dailyIncrease",
        method: "get",
        params: parameter,
    });
}

/**
 * 各专业答卷排行
 *
 * @author frl
 * @date 2022-5-05
 */
export function meiriMajor(parameter) {
    return axios({
        url: "/analysis_api/survey/professionalType",
        method: "get",
        params: parameter,
    });
}

/**
 * 获取存在答卷的省份信息
 *
 * @author frl
 * @date 2022-5-05
 */
export function Provincexiala(parameter) {
    return axios({
        url: "/analysis_api/province/query",
        method: "get",
        params: parameter,
    });
}

/**
 * 查询有答卷的年份
 *
 * @author frl
 * @date 2022-5-05
 */
export function showYear(parameter) {
    return axios({
        url: "/analysis_api/survey/years",
        method: "get",
        params: parameter,
    });
}

/**
 * 获取存在答卷的省份信息
 *
 * @author frl
 * @date 2022-5-05
 */
export function showProvince(parameter) {
    return axios({
        url: "/analysis_api/province/query",
        method: "get",
        params: parameter,
    });
}

/**
 * 根据省份查询高校信息表
 *
 * @author frl
 * @date 2022-5-05
 */
export function showSchool(parameter) {
    return axios({
        url: "/analysis_api/school/queryByProviceId",
        method: "get",
        params: parameter,
    });
}

/**
 * 高校参与答卷专业分组
 *
 * @author frl
 * @date 2022-5-05
 */
    export function groupSubject(parameter) {
    return axios({
        url: "/analysis_api/university/groupSubject",
        method: "get",
        params: parameter,
    });
}

/**
 * 社会主义核心价值观
 *
 * @author frl
 * @date 2022-5-05
 */
export function hxjzg(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/coreSocialistValues",
        method: "get",
        params: parameter,
    });
}

/**
 * 如何看待自己的未来人生发展
 *
 * @author frl
 * @date 2022-5-05
 */
export function futureRsfz(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/futureDevelopView",
        method: "get",
        params: parameter,
    });
}

/**
 * 各年级对比分析（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function wdgnjdb(parameter) {
    return axios({
        url: "/analysis_api/school/queryGradeRatio",
        method: "get",
        params: parameter,
    });
}

/**
 * 交互分析结果查询（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function varianceFenxi(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/varianceAnalysis",
        method: "get",
        params: parameter,
    });
}

/**
 * 分析模板结果查询（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function multivariantFenxi(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/multivariateAnalysis",
        method: "get",
        params: parameter,
    });
}

/**
 * 列联表检验结果查询（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function llbList(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/contingencyTables",
        method: "get",
        params: parameter,
    });
}

/**
 * 对雷锋精神看法（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function leifengSpirit(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/viewLeifengSpirit",
        method: "get",
        params: parameter,
    });
}

/**
 * 相关分析（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function correlationfenxi(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/correlationAnalysis",
        method: "get",
        params: parameter,
    });
}

/**
 * 人生观与人生追求看法（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function correlationRsg(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/lifeViewAndPursuit",
        method: "get",
        params: parameter,
    });
}

/**
 * 历时分析结果查询（维度）
 *
 * @author frl
 * @date 2022-5-05
 */
export function timeFenxi(parameter) {
    return axios({
        url: "/analysis_api/view_of_life/diachronicAnalysis",
        method: "get",
        params: parameter,
    });
}

/**
 * 年份查询
 *
 * @author frl
 */
export function inquireYear(parameter) {
    return axios({
        url: "/analysis_api/survey/years",
        method: "get",
        params: parameter,
    });
}

/**
 * 主题查询
 *
 * @author frl
 */
export function inquireTopic(parameter) {
    return axios({
        url: "/analysis_api/theme/list",
        method: "get",
        params: parameter,
    });
}

/**
 * 主题下一级指标
 *
 * @author frl
 */
export function oneIndicators(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/queryByTheme",
        method: "get",
        params: parameter,
    });
}

/**
 * 二级指标列表
 *
 * @author frl
 */
export function twoIndicators(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/list",
        method: "get",
        params: parameter,
    });
}

/**
 * 省份查询
 *
 * @author frl
 */
export function inquireProvince(parameter) {
    return axios({
        url: "/analysis_api/province/query",
        method: "get",
        params: parameter,
    });
}



/**
 * 学校列表查询
 *
 * @author frl
 */
export function schoolList(parameter) {
    return axios({
        url: "/analysis_api/university/queryByProvice",
        method: "get",
        params: parameter,
    });
}

/**
 * 学校查询
 *
 * @author frl
 */
export function inquireSchool(parameter) {
    return axios({
        url: "/analysis_api/university/query",
        method: "get",
        params: parameter,
    });
}

/**
 * 问卷统计信息查询
 *
 * @author frl
 */
export function questionnaire(parameter) {
    return axios({
        url: "/analysis_api/survey/statistics",
        method: "get",
        params: parameter,
    });
}

/**
 * 查询指标的分数值
 *
 * @author frl
 */
export function queryTargetValue(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/queryTargetValue",
        method: "get",
        params: parameter,
    });
}

/**
 * 查询单个指标查询问题及答题情况
 *
 * @author frl
 */
export function targetQuestionDetail(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/targetQuestionDetail",
        method: "get",
        params: parameter,
    });
}

/**
 * 查询单个指标查询问题及答题情况
 *
 * @author frl
 */
export function targetQuestionDetailForGraph(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/targetQuestionDetailForGraph",
        method: "get",
        params: parameter,
    });
}


/**
 * 历时分析-全国
 *
 * @author frl
 */
export function CountryList(parameter) {
    return axios({
        url: "/analysis_api/diachronic/queryWithCountry",
        method: "get",
        params: parameter,
    });
}
/**
 * 历时分析-全省
 *
 * @author frl
 */
export function provinceList(parameter) {
    return axios({
        url: "/analysis_api/diachronic/queryWithProvince",
        method: "get",
        params: parameter,
    });
}
/**
 * 历时分析-全校
 *
 * @author frl
 */
export function getSchoolList(parameter) {
    return axios({
        url: "/analysis_api/diachronic/queryWithUniversity",
        method: "get",
        params: parameter,
    });
}
/**
 * 对比分析-省份之间对比
 *
 * @author frl
 */
export function contrastProvince(parameter) {
    return axios({
        url: "/analysis_api/comparative/compareWithProvince",
        method: "get",
        params: parameter,
    });
}
/**
 * 对比分析-学校之间对比
 *
 * @author frl
 */
export function contrastSchool(parameter) {
    return axios({
        url: "/analysis_api/comparative/compareWithUniversity",
        method: "get",
        params: parameter,
    });
}
/**
 * 对比分析-学校内部学科对比
 *
 * @author frl
 */
export function contrastSchoolDiscipline(parameter) {
    return axios({
        url: "/analysis_api/comparative/compareWithSubject",
        method: "get",
        params: parameter,
    });
}
/**
 * 网络思政视频查询
 *
 * @author frl
 */
export function videoList(parameter) {
    return axios({
        url: "/analysis_api/sizheng/videoList",
        method: "get",
        params: parameter,
    });
}

/**
 * 官网数据
 *
 * @author frl
 */
 export function gwText(parameter) {
    return axios({
        url: "/analysis_api/sizheng/surveyStatistics",
        method: "get",
        params: parameter,
    });
}
//分数值
export function queryTargetValuesForGraph(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/queryTargetValuesForGraph",
        method: "get",
        params: parameter,
    });
}
