<template>
  <div class="map-box">
    <div v-if="currentCity" class="map" id="map"></div>
    <div v-if="level !== '全国'" class="map-bg"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Map",
  data() {
    return {
      level: "",
      currentCity: "",
      currentSchool: "",
    };
  },
  created() {
    this.getUserLevel();
  },
  updated() {},
  async mounted() {
    await this.setMap(this.currentCity);
    this.setMap(this.getCity)
  },
  methods: {
    ...mapActions(["setCity", "setSchool", "setSchoolListCity"]),
    /*获取当前用户级别*/
    getUserLevel() {
      this.level = JSON.parse(localStorage.getItem("role"))[0].name;
      console.log("等级");
      console.log(this.level);
      if (this.level == "高校") {
        this.currentCity = JSON.parse(localStorage.getItem("info")).province;
        this.currentSchool = JSON.parse(localStorage.getItem("info")).orgName;
        console.log("高校");
        console.log(this.currentCity, this.currentSchool);
        this.setCity(this.currentCity);
        this.setSchool(this.currentSchool);
      } else if (this.level == "全国") {
        this.currentCity = JSON.parse(localStorage.getItem("info")).orgName;
      } else {
        this.currentCity = JSON.parse(localStorage.getItem("info")).orgName;
        this.setCity(this.currentCity);
      }

      console.log("000");
      console.log(this.currentSchool);
      console.log(this.currentCity);
      if (this.currentCity !== "全国") {
        let city = this.currentCity;
        if (city.endsWith("自治区")) {
          city = city == "新疆维吾尔自治区" ? "新疆" : city;
          city = city == "宁夏回族自治区" ? "宁夏" : city;
          city = city == "广西壮族自治区" ? "广西" : city;
          city = city == "西藏自治区" ? "西藏" : city;
          city = city == "内蒙古自治区" ? "内蒙古" : city;
        } else {
          city = city.slice(0, city.length - 1);
        }

        this.currentCity = city;
        this.setCity(this.currentCity);
        this.setSchoolListCity(this.currentCity);
        this.setSchool(this.currentSchool);
      }
    },
    /*...mapActions([
                'setCity',
                'setSchool',
                'setSchoolListCity'
            ]),*/
    async setMap(selectedProvinceName) {
      /* let mode = 'single'
                if (!(this.level == '全国')){
                    mode = false
                }*/
      let selectedProvinceData = [
        { name: selectedProvinceName, selected: true },
      ];
      let mapChart = this.$echarts.getInstanceByDom(
        document.getElementById("map")
      );
      if (mapChart == null) {
        // 如果不存在，就进行初始化。
        mapChart = this.$echarts.init(document.getElementById("map"));
      }
      let mapoption = {
        backgroundColor: "transparent",
        legend: {
          show: false,
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            return params.name;
          },
          axisPointer: {
            type: "none",
            shadowColor: "rgba(255,255,255,0.4)",
            // shadowColor:'yellow'
          },
          // 背景颜色
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          // 边框颜色
          borderColor: "black",
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          show: false,
          map: "china",
          zoom: 1.1,
          label: {
            emphasis: {
              show: false,
            },
          },
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                // 隐藏地图
                normal: {
                  opacity: 0, // 为 0 时不绘制该图形
                },
              },
              label: {
                show: false, // 隐藏文字
              },
            },
          ],

          itemStyle: {
            normal: {
              borderColor: "rgba(7, 133, 212, 1)",
              borderWidth: 1,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(5,49,170, 0.8)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(5,49,170, 0.8)", // 100% 处的颜色
                  },
                ],
                data: [{ name: "湖北", provinceurl: "" }],
                globalCoord: false, // 缺省为 false
              },
              shadowColor: "rgba(128, 217, 248, 0.3)",
              // shadowColor: 'rgba(255, 255, 255, 1)',
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
            },
            emphasis: {
              areaColor: "#5e2370",
              borderWidth: 0,
            },
          },
        },
        series: [
          {
            type: "map",
            selectedMode: "single", //'single/multiple'
            mapType: "china",
            aspectScale: 0.76,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: "100%",
            zoom: 1, //当前视角的缩放比例
            // roam: true, //是否开启平游或缩放
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 1,
              max: 2,
            },
            label: {
              show: true,
              color: "#FFFFFF",
              fontSize: 16,
              normal: {
                textStyle: {
                  fontSize: 8,
                  fontWeight: "bold",
                  color: "white",
                },
              },
            },
            itemStyle: {
              normal: {
                areaColor: "rgba(5,49,170, 0.8)",
                borderColor: "rgba(7, 133, 212, 1)",
                borderWidth: 1,
                label:{
                  show: true,
                },
              },
              emphasis: {
                areaColor: "#56b1da",
                label: {
                  show: true,
                  color: "#fff",
                },
              },
            },
            select: {
              // 地图选中区域样式
              label: {
                // 选中区域的label(文字)样式
                show: false,
              },
              itemStyle: {
                // 选中区域的默认样式
                areaColor: "#5e2370",
              },
            },
            data: selectedProvinceData,
          },
        ],
      };
      mapChart.setOption(mapoption, true);
      mapChart.off("click");
      if (this.level == "全国") {
        mapChart.on("click", async (params) => {
          /*                    // //设置省数据分析联动数据
                                            this.getSubValue(params.name)
                                            this.getBigSchoolByCity(params.name)
                                            /!*        console.log(this.seCity)
                                                    console.log(this.bigSchool)
                                                    console.log(this.targetName)*!/
                                            /!*清空学校*!/
                                            this.bigSchool = ''
                                            this.setRadar(this.targetName)*/
          /*地图点击设置省份*/
          this.setCity(params.name);
          this.setSchool("");
          this.setSchoolListCity(params.name);
        });
      }
      window.addEventListener("resize", function () {
        mapChart.resize();
      });
    },
  },
  computed: {
    ...mapGetters(["getCity", "getSchoolListCity"]),
  },
  watch: {
    getCity: function (newValue, oldValue) {
      this.setMap(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-box {
  /*background-color: red;*/
  position: relative;
}
.map {
  width: 6rem;
  height: 4rem;
}
.map-bg {
  width: 6rem;
  height: 4rem;
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
</style>