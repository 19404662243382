<template>
  <!-- 概况 -->
  <div class="overview">
    <!-- 左侧的柱状图区域以及头部的标签 -->
    <div class="overview-center">
      <!-- 头部标签部分 -->
      <crumb></crumb>
      <!-- 右侧点击部分 -->
      <div class="contrast" v-if="age.provinceName">
        <div class="contrast-left">
          <span>{{ age.name }}</span>
          <!-- <i class="iconfont icon-duibi">VS</i> -->
          <i class="iconfont">VS</i>
        </div>
        <div class="contrast-center" v-if="age.provinceName">
          <span>{{ age.provinceName }}</span>
        </div>
        <div class="contrast-right" v-if="age.schoolName">
          <!-- <i class="iconfont icon-duibi"></i> -->
          <i class="iconfont">VS</i>
          <span>{{ age.schoolName }}</span>
        </div>
      </div>
      <div class="chooseProvince">
        <!-- 省份对比 -->
        <div class="right" @click="chooseProvinceAll" v-if="!getCity">
          <i class="iconfont icon-funnelloudou"></i>
          省份对比
        </div>
        <!-- 高校对比 -->
        <div
          class="right"
          @click="chooseSchoolOpen"
          v-if="getCity && !getSchool"
        >
          <i class="iconfont icon-funnelloudou"></i>
          高校对比
        </div>
        <!-- 学科对比 -->
        <div class="right" @click="chooseSubjectsOpen" v-if="getSchool">
          <i class="iconfont icon-funnelloudou"></i>
          学科对比
        </div>
        <div class="life-right">
          <div class="box1"></div>
          <div class="box2"></div>
        </div>
        <div class="indicatorFilterDialog-template">
          <el-dialog
            :visible="dialogVisible"
            :close-on-click-modal="false"
            @close="closeDialog"
          >
            <div class="annotation">
              <span class="description" v-if="!getCity"
                >注意：省份最多选择两个</span
              >
              <span class="description" v-if="getCity && !getSchool"
                >注意：大学最多选择两个</span
              >
              <span class="description" v-if="getSchool"
                >注意：学科最多选择两个</span
              >
            </div>
            <div class="firstRow">
              <div class="h4">
                <!-- <span class="description" v-if="!getCity"
                  >注意：省份最多选择两个</span
                >
                <span class="description" v-if="getCity && !getSchool"
                  >注意：大学最多选择两个</span
                >
                <span class="description" v-if="getSchool"
                  >注意：学科最多选择两个</span
                > -->
                <span class="description" v-if="!getCity">省份列表</span>
                <span class="description" v-if="getCity && !getSchool"
                  >大学列表</span
                >
                <span class="description" v-if="getSchool">学科列表</span>
              </div>
              <div class="level1_Indicators">
                <div
                  class="indicatorPanel"
                  :class="item.Selected ? 'activePaneClass' : ''"
                  v-for="(item, index) in provinceList"
                  :key="index + 'first'"
                  @click="preTargetBtn(item, index)"
                  ref="preTarget"
                >
                  {{ item.province }}
                </div>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="closeWindow">取消</el-button>
              <el-button type="primary" @click="CloseWindows" v-if="!getCity"
                >确 定</el-button
              >
              <el-button
                type="primary"
                @click="chooseSchoolClose"
                v-if="getCity && !getSchool"
                >确 定</el-button
              >
              <el-button type="primary" @click="chooseSubjects" v-if="getSchool"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
        <!-- <div class="tanchuan" v-show="chooseProvince">
          <div class="choose">
            <div class="choose-border">
              <div class="top">
                <div class="box1"></div>
                <div class="box2" @click="CloseWindows"></div>
              </div>
              <div class="center">
                <div class="center-close">
                  <span v-if="!getCity">注：省份最多选择2个</span>
                  <span v-if="getCity && !getSchool">注：大学最多选择2个</span>
                  <span v-if="getSchool">注：学科最多选择2个</span>
                  <div class="close" @click="close">X</div>
                </div>
                <div class="center-centetr">
                  <div
                    class="box"
                    :class="item.Selected ? 'red' : ''"
                    v-for="(item, index) in provinceList"
                    :key="index"
                    @click="changeColor(index)"
                  >
                    {{ item.province }}
                  </div>
                </div>
                <div class="center-bottom">
                  <div class="yes" @click="CloseWindows" v-if="!getCity">
                    确定
                  </div>
                  <div
                    class="yes"
                    @click="chooseSchoolClose"
                    v-if="getCity && !getSchool"
                  >
                    确定
                  </div>
                  <div class="yes" @click="chooseSubjects" v-if="getSchool">
                    确定
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="box3"></div>
                <div class="box4"></div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- 底部柱状图部分 -->
      <Histogram :age="age" v-on:son-click="changeRadar" ref="tab" />
    </div>
    <!-- 右侧的雷达图部分以及结论 -->
    <div class="overview-right">
      <div class="overview-right-top">
        <div class="ranking">
          <div class="fenbu">
            <!-- 右上角分析器标题 -->
            <h3 class="fenbu-title">{{ rightData.columnLabel }}分析说明</h3>
          </div>
          <div class="lookchart">
            <!-- 右上角标题的内容 -->
            <div id="lookpie">
              {{ rightData.description }}
            </div>
          </div>
        </div>
      </div>
      <div class="overview-right-bottom">
        <div class="fenbu">
          <!-- 右下角分析器标题 -->
          <h3 class="fenbu-title">{{ rightData.questionTitle }}</h3>
        </div>
        <!-- 右下角雷达图组件 -->
        <Radar :radarData="radarData" ref="tab2" />
      </div>
    </div>
    <!-- <ContrastDialog
      ref="corDialog"
      @getTarget="getSubTargetList"
    ></ContrastDialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Histogram from "../../components/Histogram.vue";
import Radar from "../../components/Radar.vue";
// import ContrastDialog from "@/components/ContrastDialog";
import {
  queryTargetValuesForGraph,
  targetQuestionDetailForGraph,
  queryTargetValue,
  analysis_province_query,
  getSchoolList,
  schoolList,
  schoolSubject,
} from "../../api/modular/analyse/analyse";
import crumb from "@/components/crumb";
export default {
  components: {
    Histogram,
    Radar,
    crumb,
  },
  data() {
    return {
      // 柱状图的数据
      age: {
        name: "全国",
        data: [],
        provinceName: "",
        provinceData: [],
        schoolName: "",
        schoolData: [],
        Xname: "", //这个可以不用传入
        Xdata: ["人生目标", "人生态度", "人生价值"],
      },
      // 雷达图的数据
      radarData: {
        name: "全国",
        data: [20, 20, 20],
        provinceName: "湖北省",
        provinceData: [30, 30, 30],
        schoolName: "武汉大学",
        schoolData: [50, 50, 50],
        Xname: "", //这个可以不用传入
        Xdata: ["自由", "民主", "和谐"],
      },
      // 右侧三个的对象
      rightData: {
        // 右侧头部标题
        columnLabel: "人生目标",
        // 右侧头部内容
        description:
          "人生目标是人在相当长的一段时间、甚至是终其一生需要达成的状态，是对自己理想的实现，也可以称之为理想目标。",
        // 右侧下面标题
        questionTitle: "您是否拥有人生目标？",
      },
      // 选择省份的弹窗
      chooseProvince: false,
      // 选择省份里面的数据
      provinceList: [],
      // 选择学校的弹窗
      chooseSchool: true,
      // 选择学科的弹窗
      chooseDiscipline: true,
      // 学科列表
      subjectList: [],
      // 弹窗开关
      dialogVisible: false,
    };
  },
  created() {},
  mounted() {
    if (this.getCity) {
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      this.targetQuestionDetailForGraph(this.age.Xdata[0], this.getCity);
    } else {
      this.queryTargetValuesForGraph();
      this.targetQuestionDetailForGraph(this.age.Xdata[0]);
    }
    this.analysis_province_query();
    // console.log("权限", this.getRoles, this.getOrgInfo);
  },
  computed: {
    ...mapGetters([
      "getCity",
      "getSchool",
      "getTargetLevel1",
      "getRoles",
      "getOrgInfo",
    ]),
  },
  methods: {
    // 关闭弹窗
    closeWindow() {
      this.dialogVisible = false;
    },
    // 点击变色事件
    preTargetBtn(item, index) {
      this.provinceList[index].Selected = !this.provinceList[index].Selected;
      let a = 0;
      let data = [];
      this.provinceList.map((item) => {
        // var a = 0;
        if (item.Selected) {
          a++;
          data.push(item);
        }
      });
      this.needProvinceListData = data;
      if (a > 2) {
        // item.Selected=false
        this.provinceList[index].Selected = false;
        // console.log("选中的数据", data);
        this.$message.error("最多只能选2个");
        return;
      }
    },
    /*显示弹窗*/
    openDialog() {
      this.dialogVisible = true;
    },
    /*关闭弹窗*/
    closeDialog() {
      this.dialogVisible = false;
    },
    /*确定按钮*/
    submitBtn() {
      this.$message.success("提交成功");
      this.dialogVisible = false;
    },
    // 将省份添加后缀的函数
    changeProvince(provinceName) {
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = provinceName;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      provinceName = city;
      return provinceName;
    },
    // 查询中间柱状图的分数值,并且赋值
    async queryTargetValuesForGraph(provinceName, schoolName) {
      // 加载动画
      let columnEchart = this.$echarts.init(this.$refs.tab.$refs.headertab);
      columnEchart.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      if (provinceName) {
        provinceName = this.changeProvince(provinceName);
        // let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        // //处理直辖市的数据
        // let central = ["北京", "天津", "上海", "重庆"];
        // let city = provinceName;
        // if (autonomousRegion.indexOf(city) !== -1) {
        //   city = city == "新疆" ? "新疆维吾尔自治区" : city;
        //   city = city == "宁夏" ? "宁夏回族自治区" : city;
        //   city = city == "广西" ? "广西壮族自治区" : city;
        //   city = city == "西藏" ? "西藏自治区" : city;
        //   city = city == "内蒙古" ? "内蒙古自治区" : city;
        // } else if (central.indexOf(city) !== -1) {
        //   city = city + "市";
        // } else {
        //   city = city + "省";
        // }
        // provinceName = city;
        // console.log("省份",provinceName);
      }
      let str = await queryTargetValuesForGraph({
        year: 2020,
        themeName: "思想政治状况",
        targetName: this.getTargetLevel1,
        province: provinceName || "",
        universityName: schoolName || "",
      });
      // 中间柱状图数据
      let countrydata = {
        name: "全国",
        data: [],
        provinceName: provinceName,
        provinceData: [],
        schoolName: schoolName,
        schoolData: [],
        Xname: "x轴", //这个可以不用传入
        Xdata: [],
      };
      // 全国数据
      if (!provinceName && !schoolName) {
        // 全国和x轴数据
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
      }
      // 全国，省份数据
      if (provinceName && !schoolName) {
        countrydata = {
          name: "全国",
          data: [],
          provinceName: provinceName,
          provinceData: [],
          Xname: "x轴", //这个可以不用传入
          Xdata: [],
        };
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
        str.data.province.list.map((item) => {
          countrydata.provinceData.push(item.targetValue);
        });
        console.log(countrydata);
      } else if (provinceName && schoolName) {
        // 全国，省份，学校数据
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
        // 省份
        str.data.province.list.map((item) => {
          countrydata.provinceData.push(item.targetValue);
        });
        // 学校
        str.data.university.list.map((item) => {
          countrydata.schoolData.push(item.targetValue);
        });
      }
      // 赋值柱状图
      this.age = countrydata;
      // 调用雷达图
      this.targetQuestionDetailForGraph(
        countrydata.Xdata[0],
        this.getCity,
        this.getSchool
      );
    },
    // 获取全国以及省份，学校，学科的分数值
    async getqueryTargetValue(provinceName, schoolName, subject) {
      let columnEchart = this.$echarts.init(this.$refs.tab.$refs.headertab);
      columnEchart.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      let str = await queryTargetValue({
        year: 2020,
        themeName: "思想政治状况",
        targetName: this.getTargetLevel1,
        province: provinceName || "",
        universityName: schoolName || "",
        subject,
      });
      // console.log("全国的分数指标", str);
      let countrydata = [];
      str.data.list.map((item) => {
        countrydata.push(item.targetValue);
      });
      // console.log("return的数据", countrydata);
      return countrydata;
    },
    // 查询雷达图以及右侧内容的数据，并且赋值
    async targetQuestionDetailForGraph(targetName, provinceName, schoolName) {
      // 雷达图的读取
      let lookpieOption = this.$echarts.init(this.$refs.tab2.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      if (provinceName) {
        provinceName = this.changeProvince(provinceName);
        // let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        // //处理直辖市的数据
        // let central = ["北京", "天津", "上海", "重庆"];
        // let city = provinceName;
        // if (autonomousRegion.indexOf(city) !== -1) {
        //   city = city == "新疆" ? "新疆维吾尔自治区" : city;
        //   city = city == "宁夏" ? "宁夏回族自治区" : city;
        //   city = city == "广西" ? "广西壮族自治区" : city;
        //   city = city == "西藏" ? "西藏自治区" : city;
        //   city = city == "内蒙古" ? "内蒙古自治区" : city;
        // } else if (central.indexOf(city) !== -1) {
        //   city = city + "市";
        // } else {
        //   city = city + "省";
        // }
        // provinceName = city;
        // console.log("省份",provinceName);
      }
      let str = await targetQuestionDetailForGraph({
        year: 2020,
        themeName: "思想政治状况",
        targetName,
        province: provinceName || "",
        universityName: schoolName || "",
      });
      // console.log("雷达图的数据", str);
      // 赋值雷达图数据
      let countrydata = {
        name: "全国",
        data: str.data.data.country.values,
        provinceName: provinceName || "",
        provinceData: str.data.data.province
          ? str.data.data.province.values
          : [],
        schoolName: schoolName || "",
        schoolData: str.data.data.university
          ? str.data.data.university.values
          : [],
        Xname: "x轴", //这个可以不用传入
        Xdata: str.data.data.country.cols,
      };
      // 右侧文字内容
      this.rightData = {
        // 右侧头部标题
        columnLabel: targetName,
        // 右侧头部内容
        description: str.data.description,
        // 右侧下面标题
        questionTitle: str.data.questionTitle,
      };
      // 更新雷达图内容
      this.radarData = countrydata;
    },
    // 查询雷达图省份的数据 return 出去(传入省份输出他和全国数据)
    async getRadarProvinceData(province, school) {
      if (!province && !school) {
        console.log(1);
        return;
      }
      if (province && !school) {
        let str = await targetQuestionDetailForGraph({
          year: 2020,
          themeName: "思想政治状况",
          targetName: this.rightData.columnLabel,
          province: province,
        });
        return str.data.data;
      }
      if (province && school) {
        let str = await targetQuestionDetailForGraph({
          year: 2020,
          themeName: "思想政治状况",
          targetName: this.rightData.columnLabel,
          province: province,
          universityName: school,
        });
        // console.log(3);
        return str.data.data;
      }
      if (!province && school) {
        let str = await targetQuestionDetailForGraph({
          year: 2020,
          themeName: "思想政治状况",
          targetName: this.rightData.columnLabel,
          universityName: school,
        });
        // console.log(4);
        return str.data.data;
      }
      // debugger;
    },
    // 子传父函数，点击中间柱状图更新右侧内容
    changeRadar(item) {
      // console.log("item");
      this.targetQuestionDetailForGraph(
        item,
        this.getCity || "",
        this.getSchool || ""
      );
    },
    // 选中字体改变颜色函数
    changeColor(index) {
      console.log(index, "index");
      this.provinceList[index].Selected = !this.provinceList[index].Selected;
      let a = 0;
      let data = [];
      this.provinceList.map((item) => {
        // var a = 0;
        if (item.Selected) {
          a++;
          data.push(item);
        }
      });
      this.needProvinceListData = data;
      if (a > 2) {
        // item.Selected=false
        this.provinceList[index].Selected = false;
        console.log("选中的数据", data);
        return;
      }
      //
      // 对数组循环一编，最多只能选中2个
    },
    // 打开选择省份的弹窗
    chooseProvinceAll() {
      this.analysis_province_query();
      this.dialogVisible = true;
    },
    // 关闭选择省份的弹窗
    async CloseWindows() {
      // this.chooseProvince = false;
      let _this = this;
      this.dialogVisible = false;
      // 柱状图数据
      let str1 = await this.getqueryTargetValue();
      let title1 = "";
      let str2 = [];
      let title2 = "";
      let str3 = [];
      if (this.needProvinceListData[0]) {
        title1 = this.needProvinceListData[0].province;
        str2 = await this.getqueryTargetValue(title1 || "");
      }
      if (this.needProvinceListData[1]) {
        title2 = this.needProvinceListData[1].province;
        str3 = await this.getqueryTargetValue(title2);
      }
      let countrydata = {
        name: "全国",
        data: [...(str1 || [])],
        provinceName: title1,
        provinceData: [...(str2 || [])],
        schoolName: title2,
        schoolData: [...(str3 || [])],
        Xname: "x轴", //这个可以不用传入
        Xdata: [...this.age.Xdata],
      };
      this.age = countrydata;
      // 雷达图数据
      let radarData1 = await this.getRadarProvinceData(title1);
      let radarData2 = await this.getRadarProvinceData(title2);
      let radar = {
        name: "全国",
        data: radarData1.country.values,
        provinceName: title1 || "",
        provinceData: radarData1.province ? radarData1.province.values : [],
        schoolName: title2 || "",
        schoolData: radarData2.province ? radarData2.province.values : [],
        Xname: "x轴", //这个可以不用传入
        Xdata: radarData1.country.cols,
      };
      this.radarData = radar;
      console.log(this.radarData, "雷达图数据1");
    },
    // 打开选择学校的函数
    async chooseSchoolOpen() {
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = this.getCity;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      let provinceName = city;
      let str = await getSchoolList({
        year: 2020,
        themeName: "思想政治状况",
        province: provinceName,
      });
      // console.log("学校列表", str);
      let data = [];
      str.data.list.map((item) => {
        data.push({ name: item.name });
      });
      this.provinceList = data;
      this.provinceList.map((item) => {
        this.$set(item, "Selected", false);
        this.$set(item, "province", item.name);
      });
      // console.log("打开选择学校弹窗显示的数据", this.provinceList);
      // this.chooseProvince = true;
      this.dialogVisible = true;
    },
    // 关闭选择学校的函数
    async chooseSchoolClose() {
      // this.chooseProvince = false;
      this.dialogVisible = false;
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = this.getCity;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      let provinceName = city;
      // console.log("省份",provinceName);
      let str1 = await this.getqueryTargetValue(provinceName);
      let title1 = "";
      let str2 = [];
      let title2 = "";
      let str3 = [];
      if (this.needProvinceListData[0]) {
        title1 = this.needProvinceListData[0].province;
        str2 = await this.getqueryTargetValue("", title1 || "");
      }
      if (this.needProvinceListData[1]) {
        title2 = this.needProvinceListData[1].province;
        str3 = await this.getqueryTargetValue("", title2 || "");
      }
      let countrydata = {
        name: this.getCity,
        data: [...str1],
        provinceName: title1,
        provinceData: [...str2],
        schoolName: title2,
        schoolData: [...str3],
        Xname: "x轴", //这个可以不用传入
        Xdata: [...this.age.Xdata],
      };
      this.age = countrydata;
      // 雷达图
      let radarData1 = await this.getRadarProvinceData(provinceName, title1);
      let radarData2 = await this.getRadarProvinceData(provinceName, title2);
      console.log(radarData1, radarData1, "雷达图的数据");
      let radar = {
        name: this.getCity,
        data: radarData1.province.values,
        provinceName: title1 || "",
        provinceData: radarData1.university ? radarData1.university.values : [],
        schoolName: title2 || "",
        schoolData: radarData2.university ? radarData2.university.values : [],
        Xname: "x轴", //这个可以不用传入
        Xdata: radarData1.country.cols,
      };
      this.radarData = radar;
      console.log(this.radarData, "雷达图数据1");
    },
    // 打开学科的函数
    async chooseSubjectsOpen() {
      let str = await schoolList({
        year: 2020,
        themeName: this.themeName,
        province: this.title2,
      });
      let _this = this;
      let subjectList = [];
      str.data.list.map((item) => {
        if (item.name == _this.getSchool) {
          item.subjectList = JSON.parse(item.subjectList);
          for (let i = 0; i < item.subjectList.length; i++) {
            subjectList[i] = {
              name: item.subjectList[i],
            };
          }
        }
      });
      this.provinceList = subjectList;
      // console.log("显示的数据2", subjectList);
      this.provinceList.map((item) => {
        this.$set(item, "Selected", false);
        this.$set(item, "province", item.name);
      });
      // this.chooseProvince = true;
      this.dialogVisible = true;
      // console.log("显示的数据", this.provinceList);
    },
    // chooseSubjects,关闭选择科目的函数
    async chooseSubjects() {
      // this.chooseProvince = false;
      this.dialogVisible = false;
      let school = this.getSchool;
      // 选中的俩个数据
      let str1 = await this.getqueryTargetValue("", this.getSchool);
      let title1 = "";
      let str2 = [];
      let title2 = "";
      let str3 = [];
      if (this.needProvinceListData[0]) {
        title1 = this.needProvinceListData[0].province;
        str2 = await this.getqueryTargetValue("", "", title1 || "");
      }
      if (this.needProvinceListData[1]) {
        title2 = this.needProvinceListData[1].province;
        str3 = await this.getqueryTargetValue("", "", title2 || "");
      }
      let schoolName = this.getSchool.slice(0, -2);
      console.log(schoolName);
      let countrydata = {
        name: `${schoolName}大学 `,
        data: [...(str1 || [])],
        provinceName: title1,
        provinceData: [...(str2 || [])],
        schoolName: title2,
        schoolData: [...(str3 || [])],
        Xname: "x轴", //这个可以不用传入
        Xdata: [...this.age.Xdata],
      };
      this.age = countrydata;
    },
    // 获取存在问卷的省份
    async analysis_province_query() {
      let str = await analysis_province_query({
        year: this.year,
        themeName: this.themeName,
      });
      console.log("参与问卷省份", str);
      // 将后续的选中红色的属性Selected添加进去
      this.provinceList = str.data.list;
      this.provinceList.map((item) => {
        this.$set(item, "Selected", false);
      });
      console.log("循环出来的全国省份", this.provinceList);
    },
    close() {
      this.chooseProvince = false;
    },
  },
  watch: {
    getSchool() {
      // 监听后更新柱状图和雷达图和右侧内容
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
    },
    getCity(newName, old) {
      // 监听后更新柱状图和雷达图和右侧内容
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      if (!newName) {
      }
    },
    getTargetLevel1() {
      // 监听后更新柱状图和雷达图和右侧内容
      this.queryTargetValuesForGraph(this.getCity, this.getSchool);
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  height: 100%;
  width: 100%;
  display: flex;
  .iconfont {
    font-size: 0.2rem;
  }
  .overview-center {
    height: 84vh;
    flex: 4;
    margin-right: 0.2rem;
    position: relative;
    background: url(@/assets/img/center.png) no-repeat bottom;
    background-size: cover;
    .overview-center-title-right {
      position: absolute;
      right: 0px;
      top: 0.13rem;
    }
    .overview-center-title {
      height: 0.4rem;
      margin-bottom: 1rem;
      line-height: 0.4rem;
      border: 1px solid #470201;
      border-right: none;
      border-left: none;
      display: flex;
      justify-content: space-between;
    }
    .chooseProvince {
      width: 100%;
      display: flex;
      position: relative;
      .right {
        cursor: pointer;
        position: absolute;
        right: 0.7rem;
        top: -0.5rem;
        line-height: 23px;
        font-size: 13px;
      }
      .center-bottom {
        display: flex;
        justify-content: center;
        text-align: center;
        .yes {
          margin-top: 00.5rem;
          background: #2f0206;
          border-radius: 6px;
          position: relative;
          cursor: pointer;
          margin-left: 13px;
          width: 1.5rem;
          height: 36px;
          border: 1px solid #680911;
          border-radius: 4px;
          font-size: 14px;
          // font-family: PingFang SC;
          font-weight: 400;
          color: #fde5e5;
          line-height: 36px;
          text-align: center;
          margin-right: 40px;
        }
      }
    }
    .tanchuan {
      width: 100%;
      height: 291px;
      position: absolute;
      left: 0rem;
      z-index: 9999;
      .choose {
        width: 100%;
        background: rgba(0, 0, 0, 0.84);
        border: 1px solid #680911;
        padding: 10px 6px 13px 6px;
        .choose-border {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .box1,
          .box2,
          .box3,
          .box4 {
            width: 8px;
            height: 8px;
            background: #c22222;
            transform: rotate(45deg);
          }
          .top,
          .bottom {
            height: 11px;
            width: 100%;
            flex: 0 0 11px;
            display: flex;
            justify-content: space-between;
          }
          .center {
            flex: 1;
            padding: 0.2rem 0 0.1rem 0.1rem;
            display: flex;
            flex-direction: column;
            .center-close {
              display: flex;
              justify-content: space-between;
              .close {
                cursor: pointer;
                margin-right: 0.5rem;
                font-size: 15px;
              }
            }
            span {
              height: 0.5rem;
              font-size: 15px;
              // font-family: PingFang SC;
              font-weight: 600;
              color: #fde5e5;
              line-height: 20px;
            }
            p {
              width: 153px;
              height: 16px;
              font-size: 14px;
              // font-family: PingFang SC;
              font-weight: 600;
              color: #fbe9e9;
              line-height: 16px;
            }
            .center-centetr {
              flex: 1;
              margin-top: 16px;
              display: flex;
              flex-wrap: wrap;
              .box {
                cursor: pointer;
                margin: 3px;
                padding: 0 0.2rem;
                height: 0.6rem;
                border: 1px solid #680911;
                border-radius: 4px;
                font-size: 10px;
                // font-family: PingFang SC;
                font-weight: 200;
                color: #fde5e5;
                line-height: 0.6rem;
                text-align: center;
              }
              .red {
                color: #f35c5c;
              }
            }
          }
        }
      }
    }
  }
  .overview-right {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    .fenbu {
      height: 0.45rem;
      background: linear-gradient(
        91deg,
        rgba(31, 4, 5, 1) 0%,
        rgba(182, 9, 15, 0) 100%
      );
      border-radius: 0.05rem 0rem 0rem 0rem;
      .fenbu-title {
        height: 0.45rem;
        line-height: 0.45rem;
        padding: 0 0 0.1rem 0.17rem;
        position: relative;
        font-family: "微软雅黑";
        font-size: 14px;
        // font-family: "微软雅黑";
        &:after {
          content: "";
          width: 0.05rem;
          height: 0.25rem;
          background-color: #a40000;
          position: absolute;
          top: 0.1rem;
          left: 0;
          margin: auto 0;
        }
        color: #fff;
      }
    }
    .overview-right-top {
      flex: 0 0 5rem;
      #lookpie {
        width: 5.2rem;
        height: 100%;
        padding: 0.2rem 0 0 0.2rem;
        font-size: 0.2rem;
        line-height: 0.35rem;
        color: #74c6f5;
      }
      .lookchart {
        width: 6rem;
        background: rgba(17, 18, 22, 0.5);

        .looktitle {
          font-size: 0.1875rem;
          // font-family: PingFang SC;
          font-weight: 400;
          color: #cccccc;
          position: relative;
          padding-left: 0.175rem;
          margin-left: 0.1875rem;
        }

        .looktitle::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.05rem;
          height: 0.225rem;
          background: #0079fe;
        }
      }
    }
    .overview-right-bottom {
      flex: 1;
    }
  }
  .contrast {
    display: flex;
    font-size: 0.35rem;
    margin: 0 auto;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translate(-50%);
    margin: 0 auto;
    white-space: nowrap;
    span {
      margin-right: 00.1rem;
    }
    .iconfont {
      font-size: 0.35rem;
      color: red;
    }
    .contrast-left {
      margin-right: 00.1rem;
      span {
        margin-right: 00.1rem;
      }
    }
    .contrast-center {
      margin-right: 0.1rem;
    }
    .contrast-right {
      span {
        margin-left: 00.1rem;
      }
    }
  }
}
.sub-list-active {
  display: inline-block !important;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
.indicatorFilterDialog-template {
  .el-dialog {
    background-color: rgba(1, 14, 33, 0.8);
    border: 1px solid #013b9b;
    border-radius: 20px;
    transform: translateX(-0.13rem);

    .annotation {
      padding-right: 20px;
      text-align: end;
      color: #4d73b5;
    }
    .firstRow {
      transform: translateY(-20px);
    }
    .firstRow,
    .secondRow {
      padding-left: 20px;
      h3 {
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 15px;
      }
      // .h4 {
      //   font-size: 14px;
      //   margin-bottom: 10px;
      //   color: #489ef7;
      //   display: flex;
      //   justify-content: space-between;
      // }

      .h4 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #fff;
        display: inline-block;
        justify-content: space-between;
        // transform: translateY(0px);
      }
      .level1_Indicators,
      .level2_Indicators {
        .indicatorPanel {
          margin: 0px 30px 20px 0px;
          display: inline-block;
          padding: 6px 20px;
          border-radius: 4px;
          min-width: 0.5rem !important;
          color: #ffffff;
          text-align: center;
          background-color: #022256;
          cursor: pointer;
        }
        .activePaneClass {
          background-color: #036dda;
        }
      }
    }
    .secondRow {
      margin: 10px 0px;
    }
    .dialog-footer {
      text-align: center;
      .el-button {
        background-color: #002258;
        border: none;
      }
      .el-button:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
