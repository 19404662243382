import Vue from 'vue'
import router from './router'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const whiteList = ['login', 'portal']

router.beforeEach((to, form, next) => {
    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - '思享珞珈'`))
    if (Vue.ls.get(ACCESS_TOKEN)) {
        if (to.path === '/login') {
            next({ path: '/special' })
        } else {
            next()
        }
    } else {
        if (whiteList.includes(to.name)) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next({ path: '/login', query: { redirect: to.fullPath } })
        }
    }
})

function setDocumentTitle (title) {
    document.title = title
    const ua = navigator.userAgent
    // eslint-disable-next-line
    const regex = /\bMicroMessenger\/([\d\.]+)/
    if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
        const i = document.createElement('iframe')
        i.src = '/favicon.ico'
        i.style.display = 'none'
        i.onload = function () {
            setTimeout(function () {
                i.remove()
            }, 9)
        }
        document.body.appendChild(i)
    }
}
