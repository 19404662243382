<template>
  <!-- 交互分析 -->
  <div class="overview">
    <!-- 左侧的柱状图区域以及头部的标签 -->
    <div class="overview-center">
      <!-- 头部标签部分 -->
      <Crumb />
      <span class="addGroupBnt" @click="openAddGroupDialog">添加分组</span>
      <AddGroup ref="addGroupRef" @getFatherGroup="getFatherGroup"></AddGroup>
      <!-- 底部柱状图部分 -->
      <div class="imgbox-center">
        <div class="imgbox-center-right">
          <span class="first">一组</span>
          <span>{{ sonForFather[0].title1 }}</span>
          <span>{{ sonForFather[0].title2 }}</span>
          <span>{{ sonForFather[0].title3 }}</span>
        </div>
        <div class="imgbox-center-left">
          <span class="first">二组</span>
          <span>{{ sonForFather[1].title1 }}</span>
          <span>{{ sonForFather[1].title2 }}</span>
          <span>{{ sonForFather[1].title3 }}</span>
        </div>
      </div>
      <Histogram :age="age" v-on:son-click="changeRadar" ref="tab" />
    </div>
    <!-- 右侧的雷达图部分以及结论 -->
    <div class="overview-right">
      <div class="overview-right-top">
        <div class="ranking">
          <div class="fenbu">
            <!-- 右上角分析器标题 -->
            <h3 class="fenbu-title">{{ rightData.columnLabel }}</h3>
          </div>
        </div>
        <div class="lookchart">
          <!-- 右上角标题的内容 -->
          <div id="lookpie">
            {{ rightData.description }}
          </div>
        </div>
      </div>
      <div class="overview-right-bottom">
        <div class="fenbu">
          <!-- 右上角分析器标题 -->
          <h3 class="fenbu-title">{{ rightData.questionTitle }}</h3>
        </div>
        <div class="lookchart">
          <!-- 右上角标题的内容 -->
          <Radar :radarData="radarData" ref="tab2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Histogram from "../../components/Histogram.vue";
import Radar from "../../components/Radar.vue";
import AddGroup from "../../components/AddGroup.vue";
import Crumb from "../../components/crumb.vue";
import { mapGetters } from "vuex";
import {
  targetQuestionDetailForGraph,
  interactive,
  correlation,
} from "../../api/modular/analyse/analyse";
export default {
  components: {
    Histogram,
    Radar,
    AddGroup,
    Crumb,
  },
  data() {
    return {
      // 柱状图的数据
      age: {
        name: "一组",
        data: [],
        provinceName: "二组",
        provinceData: [],
        schoolName: "",
        schoolData: [],
        Xname: "", //这个可以不用传入
        Xdata: ["人生目标", "人生态度", "人生价值"],
      },
      // 雷达图的数据
      radarData: {
        name: "全国",
        data: [20, 20, 20],
        provinceName: "湖北省",
        provinceData: [30, 30, 30],
        schoolName: "武汉大学",
        schoolData: [50, 50, 50],
        Xname: "", //这个可以不用传入
        Xdata: ["自由", "民主", "和谐"],
      },
      // 右侧三个的对象
      rightData: {
        // 右侧头部标题
        columnLabel: `人生目标分析说明`,
        // 右侧头部内容
        description:
          "人生目标是人在相当长的一段时间、甚至是终其一生需要达成的状态，是对自己理想的实现，也可以称之为理想目标。",
        // 右侧下面标题
        questionTitle: "您是否拥有人生目标？",
      },
      // 分组数据
      sonForFather: [
        {
          age: "",
          grade: "大一",
          nationality: "",
          sex: "女",
          subject: "文学",
          title1: "女",
          title2: "大一",
          title3: "文学",
        },
        {
          age: "",
          grade: "大二",
          nationality: "",
          sex: "男",
          subject: "工学",
          title1: "男",
          title2: "大二",
          title3: "工学",
        },
      ],
      // 右下角的数据
      conclusions:
        "相关值在[-1,0]负相关[0,1]正相关；绝对值越大表示相关性越强(0.8~1非常强 0.6~0.8强相关 0.4~0.6中度相关 0.2~0.4弱相关 0~0.2无关)",
    };
  },
  created() {},
  computed: {
    ...mapGetters(["getCity", "getSchool", "getTargetLevel1"]),
  },
  mounted() {
    if (this.getCity) {
      this.targetQuestionDetailForGraph(this.age.Xdata[0], this.getCity);
    } else {
      this.targetQuestionDetailForGraph(this.age.Xdata[0]);
    }
    this.interactive(this.sonForFather[0], this.sonForFather[1]);
  },
  methods: {
    // 打开头部弹窗
    openAddGroupDialog() {
      this.$refs.addGroupRef.openDialog();
    },
    // 头部弹窗的组件传值子传父
    async getFatherGroup(val) {
      console.log("子传父", val);
      this.sonForFather = val;
      this.interactive(val[0], val[1]);
    },
    // 中间柱状图函数
    async interactive(item1, item2) {
      // 柱状图的读取
      if (!this.getCity && !this.getSchool) {
        return;
      }
      let columnEchart = this.$echarts.init(this.$refs.tab.$refs.headertab);
      columnEchart.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      // 雷达图的读取
      let lookpieOption = this.$echarts.init(this.$refs.tab2.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = this.getCity;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      let provinceName = city;
      let str = await interactive({
        year: 2020,
        themeName: "思想政治状况",
        targetName: this.getTargetLevel1,
        province: provinceName,
        universityName: this.getSchool,
        sex1: item1.sex || "",
        sex2: item2.sex || "",
        nation1: item1.nation || "",
        nation2: item2.nation || "",
        grade1: item1.grade || "",
        grade2: item2.grade || "",
        age1: item1.age || "",
        age2: item2.age || "",
        subject1: item1.subject || "",
        subject2: item2.subject || "",
      });
      // console.log("全国的分数值", str.data);
      let data = [];
      let Xdata = [];
      let provinceData = [];
      str.data["组1"].map((item) => {
        data.push(item.targetValue);
        Xdata.push(item.name);
      });
      str.data["组2"].map((item) => {
        provinceData.push(item.targetValue);
      });
      let countrydata = {
        name: "一组",
        data: [...data],
        provinceName: "二组",
        provinceData: [...provinceData],
        schoolName: "",
        schoolData: [],
        Xname: "", //这个可以不用传入
        Xdata: [...Xdata],
      };
      // console.log("全国的分数值", countrydata);
      this.age = countrydata;
      this.targetQuestionDetailForGraph(
        countrydata.Xdata[0],
        this.getCity,
        this.getSchool
      );
    },
    // 查询雷达图以及右侧内容的数据，并且赋值
    async targetQuestionDetailForGraph(targetName, provinceName, schoolName) {
      if (provinceName) {
        let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        //处理直辖市的数据
        let central = ["北京", "天津", "上海", "重庆"];
        let city = provinceName;
        if (autonomousRegion.indexOf(city) !== -1) {
          city = city == "新疆" ? "新疆维吾尔自治区" : city;
          city = city == "宁夏" ? "宁夏回族自治区" : city;
          city = city == "广西" ? "广西壮族自治区" : city;
          city = city == "西藏" ? "西藏自治区" : city;
          city = city == "内蒙古" ? "内蒙古自治区" : city;
        } else if (central.indexOf(city) !== -1) {
          city = city + "市";
        } else {
          city = city + "省";
        }
        provinceName = city;
        // console.log("省份",provinceName);
      }
      let str = await targetQuestionDetailForGraph({
        year: 2020,
        themeName: "思想政治状况",
        targetName,
        province: provinceName || "",
        universityName: schoolName || "",
      });
      // console.log("雷达图的数据", str);
      let countrydata = {
        name: "全国",
        data: str.data.data.country.values,
        provinceName: provinceName || "",
        provinceData: str.data.data.province
          ? str.data.data.province.values
          : [],
        schoolName: schoolName || "",
        schoolData: str.data.data.university
          ? str.data.data.university.values
          : [],
        Xname: "x轴", //这个可以不用传入
        Xdata: str.data.data.country.cols,
      };
      console.log("右边的数据", str.data);
      this.rightData = {
        // 右侧头部标题
        columnLabel: `${targetName}分析说明`,
        // 右侧头部内容
        description: str.data.description,
        // 右侧下面标题
        questionTitle: str.data.questionTitle,
      };
      this.radarData = countrydata;
      // 相关分析右下角
      let obj = await correlation({
        year: 2020,
        themeName: "思想政治状况",
        universityName: schoolName || "",
        province: provinceName || "",
        targetName: this.getTargetLevel1,
        subTargetNames: `${this.age.Xdata[0]},${this.age.Xdata[1]},${this.age.Xdata[2]}`,
      });
      console.log("右下角数据", obj);
      this.conclusions = obj.data.remark;
    },
    // 子传父函数，点击中间柱状图更新右侧内容
    changeRadar(item) {
      // console.log("item");
      // 雷达图的读取
      let lookpieOption = this.$echarts.init(this.$refs.tab2.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1",
      });
      this.targetQuestionDetailForGraph(
        item,
        this.getCity || "",
        this.getSchool || ""
      );
    },
  },
  watch: {
    getSchool() {
      // 监听后更新柱状图
      this.interactive(this.sonForFather[0], this.sonForFather[1]);
    },
    getCity() {
      // 监听后更新柱状图
      this.interactive(this.sonForFather[0], this.sonForFather[1]);
    },
    getTargetLevel1() {
      // 监听后更新柱状图更新雷达图和右侧内容
      this.interactive(this.sonForFather[0], this.sonForFather[1]);
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  height: 100%;
  width: 100%;
  display: flex;
  //   margin-top: 0.25rem;
  .overview-center {
    height: 100%;
    flex: 4;
    margin-right: 0.2rem;
    position: relative;
    .addGroupBnt {
      display: inline-block;
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0.7rem;
      line-height: 00.5rem;
      font-size: 14px;
    }
    .overview-center-title {
      height: 0.4rem;
      line-height: 0.4rem;
      border: 1px solid #470201;
      border-right: none;
      border-left: none;
      display: flex;
      justify-content: space-between;

      .overview-center-title-right {
        display: flex;
        line-height: 0.4rem;
      }
    }
    .imgbox-center {
      display: flex;
      width: 100%;
      margin: 0.5rem 0;
      .imgbox-center-left {
        flex: 1;
        height: 0.5rem;
        background: #110002;
        border: 1px solid #3c0307;
        display: flex;

        span {
          text-align: center;
          flex: 1;
          border-left: 1px solid rgba(96, 5, 5, 0.3);
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 0.5rem;
        }

        .first {
          border-left: none;
          background: rgba(96, 5, 5, 0.3);
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #e53d4a;
          line-height: 0.5rem;
        }
      }

      .imgbox-center-right {
        margin-right: 1rem;
        flex: 1;
        height: 0.5rem;
        background-color: #010816;
        border: 1px solid #003567;
        display: flex;
        span {
          text-align: center;
          flex: 1;
          border-left: 1px solid #003567;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 0.5rem;
        }
        .first {
          border-left: none;
          background: rgba(0, 160, 233, 0.44);
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #4fa5f5;
          line-height: 0.5rem;
        }
      }
    }
  }
  .overview-right {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    // .ranking {
    //   height: 5rem;
    // }
    .fenbu {
      height: 0.45rem;
      background: linear-gradient(
        91deg,
        rgba(31, 4, 5, 1) 0%,
        rgba(182, 9, 15, 0) 100%
      );
      border-radius: 0.05rem 0rem 0rem 0rem;
      .fenbu-title {
        // background-color: rgba(44, 3, 3, 0.7);
        color: #fff;
        height: 0.45rem;
        line-height: 0.45rem;
        padding: 0 0 0.1rem 0.17rem;
        position: relative;
        font-family: "微软雅黑";
        font-size: 12px;
        &:after {
          content: "";
          width: 0.05rem;
          height: 0.25rem;
          background-color: #a40000;
          position: absolute;
          top: 0.1rem;
          left: 0;
          margin: auto 0;
        }
      }
    }
    .overview-right-top {
      flex:0 0 5rem;
      #lookpie {
        width: 5.2rem;
        height: 100%;
        padding: 0.2rem 0 0 0.2rem;
        font-size: 0.2rem;
        line-height: 0.35rem;
        color: #74c6f5;
      }
      .lookchart {
        width: 6rem;
        // height: 5rem;
        background: rgba(17, 18, 22, 0.5);

        .looktitle {
          font-size: 0.1875rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cccccc;
          position: relative;
          padding-left: 0.175rem;
          margin-left: 0.1875rem;
        }

        .looktitle::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.05rem;
          height: 0.225rem;
          background: #0079fe;
        }
      }
    }
    .overview-right-bottom {
      flex: 1;
      .Pyramid-c-box {
        padding: 0.2rem;
        margin-top: 00.5rem;
        position: relative;
        left: 15%;
        margin-bottom: 0.2rem;
        width: 4rem;
        height: 1.4rem;
        background: transparent;
        border: 1px solid #4ed5f8;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1em 2em rgba(0, 0, 0, 0.3);
        animation: breath1 2s infinite;
      }

      .Pyramid-c-box::before {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 15px;
        width: calc(100% - 30px);
        content: "";
        border-top: 1px solid #016886;
        border-bottom: 1px solid #016886;
        z-index: 0;
      }

      .Pyramid-c-box::after {
        position: absolute;
        top: 15px;
        right: -1px;
        left: -1px;
        height: calc(100% - 30px);
        content: "";
        border-right: 1px solid #016886;
        border-left: 1px solid #016886;
        z-index: 0;
      }

      @keyframes breath1 {
        0% {
          border: 1px solid #4ed5f8;
        }

        /* 动画开始时的不透明度 */
        50% {
          border: 1px solid #176785;
        }

        /* 动画50% 时的不透明度 */
        100% {
          border: 1px solid #4ed5f8;
        }

        /* 动画结束时的不透明度 */
      }

      .Pyramid-c-box1 {
        position: relative;
        top: 5%;
        left: 15%;
        margin-bottom: 0.5rem;
        width: 4rem;
        height: 2rem;
        background: transparent;
        border: 1px solid #4ed5f8;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1em 2em rgba(0, 0, 0, 0.3);
        animation: breath2 2s infinite;
      }

      .Pyramid-c-box1::before {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 15px;
        width: calc(100% - 30px);
        content: "";
        border-top: 1px solid #016886;
        border-bottom: 1px solid #016886;
        z-index: 0;
      }

      .Pyramid-c-box1::after {
        position: absolute;
        top: 15px;
        right: -1px;
        left: -1px;
        height: calc(100% - 30px);
        content: "";
        border-right: 1px solid #016886;
        border-left: 1px solid #016886;
        z-index: 0;
      }

      @keyframes breath2 {
        0% {
          border: 1px solid #4ed5f8;
        }

        /* 动画开始时的不透明度 */
        50% {
          border: 1px solid #176785;
        }

        /* 动画50% 时的不透明度 */
        100% {
          border: 1px solid #4ed5f8;
        }

        /* 动画结束时的不透明度 */
      }
    }
  }
}
</style>