<template>
  <div class="AddGroup-template">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div class="alreadyGroupBox">
        <h3>已有分组</h3>
        <div class="father">
          <div
            :class="[
              'groupItem',
              alreadyGroupActiveIndex == index ? 'activeAlreadyGroup' : '',
            ]"
            v-for="(item, index) in alreadyGroupData"
            :key="index + 'group'"
            @click="clickAlreadyGroup(item, index)"
          >
            <!-- <el-button
            icon="el-icon-close"
            circle
            size="mini"
            @click.stop="delectAlreadyGroup(index)"
          ></el-button> -->
            <span class="groupIndex">{{ numberTransform(index) + "组" }}</span>
            <span>{{ item.title1 }}</span>
            <span class="line" id="line"> | </span>
            <span>{{ item.title2 }}</span>
            <span>{{ item.title3 }}</span>
          </div>
        </div>

        <el-button
          type="primary"
          @click="add"
          v-if="alreadyGroupData.length < 2"
          class="addNewBnt"
          >新增</el-button
        >
      </div>
      <div
        class="slectGroupBox"
        v-if="!isObjectNull && alreadyGroupData.length > 0"
      >
        <div class="annotation">
          <span>注释：选择条件限制三个</span>
        </div>
        <div class="slectGroup">
          <h3>性别</h3>
          <div class="sex_row">
            <div
              :class="[
                'groupPanel',
                alreadyGroupActiveItem.sex == item.label
                  ? 'activePaneClass'
                  : '',
              ]"
              v-for="(item, index) in sexOption"
              :key="index + 'sex'"
              @click="handleItemClick(item, item.label, 'sex')"
            >
              {{ item.label }}
            </div>
          </div>
          <h3>民族</h3>
          <div class="nationality_row">
            <div
              :class="[
                'groupPanel',
                alreadyGroupActiveItem.nationality == item.label
                  ? 'activePaneClass'
                  : '',
              ]"
              v-for="(item, index) in nationalityOption"
              :key="index + 'nationality'"
              @click="handleItemClick(item, item.label, 'nationality')"
            >
              {{ item.label }}
            </div>
          </div>

          <h3>年级</h3>
          <div class="grade_row">
            <div
              :class="[
                'groupPanel',
                alreadyGroupActiveItem.grade == item.label
                  ? 'activePaneClass'
                  : '',
              ]"
              v-for="(item, index) in gradeOption"
              :key="index + 'grade'"
              @click="handleItemClick(item, item.label, 'grade')"
            >
              {{ item.label }}
            </div>
          </div>

          <h3>年龄</h3>

          <div class="age_row">
            <div
              :class="[
                'groupPanel',
                alreadyGroupActiveItem.age == item.label
                  ? 'activePaneClass'
                  : '',
              ]"
              v-for="(item, index) in ageOption"
              :key="index + 'age'"
              @click="handleItemClick(item, item.label, 'age')"
            >
              {{ item.label }}
            </div>
          </div>
          <h3>学科门类</h3>
          <div class="subject_row">
            <div
              :class="[
                'groupPanel',
                alreadyGroupActiveItem.subject == item.label
                  ? 'activePaneClass'
                  : '',
              ]"
              v-for="(item, index) in subjectOption"
              :key="index + 'subject'"
              @click="handleItemClick(item, item.label, 'subject')"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { inquireSchool, interactive } from "../api/api.js";

export default {
  name: "addGroup",
  data() {
    return {
      // @click="sexPanelActiveId = item.value"
      // @click="nationalityPanelActiveId = item.value"
      // @click="gradePanelActiveId = item.value"
      // @click="agePanelActiveId = item.value"
      // @click="subjectPanelActiveId = item.value"

      dialogVisible: false,
      alreadyGroupActiveIndex: null,
      alreadyGroupActiveItem: {
        sex: "",
        nationality: "",
        grade: "",
        age: "",
        subject: "",
      },
      alreadyGroupData: [
        {
          sex: "女",
          nationality: "",
          grade: "大一",
          age: "",
          subject: "文学",
          title1: "",
          title2: "",
          title3: "",
        },
        {
          sex: "男",
          nationality: "",
          grade: "大二",
          age: "",
          subject: "工学",
          title1: "",
          title2: "",
          title3: "",
        },
      ],
      sexPanelActiveLabel: "",
      sexOption: [
        {
          value: 0,
          label: "女",
          isActive: false,
        },
        {
          value: 1,
          label: "男",
          isActive: false,
        },
      ],
      nationalityPanelActiveLabel: "",
      nationalityOption: [
        {
          value: 1,
          label: "汉族",
          isActive: false,
        },
        {
          value: 2,
          label: "维吾尔族",
          isActive: false,
        },
        {
          value: 3,
          label: "壮族",
          isActive: false,
        },
        {
          value: 4,
          label: "藏族",
          isActive: false,
        },
        {
          value: 5,
          label: "满族",
          isActive: false,
        },
        {
          value: 6,
          label: "蒙古族",
          isActive: false,
        },
        {
          value: 0,
          label: "其他",
          isActive: false,
        },
      ],
      gradePanelActiveLabel: "",
      gradeOption: [
        {
          value: 1,
          label: "大一",
          isActive: false,
        },
        {
          value: 2,
          label: "大二",
          isActive: false,
        },
        {
          value: 3,
          label: "大三",
          isActive: false,
        },
        {
          value: 4,
          label: "大四",
          isActive: false,
        },
        {
          value: 5,
          label: "硕士生",
          isActive: false,
        },
        {
          value: 6,
          label: "博士生",
          isActive: false,
        },
      ],
      agePanelActiveLabel: "",
      ageOption: [
        {
          value: 1,
          label: "20岁以下",
          isActive: false,
        },
        {
          value: 2,
          label: "21岁至24岁",
          isActive: false,
        },
        {
          value: 3,
          label: "24岁以上",
          isActive: false,
        },
      ],
      subjectPanelActiveLabel: "",
      subjectOption: [],
      isObjectNull: true,
      lastActiveIndex: null,
    };
  },
  methods: {
    handleItemClick(item, value, key) {
      item.isActive = !item.isActive;
      if (this.alreadyGroupActiveItem[key] === value) {
        this.alreadyGroupActiveItem[key] = "";
      } else this.alreadyGroupActiveItem[key] = value;
    },
    //打开弹窗
    openDialog() {
      this.dialogVisible = true;
      this.setSearchTitle();
      this.querySchoolInfo();
    },
    add() {
      this.isObjectNull = false;
      this.alreadyGroupActiveItem = {
        sex: "",
        nationality: "",
        grade: "",
        age: "",
        subject: "",
      };
      this.alreadyGroupData.push(this.alreadyGroupActiveItem);
    },
    //点击已有分组进行修改
    clickAlreadyGroup(item, index) {
      this.isObjectNull = false;
      this.lastActiveIndex !== null &&
        (this.alreadyGroupData[this.lastActiveIndex] = {
          ...this.alreadyGroupActiveItem,
        });
      this.alreadyGroupActiveIndex = index;
      this.alreadyGroupActiveItem = { ...item };
      this.lastActiveIndex = index;

      // this.sexPanelActiveLabel = item.sex;
      // this.nationalityPanelActiveLabel = item.nationality;
      // this.gradePanelActiveLabel = item.grade;
      // this.agePanelActiveLabel = item.age;
      // this.subjectPanelActiveLabel = item.subject;
    },
    //删除某个已有分组
    delectAlreadyGroup(index) {
      let isEqual = this.isEqual(
        this.alreadyGroupActiveItem,
        this.alreadyGroupData[index]
      );

      this.alreadyGroupData = this.alreadyGroupData.filter((x, i) => {
        return index !== i;
      });
      if (isEqual) {
        this.isObjectNull = true;
      } else {
        this.alreadyGroupActiveIndex = this.alreadyGroupData.length - 1;
        this.isObjectNull = false;
      }
    },
    //高校信息查询
    async querySchoolInfo() {
      let arr = [];
      let params = {
        universityName: "武汉大学",
      };

      let reponse = await inquireSchool(params);
      if (reponse.code == 200) {
        arr = JSON.parse(reponse.data.subjectList);
        this.subjectOption = arr.map((x) => {
          return {
            label: x,
            isActive: false,
          };
        });
      }
    },
    //提交
    async submit() {
      let isLimit = false;

      this.alreadyGroupData[this.alreadyGroupActiveIndex] =
        this.alreadyGroupActiveItem;
      let params = {
        year: new Date().getFullYear(),
        themeName: "思想政治状况",
        targetName: "",
        sex1: "",
        sex2: "",
        nation1: "",
        nation2: "",
        grade1: "",
        grade2: "",
        age1: "",
        age2: "",
        subject1: "",
        subject2: "",
      };
      if (this.alreadyGroupData.length == 0) {
        this.$message.warning("分组不少于两个！");
        return;
      }
      this.alreadyGroupData.forEach((x, i) => {
        let count = 0;
        if (i == 0) {
          x.sex && ((params.sex1 = x.sex), count++);
          x.nationality && ((params.nation1 = x.nationality), count++);
          x.grade && ((params.grade1 = x.grade), count++);
          x.age && ((params.age1 = x.age), count++);
          x.subject && ((params.subject1 = x.subject), count++);
          if (count > 3) {
            isLimit = true;
            return this.$message.warning("选择条件限制3个");
          }
        } else {
          x.sex && ((params.sex2 = x.sex), count++);
          x.nationality && ((params.nation2 = x.nationality), count++);
          x.grade && ((params.grade2 = x.grade), count++);
          x.age && ((params.age2 = x.age), count++);
          x.subject && ((params.subject2 = x.subject), count++);
          if (count > 3) {
            isLimit = true;
            return this.$message.warning("选择条件限制3个");
          }
        }
      });
      if (isLimit) {
        return;
      }
      this.setSearchTitle();
      let reponse = {};
      !isLimit && (reponse = await interactive(params));
      if (reponse.code == 200) {
        this.dialogVisible = false;
        this.$message.success("添加成功");
      }
      this.$emit("getFatherGroup", this.alreadyGroupData);
    },
    setSearchTitle() {
      this.alreadyGroupData.forEach((x, i) => {
        x.title1 = "";
        x.title2 = "";
        x.title3 = "";
        for (let m = 0; m < 3; m++) {
          Object.keys(x).forEach((key) => {
            if (!x["title1"]) {
              if (!["title1", "title2", "title3"].includes(key) && x[key]) {
                x["title1"] = x[key];
              }
            } else if (!x["title2"]) {
              if (
                !["title1", "title2", "title3"].includes(key) &&
                x[key] &&
                x[key] !== x["title1"]
              ) {
                x["title2"] = x[key];
              }
            } else if (!x["title3"]) {
              if (
                !["title1", "title2", "title3"].includes(key) &&
                x[key] &&
                x[key] !== x["title1"] &&
                x[key] !== x["title2"]
              ) {
                x["title3"] = x[key];
              }
            }
          });
        }
      });
    },
    numberTransform(index) {
      let str = "";
      switch (index + 1) {
        case 1:
          str = "一";
          break;
        case 2:
          str = "二";
          break;
        case 3:
          str = "三";
          break;
        case 4:
          str = "四";
          break;
      }
      return str;
    },
    // 判断是否是对象或数组
    isObject(obj) {
      return typeof obj === "object" && obj !== null;
    },
    // 全相等（深度）
    isEqual(obj1, obj2) {
      if (!this.isObject(obj1) || !this.isObject(obj2)) {
        // 值类型（注意，参与 equal 的一般不会是函数）
        return obj1 === obj2;
      }
      //如果传入两个相同的对象，如isEqual(obj1, obj1)
      if (obj1 === obj2) {
        return true;
      }
      // 两个都是对象或数组，而且不相等
      // 1. 先取出 obj1 和 obj2 的 keys ，比较个数
      const obj1Keys = Object.keys(obj1);
      const obj2Keys = Object.keys(obj2);
      if (obj1Keys.length !== obj2Keys.length) {
        return false;
      }
      // 2. 以 obj1 为基准，和 obj2 一次递归比较
      for (let key in obj1) {
        // 比较当前 key 的 val —— 递归！！！
        const res = this.isEqual(obj1[key], obj2[key]);
        if (!res) {
          return false;
        }
      }
      // 3. 全相等
      return true;
    },
    //关闭弹窗
    closeDialog() {
      this.isObjectNull = true;
      this.alreadyGroupActiveIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/addGroup.scss";
</style>
<style lang="scss">
.AddGroup-template {
  /deep/ #line {
    color: #fff;
  }
  .el-dialog {
    // background-color: rgba(14, 1, 2, 0.8);
    background-color: rgba(1, 14, 33, 0.8);
    // border: 1px solid #680911;
    border: 1px solid #013b9b;
    border-radius: 20px;
    // min-width: 950px;
    .el-dialog__body {
      padding-top: 0px;
      .alreadyGroupBox {
        .father {
          display: flex;
          justify-content: space-between;
          .groupItem {
            .el-button {
              .el-icon-close {
                color: #000000;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
</style>
