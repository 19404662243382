const getters = {
  token: state => state.user.token,
  nickname: state => state.user.name,
  buttons: state => state.user.buttons,
  admintype: state => state.user.admintype,
  userInfo: state => state.user.info,
  getCity: state => state.analyse.selectCity,
  getSchool: state => state.analyse.selectSchool,
  getSchoolListCity: state => state.analyse.schoolListCity,
  getTargetLevel1: state => state.analyse.targetLevel1,
  getRoles: state => state.user.roles,
  getOrgInfo: state => state.user.orgInfo,
  getUserName: state => state.user.userName,
  avatar: state => state.user.avatar,
  getIsHome : state => state.analyse.isHome,
 /* getUserName */
}
export default getters
