<template>
  <!-- 分析页面 -->
  <div class="analyse">
    <!-- 头部组件 -->
    <HeaderTitle />
    <div class="main">
      <div class="main-left">
        <div class="left-map">
          <h3 class="map-title">全国省份地图</h3>
          <Map class="map"></Map>
        </div>
        <div class="left-list">
          <h3 class="list-title" v-if="isShowDes">各年级人数分布</h3>
          <h3 class="list-title" v-if="!isShowDes">高校列表</h3>
          <SchoolList></SchoolList>
        </div>
      </div>
      <!-- 中间以及右边的路由部分 -->
      <div class="main-right">
        <router-view />
      </div>
    </div>
    <!-- 底部导航 -->
    <FootNav class="foot" />
  </div>
</template>

<script>
import HeaderTitle from "../components/HeaderTitle.vue";
import FootNav from "../components/FootNav.vue";
import Map from "@/components/Map";
import SchoolList from "@/components/SchoolList";
import {mapActions} from "vuex";
export default {
  components: {
    HeaderTitle,
    FootNav,
    Map,
    SchoolList,
  },
  created() {
    this.isShowDes =
      JSON.parse(localStorage.getItem("role"))[0].name == "高校" ? true : false; //高校
    this.setIsHome(false)
  },
  methods:{
    ...mapActions([
      'setIsHome'
    ]),
  }
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  width: 100%;
}
.analyse {
  width: 100vw;
  height: 100vh;
  padding-bottom: 2.125rem;
  color: #fff;
  width: 100%;
  height: 100vh;
  padding: 0rem 0.2rem 0 -0.1rem;
  // height: 900rem;
  background: url("~@/assets/img/bj16.png") no-repeat;
  background-size: 100% 100%;
  .main {
    // height: 100%;
    height: 70vh;
    display: flex;
    .main-left {
      width: 5.5rem;
      /*background-color: yellow;*/
      margin: 0 0.2rem;
      /*左边地图部分样式*/
      .left-map {
        width: 100%;
        height: 5rem;
        /*background-color: pink;*/
        position: relative;
        .map-title {
          font-size: 14px;
          background-image: linear-gradient(
            to right,
            rgba(44, 3, 3, 0.7),
            rgba(44, 3, 3, 0)
          );
          color: #fff;
          position: relative;
          padding: 0.1rem 0 0.1rem 0.17rem;
          &:after {
            content: "";
            width: 0.05rem;
            height: 0.3rem;
            background-color: #a40000;
            position: absolute;
            left: 0;
            margin: auto 0;
          }
        }
        .map {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .left-list {
        .list-title {
          font-size: 14px;
          background-image: linear-gradient(
            to right,
            rgba(44, 3, 3, 0.7),
            rgba(44, 3, 3, 0)
          );
          color: #fff;
          padding: 0.1rem 0 0.1rem 0.17rem;
          position: relative;
          &:after {
            content: "";
            width: 0.05rem;
            height: 0.3rem;
            background-color: #a40000;
            position: absolute;
            left: 0;
            margin: auto 0;
          }
        }
      }
    }
    .main-right {
      flex: 1;
    }
  }
  .foot {
  }
}
</style>
