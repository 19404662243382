<template>
    <div class="background-wrapper" @click="cancelPop($event)">
        <div class="portal-header">
            <img
                    src="~@/assets/img/website/gwbg-3.png"
                    class="portal-background"
                    alt=""
                    ref="bannerImg"
            />
            <div class="icon"></div>
            <div class="header-buttons">
                <div class="button--text first">首页</div>
                <div class="button--text"><a href="#platform_intro">平台概述</a></div>
                <div class="button--text"><a href="#course">网络思政</a></div>
                <div class="button--text">研究动态</div>
                <div class="button--text"><a href="http://www.ejuster.cn" target="_blank">调研平台</a></div>
                <div class="button--text"><a href="http://image.ejuster.cn/sxlj_intro.pdf" target="_blank">中心展示</a></div>
                <div class="button--text" @click="login">数据大屏</div>
            </div>
            <!-- <div v-show="this.userName" class="usersName">{{this.userName}}</div> -->
            <!-- <div v-if="logoutVisible" class="login-button" @click="logout">登出</div> -->
            <div v-if="logoutVisible" class="login-right-p" @click="openQuit = !openQuit">
                <!-- <span class="iconfont" :class="avatar ? '' : 'icon-geren'" v-if="!avatar"></span> -->
                <!-- <img :src="avatar" alt="" class="border" v-if="avatar"/>
                -->
                <el-avatar :size="30" :src="avatar" @error="errorHandler" icon="el-icon-user-solid">
                </el-avatar>
            </div>
            <div v-if="!logoutVisible" class="login-button" @click="login">登录</div>
            <!-- 头像 -->
            <div class="window" v-show="openQuit">
                <!-- @click="handoff" -->
                <div class="handoff">
                    <i class="iconfont icon-geren"> </i>
                    <span>{{this.userName}}</span>
                </div>
                <div class="quit" @click="logout">
                    <i class="iconfont icon-tuichu1"> </i>
                    <span>退出</span>
                </div>
            </div>
            <div class="content-statistic" id="stat">
                <div class="statistic-item">
                    <p class="item-number">{{ surveyStatData.surveyTtl }}</p>
                    <p class="item-label">总问卷数</p>
                </div>
                <div class="statistic-item">
                    <p class="item-number">{{ surveyStatData.answerTtl }}</p>
                    <p class="item-label">总答卷数</p>
                </div>
                <div class="statistic-item">
                    <p class="item-number">{{ surveyStatData.provinceTotal }}</p>
                    <p class="item-label">省份数</p>
                </div>
                <div class="statistic-item">
                    <p class="item-number">{{ surveyStatData.collegeTotal }}</p>
                    <p class="item-label">学校总数</p>
                </div>
                <div class="statistic-item">
                    <p class="item-number">{{ surveyStatData.collegeNum }}</p>
                    <p class="item-label">参与高校</p>
                </div>
            </div>
        </div>
        <div class="portal-about" id="platform_intro">
            <!-- <div class="about-background">
              <div class="line-icon"></div>
              <div class="about-title">ABOUT US</div>
              <div class="about-text">关于我们</div>
            </div> -->
            <div class="about-content">
                <div class="content-title">思享珞珈数据分析平台</div>
                <div class="content-text">
                    思享珞珈是教育部高校思想政治工作创新发展中心(武汉大学)和武汉大学大数据研究院共同研发的中国大学生研究数据平台。<br/>
                    研究团队坚持以习近平新时代中国特色社会主义思想为指导，坚持”为着科学化，打造硬支撑”的理念，以“顶端设计、长线规划、多维布局”为原则，以高校师生思想政治状况调查研究和高校思想政治工作理论创新研究为主攻方向研发的问卷调查、网络舆情、知识图谱、数据分析、机器学习、知识库等六大系统，发挥数据平台采、集、用、研、视等五大功能，着力将思享珞珈建成全国高校思想政治工作创新发展的专业标杆和高端智库。
                </div>
            </div>
            <div class="about-video">
                <video
                    v-if="videoUrl"
                    width="100%"
                    height="80%"
                    controls
                    autoplay="autoplay"
                    poster="~@/assets/img/shiping.png"
                >
                    <source :src="videoUrl" type="video/mp4"/>
                </video>
            </div>
        </div>
        <div class="portal-system">
            <div class="system-title">调研六大系统</div>
            <div class="system-content">
                <div class="system-item mapping-knowledge-domain">
                    <p class="system-item--title xtitle1">知识图谱</p>
                    <p class="system-item--remark xtext1">
                        基于《中国大学生思想政治教育发展报告》的调查数据，运用信息可视化的技术呈现当代大学生思想政治状况整体貌态和发展趋势。
                    </p>
                </div>
                <div class="system-item data-analysis" @click="login">
                    <p class="system-item--title xtitle2">数据分析</p>
                    <p class="system-item--remark xtext2">
                        通过横向分析、纵向分析、宏观分析、回归分析等多重维度深度挖掘大学生思想政治观念及其教育状况之间的亲疏关系，按需呈现大学生思想政治状况的省际差异、校际差异。
                    </p>
                </div>
                <div class="system-item public-opinion">
                    <p class="system-item--title xtitle3">网络舆情</p>
                    <p class="system-item--remark xtext3">
                        通过网络爬虫技术在微博、微信、百度贴吧知乎等新媒体平台上抓取大学生思想政治状况的网络舆情信息，从而对大学生的思想动态进行及时且精准的画像。
                    </p>
                </div>
                <div class="system-item survey">
                    <p class="system-item--title xtitle4">问卷调查</p>
                    <p class="system-item--remark xtext4">
                        基于网络平台开展的大学生思想政治教育状况问卷调查，包括系统调查与专题调查即时调查与追踪调查等，具有可示范性、能复制易操作、便推广等特点。
                    </p>
                </div>
                <div class="system-item machine-learning">
                    <p class="system-item--title xtitle5">机器学习</p>
                    <p class="system-item--remark xtext5">
                        基于人工智能对大学生群体进行聚类分析进而深入分析不同类别大学生的群体特征、思想状况、行为习惯等。
                    </p>
                </div>
                <div class="system-item knowledge-base">
                    <p class="system-item--title xtitle6">知识库</p>
                    <p class="system-item--remark xtext6">
                        集成问卷数据、舆情数据和行为数据等的大数据资源，附有包括新中国学校德育史大学生思想政治教育重要政策文献在内的众多文献事记。
                    </p>
                </div>
            </div>
        </div>
        <div class="portal-video" id="course">
            <div class="portal-video--title">我心中的思政课</div>
            <div class="portal-video-box">
                <div class="portal-video--more">查看更多</div>
                <img class="more-ico" src="~@/assets/img/website/more.png" alt=""/>
            </div>
            <div class="video-wrapper">
                <div class="video-item" v-for="(item, index) in videos" :key="index">
                    <div class="video-content">
                        <video
                                v-if="item.source"
                                width="100%"
                                height="100%"
                                controls
                                :poster="item.picture"
                        >
                            <source :src="item.source" type="video/mp4"/>
                        </video>
                    </div>
                    <p class="video-name">{{ item.title }}</p>
                </div>
            </div>
        </div>
        <div class="portal-footer">
            <img class="left-icon" src="~@/assets/img/website/lianjie.png" alt="">
            <img class="right-icon" src="~@/assets/img/website/mail.png" alt="">
            <div class="footer-top">联系我们</div>
            <div class="footer-left">
                <div class="title">友情链接</div>
                <div class="left-content">
                    <a href="##" class="item">武汉大学</a>
                    <a href="##" class="item">教育部高校思想政治工作创新发展中心</a>
                </div>
                <div class="left-content">
                    <a href="##" class="item">武汉大学马克思主义学院</a>
                    <a href="##" class="item">北京高校思想政治理论高精尖仓</a>
                </div>
            </div>
            <div class="footer-right">
                <div class="left-title">
                    <span>武汉大学大数据研究院</span>
                </div>
                <div class="right-detail">
                    <div class="item">邮编：430072</div>
                    <div class="item">武汉市武昌区八一路武汉大学本科生院楼北栋一楼</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {videoList, gwText} from "@/api/modular/portal";
    import {ACCESS_TOKEN} from "@/store/mutation-types";
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                videoUrl: "https://sxljcdn.ninemm.cn/sxlj_intro.mp4",
                defaultVideoUrl:"https://sxljcdn.ninemm.cn/sxlj_intro.mp4",
                photoList: [],
                photoText: [],
                videosList: [],
                logoutVisible: false,
                surveyStatData: {
                    surveyTtl: 0,
                    answerTtl: 0,
                    provinceTotal: 0,
                    collegeTotal: 0,
                    collegeNum: 0,
                },
                videos: [],
                userName: "",
                openQuit: false,
            };
        },
        created() {
            this.avatar = JSON.parse(localStorage.getItem("avatar"));
            this.userName = this.getUserName;
            if (JSON.parse(localStorage.getItem("user"))) {
                this.userName = JSON.parse(localStorage.getItem("user")).name;
                this.setUserName(this.userName);
            }
            
            const token = this.$ls.get(ACCESS_TOKEN);
            if (token) {
                this.logoutVisible = true;
            } else {
                this.logoutVisible = false;
            }
            // document.body.scrollTop = 100
            
        },
        computed: {
            ...mapGetters(["nickname", "getUserName"]),
        },
        mounted() {
            this.videoUrl = this.defaultVideoUrl;
            this.init();

            this.setBanner()
            document.getElementById("stat").scrollIntoView({offset: {top: 10, left: 0}, behavior: "smooth"})

            // console.log("scroll................")
        },
        methods: {
            ...mapActions(["Logout", "setUserName"]),
            login() {
                this.$router.push("/chinaHome");
            },
            init() {
                let _this = this;
                videoList({pageNo: 1, pageSize: 6}).then((res) => {
                    if (res.success) {
                        _this.videos = [];
                        _this.videos = res.data.rows;
                    } else {
                        _this.videos = [];
                    }
                });
                gwText().then((res) => {
                    if (res.success) {
                        this.surveyStatData = res.data;
                    } else {
                        console.log(res);
                    }
                });
            },
            logout() {
                const _that = this;
                this.$confirm("确认退出登录吗？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                    customClass: "reLoginBox",
                })
                    .then(() => {
                        _that.Logout({token: this.$ls.get(ACCESS_TOKEN)}).then(() => {
                            _that.logoutVisible = false;
                            _that.openQuit = false;
                        });
                    })
                    .catch(() => {
                    });
            },
            cancelPop(event) {
                let tp = document.querySelector(".login-right-p");
                if (tp) {
                    if (!tp.contains(event.target)) {
                        this.openQuit = false;
                    }
                }
            },
            setBanner() {
                console.log('112345')
                console.log(window.screen.height);
                console.log(window.screen.availWidth);
                this.$refs.bannerImg.style.width = window.screen.width  + 'px'
                this.$refs.bannerImg.style.height = window.screen.height * 0.84  + 'px'
            }
        },
        watch: {
            getUserName: function (value) {
                this.userName = value;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/index.scss";

    .el-avatar {
        transform: translate(-0.5rem, -0.13rem);
    }

    .login-right-p {
        height: 100%;
        display: flex;
        position: absolute;
        top: 2%;
        right: 12%;
        height: 0.7rem;
        line-height: 00.7rem;
        margin-right: 00.8rem;
        margin-top: 00.04rem;
        // .iconfont{
        //   font-size: 40px;
        //   color: #fff;
        // }
        // 111
    }

    .border {
        border-radius: 50%;
        padding: 0.15rem 0;
    }

    .window {
        width: 2.5rem;
        height: 1.5rem;
        background-color: #4c1010;
        color: #fff;
        position: absolute;
        right: 11%;
        top: 8%;
        z-index: 99;
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        // justify-content: center;
        .handoff {
            border-bottom: 1px solid black;
            color: #e4bbbb;

            .iconfont {
                font-size: 20px;
            }
        }

        .quit {
            i {
                margin-right: 00.1rem;
            }

            padding-left: 0.14rem;
            flex: 1;
            text-align: left;
            line-height: 00.5rem;
            cursor: pointer;

            &:hover {
                background-color: #570d0f;
            }

            .iconfont {
                font-size: 20px;
                margin-left: .06rem;
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none
    }
</style>
