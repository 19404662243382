<template>
  <div class="schoolDes">
    <!-- <img :src="this.imgUrl" alt="">
        {{this.schoolDes}} -->
    <div id="lookchart"></div>
  </div>
</template>
<script>
// import {analysis_university_query} from '../../api/api'
// import {analysis_university_query} from "@/api/api";
import { queryGradeRatio } from "@/api/api";
export default {
  name: "schoolDescribe",
  data() {
    return {
      // imgUrl : '',
      // schoolDes : ''
      year: 2020,
      themeName: "思想政治状况",
    };
  },
  props: {
    schoolName: {
      type: String,
      default: () => "武汉大学",
      required: true,
    },
  },
  created() {
    // this.getSchoolDes()
    this.getSchoolGrade();
  },
  methods: {
    // getSchoolDes(){
    //     analysis_university_query({universityName:this.schoolName}).then((data)=>{
    //             //设置学校简介和学校描述
    //             console.log(data);
    //             this.imgUrl = data.data.logoUrl
    //             this.schoolDes = data.data.introduct
    //         console.log('学校详情')
    //         console.log(this.imgUrl,this.schoolDes)
    //         })
    // }
    getSchoolGrade() {
      queryGradeRatio({
        universityName: this.schoolName,
        themeName: this.themeName,
        year: this.year,
      }).then((data) => {
        console.log("1111111", data.data.univs);
        //设置学校简介和学校描述
        let ageList = [];
        let ageNumList = [];
        let maxCapacity = [];
        data.data.univs.forEach((item) => {
          ageList.push(item.grade);
          ageNumList.push(item.total);
          maxCapacity.push(item.maxCapacity);
        });
        this.lookchart(ageList, ageNumList, maxCapacity);
      });
    },

    lookchart(ageList, ageNumList, maxCapacity) {
      let lookchart = this.$echarts.init(document.getElementById("lookchart"));
      let _this = this;
      let dataZoom;
      dataZoom = [];
      if (ageList.length > 4) {
        dataZoom = [
          {
            show: true,
            height: 12,
            xAxisIndex: [0],
            brushSelect: false,
            bottom: "8%",
            start: 0,
            end: 50,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ];
      } else {
        dataZoom = [
          {
            show: false,
            height: 12,
            xAxisIndex: [0],
            brushSelect: false,
            bottom: "8%",
            start: 0,
            end: 100,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ];
        // console.log(dataZoom,"datazoom");
      }
      let option = {
        /*tooltip: {
          trigger: "axis",

          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            if (params[0].name == "大一") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "大二") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "大三") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "大四（大五）") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "硕士生") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "博士生") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            }
          },
        },*/

        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "none",
            shadowColor: "rgba(255,255,255,0.4)",
            // shadowColor:'yellow'
          },
          // 背景颜色
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          // 边框颜色
          borderColor: "black",
          textStyle: {
            color: "#fff",
          },
          formatter: function (params) {
            if (params[0].name == "大一") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "大二") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "大三") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "大四（大五）") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "硕士生") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            } else if (params[0].name == "博士生") {
              return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
            }
          },
        },





        grid: {
          top: "20%",
          left: "10%",
          right: "10%",
          bottom: "18%",
          containLabel: true,
        },
        xAxis: {
          data: ageList,
          axisTick: { show: false },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#434343",
            },
          },
          axisLabel: {
            interval:0,
            textStyle: {
              color: "#FFF",
            },
          },
        },
        yAxis: [
          {
            name: "单位 ：个",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#fff",
              padding: [0, 0, 10, -40], //---坐标轴名称相对位置
            },
            nameGap: 15, //---坐标轴名称与轴线之间的距离
            type: "value",
            gridIndex: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(67,67,67,0.1)",
                width: 1,
                type: "solid",
              },
            },
            axisTick: {
              show: false,
            },
            // min: 0,
            // max: 1500,
            // max:maxCapacity,
            axisLine: {
              lineStyle: {
                color: "#0c3b71",
              },
            },
            axisLabel: {
              color: "#FFF",
              formatter: "{value}",
            },
          },
          {
            splitLine: { show: false },
            axisTick: { show: false },
            axisLine: { show: false },
            axisLabel: {
              show: false,
              textStyle: {
                color: "#FFF",
              },
            },
          },
        ],
        dataZoom: dataZoom,
        series: [
          {
            name: "hill",
            type: "pictorialBar",
            barCategoryGap: "50%",
            symbol: "triangle",
            // 时间越长动画越慢
            animationDuration: 3000,
            animationEasing: "cubicInOut",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "white",
              },
            },
            itemStyle: {
              normal: {
                opacity: 1,
                color: function (params) {
                  if (params.name == "大一") {
                    return new _this.$echarts.graphic.LinearGradient(
                      0,
                      1,
                      0,
                      0,
                      [
                        {
                          offset: 0,
                          color: "transparent",
                        },
                        {
                          offset: 1,
                          color: "#01dde8",
                        },
                      ]
                    );
                  } else if (params.name == "大二") {
                    return new _this.$echarts.graphic.LinearGradient(
                      0,
                      1,
                      0,
                      0,
                      [
                        {
                          offset: 0,
                          color: "transparent",
                        },
                        {
                          offset: 1,
                          color: "#d44343",
                        },
                      ]
                    );
                  } else if (params.name == "大三") {
                    return new _this.$echarts.graphic.LinearGradient(
                      0,
                      1,
                      0,
                      0,
                      [
                        {
                          offset: 0,
                          color: "transparent",
                        },
                        {
                          offset: 1,
                          color: "#01dde8",
                        },
                      ]
                    );
                  } else if (params.name == "大四（大五）") {
                    return new _this.$echarts.graphic.LinearGradient(
                      0,
                      1,
                      0,
                      0,
                      [
                        {
                          offset: 0,
                          color: "transparent",
                        },
                        {
                          offset: 1,
                          color: "#d44343",
                        },
                      ]
                    );
                  } else if (params.name == "硕士生") {
                    return new _this.$echarts.graphic.LinearGradient(
                      0,
                      1,
                      0,
                      0,
                      [
                        {
                          offset: 0,
                          color: "transparent",
                        },
                        {
                          offset: 1,
                          color: "#01dde8",
                        },
                      ]
                    );
                  } else if (params.name == "博士生") {
                    return new _this.$echarts.graphic.LinearGradient(
                      0,
                      1,
                      0,
                      0,
                      [
                        {
                          offset: 0,
                          color: "transparent",
                        },
                        {
                          offset: 1,
                          color: "#d44343",
                        },
                      ]
                    );
                  }
                },
              },
              emphasis: {
                opacity: 1,
              },
            },
            data: ageNumList,
            z: 10,
          },
          {
            name: "glyph",
            type: "pictorialBar",
            barGap: "-100%",
            color: "yellow",
            symbolPosition: "end",
            symbolSize: 50,
            symbolOffset: [0, "-120%"],
          },
          {
            name: "背景",
            type: "bar",
            barWidth: "50%",
            xAxisIndex: 0,
            yAxisIndex: 1,
            barGap: "-135%",
            data: maxCapacity,
            itemStyle: {
              normal: {
                color: "rgba(112,112,112,0.1)",
              },
            },
            z: 9,
          },
        ],
      };
      lookchart.setOption(option);
      window.addEventListener("resize", function () {
        lookchart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.schoolDes {
  width: 100%;
  margin-top: 0.2rem;
  height: 3.9rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  overflow: hidden;
  -webkit-box-orient: vertical;
  line-height: 0.35rem;
  img {
    border-radius: 3px;
    width: 100%;
    height: 2rem;
    margin-bottom: 0.1rem;
  }
}
#lookchart {
    width: 6rem;
    height: 4rem;
}

</style>
