import { axios } from '@/utils/request'

/*根据省份获取学校列表*/
export function getSchoolList(parameter) {
    return axios({
        url: "/analysis_api/university/queryByProvice",
        method: "get",
        params: parameter,
    });
}
// 根据一级指标，查询二级指标的分数值，输入省份，学校，科目查询分数值
export function queryTargetValue(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/queryTargetValue",
        method: "get",
        params: parameter,
    });
}
// 查询指标的分数值（图标数据）
export function queryTargetValuesForGraph(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/queryTargetValuesForGraph",
        method: "get",
        params: parameter,
    });
}
// 查询单个指标查询问题及答题情况（雷达图）
export function targetQuestionDetailForGraph(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/targetQuestionDetailForGraph",
        method: "get",
        params: parameter,
    });
}
// 历时分析-全国
export function queryWithCountry(parameter) {
    return axios({
        url: "/analysis_api/diachronic/queryWithCountry",
        method: "get",
        params: parameter,
    });
}
// 历时分析-全省
export function queryWithProvince(parameter) {
    return axios({
        url: "/analysis_api/diachronic/queryWithProvince",
        method: "get",
        params: parameter,
    });
}
// 历时分析-全校
export function queryWithUniversity(parameter) {
    return axios({
        url: "/analysis_api/diachronic/queryWithUniversity",
        method: "get",
        params: parameter,
    });
}
/*根据一级指标查询二级指标列表*/
export function Secondlist(parameter) {
    return axios({
        url: "/analysis_api/analysisTarget/list",
        method: "get",
        params: parameter,
    });
}
/*交互分析*/
export function interactive(parameter) {
    return axios({
        url: "/analysis_api/interactive/query",
        method: "get",
        params: parameter,
    });
}
/*存在问卷的省份*/
export function analysis_province_query(parameter) {
    return axios({
        url: "/analysis_api/province/query",
        method: "get",
        params: parameter,
    });
}

/*根据学校获取专业*/
export function schoolList(parameter) {
    return axios({
        url: "/analysis_api/university/queryByProvice",
        method: "get",
        params: parameter,
    });
}

/*高校信息查询*/
export function schoolSubject(parameter) {
    return axios({
        url: "/analysis_api/university/query",
        method: "get",
        params: parameter,
    });
}
/*相关分析*/
export function correlation(parameter) {
    return axios({
        url: "/analysis_api/correlation/query",
        method: "get",
        params: parameter,
    });
}