<template>
  <!-- <div class="tanchuan" v-show="chooseShow">
    <div class="choose">
      <div class="choose-border">
        <div class="top">
          <div class="box1"></div>
          <div class="box2" @click="CloseWindows"></div>
        </div>
        <div class="center">
          <span>选择年份</span>
          <div class="center-centetr">
            <div
              class="box"
              :class="item.Selected ? 'red' : ''"
              v-for="(item, index) in year"
              :key="index"
              @click="changeColor(index)"
            >
              {{ item.data }}
            </div>
          </div>
          <div class="center-bottom">
            <div class="yes" @click="CloseWindows">确定</div>
          </div>
        </div>
        <div class="bottom">
          <div class="box3"></div>
          <div class="box4"></div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="indicatorFilterDialog-template">
    <el-dialog
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div class="firstRow">
        <div class="h4">
          <span>选择年份</span>
          <!-- <span class="description"
            >注意：因变量只能选一个 自变量最多选四个</span
          > -->
        </div>
        <div class="level1_Indicators">
          <div
            class="indicatorPanel"
            :class="item.Selected ? 'activePaneClass' : ''"
            v-for="(item, index) in year"
            :key="index + 'first'"
            @click="changeColor(index)"
            ref="variablePreActive"
          >
            {{ item.data }}
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="Cancel">取消</el-button>
        <el-button type="primary" @click="CloseWindows">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chooseShow: true,
      year: [
        {
          data: "2019年数据",
          Selected: true,
          year: 2019,
        },
        {
          data: "2020年数据",
          Selected: true,
          year: 2020,
        },
        {
          data: "2021年数据",
          Selected: true,
          year: 2021,
        },
        {
          data: "2022年数据",
          Selected: false,
          year: 2022,
        },
      ],
      //需要传递给父亲的参数
      years: [2019, 2020, 2021],
      dialogVisible: false,
    };
  },
  methods: {
    /*关闭弹窗*/
    closeDialog() {
      this.dialogVisible = false;
    },
    /*显示弹窗方法*/
    showRegDialog() {
      this.dialogVisible = true;
    },
    // 取消按钮
    Cancel() {
      this.dialogVisible = false;
    },
    // 确定按钮的函数
    CloseWindows() {
      // this.chooseShow = false;
      this.dialogVisible = false;
      this.addYears();
      this.$emit("btn-click", this.years);
    },
    // 改变颜色的函数
    changeColor(index) {
      this.year[index].Selected = !this.year[index].Selected;
    },
    // 将选中了的push出去
    addYears() {
      this.years = [];
      this.year.map((item) => {
        if (item.Selected) {
          this.years.push(item.year);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.indicatorFilterDialog-template {
  .el-dialog {
    background-color: rgba(1, 14, 33, 0.8);
    border: 1px solid #013b9b;
    border-radius: 20px;
    transform: translateX(-0.13rem);
    .annotation {
      padding-right: 20px;
      text-align: end;
      color: #4d73b5;
    }
    .firstRow,
    .secondRow {
      /*padding-left: 20px;*/
      h3 {
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .h4 {
        font-size: 16px;
        margin-bottom: 10px;
        color: #489ef7;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 14px !important;
        }
      }
      .level1_Indicators {
        margin-top: 0.1rem;
      }
      .level2_Indicators {
        margin-top: 0.1rem;
      }
    }
    .secondRow {
      margin: 10px 0px;
    }
    .dialog-footer {
      text-align: center;
      .el-button {
        background-color: #002258;
        border: none;
      }
      .el-button:hover {
        opacity: 0.8;
      }
    }
  }
  .indicatorPanel {
    margin: 0px 30px 10px 0px;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 4px;
    min-width: 0.5rem !important;
    color: #ffffff;
    text-align: center;
    background-color: #022256;
    // background-color: #909399;
    cursor: pointer;
  }
  .activePaneClass {
    background-color: #036dda;
  }
  .self-list-active {
    display: inline-block !important;
  }
  h4 {
    // color: #fff !important;
    color: #489ef7;
    margin-bottom: 10px;
  }
}
.tanchuan {
  width: 100%;
  height: 291px;
  position: absolute;
  left: 0rem;
  z-index: 9999;
  .choose {
    // width: 877px;
    width: 100%;
    height: 291px;
    background: rgba(0, 0, 0, 0.84);
    border: 1px solid #680911;
    padding: 10px 6px 13px 6px;
    .choose-border {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .box1,
      .box2,
      .box3,
      .box4 {
        width: 8px;
        height: 8px;
        background: #c22222;
        transform: rotate(45deg);
      }
      .top,
      .bottom {
        height: 11px;
        width: 100%;
        flex: 0 0 11px;
        display: flex;
        justify-content: space-between;
      }
      .center {
        flex: 1;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        span {
          height: 20px;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #fde5e5;
          line-height: 20px;
        }
        p {
          width: 153px;
          height: 16px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #fbe9e9;
          line-height: 16px;
        }
        .center-centetr {
          flex: 1;
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .box {
            cursor: pointer;
            margin-top: 0.2rem;
            margin-left: 13px;
            width: 2rem;
            height: 36px;
            border: 1px solid #680911;
            border-radius: 4px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #fde5e5;
            line-height: 36px;
            text-align: center;
          }
          .red {
            color: #f35c5c;
          }
        }
      }
    }
  }
  .center-bottom {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .yes {
    margin: 00.3rem auto 0;
    background: #2f0206;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    margin-left: 13px;
    width: 130px;
    height: 36px;
    border: 1px solid #680911;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #fde5e5;
    line-height: 36px;
    text-align: center;
    margin-right: 40px;
  }
}
</style>