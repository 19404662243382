<template>
  <div
    class="background-wrapper"
    v-loading="loading"
    element-loading-text="登录中..."
    element-loading-background="rgba(0, 0, 0, 0.3)"
    element-loading-custom-class="loginLoading"
  >
    <div class="login-header">
      <div class="header-ico" @click="gohome"></div>
      <div class="header-title">全国大学生思政数据分析</div>
    </div>
    <div class="login-body">
      <div class="user-title">用户登录</div>
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        @mousedown.native.stop
        @keyup.enter.native="submitForm('ruleForm')"
      >
        <el-form-item class="user-name" prop="account" :error="errorMsg">
          <el-input
            placeholder="请输入用户名"
            prefix-icon="el-icon-user-solid"
            v-model="form.account"
          ></el-input>
        </el-form-item>
        <el-form-item class="user-password" prop="password">
          <el-input
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            show-password
            v-model="form.password"
          ></el-input>
        </el-form-item>
        <!-- <div class="remember" @click="ischeck = !ischeck">
          <div class="remember-radio">
            <img src="~@/assets/img/login/uncheck.png" v-if="ischeck" alt="" />
            <img src="~@/assets/img/login/check.png" v-else alt="" />
          </div>
          <p class="remember-password">记住密码</p>
        </div> -->
        <el-form-item class="remember">
          <el-checkbox v-model="checked">记住密码</el-checkbox>
        </el-form-item>
        <div class="forget-password">忘记密码？</div>
        <el-form-item class="login-box">
          <el-button
            type="primary"
            class="login-button"
            @click="submitForm('ruleForm')"
            >登 录</el-button
          >
        </el-form-item>
        <!-- <div class="login-button" @click="doLogin">登录</div> -->
      </el-form>
      <!-- <div v-if="errorStatus" class="error-text">{{ errorText }}</div> -->
      <!-- <el-alert
      v-if="errorStatus"
      class="error-text"
      title="用户名或密码不正确"
      type="error"
      description="请输入正确的用户名和密码"
      center
      show-icon
    >
    </el-alert>
      <el-input
        placeholder="请输入用户名"
        prefix-icon="el-icon-user-solid"
        v-model="form.account"
        class="user-name"
      >
      </el-input>
      <el-input
        placeholder="请输入密码"
        prefix-icon="el-icon-lock"
        show-password
        v-model="form.password"
        class="user-password"
      >
      </el-input> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      checked: false,
      form: {
        account: "",
        password: "",
      },
      redirectPath: "/",
      loading: false,
      errorMsg: "",
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    // this.redirectPath = this.$route.query.redirect;
  },
  mounted() {},
  watch: {
    "rules.username": function () {
      this.validateForm();
    },
    "rules.password": function () {
      this.validateForm();
    },
  },
  methods: {
    gohome() {
      this.$router.push({
        name: "portal",
      });
    },
    ...mapActions(["Login", "GetInfo"]),
    // doLogin() {
    //   // this.validateForm();
    //   const _that = this;
    //   if (!this.errorStatus) {
    //     this.loading = true;
    //     this.Login(this.ruleForm)
    //       .then(async () => {
    //         let res = await this.GetInfo();
    //         if (_that.redirectPath) {
    //           this.$router.push(_that.redirectPath).then(() => {
    //             _that.loading = false;
    //           });
    //         } else {
    //           this.$router.push("/special").then(() => {
    //             _that.loading = false;
    //           });
    //         }
    //       })
    //       .catch((err) => {
    //         _that.errorStatus = true;
    //         _that.errorText = err;
    //         _that.loading = false;
    //       });
    //   }
    // },
    // validateForm() {
    //   const _at = this.validateAccount(),
    //     _pd = this.validatePassword();
    //   this.errorStatus = !_at;
    //   this.errorText = _at
    //     ? _pd
    //       ? ""
    //       : "密码不能为空"
    //     : _pd
    //     ? "用户名不能为空"
    //     : "用户名和密码不能为空";
    // },
    // validateAccount() {
    //   return !(this.form.account === null || this.form.account.trim() === "");
    // },
    // validatePassword() {
    //   return !(this.form.password === null || this.form.password.trim() === "");
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _that = this;
          this.loading = true;
          this.Login(this.form)
            .then(() => {
              let res = this.GetInfo();
/*              if (_that.redirectPath) {
                this.$router.push(_that.redirectPath).then(() => {
                  _that.loading = false;
                  console.log("111");
                });} else {
                // 定时器解决，进去之后本地存储的数据还没存进去，homepage就已经开始读取，然后报错
                setTimeout(()=>{
                this.$router.push("/special").then(() => {
                  _that.loading = false;
                  console.log("222");
                });
                },3500)
              }*/
              console.log('95586')
              console.log(localStorage.getItem("role"))
              if (localStorage.getItem("role")){
                let path = JSON.parse(localStorage.getItem("role"))[0].name == "全国" ? "/chinaHome" : "/chinaHome"; //高校
                _that.$ls.set("year", 2020, 72 * 60 * 60 * 1000);
                _that.$router.push({
                  path: path,
                  query: {
                    years: 2020,
                  },
                }).then(() => {
                  _that.loading = false;
                });
              }else {
                // 定时器解决，进去之后本地存储的数据还没存进去，homepage就已经开始读取，然后报错
                setTimeout(()=>{
                  this.$router.push("/chinaHome").then(() => {
                    _that.loading = false;
                    console.log("222");
                  });
                },5500)
              }
            })
            .catch((err) => {
              _that.loading = false;
              console.log('112345')
              console.log(err)
              _that.errorMsg = err;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/login.scss";
// 改变input里的字体颜色
/deep/input::-webkit-input-placeholder {
  color: #a26a6a;
  // font-size: 15px;
}

// 改变input框背景颜色
/deep/.el-input__inner {
  background-color: transparent !important;
  border: 1px solid #841213;
}
// 改变图标颜色
/deep/.el-icon-user-solid:before {
  color: #cd1f32;
}

/deep/.el-icon-lock:before {
  color: #cd1f32;
}
// 改变input颜色
/deep/.el-input__inner {
  color: #fff;
}
//改变error样式
/deep/.el-form-item__error {
  margin-top: 0.1rem;
  font-size: 12px;
}

/deep/.el-button--primary {
  border: 0rem;
}
/deep/.el-button {
  padding: 0;
}

//改变复选框样式
/deep/.el-checkbox__inner{
  background-color: transparent !important;
  border: 1px solid #821416;
}

/deep/.el-checkbox__label{
  color: #fff;
  font-size: 0.17rem;
}

/deep/.el-form-item__content{
  margin-left: 0 !important;
}
</style>
