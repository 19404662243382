<template>
  <div class="background-wrapper">
    <!-- <dv-loading v-if="loading">Loading...</dv-loading> -->
    <div class="host-body">
      <HomeHeader
        @year-change="handleYearChange"
        ref="Head"
        v-model="queryYear"
      />
    </div>
    <div class="second-body">
      <div class="second-left">
        <div>
          <div class="second-map">
            <div class="distribution">
              <div class="distribution-title">全国省份地图</div>
            </div>
            <div class="country-map">
              <div id="map"></div>
              <div
                class="map-son"
                v-if="level !== '全国'"
                @click.stop.prevent="clickleft"
              ></div>
            </div>
          </div>
        </div>
        <div>
          <div class="second-map" :reverse="true">
            <div class="distribution">
              <div class="distribution-title">省份数据分析</div>
            </div>
            <div class="second-right-bottom">
              <div class="second-box">
                <div class="map-provice" id="map-provice"></div>
                <div class="map-bg">
                  <img src="../../assets/img/shengfen/gq.png" alt="" />
                </div>
                <img
                  @click="runLeft(list, dataIndex)"
                  class="left-arrow"
                  src="~@/assets/img/shengfen/2.png"
                  alt=""
                />
                <img
                  @click="runRight(list, dataIndex)"
                  class="right-arrow"
                  src="~@/assets/img/shengfen/1.png"
                  alt=""
                />
                <div style="position: relatives">
                  <div class="animate wave">
                    <img
                      id="corrugated1"
                      src="~@/assets/img/shouye/q1.png"
                      style="position: absolute; top: 3rem; left: 3rem"
                    />
                    <img
                      id="corrugated2"
                      src="~@/assets/img/shouye/q2.png"
                      style="position: absolute; top: 3rem; left: 2.5rem"
                    />
                    <img
                      id="corrugated3"
                      src="~@/assets/img/shouye/q3.png"
                      style="position: absolute; top: 2.6rem; left: 1.6rem"
                    />
                    <img
                      id="corrugated4"
                      src="~@/assets/img/shouye/q4.png"
                      style="position: absolute; top: 2.5rem; left: 0.8rem"
                    />
                    <img
                      id="corrugated5"
                      src="~@/assets/img/shouye/q5.png"
                      style="position: absolute; top: 2.2rem; left: -0.4rem"
                    />
                    <img
                      id="corrugated6"
                      src="~@/assets/img/shouye/q6.png"
                      style="position: absolute; top: 2rem; left: -2rem"
                    />
                  </div>
                </div>
                <div class="proName">{{ provinceName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="second-center">
        <div class="title-left-img">
          <img src="~@/assets/img/title.png" alt="" />
        </div>
        <div class="crumbs">
          <div class="title-box">
            <div class="no-click" v-if="level !== '全国'"></div>
            <span class="title" @click="handleTitleClick(1)">全国 </span>
            <span class="title" v-if="getCity" @click="handleTitleClick(2)">
              > {{ getCity }}
            </span>
            <span class="title" v-if="getSchool" @click="handleTitleClick(3)">
              > {{ getSchool }}
            </span>
          </div>
          <div class="crumb-right">
            <div>
              <img src="../../assets/img/title_rectangle1.png" alt="" />
            </div>
            <div>
              <img src="../../assets/img/title_rectangle2.png" alt="" />
            </div>
          </div>
        </div>
        <div class="borderNone">
          <div class="analysis-line">
            <div id="analysis-line" @click="overview"></div>
          </div>
        </div>
        <div class="streamline">
          <span class="streamline-1"></span>
          <span class="streamline-2"></span>
          <span class="streamline-3"></span>
          <span class="streamline-4"></span>
        </div>
        <div class="second-center-conclusion">
          <div class="second-center-title">结论指标分析 :</div>
          <div class="second-center-text">
            大学生调查数据分析:基于多维分析数据仓库，对高校思政调查历史数据进行多维分析，具备自动化报告、对比分析、回归分析、可视化分析等功能。
          </div>
        </div>
      </div>
      <div class="second-right">
        <div>
          <div class="ranking">
            <div class="distribution">
              <div class="distribution-title">分析指标知识图谱</div>
            </div>
            <div class="lookchart">
              <div id="cloud"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="ranking">
            <div class="distribution">
              <div class="distribution-title">视频介绍</div>
            </div>
            <video
              v-if="videoUrl"
              width="90%"
              height="100%"
              controls
              poster="~@/assets/img/shiping.png"
            >
              <source :src="videoUrl" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="foot-l">
        <div class="foot-box">
          <div class="distribution">
            <div class="distribution-title">高校列表</div>
          </div>
          <div class="foot-l-box">
            <div class="foot-l-nav">
              <div class="foot-l-data">
                <iscrollWrapper>
                  <ul class="foot-line-father" v-if="showSchool">
                    <li
                      class="foot-line"
                      v-for="(item, index) in schoolList"
                      :key="index"
                      ref="schoolItem"
                    >
                    <div
                      class="foot-school"
                      @click="showCollage(item, index, item.name)"
                    >
                      <span>{{ index + 1 }}、 {{ item.name }}</span>
                      <span class="foot-more">查看详情></span>
                    </div>
                    </li>
                  </ul>
                  <ul v-if="!showSchool" >
                    <li class="showSchool">
                      该区域暂无数据
                    </li>
                  </ul>
                </iscrollWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot-c">
        <div class="Pyramid">
          <div class="distribution">
            <div class="distribution-title">问卷统计</div>
          </div>
          <div class="Pyramid-box">
            <div class="Pyramid-l">
              <div class="Pyramid-num">
                {{ surveyStatData.totalViews }}
              </div>
              <div class="Pyramid-l-box">
                <div class="father">
                  <div class="son"></div>
                  <div :class="setTime < 5 ? 'son1' : 'son2'"></div>
                </div>
              </div>
              <div class="Pyramid-c-num">总浏览量</div>
            </div>
            <div class="Pyramid-c">
              <div class="Pyramid-c-box">
                <div>
                  <p class="Pyramid-c-people">总执行时长</p>
                  <p class="Pyramid-c-peopleNumber">
                    {{ surveyStatData.executeTime }}
                  </p>
                </div>
              </div>
              <div class="Pyramid-c-box">
                <div>
                  <p class="Pyramid-c-people">参与专业数</p>
                  <p class="Pyramid-c-peopleNumber">
                    {{ surveyStatData.majorNum }}
                  </p>
                </div>
              </div>
              <div class="Pyramid-c-box">
                <div>
                  <p class="Pyramid-c-people">平均答题时长</p>
                  <p class="Pyramid-c-peopleNumber">
                    {{ surveyStatData.averageTime }}
                  </p>
                </div>
              </div>
              <div class="Pyramid-c-box">
                <div>
                  <p class="Pyramid-c-people">总参与高校数</p>
                  <p class="Pyramid-c-peopleNumber">
                    {{ surveyStatData.collegeNum }}
                  </p>
                </div>
              </div>
            </div>
            <div class="Pyramid-r">
              <div class="Pyramid-num">{{ surveyStatData.answerTtl }}</div>
              <div class="Pyramid-l-box">
                <div class="father">
                  <div class="son"></div>
                  <div :class="setTime < 5 ? 'son1' : 'son2'"></div>
                </div>
              </div>
              <div class="Pyramid-c-num">总答卷数量</div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot-r">
        <div class="cloud">
          <div class="distribution">
            <div class="distribution-title">指标结果说明</div>
          </div>
          <div class="instructions">{{ targetDescription }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HomeHeader from "../../components/HomeHeader.vue";
import iscrollWrapper from "../../components/iscrollWrapper";
import {
  inquireProvince,
  analysis_province_query,
  analysis_university_queryByProvice,
  queryTargetScore,
  queryOneTwoTarget,
  shouyeWenjuan,
  schoolList,
  queryTargetValue,
  queryTargetValuesForGraph,
} from "../../api/api.js";

export default {
  data() {
    return {
      loading: true,
      dataIndex: 0,
      list: [{}], // 省份数组
      provinceId: "",
      curYear: "",
      setTime: 0,
      value: "",
      show1: true,
      show2: false,
      provinceName: "湖北省",
      defaultProvName: "湖北",
      queryYear: 2020,
      queryThemeName: "思想政治状况",
      baseCloudData: [],
      targetDescription:
        "大学生调查数据分析:基于多维分析数据仓库，对高校思政调查历史数据进行多维分析，具备自动化报告、对比分析、回归分析、可视化分析等功能。通过数据探查，完善对思政数据的理解，以此为基础进行数据建模与数据挖掘，实现可视化分析和业务应用。",
      videoUrl: "",
      all: "全国",
      provName: "",
      schoolName: "",
      schoolList: [],
      defaultVideoUrl:
        "http://1254166621.vod2.myqcloud.com/284e0ea9vodtransgzp1254166621/3b5452893701925923953345491/v.f100030.mp4",
      age: {
        name: "全国",
        provinceName: "",
        schoolName: "",
      },
      secondCenterData: [
        {
          name: "全国",
          linearColor: "rgba(0,160,233,1)",
          areaColor0: "rgba(1,45,128,0.4)",
          areaColor1: "rgba(1,45,128,1)",
          data: [],
          isShow: false,
          color: "rgba(0,160,233,1)",
        },
        {
          name: "",
          linearColor: "rgba(164,0,53,1)",
          areaColor0: "rgba(164,0,53,0.2)",
          areaColor1: "rgba(164,0,53,0.6)",
          data: [],
          isShow: false,
          color: "rgba(164,0,53,1)",
        },
        {
          name: "",
          linearColor: "rgba(19,181,177,1)",
          areaColor0: "rgba(19,181,177,0.2)",
          areaColor1: "rgba(19,181,177,0.6)",
          data: [],
          isShow: false,
          color: "rgba(19,181,177,1)",
        },
      ],
      surveyStatData: {
        answerTtl: 0,
        averageTime: "",
        collegeNum: 0,
        executeTime: "",
        majorNum: 0,
        totalViews: "",
      },
      first: true,
      firstEchartsData: {
        allData: [],
        allName: "全国",
        provinceData: [],
        provinceName: "",
        schoolData: [],
        schoolName: "",
        Xdata: ["人生观", "价值观", "道德观", "网络观", "政治观", "文化观"],
      },
      currentCity: "",
      level: "",
      currentSchool: "",
      showSchool:true,
    };
  },
  created() {
    // 主题所在年份
    // this.queryYear =
    //   Number.parseInt(this.$route.query.years) || this.$ls.get("year");
    this.getUserLevel();
  },
  computed: {
    title() {
      if (this.provName && this.schoolName) {
        return `${this.all}>>${this.provName}>>${this.schoolName}`;
      }
      if (!this.first && this.provName) {
        return `${this.all}>>${this.provName}`;
      } else {
        return `${this.all}`;
      }
    },
    ...mapGetters([
      "getCity",
      "getSchool",
      "getTargetLevel1",
      "getSchoolListCity",
    ]),
  },
  async mounted() {
    let _this = this;
    let age = _this.age;
    this.videoUrl = _this.defaultVideoUrl;
    /* window.runLeft = _this.runLeft;
    window.runRight = _this.runRight;*/
    // 首次初始化数据
    // 查询所有省（可以缓存）
    let provinceList = await _this.analysis_province_query();
    // 1. 全国地图
    // 初始化左上角地图
    // this.mapchart(this.getCity, true);
    await this.mapchart(this.currentCity);
    // 2. 默认省份地图
    // 省份地图切换
    _this.dataIndex = provinceList.findIndex(
      (x) => x.shortName === _this.defaultProvName
    );
    let provItem = provinceList[_this.dataIndex];
    await this.provMapChart(provItem.url, null, provItem.province);

    // 3. 全国高校列表数据

    // 4. 全国分析数据
    // 设置中上位echarts图
    await this.loadSecondCenterTopChartData("", "", true);
    // 5. 全国统计数据
    await this.loadWenjuanStatisticsData();
    // 6. 分析指标（一、二维）
    await this.loadCloud(this.queryProvince, "", "");
    // 7. 默认指标、指标结果说明

    // 问卷统计的动画
    let time = "";
    if (_this.setTime <= 5) {
      time = setInterval(function () {
        _this.setTime++;
        if (_this.setTime >= 5) {
          clearInterval(time);
        }
      }, 1000);
    }
    let time1 = 0;
    setInterval(function () {
      time1++;
      if (time1 % 10 == 0 && time1 != 0) {
        _this.show1 = !_this.show1;
      }
    }, 1000);
    let time2 = 0;
    setInterval(function () {
      time2++;
      if (time2 % 8 == 0 && time2 != 0) {
        _this.show2 = !_this.show2;
      }
    }, 1000);
    // this.firstEcharts(this.firstEchartsData);
    this.firstEcharts(this.firstEchartsData);
    this.setFristEcharts(this.getCity, this.getSchool);
    // console.log(this.schoolList);
    // 其他页面进来后保留选中的效果
    if (this.getSchool) {
      this.schoolList.map((item, index) => {
        if (item.name == this.getSchool) {
          // console.log(item);
          for (let i = 0; i < this.$refs.schoolItem.length; i++) {
            this.$refs.schoolItem[i].classList.remove("school-active");
          }
          this.$refs.schoolItem[index].classList.add("school-active");
          // debugger;
        }
      });
    }
    // debugger
  },
  components: {
    HomeHeader,
    iscrollWrapper,
  },
  methods: {
    clickleft() {
      return false;
    },
    ...mapActions([
      "setCity",
      "setSchool",
      "setSchoolListCity",
      "setTargetLevel1",
    ]),
    // 获取用户级别
    getUserLevel() {
      this.level = JSON.parse(localStorage.getItem("role"))[0].name;
      console.log("等级");
      console.log(this.level);
      if (this.level == "高校") {
        this.currentCity = JSON.parse(localStorage.getItem("info")).province;
        this.currentSchool = JSON.parse(localStorage.getItem("info")).orgName;
        console.log("高校");
        console.log(this.currentCity, this.currentSchool);
        this.setCity(this.currentCity);
        this.setSchool(this.currentSchool);
      } else if (this.level == "全国") {
        this.currentCity = JSON.parse(localStorage.getItem("info")).orgName;
      } else {
        this.currentCity = JSON.parse(localStorage.getItem("info")).orgName;
        this.setCity(this.currentCity);
      }

      console.log("000");
      console.log(this.currentSchool);
      console.log(this.currentCity);
      if (this.currentCity !== "全国") {
        let city = this.currentCity;
        if (city.endsWith("自治区")) {
          city = city == "新疆维吾尔自治区" ? "新疆" : city;
          city = city == "宁夏回族自治区" ? "宁夏" : city;
          city = city == "广西壮族自治区" ? "广西" : city;
          city = city == "西藏自治区" ? "西藏" : city;
          city = city == "内蒙古自治区" ? "内蒙古" : city;
        } else {
          city = city.slice(0, city.length - 1);
        }

        this.currentCity = city;
        this.setCity(this.currentCity);
        this.setSchoolListCity(this.currentCity);
        this.setSchool(this.currentSchool);
      }
    },
    async handleYearChange(value) {
      this.$ls.set("year", value, 72 * 60 * 60 * 1000);
      this.queryYear = value;
      //设置高校列表数据联动
      await this.analysis_university_queryByProvice(this.provName);
      //设置中上位echarts图
      await this.loadSecondCenterTopChartData(this.provName);
      //设置中下位统计数据
      await this.loadWenjuanStatisticsData(this.provName);
    },
    async handleTitleClick(type) {
      if (type === 1) {
        this.setCity("");
        this.setSchool("");
        this.provinceName = "";
      } else if (type === 2) {
        console.log("0000111");
        console.log(this.currentCity);
        this.setSchool("");
        this.setSchoolListCity(this.getCity);
      }
    },
    async loadWenjuanStatisticsData(provinceName) {
      let resp = await shouyeWenjuan({
        province: provinceName || "",
        year: this.queryYear,
        themeName: this.queryThemeName,
      });
      const data = resp.data;
      this.surveyStatData = data;
      return data;
    },
    async loadSecondCenterTopChartData(
      provinceName,
      schoolName,
      isFirst = false,
      index
    ) {
      this.provName = provinceName || "";
      this.schoolName = schoolName || "";
      this.first = isFirst || false;
      let dataX = [];

      if (isFirst) {
        // 分析指标
        let list = await this.queryTargetScore();
        dataX = list.map((x) => x.name);
        //全国的数据
        this.secondCenterData[0].data = list.map((x) => x.targetValue);
        this.secondCenterData[0].isShow = true;
      }

      // 加载省份数据
      if (provinceName && this.secondCenterData[1].name !== provinceName) {
        let list = await this.queryTargetScore(provinceName);
        dataX = list.map((x) => x.name);
        this.secondCenterData[1].name = provinceName;
        this.secondCenterData[1].data = list.map((x) => x.targetValue);
        this.secondCenterData[1].isShow = true;
        this.secondCenterData[2].name = "";
        this.secondCenterData[2].data = [];
        this.secondCenterData[2].isShow = false;
      } else if (!provinceName) {
        this.secondCenterData[1].name = "";
        this.secondCenterData[1].data = [];
        this.secondCenterData[1].isShow = false;
        this.secondCenterData[2].name = "";
        this.secondCenterData[2].data = [];
        this.secondCenterData[2].isShow = false;
      }

      // 加载学校数据
      if (schoolName) {
        let list = await this.queryTargetScore(null, schoolName);
        dataX = list.map((x) => x.name);
        this.secondCenterData[2].name = schoolName;
        this.secondCenterData[2].data = list.map((x) => x.targetValue);
        this.secondCenterData[2].isShow = true;
      } else {
        this.secondCenterData[2].name = "";
        this.secondCenterData[2].data = [];
        this.secondCenterData[2].isShow = false;
      }

      // 首次渲染
      if (isFirst) {
        // this.renderSecondCenterTopChart(dataX);
      } else {
        let eChartSeries = this.genSecondCenterTopChartSeries();
        console.log(eChartSeries);
        // this.sexLineChart.setOption({
        //   series: eChartSeries,
        // });
      }
    },
    genSecondCenterTopChartSeries() {
      let _this = this;
      let eChartSeries = [];
      this.secondCenterData.forEach((item) => {
        if (!item.isShow) {
          item.data = [];
        }
        let itemAreaStyleColor = new _this.$echarts.graphic.LinearGradient(
          0,
          1,
          0,
          0,
          [
            {
              offset: 0,
              color: item.areaColor0, // 0% 处的颜色
            },
            {
              offset: 1,
              color: item.areaColor1, // 100% 处的颜色
            },
          ]
        );
        eChartSeries.push({
          name: item.name,
          type: "line",
          smooth: true,
          symbolSize: 8,
          label: {
            show: true,
            position: "top",
            textStyle: {
              color: item.linearColor,
            },
          },
          itemStyle: {
            normal: {
              color: item.color,
              lineStyle: {
                color: item.linearColor,
                width: 1,
              },
              areaStyle: {
                color: itemAreaStyleColor,
              },
            },
          },
          data: item.data,
        });
      });
      return eChartSeries;
    },
    async analysis_province_query() {
      let resp = await analysis_province_query({
        year: this.queryYear,
        themeName: this.queryThemeName,
      });
      this.list = resp.data.list;
      return this.list;
    },
    async analysis_university_queryByProvice(provinceName) {
      let resp = await analysis_university_queryByProvice({
        province: provinceName,
        year: this.queryYear,
        themeName: this.queryThemeName,
      });
      this.showSchool=true
      if (resp.data.list.length === 0) {
        this.$message({
          message: "该区域暂无数据",
          type: "warning",
        });
        this.showSchool=false
      }
      this.schoolList = resp.data.list;
    },
    async queryTargetScore(provinceName, universityName, targetName) {
      let resp = await queryTargetScore({
        targetName: targetName || "",
        province: provinceName || "",
        universityName: universityName || "",
        year: this.queryYear || this.$ls.get("year"),
        themeName: this.queryThemeName,
      });
      const list = resp.data.list;
      return list;
    },
    async loadCloud(provinceName, universityName, targetName) {
      let list = await this.queryTargetScore(
        provinceName,
        universityName,
        targetName
      );
      if (!list) {
        return;
      }
      this.baseCloudData = list.map((x) => {
        return {
          label: x.name,
          amount: 100,
          id: x.id,
          pid: x.pid,
          videoUrl: x.videoUrl,
          description: x.description,
        };
      });
      this.cloud(this.baseCloudData, ["label", "amount"], "bubble"); //词云
    },
    async queryOneTwoTarget(targetName) {
      let r = [];
      let resp = await queryOneTwoTarget({
        targetName: targetName || "",
      });
      const list = resp.data.list;
      let cloudData = JSON.parse(JSON.stringify(this.baseCloudData));
      let childCloudData = list.map((x) => {
        return {
          label: x.name,
          amount: 80,
          id: x.id,
          pid: x.pid,
          videoUrl: x.videoUrl,
          description: x.description,
        };
      });
      cloudData = [...cloudData, ...childCloudData];
      cloudData.forEach((item) => {
        if (r.findIndex((x) => x.id === item.id) === -1) {
          r.push(item);
        }
      });

      this.baseCloudData = r;
      this.cloud(this.baseCloudData, ["label", "amount"], "bubble"); //词云
    },
    fontSize(res) {
      let clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      // 此处的3840 为设计稿的宽度，记得修改！
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    province() {
      this.$router.push("/province");
    },
    homePage() {
      this.$router.push("/homePage");
    },
    overview() {
      //   this.$router.push("/overview");
      // 跳转
    },
    async showCollage(item, index, name) {
      console.log(this.schoolList, name);
      //   this.schoolName = e.name;
      //   设置中上位echarts图
      //   await this.loadSecondCenterTopChartData(this.provName, this.schoolName);
      /*为选中学校项添加样式*/
      console.log(item, "item的值");
      //同步更新地图
      let city = item.province;
      if (city.endsWith("自治区")) {
        city = city == "新疆维吾尔自治区" ? "新疆" : city;
        city = city == "宁夏回族自治区" ? "宁夏" : city;
        city = city == "广西壮族自治区" ? "广西" : city;
        city = city == "西藏自治区" ? "西藏" : city;
        city = city == "内蒙古自治区" ? "内蒙古" : city;
      } else {
        city = city.slice(0, city.length - 1);
      }
      // console.log(this.list);
      // let _this = this;
      // this.list.forEach((arr) => {
      //   if (arr.shortName == city) {
      //     let coordinate = [];
      //     this.schoolList.forEach((obj) => {
      //       if (obj.name == item.name) {
      //         coordinate = [obj.lng, obj.lat];
      //       }
      //     });
      //     _this.provMapChart(arr.url, [...coordinate]);
      //     // debugger
      //   }
      // });
      this.mapchart(city);
      this.setCity(city);
      this.setSchool(item.name);
      this.activeSchool = item.name;
      for (let i = 0; i < this.$refs.schoolItem.length; i++) {
        this.$refs.schoolItem[i].classList.remove("school-active");
      }
      this.$refs.schoolItem[index].classList.add("school-active");
    },
    getvalue(index, item) {
      this.value = item + "年";
      this.curYear = item;
      this.init();
    },
    async init() {
      let _this = this;
      let y = { years: this.curYear, provinceId: this.provinceId };
      if (y.provinceId == "") {
        y.provinceId = "420000000000";
      }

      inquireProvince(y).then((res) => {
        if (res.success) {
          let url = res.data.list[0].url;
          _this.provMapChart(url, "");
        } else {
          console.log(res);
        }
      });

      schoolList(y).then((res) => {
        if (res.success) {
          let latList = [];
          res.data.list.forEach((item) => {
            latList.push([item.lng, item.lat]);
          });
          this.provMapChart(latList, "");
        } else {
          console.log(res);
        }
      });
    },
    changeProvince(url) {
      let y = { provinceId: this.provinceId };
      if (y.provinceId == "") {
        y.provinceId = "420000000000";
      }
      schoolList(y).then((res) => {
        if (res.success) {
          let latList = [];
          res.data.list.forEach((item) => {
            latList.push([item.lng, item.lat]);
          });
          this.provMapChart(latList, "");
        } else {
          console.log(res);
        }
      });
    },
    async mapchart(selectedProvinceName, isFirst) {
      let _this = this;
      let selectedProvinceData = [
        { name: selectedProvinceName, selected: true },
      ];
      if (isFirst) {
        selectedProvinceData = [];
      }
      let allMapChart = this.$echarts.getInstanceByDom(
        document.getElementById("map")
      );
      // 如果不存在，初始化全国地图
      if (allMapChart == null) {
        allMapChart = this.$echarts.init(document.getElementById("map"));
      }
      allMapChart.clear();
      let allMapOption = _this.initAllMapOption(selectedProvinceData);
      allMapChart.setOption(allMapOption);
      if (this.level == "全国") {
        allMapChart.on("click", async function (params) {
          _this.setCity(params.name);
          _this.setSchool("");
          _this.setSchoolListCity(params.name);
          for (let i = 0; i < _this.$refs.schoolItem.length; i++) {
            _this.$refs.schoolItem[i].classList.remove("school-active");
          }
          // 设置省数据分析联动数据
          let index = _this.list.findIndex((x) => x.shortName === params.name);
          if (index === -1) return;
          _this.provinceName = params.province;
          _this.dataIndex = index;
          let provinceItem = _this.list[index];
          _this.provMapChart(provinceItem.url, null);
          _this.value = provinceItem.province;
          _this.provinceId = provinceItem.provinceId;
          //设置高校列表数据联动
          await _this.analysis_university_queryByProvice(provinceItem.province);
          //设置中上位echarts图
          await _this.loadSecondCenterTopChartData(provinceItem.province);
          //设置中下位统计数据
          await _this.loadWenjuanStatisticsData(provinceItem.province);
        });
      }
      window.addEventListener("resize", function () {
        allMapChart.resize();
      });
      //设置省数据分析联动数据
      let provinceItem = this.list.find(
        (x) => x.shortName === selectedProvinceName
      );
      if (provinceItem != null) {
        this.analysis_university_queryByProvice(provinceItem.province);
      } else {
        this.analysis_university_queryByProvice();
      }
    },
    provMapChart(mapURL, latList) {
      let _this = this;
      $.getJSON(mapURL, function (geoJson) {
        _this.$echarts.registerMap("省", geoJson);
        let mapChart = _this.$echarts.getInstanceByDom(
          document.getElementById("map-provice")
        );
        if (mapChart == null) {
          // 如果不存在，就进行初始化。
          mapChart = _this.$echarts.init(
            document.getElementById("map-provice")
          );
        }
        let provData = [{ name: "湖北", provinceurl: "" }];
        if (latList) {
          latList = [{ value: [...latList] }];
        } else {
          latList = [];
        }
        let provMapOption = _this.initProvMapOption(provData, latList);
        mapChart.setOption(provMapOption);
        //点击事件,根据点击某个省份计算出这个省份的数据
        latList = [];
        mapChart.on("click", function (params) {
          console.log(params);
        });
        window.addEventListener("resize", function () {
          mapChart.resize();
        });
        _this.loading = false;
      });
    },
    async runLeft() {
      if (this.level == "全国") {
        let list = this.$data.list;
        this.dataIndex = this.dataIndex - 1;
        if (this.dataIndex < 0) {
          this.dataIndex = list.length + this.dataIndex;
        }
        await this.provMapChart(list[this.dataIndex].url, "");
        this.value = list[this.dataIndex].province;
        this.provinceId = list[this.dataIndex].provinceId;
        // this.provinceName = list[this.dataIndex].province;
        this.first = false;
        await this.mapchart(list[this.dataIndex].shortName);
        // console.log(
        //   list[this.dataIndex],
        //   "传入ecahrsadasdadasdasdasdasd啊飒飒大苏打撒旦"
        // );
        this.setCity(list[this.dataIndex].shortName);
        this.setSchool("");
        for (let i = 0; i < this.$refs.schoolItem.length; i++) {
          this.$refs.schoolItem[i].classList.remove("school-active");
        }
        //设置中上位echarts图
        await this.loadSecondCenterTopChartData(list[this.dataIndex].province);
        //设置中下位统计数据
        await this.loadWenjuanStatisticsData(list[this.dataIndex].province);
      }
    },
    async runRight() {
      if (this.level == "全国") {
        let list = this.$data.list;
        this.dataIndex = this.dataIndex + 1;
        if (this.dataIndex >= list.length) {
          this.dataIndex = 0;
        }
        await this.provMapChart(list[this.dataIndex].url, "");
        this.value = list[this.dataIndex].province;
        this.provinceId = list[this.dataIndex].provinceId;
        // this.provinceName = list[this.dataIndex].province;
        this.first = false;
        this.setCity(list[this.dataIndex].shortName);
        this.setSchool("");
        await this.mapchart(list[this.dataIndex].shortName);
        for (let i = 0; i < this.$refs.schoolItem.length; i++) {
          this.$refs.schoolItem[i].classList.remove("school-active");
        }
        //设置中上位echarts图
        await this.loadSecondCenterTopChartData(list[this.dataIndex].province);
        //设置中下位统计数据
        await this.loadWenjuanStatisticsData(list[this.dataIndex].province);
      }
    },
    // 获取x轴数据
    // 设置中间数据
    async setFristEcharts(provinceName, schoolName) {
      let columnEchart = this.$echarts.init(
        document.getElementById("analysis-line")
      );
      columnEchart.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1)",
      });
      if (provinceName) {
        let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        //处理直辖市的数据
        let central = ["北京", "天津", "上海", "重庆"];
        let city = provinceName;
        if (autonomousRegion.indexOf(city) !== -1) {
          city = city == "新疆" ? "新疆维吾尔自治区" : city;
          city = city == "宁夏" ? "宁夏回族自治区" : city;
          city = city == "广西" ? "广西壮族自治区" : city;
          city = city == "西藏" ? "西藏自治区" : city;
          city = city == "内蒙古" ? "内蒙古自治区" : city;
        } else if (central.indexOf(city) !== -1) {
          city = city + "市";
        } else {
          city = city + "省";
        }
        provinceName = city;
      }
      //   queryTargetValue
      let str = await queryTargetValuesForGraph({
        year: 2020,
        themeName: "思想政治状况",
        province: provinceName || "",
        universityName: schoolName || "",
      });
      // console.log("一级指标的数据", str);
      // let Xdata=[]
      // str.data.list.map((item)=>{
      //     Xdata.push(item.name)
      // })
      let countrydata = {
        name: "全国",
        data: [],
        provinceName: provinceName,
        provinceData: [],
        schoolName: schoolName,
        schoolData: [],
        Xname: "x轴", //这个可以不用传入
        Xdata: [],
      };
      // console.log("指标的分数值",str);
      if (!provinceName && !schoolName) {
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
      }
      if (provinceName && !schoolName) {
        countrydata = {
          name: "全国",
          data: [],
          provinceName: provinceName,
          provinceData: [],
          Xname: "x轴", //这个可以不用传入
          Xdata: [],
        };
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
        str.data.province.list.map((item) => {
          countrydata.provinceData.push(item.targetValue);
        });
        console.log(countrydata);
        // debugger;
      } else if (provinceName && schoolName) {
        str.data.country.list.map((item) => {
          countrydata.data.push(item.targetValue),
            countrydata.Xdata.push(item.name);
        });
        str.data.province.list.map((item) => {
          countrydata.provinceData.push(item.targetValue);
        });
        str.data.university.list.map((item) => {
          countrydata.schoolData.push(item.targetValue);
        });
      }
      // if (schoolName) {
      // }

      // console.log(countrydata, "武汉大学的数据push之后");
      //   this.firstEchartsData=countrydata
      this.firstEcharts(countrydata);
      this.loadWenjuanStatisticsData(provinceName);
    },
    /*firstEchartsData:{
        name: "全国",
                data: [],
        schoolData:[],
        schoolName:"",
        provinceData:[],
        provinceName:"",
        Xdata:[]
    }*/
    // 柱状图echarts
    firstEcharts(firstEchartsData) {
      let _this = this;
      var option = {
        // backgroundColor: "#23243a",
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "none",
            shadowColor: "rgba(0, 0, 0, 0.2)",
          },
          // 背景颜色
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          // 边框颜色
          borderColor: "black",
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "1%",
          right: "4%",
          bottom: "6%",
          top: 30,
          padding: "0 0 10 0",
          containLabel: true,
        },
        legend: {
          //图例组件，颜色和名字
          right: 23,
          top: 0,
          itemGap: 16,
          itemWidth: 18,
          itemHeight: 10,
          data: [
            {
              name: "全国",
              //icon:'image://../wwwroot/js/url2.png', //路径
            },
            {
              name: firstEchartsData.provinceName || "",
            },
            {
              name: firstEchartsData ? firstEchartsData.schoolName : "",
            },
          ],
          textStyle: {
            color: "#a8aab0",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: 14,
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true, //坐标轴两边留白
            triggerEvent: true,
            data: [...firstEchartsData.Xdata],
            axisLabel: {
              // x轴文本换行
              formatter: function (params) {
                var newParamsName = ""; // 最终拼接成的字符串
                var paramsNameNumber = params.length; // 实际标签的个数
                var provideNumber = 5; // 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  // 循环每一行,p表示行
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ""; // 表示每一次截取的字符串
                    var start = p * provideNumber; // 开始截取的位置
                    var end = start + provideNumber; // 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p == rowNumber - 1)
                      tempStr = params.substring(start, paramsNameNumber);
                    else tempStr = params.substring(start, end) + "\n"; // 每一次拼接字符串并换行
                    newParamsName += tempStr; // 最终拼成的字符串
                  }
                } else {
                  newParamsName = params; // 将旧标签的值赋给新标签
                }
                return newParamsName;
              },
              //坐标轴刻度标签的相关设置。
              interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
              margin: 15,
              textStyle: {
                color: "#fff",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 14,
              },
            },
            axisTick: {
              //坐标轴刻度相关设置。
              show: false,
            },
            axisLine: {
              //坐标轴轴线相关设置
              lineStyle: {
                color: "#fff",
                opacity: 0.2,
              },
            },
            splitLine: {
              //坐标轴在 grid 区域中的分隔线。
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            // min:5,
            max: 6,
            axisLabel: {
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#fff"],
                opacity: 0.06,
              },
            },
          },
        ],
        series: [
          {
            name: "全国",
            type: "bar",
            data: firstEchartsData.data,
            barWidth: 15,
            barGap: 0.8, //柱间距离
            label: {
              //图形上的文本标签
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#a8aab0",
                  fontStyle: "normal",
                  fontFamily: "微软雅黑",
                  fontSize: 14,
                },
              },
            },
            itemStyle: {
              //图形样式
              normal: {
                barBorderRadius: [5, 5, 0, 0],
                color: new _this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 1,
                      color: "rgba(127, 128, 225, 0.7)",
                    },
                    {
                      offset: 0.9,
                      color: "rgba(72, 73, 181, 0.7)",
                    },
                    {
                      offset: 0.31,
                      color: "rgba(0, 208, 208, 0.7)",
                    },
                    {
                      offset: 0.15,
                      color: "rgba(0, 208, 208, 0.7)",
                    },
                    {
                      offset: 0,
                      color: "rgba(104, 253, 255, 0.7)",
                    },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: firstEchartsData.provinceName || "",
            type: "bar",
            data: firstEchartsData.provinceData || [],
            barWidth: 15,
            barGap: 0.8, //柱间距离
            label: {
              //图形上的文本标签
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#a8aab0",
                  fontStyle: "normal",
                  fontFamily: "微软雅黑",
                  fontSize: 14,
                },
              },
            },
            itemStyle: {
              //图形样式
              normal: {
                barBorderRadius: [5, 5, 0, 0],
                color: new _this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 1,
                      color: "rgba(127, 128, 225, 0.7)",
                    },
                    {
                      offset: 0.9,
                      color: "rgba(72, 73, 181, 0.7)",
                    },
                    {
                      offset: 0.25,
                      color: "rgba(226, 99, 74, 0.7)",
                    },
                    {
                      offset: 0,
                      color: "rgba(253, 200, 106, 0.7)",
                    },
                  ],
                  false
                ),
              },
            },
          },
          {
            name: firstEchartsData ? firstEchartsData.schoolName : "",
            type: "bar",
            data: firstEchartsData ? firstEchartsData.schoolData : [],
            barWidth: 15,
            barGap: 0.8, //柱间距离
            label: {
              //图形上的文本标签
              normal: {
                show: true,
                position: "top",
                textStyle: {
                  color: "#a8aab0",
                  fontStyle: "normal",
                  fontFamily: "微软雅黑",
                  fontSize: 14,
                },
              },
            },
            itemStyle: {
              //图形样式
              normal: {
                barBorderRadius: [5, 5, 0, 0],
                color: new _this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 1,
                      color: "rgba(127, 128, 225, 0.7)",
                    },
                    {
                      offset: 0.9,
                      color: "rgba(75, 177, 156, 0.7)",
                    },
                    {
                      offset: 0.25,
                      color: "rgba(120, 193, 156)",
                    },
                    {
                      offset: 0,
                      color: "rgba(100, 255, 140, 0.7)",
                    },
                  ],
                  false
                ),
              },
            },
          },
        ],
        dataZoom: [
          {
            show: true,
            height: 12,
            xAxisIndex: [0],
            brushSelect: false,
            bottom: "0%",
            start: 0,
            end: 50,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ],
      };
      let columnEchart = this.$echarts.init(
        document.getElementById("analysis-line")
      );
      columnEchart.hideLoading();
      columnEchart.setOption(option);
      columnEchart.off("click");
      columnEchart.on("click", async (params) => {
        // this.$router.push("/overview");
        if (params.componentType == "xAxis") {
          // alert("单击了"+params.value+"x轴标签");
          // console.log("传入的数据",params.value);
          this.setTargetLevel1(params.value);
          //   _this.$emit("son-click", params.value);
        } else {
          //   _this.$emit("son-click", params.name);
          // console.log("传入的数据",params.name);
          this.setTargetLevel1(params.name);
        }
        this.$router.push("/overview");
      });

      window.addEventListener("resize", function () {
        columnEchart.resize();
      });
    },
    cloud(data = [], format = [], dom) {
      let _this = this;
      let bubbleChart = this.$echarts.init(document.getElementById("cloud"));
      //跳转代码
      let [maxValue, temp] = [0, []];
      let num = 0;
      data.forEach((item) => {
        temp.push(item[format[1]]);
      });
      maxValue = Math.max.apply(null, temp);

      // 气泡颜色数组
      let color = [
        "red",
        "#e65852",
        "#CE2D65",
        "#0075A9 ",
        "#13B5B1",
        "#e65852",
        "#00D9D9",
        "#556FB5",
        "#08BCFB",
      ];
      // 气泡颜色备份
      let bakeColor = [...color];
      // 气泡数据
      let bubbleData = [];
      //关联关系
      let linksData = [];
      // 气泡基础大小
      let basicSize = this.fontSize(25);
      // 节点之间的斥力因子,值越大,气泡间距越大
      let repulsion = this.fontSize(140);
      // 根据气泡数量配置基础大小和斥力因子（以实际情况进行适当调整，使气泡合理分布）
      if (data.length >= 5 && data.length < -8) {
        basicSize = this.fontSize(25);
        repulsion = this.fontSize(140);
      } else if (data.length > 8) {
        basicSize = this.fontSize(20);
        repulsion = this.fontSize(100);
      }

      // 填充气泡数据数组bubbleData
      for (let item of data) {
        num++;
        // 确保气泡数据条数少于或等于气泡颜色数组大小时，气泡颜色不重复
        if (!bakeColor.length) bakeColor = [...color];
        let colorSet = new Set(bakeColor);
        let curIndex = Math.round(Math.random() * (colorSet.size - 1));
        console.log(curIndex);
        let curColor = bakeColor[curIndex];
        console.log(curColor);
        colorSet.delete(curColor);
        bakeColor = [...colorSet];
        // 气泡大小设置
        let size = (item[format[1]] * basicSize * 2) / maxValue;
        if (size < basicSize) size = basicSize;
        let a = color[num];
        bubbleData.push({
          name: item[format[0]],
          value: item[format[1]],
          symbolSize: size,
          draggable: true,
          itemStyle: {
            normal: {
              shadowBlur: 14,
              shadowColor: a,
              borderColor: a,
              borderWidth: 4,
              color: a,
            },
          },
        });

        let pNode = data.find((x) => x.id === item.pid);
        let pName = pNode ? pNode.label : "";
        linksData.push({
          id: item.id,
          pid: item.pid,
          source: item.label,
          target: pName,
          value: 100, //边的数值,可以在力引导布局中用于映射到边的长度。
          ignoreForceLayout: true, //使此边不进行力导图布局的计算
        });
      }
      linksData = linksData.filter((x) => x.pid !== "0");
      let bubbleOptions = {
        animationEasingUpdate: "bounceIn",
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              repulsion: repulsion,
              edgeLength: 10,
            },
            // 是否开启鼠标缩放和平移漫游
            roam: false,
            label: { normal: { show: true, color: "#fff" } },
            data: bubbleData,
            links: linksData,
          },
        ],
      };
      bubbleChart.setOption(bubbleOptions);
      //点击事件,根据点击某个省份计算出这个省份的数据
      bubbleChart.on("dblclick", async function (params) {
        console.log(params);
        const targetName = params.name;
        await _this.queryOneTwoTarget(targetName);
        //逻辑控制
      });
      //点击事件,根据点击某个省份计算出这个省份的数据
      bubbleChart.on("click", async function (params) {
        let item = _this.baseCloudData.find((x) => x.label === params.name);
        _this.videoUrl = null;
        _this.targetDescription = item.description;
        _this.$nextTick(() => {
          _this.videoUrl = item.videoUrl || _this.defaultVideoUrl;
        });
      });
      window.addEventListener("resize", function () {
        bubbleChart.resize();
      });
    },
    initAllMapOption(selectedProvinceData) {
      let option = {
        backgroundColor: "transparent",
        legend: {
          show: false,
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            return params.name;
          },
          axisPointer: {
            type: "none",
            shadowColor: "rgba(255,255,255,0.4)",
            // shadowColor:'yellow'
          },
          // 背景颜色
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          // 边框颜色
          borderColor: "black",
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          show: true,
          map: "china",
          zoom: 1.1,
          label: {
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(7, 133, 212, 1)",
              borderWidth: 1,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(5,49,170, 0.8)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(5,49,170, 0.8)", // 100% 处的颜色
                  },
                ],
                data: [{ name: "湖北", provinceurl: "" }],
                globalCoord: false, // 缺省为 false
              },
              shadowColor: "rgba(128, 217, 248, 0.3)",
              // shadowColor: 'rgba(255, 255, 255, 1)',
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
            },
            emphasis: {
              areaColor: "#5e2370",
              borderWidth: 0,
            },
          },
        },
        series: [
          {
            type: "map",
            selectedMode: "single", //'single/multiple'
            mapType: "china",
            aspectScale: 0.76,
            layoutCenter: ["50%", "50%"], //地图位置
            layoutSize: "114%",
            zoom: 1, //当前视角的缩放比例
            // roam: true, //是否开启平游或缩放
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 1,
              max: 2,
            },
            label: {
              show: true,
              color: "#FFFFFF",
              fontSize: 16,
              normal: {
                textStyle: {
                  fontSize: 8,
                  fontWeight: "bold",
                  color: "white",
                },
              },
            },
            itemStyle: {
              normal: {
                areaColor: "rgba(5,49,170, 0.8)",
                borderColor: "rgba(7, 133, 212, 1)",
                borderWidth: 1,
                label: {
                  show: true,
                },
              },
              emphasis: {
                areaColor: "#56b1da",
                label: {
                  show: true,
                  color: "#fff",
                },
              },
            },
            select: {
              // 地图选中区域样式
              label: {
                // 选中区域的label(文字)样式
                show: false,
              },
              itemStyle: {
                // 选中区域的默认样式
                areaColor: "#5e2370",
              },
            },
            data: selectedProvinceData,
          },
        ],
      };
      return option;
    },
    initProvMapOption(provData, latList) {
      // latList=[{value:[114.365248,30.53786]}]
      let option = {
        backgroundColor: "transparent",
        legend: {
          show: false,
        },
        tooltip: {
          show: true,
        },
        geo: {
          show: true,
          map: "省",
          zoom: 1.1,
          label: {
            emphasis: {
              show: false,
            },
          },
          regions: [
            {
              name: "南海诸岛",
              itemStyle: {
                // 隐藏地图
                normal: {
                  opacity: 0, // 为 0 时不绘制该图形
                },
              },
              label: {
                show: false, // 隐藏文字
              },
            },
          ],

          itemStyle: {
            normal: {
              borderColor: "rgba(7, 133, 212, 1)",
              borderWidth: 1,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(5,49,170, 0.8)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(5,49,170, 0.8)", // 100% 处的颜色
                  },
                ],
                data: provData,
                globalCoord: false, // 缺省为 false
              },
              shadowColor: "rgba(128, 217, 248, 0.3)",
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
            },
            emphasis: {
              areaColor: "#5e2370",
              borderWidth: 0,
            },
          },
        },
        series: [
          {
            name: "点",
            type: "scatter",
            coordinateSystem: "geo",
            symbol: "pin",
            symbolSize: 20,
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: "#fff",
                  fontSize: 12,
                },
              },
            },
            tooltip: {
              show: false,
            },
            itemStyle: {
              normal: {
                color: "#F62157", //标志颜色
              },
            },
            zlevel: 6,
            data: latList,
          },
        ],
      };
      // debugger;
      return option;
    },
  },
  watch: {
    getSchool(newName, old) {
      // 监听后更新柱状图更新雷达图和右侧内容
      this.setFristEcharts(this.getCity, this.getSchool);
      if (!newName) {
        for (let i = 0; i < this.$refs.schoolItem.length; i++) {
          this.$refs.schoolItem[i].classList.remove("school-active");
        }
      }
      let city = this.getCity;
      let _this = this;
      this.list.forEach((arr) => {
        if (arr.shortName == city) {
          let coordinate = [];
          this.schoolList.forEach((obj) => {
            if (obj.name == newName) {
              coordinate = [obj.lng, obj.lat];
            }
          });
          _this.provMapChart(arr.url, [...coordinate]);
        }
      });
    },
    getCity(newName, old) {
      // 监听后更新柱状图更新雷达图和右侧内容
      this.setFristEcharts(this.getCity, this.getSchool);
      if (newName) {
        let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        //处理直辖市的数据
        let central = ["北京", "天津", "上海", "重庆"];
        let city = newName;
        if (autonomousRegion.indexOf(city) !== -1) {
          city = city == "新疆" ? "新疆维吾尔自治区" : city;
          city = city == "宁夏" ? "宁夏回族自治区" : city;
          city = city == "广西" ? "广西壮族自治区" : city;
          city = city == "西藏" ? "西藏自治区" : city;
          city = city == "内蒙古" ? "内蒙古自治区" : city;
        } else if (central.indexOf(city) !== -1) {
          city = city + "市";
        } else {
          city = city + "省";
        }
        newName = city;
      }
      this.analysis_university_queryByProvice(newName);
      this.provinceName = newName;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/homePage.scss";
::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.titleCenter {
  width: 6rem;
  height: 1rem;
  position: relative;
  top: 0%;
  left: 50%;
}
.distribution-title {
  font-weight: 600;
  font-size: 12px !important;
}
.menu {
  margin-top: 0.1rem;
  height: 0.6rem;
  color: #fff;
  background-image: url("~@/assets/img/shengfen/top2.png");
  display: flex;
  position: relative;
  .texiao1 {
    width: 1.2rem;
    height: 0.02rem;
    position: absolute;
    animation: ab 10s linear infinite;
    background: linear-gradient(to left, #e20c1a, #5a0911);
  }
  @keyframes ab {
    from {
      left: 0%;
    }
    to {
      left: 100%;
    }
  }

  .texiao2 {
    width: 1.2rem;
    height: 0.02rem;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: bc 10s linear infinite;
    background: linear-gradient(to right, #e20c1a, #1f0405);
  }
  @keyframes bc {
    from {
      left: 100%;
    }
    to {
      left: 0%;
    }
  }
}
.crumbs {
  line-height: 0.4rem;
  margin-left: 1rem;
  padding-left: 0.4rem;
  width: 11.6rem;
  background-image: url("../../assets/img/title-1.png") !important;
  background-size: 80% 95%;
  font-size: 14px;
  .title-box {
    padding-right: 0.2rem;
    display: inline-block;
    background-color: rgba(96, 5, 5, 0.3);
    font-size: 14px;
  }
  .page-title {
    cursor: pointer;
  }
}
.check {
  background-color: #890c0d;
  width: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.uncheck {
  width: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.xtb {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.2rem;
}
.second-right-bottom {
  height: 4rem;
  background: rgba(17, 18, 22, 0.5);
}
.second-box {
  background-image: url("~@/assets/img/shengfen/zj1.png");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 90%;
  position: relative;
  background-position-y: 1rem;
}

.second-box .left-arrow {
  width: 0.3rem;
  height: 0.4rem;
  position: absolute;
  left: 0.1rem;
  top: 1.62rem;
  cursor: pointer;
}

.second-box .right-arrow {
  width: 0.3rem;
  height: 0.4rem;
  position: absolute;
  right: 0.1rem;
  top: 1.62rem;
  cursor: pointer;
}
.second-box .proName {
  width: 100%;
  height: 0.4rem;
  margin-top: 0.35rem;
  text-align: center;
  font-family: "微软雅黑";
  font-weight: bold;
  color: #dbe5fa;
  background-image: linear-gradient(to bottom, #fff, #e9bfc1);
  -webkit-background-clip: text;
  color: transparent;
}
#second-right-bottom {
  width: 4.8rem;
  height: 3.5rem;
}
.lookchart {
  width: 6.0625rem;
  height: 3.7rem;
  background: rgba(17, 18, 22, 0.5);

  .looktitle {
    font-size: 0.1875rem;
    // font-family: PingFang SC;
    font-weight: 400;
    color: #cccccc;
    position: relative;
    padding-left: 0.175rem;
    margin-left: 0.1875rem;
  }
  .looktitle::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0.05rem;
    height: 0.225rem;
    background: #0079fe;
  }
}

.country-map {
  width: 4.5rem;
  height: 3.5rem;
  background: rgba(17, 18, 22, 0.5);
}
.danwei {
  text-align: center;
  padding-bottom: 0.16rem;
  padding-left: 0.15rem;
}
.foot-l-data {
  width: 5rem;
  display: flex;
  flex-wrap: wrap;
  height: 3rem;
  overflow: hidden;
  margin-top: 00.3rem;
  .foot-line-father {
    width: 100%;
    li {
      width: 100%;
      line-height: 00.5rem;
      height: 0.5rem;
      padding: 0;
      .foot-school {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .foot-more {
          color: #0082ff;
          font-size: 0.16rem;
        }
      }
    }
    li:nth-child(odd) {
      //双行
      // background: red;
      // background-color: red;
      background-color: rgba(3, 57, 110, 0.2);
    }
    li:nth-child(even) {
      //单行
      // background: yellow;
    }
  }
  .showSchool{
      // background-color: rgba(3, 57, 110, 0.2);
  }
  .foot-line {
    width: 5rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0 0 0.1rem 0.1rem;
  }
}
.shujuzhi {
  padding-bottom: 0.1rem;
}

.bgc1 {
  background-color: #fff100;
}
.bgc2 {
  background-color: #e60012;
}
.bgc3 {
  background-color: #03dacb;
}
.bgc4 {
  background-color: #0082ff;
}
.bgc5 {
  background-color: #f36403;
}
.bgc6 {
  background-color: #ff00ff;
}

ul li {
  list-style: none;
}
.uncheck1 {
  width: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.input {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
input::placeholder {
  color: #fff;
}
.input input {
  border: none;
  outline: none;
  width: 90%;
  background-color: #1f0405;
  color: #fff;
  cursor: pointer;
}
.input img {
  position: absolute;
  right: 34px;
  top: 48%;
}
.list {
  width: 2rem;
  border: 1px solid #5a0911;
  overflow: hidden;
  position: absolute;
  top: 100%;
  left: 0%;
  height: 0.8rem;
  overflow-y: auto;
  text-align: center;
  padding-right: 40%;
}
.list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
  scrollbar-arrow-color: #fff;
}

.list ul li {
  width: 200%;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
}
.list ul li:hover {
  background-color: #1f0405;
}
.title-left-img {
  display: inline-block;
  position: absolute;
  left: 7.6%;
  width: 0.4rem;
  height: 0.4rem;
  background-color: rgba(96, 5, 5, 0.3);
  img {
    width: 100%;
    height: 100%;
  }
}
.school-active {
  color: #00a0e9;
  // background-color: #03396e;
}
.crumb-right {
  float: right;
  div {
    display: inline-block;
    /*background-color: red;*/
    width: 0.2rem;
    height: 0.2rem;
    &:nth-child(1) {
      margin-right: 0.1rem;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.map-son {
  position: absolute;
  width: 5rem;
  height: 8.5rem;
  // background-color: rgba(96, 5, 5,0.1);
  top: 1rem;
  z-index: 9999;
}
.no-click {
  width: 3rem;
  height: 0.5rem;
  // background-color: pink;
  position: absolute;
}
.title {
  cursor: pointer;
  font-size: 14px;
}
.map-provice {
  position: relative;
  top: -0.5rem;
}
.map-bg {
  width: 2.7rem;
  height: 2.7rem;
  position: absolute;
  top: 0.3rem;
  left: 0.94rem;
  /*transition-timing-function:linear;
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count:infinite;*/
  animation: example 6s linear infinite forwards;
  img {
    width: 100%;
    height: 100%;
  }
}
/* 动画代码 */
@keyframes example {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(360deg);
  }
}
/* 向此元素应用动画效果 */
/*div {
  width: 100px;
  height: 100px;
  background-color: red;
}*/
.second-body,
.foot {
  margin-left: 00.2rem;
}
.background-wrapper {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
