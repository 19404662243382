<template>
  <div class="foot-bg">
    <div
      ref="item"
      v-for="(value, index) in list"
      :key="index"
      :class="setClassName(index)"
      @click="itemBtn(value, index)"
    >
      <img :src="index == 2 ? value.hoverImg : value.img" alt="" /><span>{{
        value.name
      }}</span>
    </div>
    <i class="leftBtn" @click="leftBtn()"></i>
    <i class="rightBtn" @click="rightBtn()"></i>
  </div>
</template>
<script>
export default {
  name: "FootNav",
  data() {
    return {
      list: [
        {
          name: "对比分析",
          img: require("../assets/img/duibi.png"),
          show: true,
          hoverImg: require("../assets/img/duibi_hover.png"),
          to: "/contrast",
          selected: false,
        },
        {
          name: "交互分析",
          img: require("../assets/img/fangcha.png"),
          show: true,
          hoverImg: require("../assets/img/fangcha_hover.png"),
          to: "/variance",
          selected: false,
        },
        {
          name: "概况",
          img: require("../assets/img/gaikuang.png"),
          show: true,
          hoverImg: require("../assets/img/gaikuang_hover.png"),
          to: "/overview",
          selected: false,
        },
        {
          name: "回归分析",
          img: require("../assets/img/shuju.png"),
          show: false,
          hoverImg: require("../assets/img/shuju_hover.png"),
          to: "/regression",
          selected: false,
        },

        {
          name: "相关分析",
          img: require("../assets/img/kongqiwuranfenxi.png"),
          show: true,
          hoverImg: require("../assets/img/kongqiwuranfenxi_hover.png"),
          to: "/correlation",
          selected: false,
        },
        /*{
                        name: "历时分析",
                        img: require("../assets/img/shijian1.png"),
                        show: true,
                        hoverImg: require("../assets/img/lstime.png"),
                        to:'/time',
                        selected:false
                    },*/
      ],
      currentItem: {
        name: "概况",
        img: require("../assets/img/gaikuang.png"),
        show: true,
        hoverImg: require("../assets/img/gaikuang_hover.png"),
        to: "/overview",
        selected: false,
      },
      currentIndex: 2,
    };
  },
  created() {
    let isSchoolUser = JSON.parse(localStorage.getItem("role"))[0].name;
    if (isSchoolUser == "高校") {
      this.list[1] = {
        name: "交互分析",
        img: require("../assets/img/fangcha.png"),
        show: true,
        hoverImg: require("../assets/img/fangcha_hover.png"),
        to: "/variance",
        selected: false,
      };
    } else {
      this.list[1] = {
        name: "历时分析",
        img: require("../assets/img/shijian1.png"),
        show: true,
        hoverImg: require("../assets/img/lstime.png"),
        to: "/time",
        selected: false,
      };
    }

    // this.itemBtn()
    /*从本地拿到当前选中的导航*/
    if (
      localStorage.getItem("currentIndex") &&
      localStorage.getItem("currentItem")
    ) {
      this.currentIndex = localStorage.getItem("currentIndex");
      this.currentItem = localStorage.getItem("currentItem");
    }
    /* this.itemBtn(this.currentItem,this.currentIndex)*/
  },
  mounted() {
    /*this.operation()*/
    window.runLeft = this.leftBtn;
    window.runRight = this.rightBtn;
    // console.log(this.$route.path,"路径");
    this.itemBtn(this.currentItem, this.currentIndex);
    console.log(this.$route.path);
    // debugger
    this.list.map((val, index) => {
      console.log(val, index);
      if (val.to === this.$route.path) {
        // console.log("我卢本伟没有开挂");
        this.itemBtn(val, index);
      }
    });
  },
  methods: {
    itemBtn(item, index) {
      // 如果是选中的，直接return
      if (this.list[index].selected) {
        return;
      }
      // 没选中的，更换selected
      this.list.forEach((ele) => {
        ele.selected = false;
      });
      this.list[index].selected = true;
      this.currentIndex = index;
      this.currentItem = item;
      localStorage.setItem("currentIndex", this.currentIndex);
      localStorage.setItem("currentItem", this.currentItem);
      /*替换选中的图片样式*/
      for (let i = 0; i < this.$refs.item.length; i++) {
        this.$refs.item[i].childNodes[0].src = this.list[i].img;
        this.$refs.item[i].classList.remove("active");
      }
      this.$refs.item[index].childNodes[0].src = this.list[index].hoverImg;
      this.$refs.item[index].classList.add("active");

      /*1.先计算偏移量*/
      let activeItemStr = this.$refs.item[index].classList[1];
      let activeNum = parseInt(activeItemStr.substring(4, 5));
      let offset = 2 - activeNum;
      console.log(offset);
      /*2.根据拿到的偏移量遍历每个元素*/
      for (let i = 0; i < this.$refs.item.length; i++) {
        /*使用偏移量重新设置类名*/
        let oldItem = this.$refs.item[i].classList[1];
        let oldNum = parseInt(oldItem.substring(4, 5));
        let newNum = (oldNum + offset) % 5;
        newNum = newNum < 0 ? newNum + 5 : newNum;
        let newItemStr = oldItem.replace(oldNum, newNum);
        /*替换类名*/
        this.$refs.item[i].classList.remove(oldItem);
        this.$refs.item[i].classList.add(newItemStr);
        /*this.$router.push('overview')*/
        /*
                    let queryParams = {
                        years: this.curyear,
                        province: this.value,
                        provinceId: this.provinceId,
                        univsCode: this.univsCode,
                        univsName: this.value1,
                    };
                    if (item.name == "对比分析") {
                        this.$router.push({
                            name: "contrast",
                        });
                    } else if (item.name == "概况") {
                        this.$router.push({
                            name: "overview",
                            params: queryParams,
                        });
                    } else if (item.name == "历时分析") {
                        this.$router.push({
                            name: "time",
                            params: queryParams,
                        });
                    } else if (item.name == "相关分析") {
                        this.$router.push({
                            name: "correlation",
                            params: queryParams,
                        });
                    } else if (this.list[3].name == "分析模板") {
                        this.$router.push({
                            name: "multivariant",
                            params: queryParams,
                        });
                    } else if (this.list[3].name == "交互分析") {
                        this.$router.push({
                            name: "variance",
                            params: queryParams,
                        });
                    } else if (this.list[3].name == "回归分析") {
                        this.$router.go(0);
                    }*/
      }
      /*跳转路由*/
      this.$router.push(item.to);
    },
    /*类名方法*/
    setClassName(index) {
      let className = `foot-item item${index}`;
      return className;
    },
    /*左点击按钮*/
    leftBtn() {
      console.log("左");
      if (this.currentIndex == 0) {
        this.currentIndex = 5;
      }
      this.currentIndex = this.currentIndex - 1;
      this.itemBtn(this.list[this.currentIndex], this.currentIndex);
      /* console.log('体感')
                leap.loop(function(frame){
                    console.log(frame.hands.length);
                });*/
    },
    /*右点击按钮*/
    rightBtn() {
      console.log("右");
      if (this.currentIndex == 4) {
        this.currentIndex = -1;
      }
      this.currentIndex = this.currentIndex + 1;
      this.itemBtn(this.list[this.currentIndex], this.currentIndex);
    },
    /*operation(){
                var xoffset = 0;
                var yoffset = 0;

                Leap.loop(function (frame) {
                    frame.hands.forEach(function (hand, index) {
                        var position = hand.screenPosition();
                        var rotation = hand.roll();
                        var img = document.getElementById('ico');
                        var leftMove = position[0] - xoffset - img.width / 2
                        var topMove = position[1] - yoffset - img.height / 2
                        if (leftMove > 0 && document.body.clientWidth-35 > leftMove) {
                            img.style.left = leftMove + 'px';
                        }
                        if (topMove > 0 && document.body.clientHeight-35 > topMove) {
                            img.style.top = topMove + 'px';
                        }
                        img.style.transform = 'rotate(' + -rotation + 'rad)';
                        img.style.webkitTransform = img.style.MozTransform = img.style.msTransform =
                            img.style.OTransform = img.style.transform;
                        img.style.display = "block";
                    });
                }).use('screenPosition', {
                    scale: 1
                });
                Leap.loop().use('handEntry').use('screenPosition', {
                    scale: 1
                })
                    .on('handFound', function (hand) {
                        var position = hand.screenPosition();
                        xoffset = position[0] - document.body.clientWidth / 2;
                        yoffset = position[1] - document.body.clientHeight / 2;
                        document.getElementById('ico').style.display = "block";
                    })
                    .on('handLost', function (hand) {
                        document.getElementById('ico').style.display = "none";
                    });

// This allows us to move the cat even whilst in an iFrame.
                Leap.loopController.setBackground(true)
                Leap.Controller.plugin('turnLeftRight', function (options) {
                    options || (options = {})
                    var pitchData = [],
                        pitchData2 = [], pitchData3 = [],
                        timeWindow = options.timeWindow || 1; // seconds

                    return {
                        hand: function (hand) {

                            handProcess(hand,pitchData,timeWindow,"sumPitch",hand.palmPosition[0],false);
                            handProcess(hand,pitchData2,timeWindow,"sumPitch2",hand.palmPosition[1],false);
                            //hand.fingers[1].positions[0][1]-hand.fingers[3].positions[0][1]

                            var v1 = hand.fingers[1].positions[4];
                            var v2 = hand.fingers[0].positions[4];
                            var dist = Math.sqrt((v1[0]-v2[0])*(v1[0]-v2[0])+(v1[1]-v2[1])*(v1[1]-v2[1])+(v1[2]-v2[2])*(v1[2]-v2[2]));

                            handProcess(hand,pitchData3,timeWindow,"pinchStrength",dist,true);
                            // // ==================== click 处理===============
                            // handProcess(hand,"sumPitch3",hand.pitch(),true);
                            // ===================================
                        }
                    };
                });


                function handProcess(hand,pitchData,timeWindow,propName,currentPitch,isAbs){
                    var  data, lastPitch, mostDistantTime, now, _i, _len;
                    hand[propName] = 0;
                    if (hand.timeVisible < 1) {
                        return;
                    }

                    lastPitch = hand.data(propName+'.lastPitch');
                    //currentPitch = hand.pitch();
                    hand.data(propName+'.lastPitch', currentPitch);

                    if (!lastPitch) {
                        return;
                    }

                    // store values with timesstamps
                    now = Date.now();
                    pitchData.push({
                        delta: currentPitch - lastPitch,
                        time: now
                    });
                    // manage rolling sum
                    while (pitchData[0].time < (now - (timeWindow * 1000))) {
                        pitchData.shift();
                    }

                    for (_i = 0, _len = pitchData.length; _i < _len; _i++) {
                        data = pitchData[_i];
                        if(isAbs){
                            hand[propName] += Math.abs(data.delta);
                        }else{
                            hand[propName] += data.delta;
                        }
                    }
                }


                var lrDelayFlag = false;

                function processAction(sumPitch, sumPitch2,sumPitch3) {
                    if (lrDelayFlag) {
                        return;
                    }
                    lrDelayFlag = true;
                    if (Math.abs(sumPitch) > 130) {
                        if (sumPitch > 0) {
                            dosomething("right");
                        } else {
                            dosomething("left");
                        }
                    } else if (Math.abs(sumPitch2) > 130) {
                        if (sumPitch2 > 0) {
                            dosomething("up");
                        } else {
                            dosomething("down");
                        }
                    }else if (sumPitch3 > 100) {
                        dosomething("click");
                    }

                    setTimeout(function () {
                        lrDelayFlag = false
                    }, 2000);
                }
// The controller has already been initialized at the top of the page.  Otherwise:
                var controller = new Leap.Controller();
                controller.connect();
                controller
                    .use('handHold')
                    .use('turnLeftRight')
                    .on('frame', function (frame) {
                        var hand = frame.hands[0];
                        if(hand && hand.pinchStrength > 100){
                            processAction(0, 0,hand.pinchStrength);
                        }

                        if (hand && hand.sumPitch && hand.sumPitch2) {

                            if (Math.abs(hand.sumPitch) > 130 || Math.abs(hand.sumPitch2) > 130) {
                                var sumPitch = hand.sumPitch;
                                var sumPitch2 = hand.sumPitch2;
                                processAction(sumPitch, sumPitch2);
                            }
                        }

                    });

                function dosomething(flag) {
                    console.log("dosomething: "+flag);
                    if (flag == 'click') {
                        /!*doClick();*!/
                    }else{
                        if (window.runLeft && flag == 'left') {
                            window.runLeft();
                        } else if (window.runRight && flag == 'right') {
                            window.runRight();
                        } else if (window.runOut && flag == 'up') {
                            window.runOut();
                        } else if (window.runIn && flag == 'down') {
                            window.runIn();
                        }
                    }
                }

                function doClick(){
                    var img = document.getElementById("ico");
                    var x = Number.parseInt(img.style.left.replaceAll("px",""));
                    var y = Number.parseInt(img.style.top.replaceAll("px",""));
                    console.log("x,y:"+x+" "+y);

                    var width = img.width;
                    var height = img.height;
                    img.style.zIndex="-1";
                    document.elementFromPoint(x+5, y+5).click();
                    img.style.zIndex="999";
                    img.width = width*0.75;
                    img.height = height*0.75;
                    setTimeout(function(){img.width=width;img.height = height;},500);
                }

                function cleanWindowFunction() {
                    window.runLeft = null;
                    window.runRight = null;
                    window.runIn = null;
                    window.runOut = null;
                }

            },*/
    /* /!*动画执行前*!/
            beforeEnter(el){
                el.style.opac
            },
            /!*动画执行后*!/
            enter(el,done){
                el.offsetWidth;
                /!*console.log('执行执行执行执行执行执行执行执行执行执行执行执行执行执行执行执行执行')
                console.log(el)*!/
                el.style.transition = 'all 3s';
                done()
            },
            /!*动画执行过程中*!/
            afterEnter(el){
            }*/
  },
  //   beforeDestroy() {
  //     localStorage.setItem("currentIndex", '');
  //     localStorage.setItem("currentItem", '');
  //   },
};
</script>

<style lang="scss" scoped>
.foot {
  /*bottom:-0.6rem !important;*/
}
.foot-bg {
  width: 100%;
  height: 2.6rem;
  background: url("../assets/img/foot-nav-bg-new.png") no-repeat;
  background-size: cover;
  position: relative;
  .foot-item {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    /*background-color: red;*/
    position: absolute;
    cursor: pointer;
    &:hover {
      transform: scale(1.3);
      transition: all 1s;
    }
    &.item0 {
      left: 5.3rem;
      bottom: 0.7rem;
    }
    &.item1 {
      left: 7.85rem;
      bottom: 1.2rem;
    }
    &.item2 {
      left: 11.76rem;
      bottom: 1.6rem;
      transform: scale(1.3);
      &:hover {
        transform: scale(1.6);
        transition: transform 1s;
      }
    }
    &.item3 {
      right: 7.9rem;
      bottom: 1.25rem;
    }
    &.item4 {
      right: 5.1rem;
      bottom: 0.8rem;
    }
    /*&.item5{
                right: 4.55rem;
                bottom: 0.6rem;
            }*/
    &.active {
      span {
        color: #fff;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
    span {
      display: inline-block;
      /*background-color: red;*/
      width: 1rem;
      position: absolute;
      color: #fff;
      font-weight: bold;
      left: 50%;
      bottom: -0.3rem;
      text-align: center;
      transform: translateX(-50%);
    }
  }
  i {
    display: inline-block;
    width: 0.3rem;
    height: 0.4rem;
    background-color: red;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    &.leftBtn {
      left: 42%;
      bottom: 1rem;
    }
    &.rightBtn {
      right: 41.9%;
      bottom: 1rem;
    }
  }
}
</style>