import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import analyse from "@/store/modules/analyse";
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    analyse
  },
  state: {
    
  },
  mutations: {

  },
  actions: {
    
  },
  getters:getters
})
