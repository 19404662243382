import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'
import './permissions'
import { hasBtnPermission } from './utils/permissions'
import ElementUI from 'element-ui'
import Storage from 'vue-ls';
import 'element-ui/lib/theme-chalk/index.css';
// 引入echarts
import jquery from 'jquery'
import * as echarts from 'echarts'
import * as cityMap from '../static/echarts/json/china-city-map'
import '../static/js/china.js';
import '../static/js/flexible.js';
import Router from 'vue-router'
import "../src/assets/icon/iconfont"
const options = {
  namespace: 'pro__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
};

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
}
// 引入echarts
Vue.use(Storage, options)
Vue.use(VueAxios)
Vue.use(ElementUI)
Vue.prototype.$echarts = echarts
Vue.prototype.$getCity = cityMap

Vue.prototype.hasPerm = hasBtnPermission
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()
window.jquery = window.$ = jquery

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
