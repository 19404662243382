<template>
    <div id="wrapper" ref="wrapper">
        <slot></slot>
    </div>
</template>
<script>
    import IScroll from 'iscroll'
    export default {
        name: "iscrollWrapper",
        methods:{
            initIscroll(){
                    this.iscroll = new IScroll(this.$refs.wrapper,{
                        mouseWheel: true,
                        // scrollbars: true,
                        // 解决拖拽卡顿问题
                        scrollX: false,
                        scrollY: true,
                        disablePointer: true,
                        disableTouch: false,
                        disableMouse: true,
                    })

                    var observer = new MutationObserver(()=>{
                        this.iscroll.refresh()
                    })
                    // 2.告诉观察者对象观察什么内容
                    const config = {
                        childList: true, // 观察目标子节点的变化，添加或者删除
                        subtree: true, // 默认为 false，设置为 true 可以观察后代节点
                        attributeFilter: ['height', 'offsetHeight'] // 观察特定属性
                    }
                    // 3.告诉观察者对象需要观察谁，观察什么内容
                    /**
                     * 参数1：告诉观察者对象需要观察谁
                     * 参数2：告诉观察者对象观察什么内容
                     */
                    observer.observe(this.$refs.wrapper, config)
                }
        },
        mounted() {
            this.initIscroll()
        }
    }
</script>

<style lang="scss" scoped>
    #wrapper{
        width: 100%;
        height: 100%;
    }
</style>