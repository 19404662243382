<template>
  <div class="ring">
    <div id="ring"></div>
  </div>
</template>

<script>
export default {
  name: "ringData",
  props: ["ringData"],
  data() {
    return{
      
    }
  },
  mounted() {
    this.ring(50);
  },
  methods: {
    // 右下角ring
    ring(item) {
      let labelName = ["总值", "合格值"];
      let getRate = item;
      let series = [];
      let color = [
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(31, 31, 31, 1)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(31, 31, 31, 1)", // 100% 处的颜色
            },
          ],
        },
        {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(29, 130, 255, 1)", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "rgba(0, 255, 246, 1)", // 100% 处的颜色
            },
          ],
        },
      ];

      let option = {
        polar: {
          center: ["50%", "50%"],
          radius: ["38%", "76%"],
        },
        angleAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          min: 0,
          max: 100,
          boundaryGap: ["0", "100"],
          startAngle: 90,
        },
        radiusAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: labelName,
          z: 10,
        },
        series: [
          {
            name: "",
            hoverAnimation: false,
            silent: true,
            type: "pie",
            data: [{ name: "合格值", value: 100 }],
            z: 1,
            center: ["50%", "50%"],
            radius: ["27%", "68%"],
            label: {
              show: true,
              position: "center",

              formatter: "{lname|r 值}" + "\n\n" + "{total|" + getRate + "%}",
              rich: {
                total: {
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#fff",
                },
                lname: {
                  fontSize: 12,
                  color: "#55C7FB",
                },
              },
            },
            itemStyle: {
              color: "rgba(62, 109, 255, 0)",
            },
          },
          {
            //hoverAnimation:false,
            name: labelName[0],
            barGap: "-100%",
            //silent:true,
            type: "bar",
            data: [100],
            coordinateSystem: "polar",
            z: 2,
            label: {
              show: false,
            },
            itemStyle: {
              color: color[0],
            },
          },
          {
            //hoverAnimation:false,
            roundCap: true,
            //silent:true,
            name: labelName[1],
            type: "bar",
            coordinateSystem: "polar",
            barGap: "-100%",
            data: [getRate],
            z: 3,
            label: {
              show: false,
            },
            itemStyle: {
              color: color[1],
            },
          },
        ],
      };

      let ringEchart = this.$echarts.init(document.getElementById("ring"));
      ringEchart.setOption(option);
      window.addEventListener("resize", function () {
        ringEchart.resize();
      });
    },
  },
  watch: {
    // 监听传递过来的r的值
    ringData: {
      handler(n, o) {
        console.log("子组件中的name值： ’ + n.name", n);
        this.ring(n);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
};
</script>

<style lang="scss" scoped>
.ring {
  width: 3rem;
  height: 3rem;
  position: relative;
  left: 0.5rem;
  // top: 0.5rem;
}

.ring #ring {
  width: 100%;
  height: 100%;
}
</style>