<template>
  <div class="radar">
    <div id="lookpie1" class="lookpie1" ref="headertab"></div>
  </div>
</template>

<script>
export default {
  name: "radar",
  props: {
    radarData: Object,
  },
  data() {
    return {
      age: {
        name: "全国",
        data: [],
        provinceName: "省份",
        provinceData: [],
        schoolName: "学校",
        schoolData: [],
        Xname: "x轴", //这个可以不用传入
        Xdata: ["", "", ""],
      },
    };
  },
  created() {},
  mounted() {
    this.lookpie1(this.age);
  },
  methods: {
    // 计算size
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
      if (!clientWidth) return;
      // 此处的3840 为设计稿的宽度，记得修改！
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
    // 雷达图
    lookpie1(item) {
      let valueType = [];
      // console.log("传入雷达图的数据",item);
      // console.log("Xdata",item.Xdata);
      for (let i = 0; i <item.Xdata.length; i++) {
        let obj = {
          name: item.Xdata[i],
          max:100,
          color: "#fff",
        };
        valueType.push(obj);
      }
      let lookpiechart = this.$echarts.init(
        document.getElementById("lookpie1")
      );
      /*请不要再此基础上进行修改和保存自己修改的内容，若想修改克隆一份*/
      // let lookpieOption = {
      //   normal: {
      //     top: 0,
      //     left: 0,
      //     width: this.fontSize(330),
      //     height: this.fontSize(300),
      //     zIndex: 6,
      //     backgroundColor: "",
      //   },
      //   color: ["rgba(245, 166, 35, 1)", "rgba(19, 173, 255, 1)"],
      //   title: {
      //     show: true,
      //     left: "40%",
      //     top: "1%",
      //     textStyle: {
      //       fontSize: this.fontSize(18),
      //       color: "#fff",
      //       fontStyle: "normal",
      //       fontWeight: "normal",
      //     },
      //   },
      //   tooltip: {
      //     show: true,
      //     trigger: "item",
      //     // 背景颜色
      //     backgroundColor: "rgba(0, 0, 0, 0.4)",
      //     // 边框颜色
      //     borderColor:"black",
      //     textStyle:{
      //       color:'#fff'
      //     }
      //   },
      //   legend: {
      //     show: true,
      //     icon: "circle",
      //     left: "35%",
      //     top: "90%",
      //     orient: "horizontal",
      //     textStyle: {
      //       fontSize: this.fontSize(14),
      //       color: "#fff",
      //     },
      //   },
      //   radar: {
      //     center: ["50%", "50%"],
      //     radius: "50%",
      //     startAngle: 90,
      //     splitNumber: 4,
      //     splitArea: {
      //       areaStyle: {
      //         color: ["transparent"],
      //       },
      //     },
      //     axisLabel: {
      //       show: false,
      //       fontSize: this.fontSize(18),
      //       color: "#fff",
      //     },
      //     axisLine: {
      //       show: true,

      //       lineStyle: {
      //         color: "#125bb7",
      //         type: "solid", //
      //       },
      //     },
      //     splitLine: {
      //       show: true,
      //       lineStyle: {
      //         color: "#06233d", //
      //         type: "solid",
      //       },
      //     },
      //     indicator: valueType,
      //   },
      //   //中间数据
      //   series: [
      //     {
      //       name: item.name,
      //       type: "radar",
      //       symbol: "circle",
      //       symbolSize: 5,
      //       areaStyle: {
      //         normal: {
      //           color: "#093241",
      //         },
      //       },
      //       itemStyle: {
      //         color: "#00a0e9",
      //         borderColor: "rgba(41,240,241, 1)",
      //         borderWidth: 0,
      //       },
      //       lineStyle: {
      //         normal: {
      //           type: "solid",

      //           color: "#00a0e9",
      //           width: 1,
      //         },
      //       },
      //       data: [item.data],
      //     },
      //     {
      //       name: item.provinceName || "",
      //       type: "radar",
      //       symbol: "circle",
      //       symbolSize: 5,
      //       areaStyle: {
      //         normal: {
      //           color: "#b02336",
      //           opacity: 0.2,
      //         },
      //       },
      //       itemStyle: {
      //         color: "#b02336",
      //         borderColor: "rgba(49, 22, 32, 1)",
      //         borderWidth: 0,
      //       },
      //       lineStyle: {
      //         normal: {
      //           type: "solid",
      //           color: "#b02336",
      //           width: 1,
      //         },
      //       },
      //       data: [item.provinceData] || [],
      //     },
      //     {
      //       name: item.schoolName || "",
      //       type: "radar",
      //       symbol: "circle",
      //       symbolSize: 5,
      //       areaStyle: {
      //         normal: {
      //           color: "#29af87",
      //           opacity: 0.2,
      //         },
      //       },
      //       itemStyle: {
      //         color: "#29af87",
      //         borderColor: "rgba(54, 79, 107, 1)",
      //         borderWidth: 0,
      //       },
      //       lineStyle: {
      //         normal: {
      //           type: "solid",
      //           color: "#29af87",
      //           width: 1,
      //         },
      //       },
      //       data: [item.schoolData] || [],
      //       // data:[20,30,40],
      //     },
      //   ],
      // };
      let lookpieOption = {
        // backgroundColor: "#000928",
        title: {
          textAlign: "center",
          left: "15%",
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(14),
            fontWeight: "400",
          },
        },
        color: ["#3D91F7", "#61BE67"],
        tooltip: {
          show: true,
          trigger: "item",
          // 背景颜色
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          // 边框颜色
          borderColor: "black",
          textStyle: {
            color: "#fff",
          },
          formatter: function (params) {
            // debugger
            // console.log(params);
            let html=`<span>${params.seriesName}</br></span>`;
            let data= params.value
            let value = data.reduce(function(a,b){
              return a+b;
            })
            for(let i=0;i<item.Xdata.length;i++){
              html+=`<div style='display:flex;justify-content:space-between'><span style='display:flex; align-items:center;'><i style='background-color:${params.borderColor};width:8px;height:8px;border-radius:50%;margin-right:5px;'></i>${item.Xdata[i]}  : </span> <span>&nbsp&nbsp${((data[i]/value) * 100).toFixed(2)}% </span></div>`;
            }
            return html
          }
        },
        legend: {
          show: true,
          icon: "circle",
          bottom: 30,
          center: 0,
          itemWidth: 14,
          itemHeight: 14,
          itemGap: 21,
          orient: "horizontal",
          data: ["a", "b"],
          textStyle: {
            fontSize: "70%",
            color: "#8C8C8C",
          },
        },
        radar: {
          // shape: 'circle',
          radius: "50%",
          triggerEvent: true,
          name: {
            textStyle: {
              color: "#fff",
              fontSize: this.fontSize(18),
              borderRadius: 3,
              padding: [3, 5],
            },
          },
          nameGap: "2",
          indicator:  valueType,
          splitArea: {
            areaStyle: {
              color: [
                "rgba(222,134,85, 0.1)",
                "rgba(222,134,85, 0.2)",
                "rgba(222,134,85, 0.4)",
                "rgba(222,134,85, 0.6)",
                "rgba(222,134,85, 0.8)",
                "rgba(222,134,85, 1)",
              ].reverse(),
            },
          },
          // axisLabel:{//展示刻度
          //     show: true
          // },
          axisLine: {
            //指向外圈文本的分隔线样式
            lineStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
          splitLine: {
            lineStyle: {
              width: 2,
              color: [
                "rgba(224,134,82, 0.1)",
                "rgba(224,134,82, 0.2)",
                "rgba(224,134,82, 0.4)",
                "rgba(224,134,82, 0.6)",
                "rgba(224,134,82, 0.8)",
                "rgba(224,134,82, 1)",
              ].reverse(),
            },
          },
        },
        series:[
          {
            name: item.name,
            type: "radar",
            symbol: "circle",
            symbolSize: 5,
            areaStyle: {
              normal: {
                color: "#093241",
              },
            },
            itemStyle: {
              color: "#00a0e9",
              borderColor: "rgba(41,240,241, 1)",
              borderWidth: 0,
            },
            lineStyle: {
              normal: {
                type: "solid",

                color: "#00a0e9",
                width: 1,
              },
            },
            data: [item.data],
          },
          {
            name: item.provinceName || "",
            type: "radar",
            symbol: "circle",
            symbolSize: 5,
            areaStyle: {
              normal: {
                color: "#b02336",
                opacity: 0.2,
              },
            },
            itemStyle: {
              color: "#b02336",
              borderColor: "rgba(49, 22, 32, 1)",
              borderWidth: 0,
            },
            lineStyle: {
              normal: {
                type: "solid",
                color: "#b02336",
                width: 1,
              },
            },
            data: [item.provinceData] || [],
          },
          {
            name: item.schoolName || "",
            type: "radar",
            symbol: "circle",
            symbolSize: 5,
            areaStyle: {
              normal: {
                color: "#29af87",
                opacity: 0.2,
              },
            },
            itemStyle: {
              color: "#29af87",
              borderColor: "rgba(54, 79, 107, 1)",
              borderWidth: 0,
            },
            lineStyle: {
              normal: {
                type: "solid",
                color: "#29af87",
                width: 1,
              },
            },
            data: [item.schoolData] || [],
            // data:[20,30,40],
          },
        ]
        // series: [
        //   {
        //      name: item.name,
        //     type: "radar",
        //     //areaStyle: {normal: {}},
        //     areaStyle: {
        //       normal: {
        //         color: "rgba(252,211,3, 0.3)",
        //       },
        //     },
        //     symbolSize: 0,
        //     lineStyle: {
        //       normal: {
        //         color: "rgba(252,211,3, 1)",
        //         width: 1,
        //       },
        //     },
        //      data: [item.data],
        //   },
        //   {
        //     name: item.provinceName || "",
        //     type: "radar",
        //     //areaStyle: {normal: {}},
        //     areaStyle: {
        //       normal: {
        //         color: "rgba(255,292,203, 0.3)",
        //       },
        //     },
        //     symbolSize: 0,
        //     lineStyle: {
        //       normal: {
        //         color: "rgba(255,292,203, 1)",
        //         width: 1,
        //       },
        //     },
        //   data: [item.provinceData] || [],
        //   },
        //   ,
        //   {
        //      name: item.schoolName || "",
        //     type: "radar",
        //     //areaStyle: {normal: {}},
        //     areaStyle: {
        //       normal: {
        //         color: "rgba(0,255,0, 0.3)",
        //       },
        //     },
        //     symbolSize: 0,
        //     lineStyle: {
        //       normal: {
        //         color: "rgba(0,255,0, 1)",
        //         width: 1,
        //       },
        //     },
        //       data: [item.schoolData] || [],
        //   },
        // ],
      };
      lookpiechart.hideLoading();
      lookpiechart.setOption(lookpieOption, true);
      window.addEventListener("resize", function () {
        lookpiechart.resize();
      });
    },
  },
  watch: {
    // 监听传递过来的柱状图的值
    radarData: {
      handler(n, o) {
        // console.log('子组件中的name值： ’ + n.name',n)
        this.lookpie1(n);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
};
</script>

<style lang="scss" scoped>
#lookpie1 {
  width: 100%;
  height: 5.2rem;
  
}
</style>