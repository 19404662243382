import { axios } from '@/utils/request'
/**
 * 网络思政视频查询
 *
 * @author frl
 */
export function videoList(parameter) {
    return axios({
        url: "/analysis_api/sizheng/videoList",
        method: "get",
        params: parameter,
    });
}

/**
 * 官网数据
 *
 * @author frl
 */
export function gwText(parameter) {
    return axios({
        url: "/analysis_api/sizheng/surveyStatistics",
        method: "get",
        params: parameter,
    });
}
