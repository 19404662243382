import Vue from 'vue'
import { SET_CITY ,SET_SCHOOL ,SET_SCHOOL_LIST_CITY,SET_TARGET_LEVEL_1,SET_YEAR,SET_IS_HOME} from '@/store/mutation-types'

const analyse = {
    state: {
        selectCity:'',
        selectSchool:'',
        /*专门用于控制学校列表的省份属性*/
        schoolListCity:'',
        year:'',
        /*一级指标*/
        targetLevel1:'人生观',
        /*是否在首页*/
        isHome:true,
    },
    mutations: {
        [SET_CITY]: (state,value) => {
            state.selectCity = value
        },
        [SET_IS_HOME]: (state,value) => {
            state.isHome = value
        },
        [SET_SCHOOL]: (state,value) => {
            state.selectSchool = value
        },
        [SET_SCHOOL_LIST_CITY]: (state,value) => {
            console.log('54321')
            state.schoolListCity = value
            console.log(state.schoolListCity)
        },
        [SET_YEAR]: (state,value) => {
            state.year = value
        },
        [SET_TARGET_LEVEL_1]: (state,value) => {
            state.targetLevel1 = value
        }
    },
    actions: {
        /*设置是否在首页*/
        setIsHome({ commit }, value) {
            commit(SET_IS_HOME,value)
        },
        /*设置省份*/
        setCity ({ commit }, value) {
            commit(SET_CITY,value)
        },
        /*设置学校*/
        setSchool ({ commit }, value) {
            commit(SET_SCHOOL,value)
        },
        /*设置学校列表城市属性*/
        setSchoolListCity ({ commit }, value) {
            console.log('12345')
            commit(SET_SCHOOL_LIST_CITY,value)
        },
        // 设置年份
        setYear ({ commit }, value) {
            commit(SET_YEAR,value)
        },
        /*设置一级指标*/
        setTargetLevel1 ({ commit }, value) {
            commit(SET_TARGET_LEVEL_1,value)
        }
    }
}

export default analyse
