<template>
  <div class="background-wrapper">
    <div class="special-header">
      <div class="header-ico" @click="gohome"></div>
      <div class="header-title">全国大学生思政数据分析</div>
    </div>
    <div class="cumulative-completion">
      <div class="cumulative-title">累计完成</div>
      <div class="cumulative-text">
        <p class="cumulative-number">{{ answerTtl.toLocaleString() }}</p>
        <p>份问卷</p>
      </div>
    </div>
    <div class="center-content">
      <div class="main">
        <div class="total">
          <div
            @mouseenter="mouseIn"
            @mouseleave="mouseOut"
            class="subItem cicle7"
          >
            <!-- <img :src="imgItemList[0].src" /> -->
          </div>
          <div
            @mouseenter="mouseIn"
            @mouseleave="mouseOut"
            class="subItem cicle8"
            ref="subTargetTopLeft"
          >
            <!-- <img :src="imgItemList[1].src" /> -->
          </div>
          <div
            @mouseenter="mouseIn"
            @mouseleave="mouseOut"
            class="subItem cicle9"
          >
            <!-- <img :src="imgItemList[2].src" /> -->
          </div>
          <div
            @mouseenter="mouseIn"
            @mouseleave="mouseOut"
            class="subItem cicle10"
          >
            <!-- <img :src="imgItemList[3].src" /> -->
          </div>
          <div
            @mouseenter="mouseIn"
            @mouseleave="mouseOut"
            class="subItem cicle11"
          >
            <!-- <img class="yearpic" :src="imgItemList[4].src" /> -->
            <div class="annual-picture">
              <div class="annual-title">年度调查</div>
              <div class="annual-body">
                <div
                  class="annual-text"
                  v-for="year in years"
                  :key="year.code"
                  @click="toHome(year)"
                >
                  <span class="year-text">{{ year.label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div ref="dragContainer" id="drag-container" style="z-index: 1">
      <div ref="spinContainer" id="spin-container">
        <div>
          <img :src="imgItemList[0].src" />
        </div>
        <div>
          <img :src="imgItemList[1].src" />
        </div>
        <div>
          <img :src="imgItemList[2].src" />
          <div class="annual-box">
            <div class="annual-picture">
              <div class="annual-title">年度调查</div>
              <div class="annual-body">
                <div
                        class="annual-text"
                        v-for="year in years"
                        :key="year.code"
                        @click="toHome(year)"
                >
                  {{ year.label }}
                </div>
              </div>
            </div>
            <div class="annual">
              <p>年度调查</p>
            </div>
          </div>
        </div>
        <div>
          <img :src="imgItemList[3].src" />
        </div>
        <div>
          <img :src="imgItemList[4].src" />
          <div class="annual-picture">
            <div class="annual-title">年度调查</div>
            <div class="annual-body">
              <div
                class="annual-text"
                v-for="year in years"
                :key="year.code"
                @click="toHome(year)"
              >
                {{ year.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="ground"></div>
    </div> -->
  </div>
</template>

<script>
import { inquireYear, gwText } from "../../api/api.js";
export default {
  data() {
    return {
      curyear: "",
      yearList: [],
      years: [],
      answerTtl: "",
      imgItemList: [
        {
          src: require("../../assets/img/special/img-item1.png"),
          active: require("../../assets/img/special/active-img-item1.png"),
        },
        {
          src: require("../../assets/img/special/img-item2.png"),
          active: require("../../assets/img/special/active-img-item2.png"),
        },
        {
          src: require("../../assets/img/special/img-item3.png"),
          active: require("../../assets/img/special/active-img-item3.png"),
        },
        {
          src: require("../../assets/img/special/img-item4.png"),
          active: require("../../assets/img/special/active-img-item4.png"),
        },
        {
          src: require("../../assets/img/special/img-item5.png"),
          active: require("../../assets/img/special/active-img-item5.png"),
        },
      ],
    };
  },
  created() {
    this.buildYear();
    this.init();
  },
  mounted() {},
  methods: {
    mouseIn() {
      let items = document.querySelectorAll(".subItem");
      for (let i = 0; i < items.length; i++) {
        items[i].style.animationPlayState = "paused";
      }
    },
    mouseOut() {
      let items = document.querySelectorAll(".subItem");
      for (let i = 0; i < items.length; i++) {
        items[i].style.animationPlayState = "running";
      }
    },
    gohome() {
      this.$router.push({
        name: "portal",
      });
    },
    toHome(year) {
      if (year.hasData) {
        this.$ls.set("year", year.code, 72 * 60 * 60 * 1000);
        let path =
          JSON.parse(localStorage.getItem("role"))[0].name == "高校"
            ? "/overview"
            : "/homePage"; //高校
        this.$router.push({
          path: path,
          query: {
            years: year.code,
          },
        });
      } else {
        this.$alert("所选年份暂无数据", "提示", {
          confirmButtonText: "确定",
          type: "warning",
          center: true,
          customClass: "reLoginBox",
          callback: (action) => {},
        });
      }
    },
    buildYear() {
      const currentYear = new Date().getFullYear();
      this.years = [];
      for (let i = 2; i >= 0; i--) {
        this.years.push({
          code: currentYear - i,
          label: currentYear - i + "年",
          hasData: false,
        });
      }
    },
    init() {
      let _this = this;
      inquireYear().then((res) => {
        if (res.success) {
          let yearList = res.data.list ? res.data.list : [];
          _this.years.forEach((y) => {
            if (yearList.indexOf(y.code) !== -1) {
              y.hasData = true;
            }
          });
        } else {
          console.log(res);
        }
        console.log("years", _this.years);
      });
      gwText().then((res) => {
        if (res.success) {
          this.answerTtl = res.data.answerTtl;
        } else {
          console.log(res);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/special.scss";
</style>
