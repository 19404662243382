<template>
  <div class="barckground">
    <div id="histogram" class="histogram" ref="headertab"></div>
  </div>
</template>

<script>
export default {
  name: "child",
  props: {
    age: Object,
  },
  created() {},
  mounted() {
    this.column(this.age);
  },
  data() {
    return {};
  },
  methods: {
    // 柱状图函数
    column(item) {
      // console.log("item的数据", item);
      let _this = this;
      const labelOption = {
        show: true,
        position: "top",
        textStyle: {
          //数值样式
          color: "#fff",
          fontSize: 14,
        },
        distance: 10,
        align: "middle",
        verticalAlign: "middle",
        rotate: 0,
        fontSize: 16,
        rich: {
          name: {},
        },
      };
      let dataZoom;
      dataZoom = [];
      if (item.Xdata.length > 3) {
        dataZoom = [
          {
            show: true,
            height: 12,
            xAxisIndex: [0],
            brushSelect: false,
            bottom: "8%",
            start: 0,
            end: 50,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ];
      } else {
        dataZoom = [
          {
            show: false,
            height: 12,
            xAxisIndex: [0],
            brushSelect: false,
            bottom: "8%",
            start: 0,
            end: 100,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ];
        // console.log(dataZoom,"datazoom");
      }
      let option = {
        // 放上去的图例
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "none",
            shadowColor: "rgba(255,255,255,0.4)",
            // shadowColor:'yellow'
          },
          // 背景颜色
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          // 边框颜色
          borderColor: "black",
          textStyle: {
            color: "#fff",
          },
        },
        legend: {
          //   data: [obj.china, obj.city, obj.school],
          data: [item.name, item.provinceName, item.schoolName],
          textStyle: {
            fontSize: 14,
            color: "#fff",
          },
          right: "10%",
          itemWidth: 18,
          itemHeight: 14,
        },
        toolbox: {
          show: false,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: item.Xdata,
            splitLine: {
              axisLabel: {
                color: "#fff",
              },
              lineStyle: {
                color: "#fff",
              },
            },
            triggerEvent: true,
            axisLabel: {
              interval: 0,
              /*单行显示文本过长导致数据不完整 换行显示*/
              formatter: function (params) {
                var newParamsName = "";
                const paramsNameNumber = params.length;
                const provideNumber = 6; // 单行显示文字个数
                const rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                if (paramsNameNumber > provideNumber) {
                  for (let p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    var start = p * provideNumber;
                    var end = start + provideNumber;
                    if (p === rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr;
                  }
                } else {
                  newParamsName = params;
                }
                return newParamsName;
              },
              textStyle: {
                color: "#fff", //坐标值得具体的颜色
                fontSize:"14",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            max: 5,
            splitLine: {
              lineStyle: {
                type: "dashed",
                width: 1,
                color: "#494E54",
              },
            },
          },
        ],
        dataZoom: dataZoom,
        series: [
          // 第一版
          // {
          //   name: item.name,
          //   type: "bar",
          //   barGap: "50%",
          //   barCategoryGaz: "100%",
          //   barWidth: 15,
          //   label: labelOption,
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: [...item.data],
          //   // data: [0, 1, 2, { value:0,itemStyle: { opacity: 0 } }],
          //   backgroundColor: "transparent",
          //   itemStyle: {
          //     normal: {
          //       formatter: function (params) {
          //         if (params.value > 0) {
          //           return params.value;
          //         } else {
          //           return "";
          //         }
          //       },
          //       barBorderRadius: [10, 10, 10, 10],
          //       color: new this.$echarts.graphic.LinearGradient(
          //         0,
          //         0,
          //         0,
          //         1,
          //         [
          //           {
          //             offset: 0,
          //             color: "rgba(0,51,127,1)", // 0% 处的颜色rgba(0,51,127,1)
          //           },
          //           {
          //             offset: 1,
          //             color: "rgba(0,51,127,1)", // 100% 处的颜色rgba(0,51,127,1)
          //           },
          //         ],
          //         false
          //       ),
          //     },
          //   },
          // },
          // {
          //   name: item.provinceName,
          //   type: "bar",
          //   barGap: "50%",
          //   barCategoryGaz: "100%",
          //   barWidth: 15,
          //   label: labelOption,
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: item.provinceData,
          //   itemStyle: {
          //     normal: {
          //       formatter: function (params) {
          //         if (params.value > 0) {
          //           return params.value;
          //         } else {
          //           return "";
          //         }
          //       },
          //       barBorderRadius: [10, 10, 10, 10],
          //       color: new this.$echarts.graphic.LinearGradient(
          //         0,
          //         0,
          //         0,
          //         1,
          //         [
          //           {
          //             offset: 0,
          //             color: "rgba(169,24,42,1)", // 0% 处的颜色
          //           },
          //           {
          //             offset: 1,
          //             color: "rgba(43,1,4,1)", // 100%
          //           },
          //         ],
          //         false
          //       ),
          //     },
          //   },
          // },
          // {
          //   name: item.schoolName,
          //   type: "bar",
          //   barGap: "50%",
          //   // barCategoryGaz:"100%",
          //   barWidth: 15,
          //   label: labelOption,
          //   emphasis: {
          //     focus: "series",
          //   },
          //   data: item.schoolData,
          //   itemStyle: {
          //     normal: {
          //       formatter: (params) => {
          //         console.log(params, "params的数据");
          //         if (params.value > 0) {
          //           return params.value;
          //         } else {
          //           return "";
          //         }
          //       },
          //       barBorderRadius: [10, 10, 10, 10],
          //       color: new this.$echarts.graphic.LinearGradient(
          //         0,
          //         0,
          //         0,
          //         1,
          //         [
          //           {
          //             offset: 0,
          //             color: "rgba(7,212,160,1)", // 0% 处的颜色
          //           },
          //           {
          //             offset: 1,
          //             color: "rgba(0,32,24,1)", // 100% 处的颜色
          //           },
          //         ],
          //         false
          //       ),
          //       // shadowColor: "rgba(0,160,221,1)",
          //       // shadowBlur: 4,
          //     },
          //   },
          // },
          // 第二板
          // {
          //   name: item.name,
          //   type: "bar",
          //   barWidth: "15%",
          //   label: labelOption,
          //   itemStyle: {
          //     normal: {
          //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //         {
          //           offset: 0,
          //           color: "#A35AE0",
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(18,155,249,0)",
          //         },
          //       ]),
          //       barBorderRadius: 11,
          //     },
          //   },
          //   data: [...item.data],
          // },
          // {
          //   name: item.provinceName,
          //   type: "bar",
          //   barWidth: "15%",
          //   label: labelOption,
          //   itemStyle: {
          //     normal: {
          //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //         {
          //           offset: 0,
          //           color: "#66FF66",
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(18,155,249,0)",
          //         },
          //       ]),
          //       barBorderRadius: 11,
          //     },
          //   },
          //   data: item.provinceData,
          // },
          // {
          //   name: item.schoolName,
          //   type: "bar",
          //   barWidth: "15%",
          //   label: labelOption,
          //   itemStyle: {
          //     normal: {
          //       color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //         {
          //           offset: 0,
          //           color: "#0CEBEA",
          //         },
          //         {
          //           offset: 1,
          //           color: "rgba(18,155,249,0)",
          //         },
          //       ]),
          //       barBorderRadius: 11,
          //     },
          //   },
          //   data: item.schoolData,
          // },
          // 第三版
          {
            name: item.name,
            type: "bar",
            data: [...item.data],
            barWidth: 15,
            barGap: "100%", //柱间距离
            label: labelOption,
            itemStyle: {
              //图形样式
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 1,
                      color: "rgba(127, 128, 225, 0.7)",
                    },
                    {
                      offset: 0,
                      color: "rgba(240, 104, 104, 0.7)",
                    },
                  ],
                  false
                ),
              },
            },
            zlevel: 9,
          },
          {
            name: item.provinceName,
            type: "bar",
            data: item.provinceData,
            barWidth: 15,
            barGap: "100%", //柱间距离
            label: labelOption,
            itemStyle: {
              //图形样式
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 1,
                      color: "rgba(127, 128, 225, 0.7)",
                    },
                    {
                      offset: 0,
                      color: "rgba(253, 200, 106, 0.7)",
                    },
                  ],
                  false
                ),
              },
            },
            zlevel: 9,
          },
          {
            name: item.schoolName,
            type: "bar",
            data: item.schoolData,
            barWidth: 15,
            barGap: "100%", //柱间距离
            label: labelOption,
            itemStyle: {
              //图形样式
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 1,
                      color: "rgba(127, 128, 225, 0.7)",
                    },
                    {
                      offset: 0,
                      color: "rgba(100,255,249,1)",
                    },
                  ],
                  false
                ),
              },
            },
            zlevel: 9,
          },
        ],
      };
      let columnEchart = this.$echarts.init(
        document.getElementById("histogram")
      );
      columnEchart.hideLoading();
      columnEchart.setOption(option);
      columnEchart.off("click");
      columnEchart.on("click", async (params) => {
        if (params.componentType == "xAxis") {
          // alert("单击了"+params.value+"x轴标签");
          // console.log("传入的数据",params);
          _this.$emit("son-click", params.value);
        } else {
          _this.$emit("son-click", params.name);
        }
        // _this.$emit('son-click', params.name)
      });

      window.addEventListener("resize", function () {
        columnEchart.resize();
      });
    },
  },
  watch: {
    // 监听传递过来的柱状图的值
    age: {
      handler(n, o) {
        // console.log('子组件中的name值： ’ + n.name',n)
        this.column(n);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
};
</script>

<style lang="scss" scoped>
.barckground {
  // background-image: url(@/assets/img/bj6.png);
}
#histogram {
  height: 6.5rem;
  width: 100%;
  margin-top: 1.2rem;
}
</style>