<template>
  <div class="indicatorFilterDialog-template">
    <el-dialog
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div class="annotation"></div>
      <div class="firstRow">
        <div class="h4">
          <span class="first">二级指标: {{ second }}</span>
          <span>注意：选择指标条件限制四个</span>
        </div>
        <span class="description">一级指标</span>
        <div class="level1_Indicators">
          <div
            :class="['indicatorPanel']"
            v-for="(item, index) in targetList"
            :key="index + 'first'"
            @click="preTargetBtn(item, index)"
            ref="preTarget"
          >
            {{ item.label }}
          </div>
        </div>
        <div
          ref="subList"
          v-show="false"
          v-for="(preItem, preIndex) in targetList"
          :key="preIndex"
        >
          <h4 class="description">{{ preItem.label }}二级指标</h4>
          <div class="level1_Indicators">
            <div
              :class="['indicatorPanel']"
              v-for="(subItem, subIndex) in preItem.children"
              @click="subTargetBtn(subItem, subIndex, preIndex)"
              ref="subTarget"
              :key="subIndex"
            >
              {{ subItem.label }}
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeWindow">取消</el-button>
        <el-button type="primary" @click="submitBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryOneTwoTarget, queryTargetByThemeName } from "@/api/api";

export default {
  name: "correlationDialog",
  data() {
    return {
      /*所有1级指标*/
      targetList: [],
      /*选中的二级指标列表*/
      currentTargetList: [],
      isDisabled: false,
      year: 2020,
      themeName: "思想政治状况",
      dialogVisible: false,
      second: "",
    };
  },
  methods: {
    /*一级指标点击事件*/
    preTargetBtn(item, index) {
      for (let i = 0; i < this.$refs.preTarget.length; i++) {
        this.$refs.subList[i].classList.remove("sub-list-active");
        this.$refs.preTarget[i].classList.remove("activePaneClass");
      }
      this.$refs.subList[index].classList.add("sub-list-active");
      this.$refs.preTarget[index].classList.add("activePaneClass");
    },
    /*二级指标点击事件*/
    subTargetBtn(subItem, subIndex, preIndex) {
      if (this.currentTargetList.includes(subItem.label)) {
        let index = this.currentTargetList.indexOf(subItem.label);
        this.currentTargetList.splice(index, 1);
        this.second = this.currentTargetList.toString();
      } else if (this.currentTargetList.length == 4) {
        this.$message.error("二级指标最多只能选4个");
        return;
      } else {
        this.currentTargetList.push(subItem.label);
        this.second = this.currentTargetList.toString();
      }
      /*this.$refs.selfSubActive[index].classList.toggle('activePaneClass')*/
      /*
                this.currentTargetList.push(subItem.label)*/
      /*添加选中样式*/
      this.$refs.subList[preIndex].childNodes[1].childNodes[
        subIndex
      ].classList.toggle("activePaneClass");
    },
    /*显示弹窗*/
    openDialog() {
      this.dialogVisible = true;
      /*/!*一进来清空上次选中样式*!/*/
      if (this.$refs.subTarget) {
        for (let i = 0; i < this.$refs.subTarget.length; i++) {
          this.currentTargetList = [];
          this.$refs.subTarget[i].classList.remove("activePaneClass");
        }
      }
    },
    closeWindow() {
      this.dialogVisible = false;
    },
    /*关闭弹窗*/
    closeDialog() {
      this.dialogVisible = false;
    },
    /*获取所有一级二级指标*/
    getAllTarget() {
      queryTargetByThemeName({
        themeName: this.themeName,
        year: this.year,
      })
        .then((data) => {
          if (data.code == 200) {
            return data.data.list.map((item) => {
              return {
                value: item.name,
                label: item.name,
              };
            });
          }
        })
        .then(async (list) => {
          for (let i = 0; i < list.length; i++) {
            await queryOneTwoTarget({
              targetName: list[i].label,
            }).then((data) => {
              if (data.code == 200) {
                let arr = data.data.list.map((item) => {
                  let obj = {
                    value: item.name,
                    label: item.name,
                  };
                  return obj;
                });
                list[i].children = arr;
              }
            });
          }
          this.targetList = list;
          console.log(this.targetList);
        });
    },
    /*确定按钮*/
    submitBtn() {
      if (this.currentTargetList.length == 0) {
        this.$message.error("请选择二级指标");
      } else if (this.currentTargetList.length == 1) {
        this.$message.error("最少选择两个二级指标");
      } else {
        this.$message.success("提交成功");
        this.$emit("getTarget", this.currentTargetList);
        debugger;
        this.dialogVisible = false;
      }
    },
  },
  created() {
    this.getAllTarget();
  },
};
</script>
<style lang="scss">
.sub-list-active {
  display: inline-block !important;
}
.indicatorFilterDialog-template {
  .el-dialog {
    background-color: rgba(1, 14, 33, 0.8);
    border: 1px solid #013b9b;
    border-radius: 20px;
    transform: translateX(-0.13rem);
    .annotation {
      padding-right: 20px;
      text-align: end;
      color: #4d73b5;
    }
    .firstRow,
    .secondRow {
      padding-left: 20px;
      h3 {
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .h4 {
        .first {
          font-size: 16px !important;
        }
        font-size: 14px;
        margin-bottom: 10px;
        color: #489ef7;
        display: flex;
        justify-content: space-between;
      }
      .level1_Indicators,
      .level2_Indicators {
        .indicatorPanel {
          margin: 0px 30px 20px 0px;
          display: inline-block;
          padding: 6px 20px;
          border-radius: 4px;
          min-width: 0.5rem !important;
          color: #ffffff;
          text-align: center;
          background-color: #022256;
          cursor: pointer;
        }
        .activePaneClass {
          background-color: #036dda;
        }
      }
    }
    .secondRow {
      margin: 10px 0px;
    }
    .dialog-footer {
      text-align: center;
      .el-button {
        background-color: #002258;
        border: none;
      }
      .el-button:hover {
        opacity: 0.8;
      }
    }
  }
}
// .description {
//   //   position: absolute;
//   //   left: 2.7rem;
//   //   top: 1rem;
//   font-size: 0.05rem;
// }
.description {
  // margin-left: 6.8rem;
}
</style>
