<template>
    <div class="bg">
        <dv-loading v-if="loading">Loading...</dv-loading>
        <!--<div v-else class="host-body">
            <div>
                <div class="big">
                    <div class="title">武汉大学生思想现状调查</div>
                    <div class="weather">晴 14℃</div>
                    <div class="date">
                        {{ dateYear }}
                    </div>
                    <div class="time">
                        {{ dateDay }}
                    </div>
                </div>
            </div>
        </div>-->
        <HeaderTitle></HeaderTitle>
        <div class="body">
            <!--<div class="menu">
                <div class="texiao1"></div>
                <div class="texiao2"></div>
                <div class="uncheck" @click="gohome">
                    <img class="xtb" src="~@/assets/img/shengfen/home-s.png" alt="" />
                    首页
                </div>
                <div class="uncheck1" @click="openValue">
                    &lt;!&ndash; <img class="xtb" src="~@/assets/img/shengfen/rili-3.png" alt="" />
                  2021年 &ndash;&gt;
                    <img class="xtb" src="~@/assets/img/shengfen/rili-3.png" alt="" />
                    <div class="input">
                        <input v-model="value" type="text" placeholder="2022年" />
                        <div class="list" v-show="show">
                            <ul>
                                <li
                                        @click="getvalue(index, item)"
                                        v-for="(item, index) in yearList"
                                        :key="item.index"
                                >
                                    {{ item }}年
                                </li>
                            </ul>
                        </div>
                    </div>
                    <img src="~@/assets/img/shengfen/xjt.png" alt="" />
                </div>
                <div class="uncheck" @click="ideological()">
                    <img class="xtb" src="~@/assets/img/shengfen/fenlei1.png" alt="" />
                    主题分析
                </div>
                <div class="uncheck" @click="goback">
                    <img class="xtb" src="~@/assets/img/shengfen/return.png" alt="" />
                    返回
                </div>
            </div>-->
            <div class="second">
                <div class="second-left">
                    <div>
                        <div class="city">
                            <div class="fenbu">
                                <div class="fenbu-title">各年级分布</div>
                            </div>
                            <div class="lookpie">
                                <div id="lookpie"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="city" :reverse="true">
                            <div class="fenbu">
                                <div class="fenbu-title">每日新增答卷数量</div>
                            </div>
                            <div class="second-right-bottom">
                                <div id="second-right-bottom"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second-center">
                    <div class="borderNone">
                        <!-- <div id="map"></div> -->
                        <div class="xingxi1" v-show="show1">
                            <div class="xiangqing1">
                                <img class="xqimg" src="../../assets/img/tx.png" alt="" />
                                <div>
                                    <p class="xqtext">小七</p>
                                    <p class="xqtext1">建筑学院 大四</p>
                                </div>
                            </div>
                            <div class="qipao1"></div>
                        </div>
                        <div class="xingxi2" v-show="show2">
                            <div class="xiangqing2">
                                <img class="xqimg" src="../../assets/img/tx.png" alt="" />
                                <div>
                                    <p class="xqtext">小九</p>
                                    <p class="xqtext1">医学院 大三</p>
                                </div>
                            </div>
                            <div class="qipao2"></div>
                        </div>
                        <div class="xingxi3" v-show="show3">
                            <div class="xiangqing3">
                                <img class="xqimg" src="../../assets/img/tx.png" alt="" />
                                <div>
                                    <p class="xqtext">小八</p>
                                    <p class="xqtext1">医学院 大二</p>
                                </div>
                            </div>
                            <div class="qipao3"></div>
                        </div>
                        <!-- <div class="qipao2">
                          <div class="xiangqing2">
                            <img class="xqimg" src="../assets/img/tx.png" alt="" />
                            <p>小八</p>
                            <p>医学院 大二</p>
                          </div>
                          <div class="qipao1"></div>
                        </div>
                        <div class="qipao2">
                          <div class="xiangqing2">
                            <img class="xqimg" src="../assets/img/tx.png" alt="" />
                            <p>小七</p>
                            <p>医学院 大二</p>
                          </div>
                          <div class="qipao1"></div>
                        </div> -->
                    </div>
                </div>
                <div class="second-right">
                    <div>
                        <div class="ranking">
                            <div class="fenbu">
                                <div class="fenbu-title">各专业答卷数排行</div>
                            </div>
                            <div class="lookchart">
                                <!-- <div id="lookchart1"></div> -->
                                <div id="subject"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="ranking">
                            <div class="fenbu">
                                <div class="fenbu-title">性别比例</div>
                            </div>
                            <div class="sex-box">
                                <div class="sex-l">
                                    <div id="sex-l"></div>
                                </div>
                                <div class="sex-r">
                                    <div id="sex-r"></div>
                                </div>
                            </div>
                            <div class="sex-line">
                                <div id="sex-line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="three">
                <div class="three-l">
                    <div class="three-box">
                        <div class="fenbu">
                            <div class="fenbu-title">各年龄段分布</div>
                        </div>
                        <div class="three-l-box">
                            <div>
                                <div id="lookchart1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="three-c">
                    <div class="Pyramid">
                        <div class="fenbu">
                            <div class="fenbu-title">问卷统计</div>
                        </div>
                        <div class="Pyramid-box">
                            <div class="Pyramid-l">
                                <div class="Pyramid-num">{{ totalViews }}</div>
                                <div class="Pyramid-l-box">
                                    <div class="father">
                                        <div class="son"></div>
                                        <div :class="setTime < 5 ? 'son1' : 'son2'"></div>
                                    </div>
                                </div>
                                <div class="Pyramid-c-num">总浏览量</div>
                            </div>
                            <div class="Pyramid-c">
                                <div class="Pyramid-c-box">
                                    <div>
                                        <p class="Pyramid-c-people">总执行时长</p>
                                        <p class="Pyramid-c-peopleNumber">{{ time1 }}</p>
                                    </div>
                                </div>
                                <div class="Pyramid-c-box">
                                    <div>
                                        <p class="Pyramid-c-people">参与专业数</p>
                                        <p class="Pyramid-c-peopleNumber">{{ majorNum }}</p>
                                    </div>
                                </div>
                                <div class="Pyramid-c-box">
                                    <div>
                                        <p class="Pyramid-c-people">平均答题时长</p>
                                        <p class="Pyramid-c-peopleNumber">{{ time2 }}</p>
                                    </div>
                                </div>
                                <div class="Pyramid-c-box">
                                    <div>
                                        <p class="Pyramid-c-people">总参与高校数</p>
                                        <p class="Pyramid-c-peopleNumber">{{ collegeNum }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="Pyramid-r">
                                <div class="Pyramid-num">{{ solutionNum }}</div>
                                <div class="Pyramid-l-box">
                                    <div class="father">
                                        <div class="son"></div>
                                        <div :class="setTime < 5 ? 'son1' : 'son2'"></div>
                                    </div>
                                </div>
                                <div class="Pyramid-c-num">总答卷数量</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="three-r">
                    <div class="cloud">
                        <div class="fenbu">
                            <div class="fenbu-title">词云</div>
                        </div>
                        <div id="cloud"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    /*import { formatTime } from "../utils/index.js";*/
    import HeaderTitle from "@/components/HeaderTitle";
    import {
        meiridajuan,
        shouyeLeida,
        showYear,
        meiriMajor,
        shouyeBintu,
        shouyeWenjuan,
        shouyeSanjiao,
        shouyeSex,
        showSchool,
    } from "../../api/api.js";

    export default {
        data() {
            return {
                loading: false,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                yearList: [],
                curyear: "",
                univsCode: "",
                totalViews: "",
                time1: "",
                time2: "",
                majorNum: "",
                collegeNum: "",
                solutionNum: "",
                weekday: [
                    "星期天",
                    "星期一",
                    "星期二",
                    "星期三",
                    "星期四",
                    "星期五",
                    "星期六",
                ],
                data1: [
                    { value: 38, name: "双 一 流" },
                    { value: 28, name: "985学校" },
                    { value: 72, name: "211学院" },
                    { value: 58, name: "一本院校" },
                    { value: 66, name: "二本院校" },
                    { value: 32, name: "高职高专" },
                ],
                bgc: ["bgc1", "bgc2", "bgc3", "bgc4", "bgc5", "bgc6"],
                setTime: 0,
                show1: true,
                show2: false,
                show3: false,
                show: false,
                value: "",
            };
        },
        created() {
            this.init();
        },
        mounted() {
            let data = [
                { label: "乐观", amount: 80 },
                { label: "热情", amount: 80 },
                { label: "迷茫", amount: 30 },
                { label: "积极", amount: 80 },
                { label: "阳光", amount: 50 },
                { label: "开朗", amount: 60 },
                { label: "消极", amount: 70 },
                { label: "悲观", amount: 30 },
            ];
            cleanWindowFunction()
            window.runOut = this.goback
            window.runIn = this.ideological;
            /*this.timeFn();*/
            this.lookpie(); //雷达图
            this.subject();
            this.lookchart1(); //三角圆柱图
            this.mapchart();
            this.newdate();
            this.sex(); //男性统计图
            this.sex2(); //女性统计图
            this.sex3(); //性别统计折线图
            this.cloud(data, ["label", "amount"], "cloud"); //词云
            let _this = this;
            let time = "";
            if (_this.setTime <= 5) {
                time = setInterval(function () {
                    _this.setTime++;
                    console.log(_this.setTime);
                    if (_this.setTime >= 5) {
                        clearInterval(time);
                    }
                }, 1000);
            }
            let time1 = 0;
            setInterval(function () {
                time1++;
                if (time1 % 10 == 0 && time1 != 0) {
                    _this.show1 = !_this.show1;
                }
            }, 1000);
            let time2 = 0;
            setInterval(function () {
                time2++;
                if (time2 % 8 == 0 && time2 != 0) {
                    _this.show2 = !_this.show2;
                }
            }, 1000);
            let time3 = 0;
            setInterval(function () {
                time3++;
                if (time3 % 12 == 0 && time3 != 0) {
                    _this.show3 = !_this.show3;
                }
            }, 1000);
        },

        methods: {
            fontSize(res) {
                let docEl = document.documentElement,
                    clientWidth =
                        window.innerWidth ||
                        document.documentElement.clientWidth ||
                        document.body.clientWidth;
                if (!clientWidth) return;
                // 此处的3840 为设计稿的宽度，记得修改！
                let fontSize = clientWidth / 1920;
                return res * fontSize;
            },
            /*timeFn() {
                this.timing = setInterval(() => {
                    this.dateDay = formatTime(new Date(), "HH: mm: ss");
                    this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
                    this.dateWeek = this.weekday[new Date().getDay()];
                }, 1000);
            },*/
            gohome() {
                this.$router.push({
                    name: "index",
                });
            },
            goback() {
                this.$router.go(-1);
            },
            ideological() {
                this.$router.push("/ideological");
            },
            //select 年
            openValue() {
                this.show = !this.show;
                this.show1 = false;
            },
            getvalue(index, item) {
                this.value = item + "年";
                this.curyear = item;
                this.init();
            },

            init() {
                let _this = this;
                //{k:v,k:v}
                let y = { years: this.curyear, univsCode: this.univsCode };
                if (!y.years) {
                    y.years = "2022";
                }
                if (!y.univsCode) {
                    y.univsCode = "4142010486";
                }
                meiridajuan(y).then((res) => {
                    if (res.success) {
                        let totalList = [];
                        let daysList = [];
                        let maxCapacity = res.data.list[0].maxCapacity;
                        res.data.list.forEach((item) => {
                            totalList.push(item.total);
                            daysList.push(item.days);
                        });
                        this.newdate(totalList, daysList, maxCapacity);
                    } else {
                        console.log(res);
                    }
                });

                shouyeLeida(y).then((res) => {
                    if (res.success) {
                        let totalList = [];
                        let maxCapacity = res.data.univs[0].maxCapacity;
                        res.data.univs.forEach((item) => {
                            totalList.push(item.total);
                        });
                        this.lookpie(totalList, maxCapacity);
                        console.log(maxCapacity);
                    } else {
                        console.log(res);
                    }
                });

                showYear().then((res) => {
                    if (res.success) {
                        this.$data.yearList = res.data.list;
                    } else {
                        console.log(res);
                    }
                });

                shouyeBintu(y).then((res) => {
                    if (res.success) {
                        this.shuangyiliu = res.data.shuangyiliu;
                        this.jiubawu = res.data.jiubawu;
                        this.eryiyi = res.data.eryiyi;
                        this.yiben = res.data.yiben;
                        this.erben = res.data.erben;
                        this.dazhuan = res.data.dazhuan;
                    } else {
                        console.log(res);
                    }
                });

                shouyeWenjuan(y).then((res) => {
                    if (res.success) {
                        this.totalViews = res.data.totalViews;
                        this.time1 = res.data.time1;
                        this.time2 = res.data.time2;
                        this.majorNum = res.data.majorNum;
                        this.collegeNum = res.data.collegeNum;
                        this.solutionNum = res.data.solutionNum;
                    } else {
                        console.log(res);
                    }
                });

                shouyeSanjiao(y).then((res) => {
                    if (res.success) {
                        let ageList = [];
                        let ageNumList = [];
                        let maxCapacity = [];
                        res.data.list.forEach((item) => {
                            ageList.push(item.age);
                            ageNumList.push(item.ageNum);
                            maxCapacity.push(item.maxCapacity);
                        });
                        this.lookchart1(ageList, ageNumList, maxCapacity);
                    } else {
                        console.log(res);
                    }
                });

                shouyeSex(y).then((res) => {
                    if (res.success) {
                        let boy1 = res.data.boy1;
                        let boy2 = res.data.boy2;
                        let girl1 = res.data.girl1;
                        let girl2 = res.data.girl2;
                        let boyList = res.data.boyList
                        let girlList = res.data.girlList
                        let dateList = res.data.dateList
                        this.sex(boy1, boy2);
                        this.sex2(girl1, girl2);
                        this.sex3(boyList,girlList,dateList)
                    } else {
                        console.log(res);
                    }
                });

                meiriMajor(y).then((res) => {
                    if (res.success) {
                        let totalList = [];
                        let majorList = [];
                        let maxCapacity = [];
                        res.data.list.forEach((item) => {
                            totalList.push(item.total);
                            majorList.push(item.professional_type);
                            maxCapacity.push(item.maxCapacity);
                        });
                        this.subject(totalList, majorList, maxCapacity);
                    } else {
                        console.log(res);
                    }
                });

                showSchool(y).then((res) => {
                    if (res.success) {
                    } else {
                        console.log(res);
                    }
                });
            },
            lookpie(totalList, maxCapacity) {
                let lookpiechart = this.$echarts.init(document.getElementById("lookpie"));
                /*请不要再此基础上进行修改和保存自己修改的内容，若想修改克隆一份*/

                let lookpieOption = {
                    normal: {
                        top: 0,
                        left: 0,
                        width: this.fontSize(330),
                        height: this.fontSize(300),
                        zIndex: 6,
                        backgroundColor: "",
                    },
                    color: ["rgba(245, 166, 35, 1)", "rgba(19, 173, 255, 1)"],
                    title: {
                        show: true,
                        left: "40%",
                        top: "1%",
                        textStyle: {
                            fontSize: this.fontSize(18),
                            color: "#fff",
                            fontStyle: "normal",
                            fontWeight: "normal",
                        },
                    },
                    tooltip: {
                        show: true,
                        trigger: "item",
                    },
                    legend: {
                        show: true,
                        icon: "circle",
                        left: "35%",
                        top: "90%",
                        orient: "horizontal",
                        textStyle: {
                            fontSize: this.fontSize(14),
                            color: "#fff",
                        },
                    },
                    radar: {
                        center: ["50%", "50%"],
                        radius: "60%",
                        startAngle: 90,
                        splitNumber: 4,
                        splitArea: {
                            areaStyle: {
                                color: ["transparent"],
                            },
                        },
                        axisLabel: {
                            show: false,
                            fontSize: this.fontSize(18),
                            color: "#fff",
                        },
                        axisLine: {
                            show: true,

                            lineStyle: {
                                color: "#06233d",
                                type: "solid", //
                            },
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#06233d", //
                                type: "solid",
                            },
                        },
                        indicator: [
                            {
                                name: "大一",
                                max: maxCapacity,
                                color: "#fff",
                            },
                            {
                                name: "其他",
                                max: maxCapacity,
                                color: "#fff",
                            },
                            {
                                name: "研二",
                                max: maxCapacity,
                                color: "#fff",
                            },
                            {
                                name: "研一",
                                max: maxCapacity,
                                color: "#fff",
                            },
                            {
                                name: "大五",
                                max: maxCapacity,
                                color: "#fff",
                            },
                            {
                                name: "大四",
                                max: maxCapacity,
                                color: "#fff",
                            },
                            {
                                name: "大三",
                                max: maxCapacity,
                                color: "#fff",
                            },
                            {
                                name: "大二",
                                max: maxCapacity,
                                color: "#fff",
                            },
                        ],
                    },
                    series: [
                        {
                            name: "人生态度",
                            type: "radar",
                            symbol: "circle",
                            symbolSize: 5,
                            areaStyle: {
                                normal: {
                                    color: "#093241",
                                },
                            },
                            itemStyle: {
                                color: "#00a0e9",
                                borderColor: "rgba(41,240,241, 1)",
                                borderWidth: 0,
                            },
                            lineStyle: {
                                normal: {
                                    type: "solid",

                                    color: "#00a0e9",
                                    width: 1,
                                },
                            },
                            data: [totalList],
                        },
                    ],
                };
                lookpiechart.setOption(lookpieOption);
                window.addEventListener("resize", function () {
                    lookpiechart.resize();
                });
            },
            subject(totalList, majorList, maxCapacity) {
                let subjectChart = this.$echarts.init(document.getElementById("subject"));
                let _this = this;

                let dataX = [
                    "理学",
                    "法学",
                    "教育学",
                    "经济学",
                    "历史学",
                    "农学",
                    "工学",
                    "文学",
                    "XX学",
                    "XX学",
                    "XX学",
                    "XX学",
                    "XX学",
                    "XX学",
                    "XX学",
                    "XX学",
                ]; //名称
                let dataY = [
                    1430, 1502, 1192, 1502, 1320, 1300, 1452, 1521, 1233, 1345, 1100, 1600,
                    1542, 1345, 1456, 1234, 1422, 1300,
                ]; //数据
                let zoomShow = false;

                if (totalList && totalList.length > 8) {
                    zoomShow = true;
                } else {
                    zoomShow = false;
                }
                let subjectOption = {
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                        },
                    },
                    grid: {
                        top: "20%",
                        right: "5%",
                        left: "15%",
                        bottom: "15%",
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: majorList,
                            axisLine: {
                                lineStyle: {
                                    color: "#313131",
                                },
                            },
                            axisLabel: {
                                interval: 0,
                                margin: 10,
                                color: "#FFF",
                                textStyle: {
                                    fontSize: 11,
                                },
                            },
                            axisTick: {
                                //刻度
                                show: false,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            name: "单位 ：份",
                            nameTextStyle: {
                                color: "#fff",
                            },
                            axisLabel: {
                                padding: [3, 0, 0, 0],
                                formatter: "{value}",
                                color: "#FFF",
                                textStyle: {
                                    fontSize: 11,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#313131",
                                },
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "rgba(255,255,255,0)",
                                },
                            },
                        },
                    ],
                    dataZoom: [
                        //滚动条
                        {
                            show: false,
                            type: "slider",
                            realtime: true,
                            startValue: 0,
                            endValue: 7,
                            xAxisIndex: [0],
                            left: "30",
                            height: 10,
                            handleStyle: {
                                color: "#3c1125",
                                borderColor: "#7a1339",
                            },
                            borderColor: "#7a1339",
                            fillerColor: "rgba(46, 16, 37, 1)",
                            textStyle: {
                                color: "#fff",
                            },
                        },
                        {
                            type: "inside", // 支持内部鼠标滚动平移
                            start: 0,
                            end: 7,
                            zoomOnMouseWheel: false, // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
                        },
                    ],
                    series: [
                        {
                            type: "bar",
                            data: totalList,
                            max: maxCapacity,
                            barWidth: "10",
                            // 时间越长动画越慢
                            animationDuration: 3000,
                            animationEasing: "cubicInOut",
                            itemStyle: {
                                normal: {
                                    color: "#2d0819",
                                    borderWidth: 1,
                                    borderColor: "#7d123a",
                                    // color: new this.$echarts.graphic.LinearGradient(
                                    //   0,
                                    //   0,
                                    //   0,
                                    //   1,
                                    //   [
                                    //     {
                                    //       offset: 0,
                                    //       color: "rgba(5, 213, 255, 1)", // 0% 处的颜色
                                    //     },
                                    //     {
                                    //       offset: 0.98,
                                    //       color: "rgba(5, 213, 255, 0)", // 100% 处的颜色
                                    //     },
                                    //   ],
                                    //   false
                                    // ),
                                    // shadowColor: "rgba(5, 213, 255, 1)",
                                    // shadowBlur: 4,
                                },
                            },
                            label: {
                                normal: {
                                    show: true,
                                    lineHeight: 10,
                                    formatter: "{c}",
                                    position: "top",
                                    textStyle: {
                                        color: "#fff",
                                        fontSize: 10,
                                    },
                                },
                            },
                        },
                    ],
                };
                subjectChart.setOption(subjectOption);
                window.addEventListener("resize", function () {
                    subjectChart.resize();
                });
            },
            lookchart1(ageList, ageNumList, maxCapacity) {
                let lookchart = this.$echarts.init(document.getElementById("lookchart1"));
                let _this = this;
                let option = {
                    tooltip: {
                        trigger: "axis",

                        axisPointer: {
                            type: "none",
                        },
                        formatter: function (params) {
                            if (params[0].name == "18-20岁") {
                                return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
                            } else if (params[0].name == "20-22岁") {
                                return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
                            } else if (params[0].name == "22-24岁") {
                                return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
                            } else if (params[0].name == "24-26岁") {
                                return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
                            } else if (params[0].name == "26-28岁") {
                                return `<span style="color: #FD7272">${params[0].name} : ${params[0].value}</span>`;
                            }
                        },
                    },
                    grid: {
                        top: "20%",
                        left: "12%",
                        right: "10%",
                        bottom: "18%",
                        containLabel: true,
                    },
                    xAxis: {
                        data: ["18-20岁", "20-22岁", "22-24岁", "24-26岁", "26-28岁"],
                        axisTick: { show: false },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#434343",
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                color: "#FFF",
                            },
                        },
                    },
                    yAxis: [
                        {
                            name: "单位 ：个",
                            nameTextStyle: {
                                //---坐标轴名称样式
                                color: "#fff",
                                padding: [0, 0, 10, -40], //---坐标轴名称相对位置
                            },
                            nameGap: 15, //---坐标轴名称与轴线之间的距离
                            type: "value",
                            gridIndex: 0,
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: "rgba(67,67,67,0.1)",
                                    width: 1,
                                    type: "solid",
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            // min: 0,
                            // max: 1500,
                            // max:maxCapacity,
                            axisLine: {
                                lineStyle: {
                                    color: "#0c3b71",
                                },
                            },
                            axisLabel: {
                                color: "#FFF",
                                formatter: "{value}",
                            },
                        },
                        {
                            splitLine: { show: false },
                            axisTick: { show: false },
                            axisLine: { show: false },
                            axisLabel: {
                                show: false,
                                textStyle: {
                                    color: "#FFF",
                                },
                            },
                        },
                    ],

                    series: [
                        {
                            name: "hill",
                            type: "pictorialBar",
                            barCategoryGap: "40%",
                            symbol: "triangle",
                            // 时间越长动画越慢
                            animationDuration: 3000,
                            animationEasing: "cubicInOut",
                            label: {
                                show: true,
                                position: "top",
                                textStyle: {
                                    color: "white",
                                },
                            },
                            itemStyle: {
                                normal: {
                                    opacity: 1,
                                    color: function (params) {
                                        if (params.name == "18-20岁") {
                                            return new _this.$echarts.graphic.LinearGradient(
                                                0,
                                                1,
                                                0,
                                                0,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "transparent",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "#01dde8",
                                                    },
                                                ]
                                            );
                                        } else if (params.name == "20-22岁") {
                                            return new _this.$echarts.graphic.LinearGradient(
                                                0,
                                                1,
                                                0,
                                                0,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "transparent",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "#d44343",
                                                    },
                                                ]
                                            );
                                        } else if (params.name == "22-24岁") {
                                            return new _this.$echarts.graphic.LinearGradient(
                                                0,
                                                1,
                                                0,
                                                0,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "transparent",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "#01dde8",
                                                    },
                                                ]
                                            );
                                        } else if (params.name == "24-26岁") {
                                            return new _this.$echarts.graphic.LinearGradient(
                                                0,
                                                1,
                                                0,
                                                0,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "transparent",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "#d44343",
                                                    },
                                                ]
                                            );
                                        } else if (params.name == "26-28岁") {
                                            return new _this.$echarts.graphic.LinearGradient(
                                                0,
                                                1,
                                                0,
                                                0,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "transparent",
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "#01dde8",
                                                    },
                                                ]
                                            );
                                        }
                                    },
                                },
                                emphasis: {
                                    opacity: 1,
                                },
                            },
                            data: ageNumList,
                            z: 10,
                        },
                        {
                            name: "glyph",
                            type: "pictorialBar",
                            barGap: "-100%",
                            color: "yellow",
                            symbolPosition: "end",
                            symbolSize: 50,
                            symbolOffset: [0, "-120%"],
                        },
                        {
                            name: "背景",
                            type: "bar",
                            barWidth: "50%",
                            xAxisIndex: 0,
                            yAxisIndex: 1,
                            barGap: "-135%",
                            data: maxCapacity,
                            itemStyle: {
                                normal: {
                                    color: "rgba(112,112,112,0.1)",
                                },
                            },
                            z: 9,
                        },
                    ],
                };
                lookchart.setOption(option);
                window.addEventListener("resize", function () {
                    lookchart.resize();
                });
            },
            mapchart() {
                let _this = this;
                let mapURL = "/static/hubei.json";
                $.getJSON(mapURL, function (geoJson) {
                    _this.$echarts.registerMap("武汉", geoJson);
                    let mapChart = _this.$echarts.init(document.getElementById("map"));
                    let mapoption = {
                        backgroundColor: "transparent",
                        tooltip: {},
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            show: true,
                        },
                        geo: {
                            show: true,
                            map: "武汉",
                            roam: true,
                            zoom: 1.1,
                            label: {
                                emphasis: {
                                    show: false,
                                },
                            },
                            regions: [
                                {
                                    name: "南海诸岛",
                                    itemStyle: {
                                        // 隐藏地图
                                        normal: {
                                            opacity: 0, // 为 0 时不绘制该图形
                                        },
                                    },
                                    label: {
                                        show: false, // 隐藏文字
                                    },
                                },
                            ],

                            itemStyle: {
                                normal: {
                                    borderColor: "rgba(7, 133, 212, 0.2)",
                                    borderWidth: 1,
                                    areaColor: {
                                        type: "radial",
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.8,
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "rgba(3, 23, 145, 1)", // 0% 处的颜色
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(53, 106, 243, 1)", // 100% 处的颜色
                                            },
                                        ],
                                        globalCoord: false, // 缺省为 false
                                    },
                                    shadowColor: "rgba(28, 64, 126, 0.3)",
                                    // shadowColor: 'rgba(255, 255, 255, 1)',
                                    shadowOffsetX: -2,
                                    shadowOffsetY: 2,
                                    shadowBlur: 10,
                                },
                                emphasis: {
                                    areaColor: "#5e2370",
                                    borderWidth: 0,
                                },
                            },
                        },
                    };
                    mapChart.setOption(mapoption);
                    window.addEventListener("resize", function () {
                        mapChart.resize();
                    });
                });
            },
            newdate(totalList, daysList, maxCapacity) {
                let newdatechart = this.$echarts.init(
                    document.getElementById("second-right-bottom")
                );
                let _this = this;

                let newdateOption = {
                    backgroundColor: "transparent",
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            lineStyle: {
                                color: {
                                    type: "linear",
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: "rgba(0, 255, 233,0)",
                                        },
                                        {
                                            offset: 0.5,
                                            color: "rgba(255, 255, 255,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(0, 255, 233,0)",
                                        },
                                    ],
                                    global: false,
                                },
                            },
                        },
                    },
                    grid: {
                        top: "15%",
                        left: "15%",
                        right: "5%",
                        bottom: "10%",
                        // containLabel: true
                    },
                    xAxis: [
                        {
                            type: "category",
                            axisLine: {
                                show: true,
                                color: "#818182",
                                lineStyle: {
                                    opacity: 0.5,
                                },
                            },

                            axisLabel: {
                                color: "#818182",
                                width: 100,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    //color:'red',
                                    //width:1,
                                    //type:'solid',
                                    opacity: 0.15,
                                },
                            },
                            boundaryGap: false,
                            data: daysList,
                        },
                    ],

                    yAxis: [
                        {

                            nameTextStyle: {
                                color: "#fff",
                                padding: [0, 0, 0, -20],
                            },
                            type: "value",
                            min: 0,
                            max: maxCapacity,
                            splitLine: {
                                show: false,
                                lineStyle: {
                                    color: "#00BFF3",
                                    opacity: 0.23,
                                },
                            },
                            axisLabel: {
                                show: true,
                                color: "#FFF",
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    opacity: 0.5,
                                },
                            },
                        },
                    ],
                    series: [
                        {

                            type: "line",
                            showAllSymbol: true,
                            symbol: "rectangle",
                            symbolSize: 5,
                            lineStyle: {
                                normal: {
                                    color: "#db0e2a",
                                },
                            },
                            itemStyle: {
                                color: "#db0e2a",
                            },
                            areaStyle: {
                                normal: {
                                    color: new this.$echarts.graphic.LinearGradient(
                                        0,
                                        0,
                                        0,
                                        1,
                                        [
                                            {
                                                offset: 0,
                                                color: "rgba(219, 14, 42,0.3)",
                                            },
                                            {
                                                offset: 1,
                                                color: "rgba(219, 14, 42,0)",
                                            },
                                        ],
                                        false
                                    ),
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    opacity: 0.5,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            data: totalList,
                        },
                    ],
                };
                newdatechart.setOption(newdateOption);
                window.addEventListener("resize", function () {
                    newdatechart.resize();
                });
            },
            sex(boy1, boy2) {
                let sexChart = this.$echarts.init(document.getElementById("sex-l"));
                let dataArr = [
                    {
                        value: boy1,
                        name: "综合健康评分",
                    },
                ];
                let color = new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                        offset: 0,
                        color: "#09E2F5", // 0% 处的颜色
                    },
                    {
                        offset: 1,
                        color: "#09E2F5", // 100% 处的颜色
                    },
                ]);
                let colorSet = [
                    [boy2, color],
                    [1, "#4E4E4E"],
                ];
                let rich = {
                    white: {
                        fontSize: this.fontSize(16),
                        color: "#21F0FF",
                        fontWeight: "500",
                        // fontStyle: 'oblique',
                        padding: [this.fontSize(50), 0, 0, 0],
                    },
                    bule: {
                        fontSize: this.fontSize(16),
                        fontFamily: "DINBold",
                        color: "#21F0FF",
                        // fontWeight: '700',
                        // fontStyle: 'italic',
                        padding: [this.fontSize(50), 0, 0, 0],
                    },
                    radius: {
                        width: this.fontSize(100),
                        height: this.fontSize(40),
                        // lineHeight:80,
                        // borderWidth: 1,
                        // borderColor: '#0092F2',
                        fontSize: this.fontSize(16),
                        color: "#fff",
                        // backgroundColor: '#1B215B',
                        // borderRadius: 20,
                        textAlign: "center",
                        padding: [this.fontSize(50), 0, 0, 0],
                    },
                    size: {
                        height: this.fontSize(200),
                        padding: [0, 0, 0, 0],
                    },
                };
                let sexOption = {
                    // backgroundColor: '#0E1327',
                    tooltip: {
                        formatter: "{a} <br/>{b} : {c}%",
                    },

                    series: [
                        {
                            type: "gauge",
                            radius: "100%",
                            startAngle: "180",
                            endAngle: "0",
                            pointer: {
                                show: false,
                            },
                            detail: {
                                formatter: function (value) {
                                    let num = Math.round(value);
                                    return "{radius|男性占比}{bule|" + num + "}{white|%}";
                                },
                                rich: rich,
                                offsetCenter: ["0%", "0%"],
                            },
                            data: dataArr,
                            title: {
                                // fontFamily: 'PingFang SC Regular',
                                fontSize: this.fontSize(20),
                                color: "transparent",
                                offsetCenter: ["0", "-10"],
                                formatter: "2",
                                // rich: {},
                                width: this.fontSize(20),
                                height: this.fontSize(20),
                                backgroundColor: {
                                    image: require("../../assets/img/man.png"),
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: colorSet,
                                    width: this.fontSize(8),
                                    // shadowBlur: 15,
                                    // shadowColor: '#fff',
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0,
                                    opacity: 1,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                                length: 25,
                                lineStyle: {
                                    color: "#00377a",
                                    width: 2,
                                    type: "solid",
                                },
                            },
                            axisLabel: {
                                show: false,
                            },
                        },
                        {
                            type: "gauge",
                            name: "外层辅助",
                            radius: "82%",
                            startAngle: "180",
                            endAngle: "0",
                            splitNumber: "100",
                            z: 1,
                            pointer: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [
                                {
                                    value: 1,
                                },
                            ],
                            // data: [{value: 1, name: 90}],
                            title: {
                                show: true,
                                offsetCenter: [0, 30],
                                textStyle: {
                                    color: "#fff",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontFamily: "微软雅黑",
                                    fontSize: this.fontSize(20),
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: [
                                        [0, "#09E2F5"],

                                        [1, "#09E2F5"],
                                    ],
                                    width: this.fontSize(2),
                                    opacity: 1,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: true,
                                length: 20,
                                lineStyle: {
                                    color: "#051932",
                                    width: 0,
                                    type: "solid",
                                },
                            },
                            axisLabel: {
                                show: false,
                            },
                        },
                        {
                            type: "gauge",
                            name: "外层辅助",
                            radius: "64%",
                            startAngle: "150",
                            endAngle: "30",
                            splitNumber: "100",
                            z: 1,
                            pointer: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [
                                {
                                    value: 1,
                                },
                            ],
                            // data: [{value: 1, name: 90}],
                            title: {
                                show: true,
                                offsetCenter: [0, 30],
                                textStyle: {
                                    color: "#fff",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontFamily: "微软雅黑",
                                    fontSize: 20,
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: [
                                        [0.15, "#09E2F5"],

                                        [1, "#09E2F5"],
                                    ],
                                    width: this.fontSize(5),
                                    opacity: 1,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: true,
                                length: 20,
                                lineStyle: {
                                    color: "#051932",
                                    width: 0,
                                    type: "solid",
                                },
                            },
                            axisLabel: {
                                show: false,
                            },
                        },
                    ],
                };
                sexChart.setOption(sexOption);
                window.addEventListener("resize", function () {
                    sexChart.resize();
                });
            },
            sex2(girl1, girl2) {
                let sexChart = this.$echarts.init(document.getElementById("sex-r"));
                let dataArr = [
                    {
                        value: girl1,
                        name: "综合健康评分",
                    },
                ];
                let color = new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                        offset: 0,
                        color: "#FF00FF ", // 0% 处的颜色
                    },
                    {
                        offset: 1,
                        color: "#FF00FF ", // 100% 处的颜色
                    },
                ]);
                let colorSet = [
                    [girl2, color],
                    [1, "#4E4E4E"],
                ];
                let rich = {
                    white: {
                        fontSize: this.fontSize(16),
                        color: "#FF00FF",
                        fontWeight: "500",
                        // fontStyle: 'oblique',
                        padding: [this.fontSize(50), 0, 0, 0],
                    },
                    bule: {
                        fontSize: this.fontSize(16),
                        fontFamily: "DINBold",
                        color: "#FF00FF",
                        // fontWeight: '700',
                        // fontStyle: 'italic',
                        padding: [this.fontSize(50), 0, 0, 0],
                    },
                    radius: {
                        width: this.fontSize(100),
                        height: this.fontSize(40),
                        // lineHeight:80,
                        // borderWidth: 1,
                        // borderColor: '#0092F2',
                        fontSize: this.fontSize(16),
                        color: "#fff",
                        // backgroundColor: '#1B215B',
                        // borderRadius: 20,
                        textAlign: "center",
                        padding: [this.fontSize(50), 0, 0, 0],
                    },
                    size: {
                        height: this.fontSize(200),
                        padding: [0, 0, 0, 0],
                    },
                };
                let sexOption = {
                    // backgroundColor: '#0E1327',
                    tooltip: {
                        formatter: "{a} <br/>{b} : {c}%",
                    },

                    series: [
                        {
                            type: "gauge",
                            radius: "100%",
                            startAngle: "180",
                            endAngle: "0",
                            pointer: {
                                show: false,
                            },
                            detail: {
                                formatter: function (value) {
                                    let num = Math.round(value);
                                    return "{radius|女性占比}{bule|" + num + "}{white|%}";
                                },
                                rich: rich,
                                offsetCenter: ["0%", "0%"],
                            },
                            data: dataArr,
                            title: {
                                // fontFamily: 'PingFang SC Regular',
                                fontSize: this.fontSize(18),
                                color: "transparent",
                                offsetCenter: ["0", "-10"],
                                formatter: "2",
                                // rich: {},
                                width: this.fontSize(20),
                                height: this.fontSize(20),
                                backgroundColor: {
                                    image: require("../../assets/img/woman.png"),
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: colorSet,
                                    width: this.fontSize(8),
                                    // shadowBlur: 15,
                                    // shadowColor: '#fff',
                                    shadowOffsetX: 0,
                                    shadowOffsetY: 0,
                                    opacity: 1,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                                length: 25,
                                lineStyle: {
                                    color: "#00377a",
                                    width: 2,
                                    type: "solid",
                                },
                            },
                            axisLabel: {
                                show: false,
                            },
                        },
                        {
                            type: "gauge",
                            name: "外层辅助",
                            radius: "82%",
                            startAngle: "180",
                            endAngle: "0",
                            splitNumber: "100",
                            z: 1,
                            pointer: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [
                                {
                                    value: 1,
                                },
                            ],
                            // data: [{value: 1, name: 90}],
                            title: {
                                show: true,
                                offsetCenter: [0, 30],
                                textStyle: {
                                    color: "#fff",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontFamily: "微软雅黑",
                                    fontSize: 20,
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: [
                                        [0, "#FF00FF "],

                                        [1, "#FF00FF "],
                                    ],
                                    width: this.fontSize(2),
                                    opacity: 1,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: true,
                                length: 20,
                                lineStyle: {
                                    color: "#051932",
                                    width: 0,
                                    type: "solid",
                                },
                            },
                            axisLabel: {
                                show: false,
                            },
                        },
                        {
                            type: "gauge",
                            name: "外层辅助",
                            radius: "64%",
                            startAngle: "150",
                            endAngle: "30",
                            splitNumber: "100",
                            z: 1,
                            pointer: {
                                show: false,
                            },
                            detail: {
                                show: false,
                            },
                            data: [
                                {
                                    value: 1,
                                },
                            ],
                            // data: [{value: 1, name: 90}],
                            title: {
                                show: true,
                                offsetCenter: [0, 30],
                                textStyle: {
                                    color: "#fff",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontFamily: "微软雅黑",
                                    fontSize: this.fontSize(20),
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: [
                                        [0.15, "#FF00FF "],

                                        [1, "#FF00FF "],
                                    ],
                                    width: this.fontSize(5),
                                    opacity: 1,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: true,
                                length: 20,
                                lineStyle: {
                                    color: "#051932",
                                    width: 0,
                                    type: "solid",
                                },
                            },
                            axisLabel: {
                                show: false,
                            },
                        },
                    ],
                };
                sexChart.setOption(sexOption);
                window.addEventListener("resize", function () {
                    sexChart.resize();
                });
            },
            sex3(boyList,girlList,dateList) {
                let sexLineChart = this.$echarts.init(
                    document.getElementById("sex-line")
                );
                let sexLineOption = {
                    color: ["#27d391", "#FF00FF "],
                    grid: {
                        left: "5%",
                        right: "5%",
                        top: "20%",
                        bottom: "0%",
                        containLabel: true,
                    },
                    tooltip: {
                        show: true,
                        trigger: "item",
                    },
                    legend: {
                        show: false,
                        x: "center",
                        top: "12%",
                        y: "35",
                        icon: "stack",
                        itemWidth: this.fontSize(10),
                        itemHeight: this.fontSize(10),
                        textStyle: {
                            color: "#1bb4f6",
                        },
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        axisLabel: {
                            color: "#fff",
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#707070",
                            },
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                            lineStyle: {
                                color: "#195384",
                            },
                        },
                        data: dateList,
                    },
                    yAxis: {
                        type: "value",
                        name: "男女比例",
                        scale: false,
                        nameTextStyle: {
                            color: "#fff  ",
                            padding: [0, 0, -20, 20],
                        },
                        axisLabel: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    series: [
                        {
                            name: "女性占比",
                            type: "line",
                            // stack: "总量",
                            symbol: "none",
                            smooth: true,
                            symbolSize: 8,
                            itemStyle: {
                                normal: {
                                    color: "#FF00FF ",
                                    lineStyle: {
                                        color: "#FF00FF ",
                                        width: 1,
                                    },
                                    areaStyle: {
                                        //color: '#94C9EC'
                                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                            {
                                                offset: 1,
                                                color: "rgba(255,0,255,0.8)",
                                            },
                                            {
                                                offset: 0,
                                                color: "rgba(67,67,67,0.1)",
                                            },
                                        ]),
                                    },
                                },
                            },
                            data: girlList,
                        },
                        {
                            name: "男性占比",
                            type: "line",
                            // stack: "总量",
                            symbol: "none",
                            smooth: true,
                            symbolSize: 8,
                            itemStyle: {
                                normal: {
                                    color: "#09E2F5",
                                    lineStyle: {
                                        color: "#09E2F5",
                                        width: 1,
                                    },
                                    areaStyle: {
                                        //color: '#94C9EC'
                                        color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                            {
                                                offset: 1,
                                                color: "rgba(9,226,245,0.6)",
                                            },
                                            {
                                                offset: 0,
                                                color: "rgba(9,226,245,0.1)",
                                            },
                                        ]),
                                    },
                                },
                            },
                            markPoint: {
                                itemStyle: {
                                    normal: {
                                        color: "red",
                                    },
                                },
                            },
                            data: boyList,
                        },
                    ],
                };
                sexLineChart.setOption(sexLineOption);
                window.addEventListener("resize", function () {
                    sexLineChart.resize();
                });
            },
            cloud(data = [], format = [], dom) {
                let bubbleChart = this.$echarts.init(document.getElementById("cloud"));
                //跳转代码
                let [maxValue, temp] = [0, []];
                let num = 0;
                data.forEach((item) => {
                    temp.push(item[format[1]]);
                });
                maxValue = Math.max.apply(null, temp);

                // 气泡颜色数组
                let color = [
                    "red",
                    "#e65852",
                    "#CE2D65",
                    "#0075A9 ",
                    "#13B5B1",
                    "#e65852",
                    "#00D9D9",
                    "#556FB5",
                    "#08BCFB",
                ];
                // 气泡颜色备份
                let bakeColor = [...color];
                // 气泡数据
                let bubbleData = [];
                // 气泡基础大小
                let basicSize = this.fontSize(30);
                // 节点之间的斥力因子,值越大,气泡间距越大
                let repulsion = this.fontSize(100);
                // 根据气泡数量配置基础大小和斥力因子（以实际情况进行适当调整，使气泡合理分布）
                if (data.length >= 5 && data.length < 10) {
                    basicSize = this.fontSize(20);
                    repulsion = this.fontSize(80);
                }

                // 填充气泡数据数组bubbleData
                for (let item of data) {
                    num++;
                    // 确保气泡数据条数少于或等于气泡颜色数组大小时，气泡颜色不重复
                    if (!bakeColor.length) bakeColor = [...color];
                    let colorSet = new Set(bakeColor);
                    let curIndex = Math.round(Math.random() * (colorSet.size - 1));
                    console.log(curIndex);
                    let curColor = bakeColor[curIndex];
                    console.log(curColor);
                    colorSet.delete(curColor);
                    bakeColor = [...colorSet];
                    // 气泡大小设置
                    let size = (item[format[1]] * basicSize * 2) / maxValue;
                    if (size < basicSize) size = basicSize;
                    let a = color[num];
                    bubbleData.push({
                        name: item[format[0]],
                        value: item[format[1]],
                        symbolSize: size,
                        draggable: true,
                        itemStyle: {
                            normal: {
                                shadowBlur: 14,
                                shadowColor: a,
                                borderColor: a,
                                borderWidth: 4,
                                color: a,
                            },
                        },
                    });
                }

                let bubbleOptions = {
                    animationEasingUpdate: "bounceIn",
                    series: [
                        {
                            type: "graph",
                            layout: "force",
                            force: {
                                repulsion: repulsion,
                                edgeLength: 10,
                            },
                            // 是否开启鼠标缩放和平移漫游
                            roam: false,
                            label: { normal: { show: true, color: "#fff" } },
                            data: bubbleData,
                        },
                    ],
                };
                bubbleChart.setOption(bubbleOptions);
                window.addEventListener("resize", function () {
                    bubbleChart.resize();
                });
            },
        },
        components: {
            HeaderTitle
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/schoolHome.scss";
</style>
