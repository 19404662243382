import Vue from 'vue'
import { login, getLoginUser, logout } from '@/api/modular/system/loginManage'
import {ACCESS_TOKEN, SET_SCHOOL_LIST_CITY, SET_USER_NAME} from '@/store/mutation-types'

const user = {
  state: {
    token: '',
    name: '',
    info: {},
    buttons: [], // 按钮权限
    admintype: '', // 是否是超管
    orgInfo: {},
    roles: [],
    userName : '',
    avatar:''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_BUTTONS: (state, buttons) => {
      state.buttons = buttons
    },
    SET_ADMINTYPE: (state, admintype) => {
      state.admintype = admintype
    },
    SET_ORG_INFO: (state, info) => {
      state.orgInfo = info
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    [SET_USER_NAME]: (state, roles) => {
      state.userName = roles
    },
    SET_AVATAR:(state, avatar) => {
      state.avatar = avatar
    },
  },

  actions: {
    setUserName ({ commit }, userInfo){
      commit(SET_USER_NAME,userInfo)
    },
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (!response.success) {
            reject(response.message)
            return
          }
          const result = response.data
          Vue.ls.set(ACCESS_TOKEN, result, 7 * 24 * 60 * 60 * 1000)
          commit('SET_TOKEN', result)
          resolve()
        // eslint-disable-next-line handle-callback-err
        }).catch(() => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('后端未启动或代理错误')
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getLoginUser().then(response => {
          if (response.success) {
            const data = response.data
            commit('SET_ADMINTYPE', data.adminType)
            commit('SET_BUTTONS', data.permissions)
            commit('SET_INFO', data)
            commit('SET_NAME', data.name)
            commit('SET_ORG_INFO', data.loginEmpInfo)
            commit('SET_ROLES', data.roles)
            /*localStorage.setItem("role",JSON.stringify(data.roles))
            localStorage.setItem("name",JSON.stringify(data.loginEmpInfo))*/
            localStorage.setItem('role',JSON.stringify(data.roles))
            localStorage.setItem('info',JSON.stringify(data.loginEmpInfo))
            localStorage.setItem('user',JSON.stringify(data))
            if (data.avatar != null) {
              localStorage.setItem('avatar',JSON.stringify(`https://apisxlj.ninemm.cn/sysFileInfo/preview?id=${data.avatar}`))
            }
            resolve(data)
          } else {
            // eslint-disable-next-line no-undef
            reject(new Error(data.message))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },


    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          console.log('登出')
          localStorage.removeItem('role')
          localStorage.removeItem('info')
          localStorage.removeItem('user')
          localStorage.removeItem('avatar')
          console.log(state)
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ADMINTYPE', '')
          commit('SET_BUTTONS', [])
          commit('SET_INFO', {})
          commit('SET_NAME', '')
          commit('SET_SCHOOL_LIST_CITY', '')
          commit('SET_CITY', '')
          commit('SET_SCHOOL', '')
          commit('SET_USER_NAME', '')
          Vue.ls.remove(ACCESS_TOKEN)
        })
      })
    }


  }
}

export default user
