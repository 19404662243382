<template>
    <div class="crumb-box">
        <div class="crumb-left-dot"><img src="../assets/img/title.png"></div>
        <div class="crumb-text"><span @click="chinaBtn">全国</span><span @click="cityBtn" v-show="this.getCity"> > {{this.getCity}}</span><span @click="schoolBtn" v-show="this.getSchool"> > {{this.getSchool}}</span></div>
        <div class="crumb-right">
            <div><img src="../assets/img/title_rectangle1.png" alt=""></div>
            <div><img src="../assets/img/title_rectangle2.png" alt=""></div>
        </div>
    </div>
</template>

<script>
    import { mapGetters,mapActions } from 'vuex'
    export default {
        name: "crumb",
        data(){
            return {
                currentCity:''
            }
        },
        methods:{
            ...mapActions([
                'setCity',
                'setSchool',
                'setSchoolListCity'
            ]),
            chinaBtn(){
                this.currentCity = JSON.parse(localStorage.getItem("info")).orgName
                if (this.currentCity == '全国'){
                    this.setCity('')
                    this.setSchool('')
                }
            },
            cityBtn(){
                this.currentCity = JSON.parse(localStorage.getItem("info")).orgName
                if (JSON.parse(localStorage.getItem("role"))[0].name !== '高校') {
                    console.log('0000111')
                    console.log(this.currentCity)
                    this.setSchool('')
                    this.setSchoolListCity(this.getCity)
                }
            },
            schoolBtn(){

            },
        },
        computed:{
            ...mapGetters([
                "getCity",
                "getSchool",
                "getSchoolListCity"
            ])
        },
    }
</script>

<style lang="scss" scoped>
    .crumb-box{
        width: 100%;
        height: 0.5rem;
        border: 1px solid rgba(44, 3, 3, 0.7);
        border-right: none;
        border-left: none;
        /*background-color: red;*/
        padding: 0.03rem 0;
        line-height: 0.5rem;
        .crumb-left-dot{
            display: inline-block;
            height: 0.4rem;
            width: 0.4rem;
            /*background-color: red;*/
            background-color: rgba(44, 3, 3, 0.7);
            img{
                width: 100%;
                height: 100%;
                vertical-align:top;
            }

        }
        .crumb-text{
            font-size: 14px;
            vertical-align:top;
            height: 0.4rem;
            line-height: 0.4rem;
            display: inline-block;
            padding-right: 0.1rem;
            background-color: rgba(44, 3, 3, 0.7);
            span{
                cursor: pointer;
            }
        }
        .crumb-right{
            float: right;
            div{
                display: inline-block;
                /*background-color: red;*/
                width: 0.2rem;
                height: 0.2rem;
                &:nth-child(1){
                    margin-right: 0.1rem;
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
</style>