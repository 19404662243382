<template>
  <!-- 历时分析 -->
  <div class="overview">
    <!-- 左侧的柱状图区域以及头部的标签 -->
    <div class="overview-center">
      <!-- 头部标签部分 -->
      <crumb></crumb>
      <div class="qgtitle-right">
        <div class="chooseYears" @click.prevent.stop="chooseYears">
          <span class="aloof">选择年份</span>
          <img
            class="xtb"
            :class="life2 ? 'transform' : ''"
            src="~@/assets/img/xiala1.png"
            alt=""
          />
        </div>
        <div class="life" @click.prevent.stop="showUp">
          <span class="el-dropdown-link">
            <span class="aloof">{{ changeTitle }}</span>
            <img
              class="xtb"
              :class="life ? 'transform' : ''"
              src="~@/assets/img/xiala1.png"
              alt=""
            />
            <div class="up" v-show="openUp">
              <!-- 弹窗 -->
              <ul>
                <li
                  v-for="item in humanTitle"
                  :key="item"
                  @click.prevent.stop="upTitle(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </span>
        </div>
      </div>
      <ChooseYears
        v-show="clickChooseYears"
        @btn-click="ShutChooseYears"
        ref="regDialog"
      />
      <!-- 底部柱状图部分 -->
      <div class="background">
        <Histogram :age="age" ref="tab" />
      </div>
    </div>
    <!-- 右侧的雷达图部分以及结论 -->
    <div class="overview-right">
      <div class="overview-right-top">
        <div class="ranking">
          <div class="fenbu">
            <!-- 右上角分析器标题 -->
            <h3 class="fenbu-title">{{ changeTitle }}分析说明</h3>
          </div>
          <div class="lookchart">
            <!-- 右上角的内容 -->
            <div id="lookpie">{{ rightData.description }}</div>
          </div>
        </div>
      </div>
      <div class="overview-right-bottom">
        <div class="fenbu">
          <!-- 右下角分析器标题 -->
          <h3 class="fenbu-title">{{ rightData.questionTitle }}</h3>
        </div>
        <!-- 右下角雷达图组件 -->
        <Radar :radarData="radarData" ref="tab2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  queryTargetValuesForGraph,
  targetQuestionDetailForGraph,
  queryWithCountry,
  queryWithProvince,
  queryWithUniversity,
  Secondlist,
} from "../../api/modular/analyse/analyse";
import Histogram from "../../components/Histogram.vue";
import Radar from "../../components/Radar.vue";
import crumb from "@/components/crumb";
import ChooseYears from "@/components/ChooseYears";
export default {
  components: {
    Histogram,
    Radar,
    crumb,
    ChooseYears,
  },
  data() {
    return {
      // 柱状图的数据
      age: {
        name: "全国",
        data: [],
        provinceName: "",
        provinceData: [],
        schoolName: "",
        schoolData: [],
        Xname: "", //这个可以不用传入
        Xdata: ["2019", "2020", "2021"],
      },
      // 雷达图的数据
      radarData: {
        name: "全国",
        data: [20, 20, 20],
        provinceName: "湖北省",
        provinceData: [30, 30, 30],
        schoolName: "武汉大学",
        schoolData: [50, 50, 50],
        Xname: "", //这个可以不用传入
        Xdata: ["自由", "民主", "和谐"],
      },
      // 右侧三个的对象
      rightData: {
        // 右侧头部标题
        columnLabel: "您是否拥有人生目标？",
        // 右侧头部内容
        description:
          "人生目标是人在相当长的一段时间、甚至是终其一生需要达成的状态，是对自己理想的实现，也可以称之为理想目标。",
        // 右侧下面标题
        questionTitle: "您是否拥有人生目标？",
      },
      openUp: false,
      // 年份弹窗
      clickChooseYears: true,
      humanTitle: ["人生态度", "人生目标", "人生价值"],
      changeTitle: "人生态度",
      years: ["2019", "2020", "2021"],
      life: true,
      life2: true,
    };
  },
  computed: {
    ...mapGetters(["getCity", "getSchool", "getTargetLevel1"]),
  },
  created() {},
  mounted() {
    if (this.getCity) {
      // this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      this.targetQuestionDetailForGraph(this.humanTitle[0], this.getCity);
    } else {
      // this.queryTargetValuesForGraph();
      this.targetQuestionDetailForGraph(this.humanTitle[0]);
    }
    this.changeAge();
    // 进来刷新二级指标的弹窗ss
    this.Secondlist(this.getTargetLevel1);
  },
  methods: {
    // 将省份添加后缀的函数
    changeProvince(provinceName) {
      let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
      //处理直辖市的数据
      let central = ["北京", "天津", "上海", "重庆"];
      let city = provinceName;
      if (autonomousRegion.indexOf(city) !== -1) {
        city = city == "新疆" ? "新疆维吾尔自治区" : city;
        city = city == "宁夏" ? "宁夏回族自治区" : city;
        city = city == "广西" ? "广西壮族自治区" : city;
        city = city == "西藏" ? "西藏自治区" : city;
        city = city == "内蒙古" ? "内蒙古自治区" : city;
      } else if (central.indexOf(city) !== -1) {
        city = city + "市";
      } else {
        city = city + "省";
      }
      provinceName = city;
      return provinceName;
    },
    // 查询雷达图以及右侧内容的数据，并且赋值
    async targetQuestionDetailForGraph(targetName, provinceName, schoolName) {
      // 雷达图的读取
      let lookpieOption = this.$echarts.init(this.$refs.tab2.$refs.headertab);
      lookpieOption.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1)",
      });
      let columnEchart = this.$echarts.init(this.$refs.tab.$refs.headertab);
      columnEchart.showLoading({
        text: "数据加载中...",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(0, 0, 0, 0.1)",
      });
      if (provinceName) {
        provinceName = this.changeProvince(provinceName);
      }
      let str = await targetQuestionDetailForGraph({
        year: 2020,
        themeName: "思想政治状况",
        targetName,
        province: provinceName || "",
        universityName: schoolName || "",
      });
      // console.log("雷达图的数据", str);
      let countrydata = {
        name: "全国",
        data: str.data.data.country ? str.data.data.country.values : [],
        provinceName: provinceName || "",
        provinceData: str.data.data.province
          ? str.data.data.province.values
          : [],
        schoolName: schoolName || "",
        schoolData: str.data.data.university
          ? str.data.data.university.values
          : [],
        Xname: "x轴", //这个可以不用传入
        Xdata: str.data.data.country.cols,
      };
      this.rightData = {
        // 右侧头部标题
        columnLabel: str.data.columnLabel,
        // 右侧头部内容
        description: str.data.description,
        // 右侧下面标题
        questionTitle: str.data.questionTitle,
      };
      this.radarData = countrydata;
    },
    // 全国分数的函数
    async queryWithCountry() {
      let _this = this;
      let years = `${_this.years}`;
      let str = await queryWithCountry({
        themeName: "思想政治状况",
        targetName: _this.changeTitle,
        years,
      });
      // console.log("全国的分数", str);
      let val = [];
      str.data.country.map((item) => {
        val.push(item.val);
      });
      return val;
    },
    // 全省分数的函数
    async queryWithProvince() {
      let _this = this;
      if (_this.getCity) {
        let autonomousRegion = ["内蒙古", "广西", "西藏", "宁夏", "新疆"];
        //处理直辖市的数据
        let central = ["北京", "天津", "上海", "重庆"];
        let city = _this.getCity;
        if (autonomousRegion.indexOf(city) !== -1) {
          city = city == "新疆" ? "新疆维吾尔自治区" : city;
          city = city == "宁夏" ? "宁夏回族自治区" : city;
          city = city == "广西" ? "广西壮族自治区" : city;
          city = city == "西藏" ? "西藏自治区" : city;
          city = city == "内蒙古" ? "内蒙古自治区" : city;
        } else if (central.indexOf(city) !== -1) {
          city = city + "市";
        } else {
          city = city + "省";
        }
        let province = city;
        let years = `${_this.years}`;
        let str = await queryWithProvince({
          themeName: "思想政治状况",
          targetName: _this.changeTitle,
          province: province,
          years,
        });
        // console.log("全省的分数", str);
        let val = [];
        str.data.province.map((item) => {
          val.push(item.val);
        });
        return val;
      } else {
        return;
      }
    },
    // 全校分数的函数
    async queryWithUniversity() {
      let _this = this;
      if (_this.getSchool) {
        let years = `${_this.years}`;
        let str = await queryWithUniversity({
          themeName: "思想政治状况",
          targetName: _this.changeTitle,
          universityName: _this.getSchool,
          years,
        });
        // console.log("全校的分数", str);
        let val = [];
        str.data.university.map((item) => {
          val.push(item.val);
        });
        return val;
      } else {
        return;
      }
    },
    // 改变柱状图数据的函数
    async changeAge() {
      let data = await this.queryWithCountry();
      if (!this.getSchool && !this.getCity) {
        let newAge = {
          name: "全国",
          data: data,
          Xname: "", //这个可以不用传入
          Xdata: this.years,
        };
        this.age = newAge;
      }
      if (this.getSchool) {
        let provinceData = await this.queryWithProvince();
        let schoolData = await this.queryWithUniversity();
        let newAge = {
          name: "全国",
          data: data,
          provinceName: this.getCity,
          provinceData: provinceData,
          schoolName: this.getSchool,
          schoolData: schoolData,
          Xname: "", //这个可以不用传入
          Xdata: this.years,
        };
        this.age = newAge;
      }
      if (this.getCity && !this.getSchool) {
        let provinceData = await this.queryWithProvince();
        let newAge = {
          name: "全国",
          data: data,
          provinceName: this.getCity,
          provinceData: provinceData,
          Xname: "", //这个可以不用传入
          Xdata: this.years,
        };
        this.age = newAge;
      }
      // console.log(newAge, "输出的data值");
    },
    // 子传父函数，点击中间柱状图更新右侧内容
    changeRadar(item) {
      // console.log("item");
      this.targetQuestionDetailForGraph(
        item,
        this.getCity || "",
        this.getSchool || ""
      );
    },
    // 关闭二级指标的选择框
    upTitle(item) {
      this.life = true;
      this.openUp = false;
      this.changeTitle = item;
      if (this.getCity) {
        // this.queryTargetValuesForGraph(this.getCity, this.getSchool);
        this.targetQuestionDetailForGraph(item, this.getCity);
      } else {
        // this.queryTargetValuesForGraph();
        this.targetQuestionDetailForGraph(item);
      }
      this.changeAge();
    },
    // 打开二级指标的选择框
    showUp() {
      this.openUp = !this.openUp;
      this.life = !this.life;
    },
    // 打开年份的弹窗
    chooseYears() {
      // this.clickChooseYears = !this.clickChooseYears;
      this.$refs.regDialog.showRegDialog();
    },
    // 年份弹窗子传父函数
    async ShutChooseYears(item) {
      // this.clickChooseYears = false;
      console.log(item);
      console.log(this.age);
      // this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      // this.age.Xdata = item;
      this.years = item;
      this.changeAge();
    },
    // 输入一级指标，return二级指标
    async Secondlist(item) {
      // console.log(111);
      let str = await Secondlist({
        targetName: item,
      });
      // console.log(str,"输入一级指标");
      this.humanTitle = [];
      str.data.list.map((item) => {
        this.humanTitle.push(item.name);
      });
      this.changeTitle = this.humanTitle[0];
      // console.log("输入一级指标获取的二级指标", this.humanTitle);
    },
  },
  watch: {
    getSchool() {
      // 监听后更新柱状图
      // this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      this.changeAge();
      // 监听后更新雷达图和右侧内容
      this.targetQuestionDetailForGraph(
        this.changeTitle,
        this.getCity,
        this.getSchool
      );
    },
    getCity() {
      // 监听后更新柱状图
      // this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      this.changeAge();
      // 监听后更新雷达图和右侧内容
      this.targetQuestionDetailForGraph(
        this.changeTitle,
        this.getCity,
        this.getSchool
      );
    },
    // 监听 一级指标
    getTargetLevel1(p) {
      // 监听后更新柱状图
      // this.queryTargetValuesForGraph(this.getCity, this.getSchool);
      // 监听后更新雷达图和右侧内容
      // console.log("一级指标",p);
      // 改变二级指标
      this.Secondlist(p);
      // 改变雷达图
      this.targetQuestionDetailForGraph(
        this.changeTitle,
        this.getCity,
        this.getSchool
      );
      // 改变柱状图
      this.changeAge();
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  height: 100%;
  width: 100%;
  display: flex;
  //   margin-top: 0.25rem;
  .overview-center {
    height: 84vh;
    flex: 4;
    margin-right: 0.2rem;
    position: relative;
    background: url(@/assets/img/center.png) no-repeat bottom;
    background-size: cover;
    .chooseYears {
      margin-right: 0.2rem;
    }
    .overview-center-title {
      height: 0.4rem;
      margin-bottom: 1rem;
      line-height: 0.4rem;
      border: 1px solid #470201;
      border-right: none;
      border-left: none;
      display: flex;
      justify-content: space-between;
    }
    .qgtitle-right {
      position: absolute;
      display: flex;
      cursor: pointer;
      right: 0.7rem;
      top: 0;
      line-height: 0.5rem;
      .aloof {
        margin-right: 00.1rem;
        font-size: 14px;
      }
      .xtb {
        transform: rotate(180deg);
        transition: 0.5s;
      }
      .transform {
        transform: rotate(0deg);
        transition: 0.5s;
      }
    }
    .up {
      width: 80px;
      background: rgba(0, 0, 0, 0.84);
      border: 1px solid #680911;
      z-index: 999;
      position: absolute;
      // left: 0.1rem;
      ul {
        display: flex;
        flex-direction: column;
        li {
          height: 30px;
          width: 100%;
          line-height: 30px;
          text-align: center;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #fbe9e9;
          cursor: pointer;
          &:hover {
            background-color: #680911;
          }
        }
      }
    }
  }
  .overview-right {
    height: 100%;
    flex: 2;
    display: flex;
    flex-direction: column;
    .fenbu {
      height: 0.45rem;
      border-radius: 0.05rem 0rem 0rem 0rem;
      .fenbu-title {
        background-image: linear-gradient(
          to right,
          rgba(44, 3, 3, 0.7),
          rgba(44, 3, 3, 0)
        );

        color: #fff;
        height: 0.45rem;
        line-height: 0.45rem;
        padding: 0 0 0.1rem 0.17rem;
        position: relative;
        font-family: "微软雅黑";
        font-size: 14px;
        &:after {
          content: "";
          width: 0.05rem;
          height: 0.25rem;
          background-color: #a40000;
          position: absolute;
          top: 0.1rem;
          left: 0;
          margin: auto 0;
        }
      }
    }
    .overview-right-top {
      // height: 4rem;
      flex: 0 0 5rem;
      #lookpie {
        width: 5.2rem;
        height: 100%;
        padding: 0.2rem 0 0 0.2rem;
        font-size: 0.2rem;
        line-height: 0.35rem;
        color: #74c6f5;
      }
      .lookchart {
        width: 6rem;
        background: rgba(17, 18, 22, 0.5);
        .looktitle {
          font-size: 0.1875rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #cccccc;
          position: relative;
          padding-left: 0.175rem;
          margin-left: 0.1875rem;
        }

        .looktitle::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.05rem;
          height: 0.225rem;
          background: #0079fe;
        }
      }
    }
    .overview-right-bottom {
      flex: 1;
    }
  }
}
</style>
