<template>
    <div>
        <el-dialog
                :visible.sync="dialogVisible"
                width="50%"
                :show-close='true'
                center
        >
            <!--<el-cascader
                    clearable
                    placeholder="请选则因变量"
                    :props="variableNumProps"
                    :show-all-levels="false"
                    :collapse-tags="true"
                    ref="variableNum"
                    expandTrigger="hover"
            ></el-cascader>
            <el-cascader
                    clearable
                    placeholder="请选择自变量"
                    :props="selfNumProps"
                    :show-all-levels="false"
                    :collapse-tags="true"
                    ref="selfNum"
                    expandTrigger="hover"
            ></el-cascader>-->
            <el-row>
                <el-col :span="12">
                    <div>因变量:</div>
                    <el-cascader
                            :options="variableNumTargetList"
                            v-model="variableNumValue"
                            ref="variableNum"
                            placeholder="请选择因变量"
                            :show-all-levels="false"
                            clearable></el-cascader>
                </el-col>
                <el-col :span="12">
                    <div>自变量:</div>
                    <el-cascader
                            :options="selfNumTargetList"
                            v-model="selfNumValue"
                            ref="selfNum"
                            placeholder="请选择自变量"
                            :show-all-levels="false"
                            :props="{multiple: true}"
                            :disabled="this.isDisabled"
                            clearable></el-cascader>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitBtn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {queryTargetByThemeName,queryOneTwoTarget}from '../api/api'
    export default {
        name: "RegDialog",
        data(){
            return {
                /*所有1级指标*/
                variableNumValue : '',
                selfNumValue : '',
                targetList : [],
                variableNumTargetList : [],
                selfNumTargetList : [],
                variableNum : '',
                selfNum : [],
                isDisabled :false,
                year : 2020,
                themeName : '思想政治状况',
                dialogVisible: false,
            }
        },
        methods:{
            /*显示弹窗方法*/
            showRegDialog(){
                this.dialogVisible = true
            },
            async submitBtn(){
                if (this.$refs.variableNum.getCheckedNodes()[0]){
                    this.variableNum = this.$refs.variableNum.getCheckedNodes()[0].data.label
                }
                if (this.$refs.selfNum.getCheckedNodes()[0]){
                    if (this.$refs.selfNum.getCheckedNodes()[0].children.length){
                        //全选
                        this.selfNum = this.$refs.selfNum.getCheckedNodes()[0].children.map((item)=>{
                            return item.label
                        })
                    }else {
                         //没有全选
                         this.selfNum = this.$refs.selfNum.getCheckedNodes().map((item)=>{
                             return item.label
                         })
                    }
                }
                try {
                    if (this.variableNum.length == 0){
                        // await this.$message.error('提交失败,请指定因变量')
                        throw new Error('提交失败,请指定因变量')
                    }
                    if (this.selfNum.length == 0){
                        // await this.$message.error('提交失败,请指定自变量')
                        throw new Error('提交失败,请指定自变量')
                    }
                    if (this.selfNum.length > 4){
                        // await this.$message.error('提交失败,请指定自变量最多只能指定4个')
                        throw new Error('提交失败,请指定自变量最多只能指定4个')
                    }
                    await this.$message.success('提交成功')
                    /*成功之后的操作*/
                    this.$emit('getTarget',this.variableNum,this.selfNum)
                }catch (e) {
                    await this.$message.error(e.message)
                }
                this.dialogVisible = false
                this.variableNumValue=''
                this.selfNumValue=''
            },
            /*获取所有一级二级指标*/
            getAllTarget() {
                queryTargetByThemeName({
                    themeName : this.themeName,
                    year: this.year
                }).then((data)=>{
                    if (data.code == 200){
                       return data.data.list.map((item)=>{
                            return {
                                value : item.name,
                                label: item.name,
                            }
                        })
                    }

                }).then(async (list)=>{
                    for (let i = 0; i < list.length;i++){
                        await queryOneTwoTarget({
                            targetName: list[i].label
                        }).then((data) => {
                            if (data.code == 200){
                                let arr = data.data.list.map((item)=>{
                                    let obj = {
                                        value : item.name,
                                        label: item.name,
                                    }
                                   return obj
                                })
                                list[i].children = arr
                            }
                        })
                    }
                    this.targetList = list
                    this.variableNumTargetList = list.splice(0,6)
                    this.selfNumTargetList = list

                })
            }
        },
        created() {
            this.getAllTarget()
        }
    }
</script>

<style lang="scss">
/*
    .el-dialog__body {
        color: #aaaeb5;
    }
    .el-dialog{
        color: #aaaeb5;
        background: #000D22;
        opacity: 0.88;
    }
    .el-input__inner{
        border: none;
        background-color: #036DDA;
        color: #fff;
    }

    .el-cascader__dropdown{
        background-color:#036DDA;
    }
    .el-cascader-node__label:hover{
        background-color: red;
    }
    !*el-input__inner:-moz-placeholder,
    textarea:-moz-placeholder {
        color: #fff;
    }
    el-input__inner:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: #fff;
    }
    el-input__inner::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: #fff ;
    }*!

    .el-input__inner:hover{
        border: none
    }
    .el-input__inner:focus{
        border: none

    }
*/

</style>