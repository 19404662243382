<template>
  <div class="indicatorFilterDialog-template">
    <el-dialog
      :visible="dialogVisible"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <!-- <div class="annotation">
            </div> -->
      <div class="firstRow">
        <div class="h4">
          <span class="h4-first"> <i class="iconfont icon-X_square"></i>因变量: {{ variableNum }}</span>
          <span>注：因变量只能选一个</span>
        </div>
        <span class="description">一级指标</span>
        <div class="level1_Indicators">
          <div
            :class="['indicatorPanel']"
            v-for="(item, index) in variableNumTargetList"
            :key="index + 'first'"
            @click="variablePreNumBtn(index)"
            ref="variablePreActive"
          >
            {{ item.label }}
          </div>
        </div>
        <h4 v-show="this.isShowVariableNumSub" class="towSelf">二级指标</h4>
        <div class="level1_Indicators" v-if="isShowVariableNumSub">
          <div
            :class="['indicatorPanel']"
            v-for="(item, index) in variableNumTargetList[variableNumPreIndex]
              .children"
            :key="index + 'first'"
            @click="variableSubNumBtn(index, item.label)"
            ref="variableSubActive"
          >
            {{ item.label }}
          </div>
        </div>
        <hr
          style="border: 1px dashed #ccc; height: 1px; margin-bottom: 0.2rem"
        />
        <div class="h4">
          <span class="h4-first"
            ><i class="iconfont icon-Y_square"></i>自变量: {{ cause }}</span
          >
          <span>注：自变量最多选四个</span>
        </div>
        <h4 class="description">一级指标</h4>
        <div class="level1_Indicators">
          <div
            :class="['indicatorPanel']"
            v-for="(item, index) in selfNumTargetList"
            :key="index + 'first'"
            @click="selfNumPreBtn(index)"
            ref="selfPreActive"
          >
            {{ item.label }}
          </div>
        </div>
        <div v-for="(preItem, preIndex) in selfNumTargetList" :key="preIndex">
          <div ref="selfNumList" v-show="false">
            <h4 class="towSelf">二级指标</h4>
            <div
              class="level2_Indicators"
              :class="['indicatorPanel']"
              v-for="(item, index) in selfNumTargetList[preIndex].children"
              :key="index + 'first'"
              @click="selfNumSubBtn(item, index, preIndex)"
              ref="selfSubActive"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeWindow">取消</el-button>
        <el-button type="primary" @click="submitBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryOneTwoTarget, queryTargetByThemeName } from "@/api/api";

export default {
  name: "RegressionDialog",
  data() {
    return {
      /*是否显示因变量二级指标*/
      isShowVariableNumSub: false,
      /*是否显示自变量二级指标*/
      isShowSelfNumSub: false,
      /*当前选中的因变量一级质变索引*/
      variableNumPreIndex: 0,
      /*当前选中的因变量二级质变索引*/
      variableNumSubIndex: 0,

      /*当前选中的自变量一级指标索引*/
      selfNumPreIndex: 0,

      /*所有1级指标*/
      variableNumValue: "",
      selfNumValue: "",
      targetList: [],
      variableNumTargetList: [],
      selfNumTargetList: [],
      variableNum: "",
      selfNum: [],
      isDisabled: false,
      year: 2020,
      themeName: "思想政治状况",
      dialogVisible: false,
      cause: "",
    };
  },
  methods: {
    /*因变量一级指标事件*/
    variablePreNumBtn(index) {
      /*显示自变量二级指标*/
      this.isShowVariableNumSub = true;
      this.variableNumPreIndex = index;
      /*添加选中样式*/
      for (let i = 0; i < this.$refs.variablePreActive.length; i++) {
        this.$refs.variablePreActive[i].classList.remove("activePaneClass");
      }
      this.$refs.variablePreActive[index].classList.add("activePaneClass");

      /*清空二级表选中样式*/
      if (this.$refs.variableSubActive) {
        for (let i = 0; i < this.$refs.variableSubActive.length; i++) {
          this.$refs.variableSubActive[i].classList.remove("activePaneClass");
        }
      }
    },
    /*因变量二级指标事件*/
    variableSubNumBtn(index, variableNum) {
      this.variableNum = variableNum;
      /*添加选中样式*/
      for (let i = 0; i < this.$refs.variableSubActive.length; i++) {
        this.$refs.variableSubActive[i].classList.remove("activePaneClass");
      }
      this.$refs.variableSubActive[index].classList.add("activePaneClass");
    },

    /*自变量一级指标事件*/
    selfNumPreBtn(index) {
      /*/!*显示自变量二级指标*!/
                this.isShowSelfNumSub = true
                this.selfNumPreIndex = index
                /!*清空自变量二级指标*!/
                if (this.$refs.selfSubActive){
                    this.selfNum = []
                    for (let i = 0;i < this.$refs.selfSubActive.length;i++){
                        this.$refs.selfSubActive[i].classList.remove('activePaneClass')
                    }
                }*/

      /*添加选中样式*/
      for (let i = 0; i < this.$refs.selfPreActive.length; i++) {
        this.$refs.selfPreActive[i].classList.remove("activePaneClass");
      }
      this.$refs.selfPreActive[index].classList.add("activePaneClass");

      /*显示对应的二级指标列表*/
      for (let i = 0; i < this.$refs.selfNumList.length; i++) {
        this.$refs.selfNumList[i].classList.remove("self-list-active");
      }
      /*console.log(this.$refs.selfNumList[index]);*/
      this.$refs.selfNumList[index].classList.add("self-list-active");
    },
    /*自变量二级指标事件*/
    selfNumSubBtn(selfNum, index, preIndex) {
      /*删除*/
      selfNum = selfNum.label;

      if (this.selfNum.includes(selfNum)) {
        let index = this.selfNum.indexOf(selfNum);
        console.log(index);
        this.selfNum.splice(index, 1);
        this.cause = this.selfNum.toString();
      } else if (this.selfNum.length == 4) {
        this.$message.error("自变量指标最多只能选4个");
        return;
      } else {
        this.selfNum.push(selfNum);
        this.cause = this.selfNum.toString();
      }
      this.$refs.selfNumList[preIndex].childNodes[index + 1].classList.toggle(
        "activePaneClass"
      );
      /*this.$refs.selfSubActive[index].classList.toggle('activePaneClass')*/
    },

    /*关闭弹窗*/
    closeDialog() {
      this.dialogVisible = false;
    },
    /*显示弹窗方法*/
    showRegDialog() {
      this.dialogVisible = true;
    },
    /*async submitBtn(){
                if (this.$refs.variableNum.getCheckedNodes()[0]){
                    this.variableNum = this.$refs.variableNum.getCheckedNodes()[0].data.label
                }
                if (this.$refs.selfNum.getCheckedNodes()[0]){
                    if (this.$refs.selfNum.getCheckedNodes()[0].children.length){
                        //全选
                        this.selfNum = this.$refs.selfNum.getCheckedNodes()[0].children.map((item)=>{
                            return item.label
                        })
                    }else {
                        //没有全选
                        this.selfNum = this.$refs.selfNum.getCheckedNodes().map((item)=>{
                            return item.label
                        })
                    }
                }
                try {
                    if (this.variableNum.length == 0){
                        // await this.$message.error('提交失败,请指定因变量')
                        throw new Error('提交失败,请指定因变量')
                    }
                    if (this.selfNum.length == 0){
                        // await this.$message.error('提交失败,请指定自变量')
                        throw new Error('提交失败,请指定自变量')
                    }
                    if (this.selfNum.length > 4){
                        // await this.$message.error('提交失败,请指定自变量最多只能指定4个')
                        throw new Error('提交失败,请指定自变量最多只能指定4个')
                    }
                    await this.$message.success('提交成功')
                    /!*成功之后的操作*!/
                    this.$emit('getTarget',this.variableNum,this.selfNum)
                }catch (e) {
                    await this.$message.error(e.message)
                }
                this.dialogVisible = false
                this.variableNumValue=''
                this.selfNumValue=''
            },*/
    async submitBtn() {
      try {
        if (!this.variableNum) {
          throw new Error("请选择因变量");
        }
        if (this.selfNum.length == 0) {
          throw new Error("请选择自变量");
        }
        await this.$message.success("提交成功");
        this.$emit("getTarget", this.variableNum, this.selfNum);
        // debugger;
        this.dialogVisible = false;
      } catch (e) {
        await this.$message.error(e.message);
      }
    },
    closeWindow() {
      // this.variableNum = "";
      // this.selfNum = [];
      // this.cause = "";
      this.dialogVisible = false;
      // for (let i = 0; i < this.$refs.selfPreActive.length; i++) {
      //   this.$refs.selfPreActive[i].classList.remove("activePaneClass");
      // }
      // for (let i = 0; i < this.$refs.variableSubActive.length; i++) {
      //   this.$refs.variableSubActive[i].classList.remove("activePaneClass");
      // }
      // for (let i = 0; i < this.$refs.selfNumList.length; i++) {
      //   this.$refs.selfNumList[i].classList.remove("self-list-active");
      // }
      // for (let i = 0; i < this.$refs.variablePreActive.length; i++) {
      //   this.$refs.variablePreActive[i].classList.remove("activePaneClass");
      // }
    },
    /*获取所有一级二级指标*/
    getAllTarget() {
      queryTargetByThemeName({
        themeName: this.themeName,
        year: this.year,
      })
        .then((data) => {
          if (data.code == 200) {
            return data.data.list.map((item) => {
              return {
                value: item.name,
                label: item.name,
              };
            });
          }
        })
        .then(async (list) => {
          for (let i = 0; i < list.length; i++) {
            await queryOneTwoTarget({
              targetName: list[i].label,
            }).then((data) => {
              if (data.code == 200) {
                let arr = data.data.list.map((item) => {
                  let obj = {
                    value: item.name,
                    label: item.name,
                  };
                  return obj;
                });
                list[i].children = arr;
              }
            });
          }
          this.targetList = list;
          this.variableNumTargetList = list.splice(0, 6);
          this.selfNumTargetList = list;
          console.log(this.selfNumTargetList);
        });
    },
  },
  created() {
    this.getAllTarget();
  },
};
</script>

<style lang="scss">
// color: #489ef7;
.indicatorFilterDialog-template {
  // transform: translateX(-2px);
  .el-dialog {
    background-color: rgba(1, 14, 33, 0.8);
    border: 1px solid #013b9b;
    border-radius: 20px;
    transform: translateX(-0.13rem);
    .annotation {
      padding-right: 20px;
      text-align: end;
      color: #4d73b5;
    }
    .firstRow,
    .secondRow {
      /*padding-left: 20px;*/
      // .iconfont{
      //   color: #fff;
      // }
      h3 {
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .h4 {
        font-size: 14px;
        margin-bottom: 20px;
        color: #489ef7;
        display: flex;
        justify-content: space-between;
        .h4-first {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .description {
        color: #489ef7;
      }
      .towSelf {
        // color: #489ef7;
        color: rgba(72, 158, 247, 0.7);
      }
      .level1_Indicators {
        margin-top: 0.1rem;
      }
      .level2_Indicators {
        margin-top: 0.1rem;
      }
    }
    .secondRow {
      margin: 10px 0px;
    }
    .dialog-footer {
      text-align: center;
      .el-button {
        background-color: #002258;
        border: none;
      }
      .el-button:hover {
        opacity: 0.8;
      }
    }
  }
  .indicatorPanel {
    margin: 0px 30px 10px 0px;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 4px;
    min-width: 0.5rem !important;
    color: #ffffff;
    text-align: center;
    background-color: #022256;
    // background-color: #909399;
    cursor: pointer;
  }
  .activePaneClass {
    background-color: #036dda;
  }
  .self-list-active {
    display: inline-block !important;
  }
  // h4 {
  //   // color: #fff !important;
  //   color: #489ef7;
  //   margin-bottom: 10px;
  // }
}
</style>